import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
  ]);
  
  const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil agressif": "100",
                "OPC ACTIONS": "100"
            },
            {
                "Date": "1/4/16",
                "Profil agressif": "100.1073853",
                "OPC ACTIONS": "100.0482663"
            },
            {
                "Date": "1/8/16",
                "Profil agressif": "99.82034649",
                "OPC ACTIONS": "99.72023137"
            },
            {
                "Date": "1/15/16",
                "Profil agressif": "99.41859524",
                "OPC ACTIONS": "99.98299059"
            },
            {
                "Date": "1/22/16",
                "Profil agressif": "99.72587284",
                "OPC ACTIONS": "100.1055181"
            },
            {
                "Date": "1/29/16",
                "Profil agressif": "100.2902399",
                "OPC ACTIONS": "100.4236168"
            },
            {
                "Date": "2/5/16",
                "Profil agressif": "101.1781322",
                "OPC ACTIONS": "100.999925"
            },
            {
                "Date": "2/12/16",
                "Profil agressif": "101.9166119",
                "OPC ACTIONS": "101.550295"
            },
            {
                "Date": "2/19/16",
                "Profil agressif": "103.7022032",
                "OPC ACTIONS": "102.66245"
            },
            {
                "Date": "2/26/16",
                "Profil agressif": "102.0171507",
                "OPC ACTIONS": "101.1243863"
            },
            {
                "Date": "3/4/16",
                "Profil agressif": "103.2237212",
                "OPC ACTIONS": "102.2932641"
            },
            {
                "Date": "3/11/16",
                "Profil agressif": "103.8600106",
                "OPC ACTIONS": "103.1719935"
            },
            {
                "Date": "3/18/16",
                "Profil agressif": "104.7460034",
                "OPC ACTIONS": "104.5341952"
            },
            {
                "Date": "3/25/16",
                "Profil agressif": "106.131811",
                "OPC ACTIONS": "105.7175124"
            },
            {
                "Date": "4/1/16",
                "Profil agressif": "104.4133599",
                "OPC ACTIONS": "103.9307092"
            },
            {
                "Date": "4/8/16",
                "Profil agressif": "104.8272504",
                "OPC ACTIONS": "104.1590793"
            },
            {
                "Date": "4/15/16",
                "Profil agressif": "106.7327521",
                "OPC ACTIONS": "105.4945024"
            },
            {
                "Date": "4/22/16",
                "Profil agressif": "106.5290238",
                "OPC ACTIONS": "105.4188194"
            },
            {
                "Date": "4/29/16",
                "Profil agressif": "109.5805168",
                "OPC ACTIONS": "108.8299624"
            },
            {
                "Date": "5/6/16",
                "Profil agressif": "111.7396671",
                "OPC ACTIONS": "110.500386"
            },
            {
                "Date": "5/13/16",
                "Profil agressif": "112.0670979",
                "OPC ACTIONS": "110.3476095"
            },
            {
                "Date": "5/20/16",
                "Profil agressif": "110.523051",
                "OPC ACTIONS": "109.2335413"
            },
            {
                "Date": "5/27/16",
                "Profil agressif": "109.6964497",
                "OPC ACTIONS": "108.5868143"
            },
            {
                "Date": "6/3/16",
                "Profil agressif": "110.8548478",
                "OPC ACTIONS": "109.0253717"
            },
            {
                "Date": "6/10/16",
                "Profil agressif": "110.4740881",
                "OPC ACTIONS": "108.7384587"
            },
            {
                "Date": "6/17/16",
                "Profil agressif": "110.0070453",
                "OPC ACTIONS": "107.8322003"
            },
            {
                "Date": "6/24/16",
                "Profil agressif": "110.5857127",
                "OPC ACTIONS": "108.0140234"
            },
            {
                "Date": "7/1/16",
                "Profil agressif": "110.8645094",
                "OPC ACTIONS": "108.3008184"
            },
            {
                "Date": "7/11/16",
                "Profil agressif": "111.5244114",
                "OPC ACTIONS": "108.7092862"
            },
            {
                "Date": "7/15/16",
                "Profil agressif": "113.2223156",
                "OPC ACTIONS": "109.9367776"
            },
            {
                "Date": "7/22/16",
                "Profil agressif": "114.2895442",
                "OPC ACTIONS": "110.3643706"
            },
            {
                "Date": "7/29/16",
                "Profil agressif": "115.0277746",
                "OPC ACTIONS": "110.7878182"
            },
            {
                "Date": "8/5/16",
                "Profil agressif": "115.3530558",
                "OPC ACTIONS": "111.524541"
            },
            {
                "Date": "8/12/16",
                "Profil agressif": "115.2407105",
                "OPC ACTIONS": "111.6561556"
            },
            {
                "Date": "8/19/16",
                "Profil agressif": "115.2800387",
                "OPC ACTIONS": "111.4003486"
            },
            {
                "Date": "8/26/16",
                "Profil agressif": "115.1688361",
                "OPC ACTIONS": "111.3014578"
            },
            {
                "Date": "9/2/16",
                "Profil agressif": "115.1867235",
                "OPC ACTIONS": "111.2647737"
            },
            {
                "Date": "9/9/16",
                "Profil agressif": "115.3847264",
                "OPC ACTIONS": "111.4958254"
            },
            {
                "Date": "9/16/16",
                "Profil agressif": "115.1493347",
                "OPC ACTIONS": "111.2915439"
            },
            {
                "Date": "9/23/16",
                "Profil agressif": "115.6230042",
                "OPC ACTIONS": "111.4429992"
            },
            {
                "Date": "9/30/16",
                "Profil agressif": "116.7135951",
                "OPC ACTIONS": "112.1458605"
            },
            {
                "Date": "10/7/16",
                "Profil agressif": "117.344103",
                "OPC ACTIONS": "113.9075673"
            },
            {
                "Date": "10/14/16",
                "Profil agressif": "118.1311519",
                "OPC ACTIONS": "114.481181"
            },
            {
                "Date": "10/21/16",
                "Profil agressif": "119.5436636",
                "OPC ACTIONS": "115.375029"
            },
            {
                "Date": "10/28/16",
                "Profil agressif": "120.3739725",
                "OPC ACTIONS": "115.8621581"
            },
            {
                "Date": "11/4/16",
                "Profil agressif": "121.0203717",
                "OPC ACTIONS": "116.3350574"
            },
            {
                "Date": "11/11/16",
                "Profil agressif": "121.8927198",
                "OPC ACTIONS": "117.1343643"
            },
            {
                "Date": "11/21/16",
                "Profil agressif": "123.0544029",
                "OPC ACTIONS": "118.0561901"
            },
            {
                "Date": "11/25/16",
                "Profil agressif": "122.5451031",
                "OPC ACTIONS": "117.901823"
            },
            {
                "Date": "12/2/16",
                "Profil agressif": "123.5924716",
                "OPC ACTIONS": "118.5386673"
            },
            {
                "Date": "12/9/16",
                "Profil agressif": "126.3637763",
                "OPC ACTIONS": "121.3880252"
            },
            {
                "Date": "12/16/16",
                "Profil agressif": "129.4666768",
                "OPC ACTIONS": "124.8290385"
            },
            {
                "Date": "12/23/16",
                "Profil agressif": "136.0860207",
                "OPC ACTIONS": "130.4604942"
            },
            {
                "Date": "12/30/16",
                "Profil agressif": "133.7966036",
                "OPC ACTIONS": "127.5582239"
            },
            {
                "Date": "1/6/17",
                "Profil agressif": "143.945249",
                "OPC ACTIONS": "136.6002392"
            },
            {
                "Date": "1/13/17",
                "Profil agressif": "144.7417124",
                "OPC ACTIONS": "137.7863385"
            },
            {
                "Date": "1/20/17",
                "Profil agressif": "143.7272328",
                "OPC ACTIONS": "136.2766324"
            },
            {
                "Date": "1/27/17",
                "Profil agressif": "143.3784633",
                "OPC ACTIONS": "135.6787889"
            },
            {
                "Date": "2/3/17",
                "Profil agressif": "141.3609536",
                "OPC ACTIONS": "132.9544782"
            },
            {
                "Date": "2/10/17",
                "Profil agressif": "141.8160538",
                "OPC ACTIONS": "133.3752795"
            },
            {
                "Date": "2/17/17",
                "Profil agressif": "141.1858771",
                "OPC ACTIONS": "132.4613973"
            },
            {
                "Date": "2/24/17",
                "Profil agressif": "137.5716914",
                "OPC ACTIONS": "129.1901312"
            },
            {
                "Date": "3/3/17",
                "Profil agressif": "138.9387693",
                "OPC ACTIONS": "130.3901087"
            },
            {
                "Date": "3/10/17",
                "Profil agressif": "138.9369691",
                "OPC ACTIONS": "130.3649117"
            },
            {
                "Date": "3/17/17",
                "Profil agressif": "139.8981885",
                "OPC ACTIONS": "131.2604535"
            },
            {
                "Date": "3/24/17",
                "Profil agressif": "135.9567421",
                "OPC ACTIONS": "127.7385907"
            },
            {
                "Date": "3/31/17",
                "Profil agressif": "135.1782163",
                "OPC ACTIONS": "126.7503631"
            },
            {
                "Date": "4/7/17",
                "Profil agressif": "134.6091921",
                "OPC ACTIONS": "126.4222248"
            },
            {
                "Date": "4/14/17",
                "Profil agressif": "134.0903332",
                "OPC ACTIONS": "125.5552373"
            },
            {
                "Date": "4/21/17",
                "Profil agressif": "135.3235929",
                "OPC ACTIONS": "126.6566074"
            },
            {
                "Date": "4/28/17",
                "Profil agressif": "137.7905703",
                "OPC ACTIONS": "129.4239482"
            },
            {
                "Date": "5/5/17",
                "Profil agressif": "138.4568683",
                "OPC ACTIONS": "130.5491288"
            },
            {
                "Date": "5/12/17",
                "Profil agressif": "138.878349",
                "OPC ACTIONS": "130.937334"
            },
            {
                "Date": "5/19/17",
                "Profil agressif": "138.6901461",
                "OPC ACTIONS": "131.0303692"
            },
            {
                "Date": "5/26/17",
                "Profil agressif": "139.0171469",
                "OPC ACTIONS": "130.8666691"
            },
            {
                "Date": "6/2/17",
                "Profil agressif": "139.5358287",
                "OPC ACTIONS": "131.4905679"
            },
            {
                "Date": "6/9/17",
                "Profil agressif": "140.5030965",
                "OPC ACTIONS": "132.623097"
            },
            {
                "Date": "6/16/17",
                "Profil agressif": "141.0785972",
                "OPC ACTIONS": "133.2549482"
            },
            {
                "Date": "6/23/17",
                "Profil agressif": "143.5040762",
                "OPC ACTIONS": "135.585572"
            },
            {
                "Date": "6/30/17",
                "Profil agressif": "144.14775",
                "OPC ACTIONS": "136.7652583"
            },
            {
                "Date": "7/7/17",
                "Profil agressif": "147.9179238",
                "OPC ACTIONS": "139.9795872"
            },
            {
                "Date": "7/14/17",
                "Profil agressif": "147.8180582",
                "OPC ACTIONS": "139.4043244"
            },
            {
                "Date": "7/21/17",
                "Profil agressif": "145.9108091",
                "OPC ACTIONS": "137.3771046"
            },
            {
                "Date": "7/28/17",
                "Profil agressif": "148.1423994",
                "OPC ACTIONS": "139.2957199"
            },
            {
                "Date": "8/4/17",
                "Profil agressif": "146.7394223",
                "OPC ACTIONS": "137.7718679"
            },
            {
                "Date": "8/11/17",
                "Profil agressif": "148.6894466",
                "OPC ACTIONS": "139.0957955"
            },
            {
                "Date": "8/18/17",
                "Profil agressif": "148.7195234",
                "OPC ACTIONS": "139.249816"
            },
            {
                "Date": "8/25/17",
                "Profil agressif": "148.2376424",
                "OPC ACTIONS": "138.8602648"
            },
            {
                "Date": "9/4/17",
                "Profil agressif": "150.2597218",
                "OPC ACTIONS": "140.6911733"
            },
            {
                "Date": "9/8/17",
                "Profil agressif": "152.2596784",
                "OPC ACTIONS": "142.0715856"
            },
            {
                "Date": "9/15/17",
                "Profil agressif": "153.2753626",
                "OPC ACTIONS": "143.5558347"
            },
            {
                "Date": "9/25/17",
                "Profil agressif": "150.4914039",
                "OPC ACTIONS": "141.6825796"
            },
            {
                "Date": "9/29/17",
                "Profil agressif": "148.4020925",
                "OPC ACTIONS": "139.1973556"
            },
            {
                "Date": "10/6/17",
                "Profil agressif": "150.7366764",
                "OPC ACTIONS": "140.9466681"
            },
            {
                "Date": "10/13/17",
                "Profil agressif": "152.1487569",
                "OPC ACTIONS": "141.7957896"
            },
            {
                "Date": "10/20/17",
                "Profil agressif": "150.2350614",
                "OPC ACTIONS": "139.753634"
            },
            {
                "Date": "10/27/17",
                "Profil agressif": "151.2850464",
                "OPC ACTIONS": "140.8329151"
            },
            {
                "Date": "11/3/17",
                "Profil agressif": "151.8123421",
                "OPC ACTIONS": "141.1203326"
            },
            {
                "Date": "11/10/17",
                "Profil agressif": "153.7368896",
                "OPC ACTIONS": "142.5106866"
            },
            {
                "Date": "11/17/17",
                "Profil agressif": "153.5169301",
                "OPC ACTIONS": "142.1240266"
            },
            {
                "Date": "11/24/17",
                "Profil agressif": "153.084322",
                "OPC ACTIONS": "141.64819"
            },
            {
                "Date": "12/4/17",
                "Profil agressif": "151.7395588",
                "OPC ACTIONS": "140.9404481"
            },
            {
                "Date": "12/8/17",
                "Profil agressif": "151.096979",
                "OPC ACTIONS": "140.1380624"
            },
            {
                "Date": "12/15/17",
                "Profil agressif": "150.880514",
                "OPC ACTIONS": "139.854392"
            },
            {
                "Date": "12/22/17",
                "Profil agressif": "148.9047687",
                "OPC ACTIONS": "138.1549744"
            },
            {
                "Date": "12/29/17",
                "Profil agressif": "150.9410185",
                "OPC ACTIONS": "140.2585867"
            },
            {
                "Date": "1/5/18",
                "Profil agressif": "152.4838406",
                "OPC ACTIONS": "141.7458702"
            },
            {
                "Date": "1/12/18",
                "Profil agressif": "154.008867",
                "OPC ACTIONS": "142.9471055"
            },
            {
                "Date": "1/19/18",
                "Profil agressif": "156.6937425",
                "OPC ACTIONS": "145.6722026"
            },
            {
                "Date": "1/26/18",
                "Profil agressif": "157.6020091",
                "OPC ACTIONS": "146.0477352"
            },
            {
                "Date": "2/2/18",
                "Profil agressif": "159.2531919",
                "OPC ACTIONS": "148.4831481"
            },
            {
                "Date": "2/9/18",
                "Profil agressif": "157.8807263",
                "OPC ACTIONS": "146.7275354"
            },
            {
                "Date": "2/16/18",
                "Profil agressif": "158.3449626",
                "OPC ACTIONS": "147.144486"
            },
            {
                "Date": "2/23/18",
                "Profil agressif": "158.4476699",
                "OPC ACTIONS": "147.5293926"
            },
            {
                "Date": "3/2/18",
                "Profil agressif": "158.2608837",
                "OPC ACTIONS": "147.0588058"
            },
            {
                "Date": "3/9/18",
                "Profil agressif": "158.8515953",
                "OPC ACTIONS": "147.9378674"
            },
            {
                "Date": "3/16/18",
                "Profil agressif": "157.9841963",
                "OPC ACTIONS": "147.3791469"
            },
            {
                "Date": "3/23/18",
                "Profil agressif": "156.9756722",
                "OPC ACTIONS": "146.2245687"
            },
            {
                "Date": "3/30/18",
                "Profil agressif": "156.8920844",
                "OPC ACTIONS": "146.1176323"
            },
            {
                "Date": "4/6/18",
                "Profil agressif": "155.7352517",
                "OPC ACTIONS": "145.0477242"
            },
            {
                "Date": "4/13/18",
                "Profil agressif": "156.483605",
                "OPC ACTIONS": "145.8421152"
            },
            {
                "Date": "4/20/18",
                "Profil agressif": "156.3173114",
                "OPC ACTIONS": "145.5870499"
            },
            {
                "Date": "4/27/18",
                "Profil agressif": "157.0330108",
                "OPC ACTIONS": "146.4009988"
            },
            {
                "Date": "5/4/18",
                "Profil agressif": "156.7861506",
                "OPC ACTIONS": "146.0697828"
            },
            {
                "Date": "5/11/18",
                "Profil agressif": "155.0460692",
                "OPC ACTIONS": "144.6802867"
            },
            {
                "Date": "5/18/18",
                "Profil agressif": "152.7262948",
                "OPC ACTIONS": "142.5403175"
            },
            {
                "Date": "5/25/18",
                "Profil agressif": "152.8221989",
                "OPC ACTIONS": "142.4418108"
            },
            {
                "Date": "6/1/18",
                "Profil agressif": "150.5814925",
                "OPC ACTIONS": "140.6306659"
            },
            {
                "Date": "6/8/18",
                "Profil agressif": "152.2175287",
                "OPC ACTIONS": "142.2462653"
            },
            {
                "Date": "6/18/18",
                "Profil agressif": "151.1934158",
                "OPC ACTIONS": "141.2932266"
            },
            {
                "Date": "6/22/18",
                "Profil agressif": "150.7922654",
                "OPC ACTIONS": "140.8919951"
            },
            {
                "Date": "6/29/18",
                "Profil agressif": "148.2710052",
                "OPC ACTIONS": "139.2825148"
            },
            {
                "Date": "7/6/18",
                "Profil agressif": "146.5148175",
                "OPC ACTIONS": "137.588468"
            },
            {
                "Date": "7/13/18",
                "Profil agressif": "143.8470282",
                "OPC ACTIONS": "135.440319"
            },
            {
                "Date": "7/20/18",
                "Profil agressif": "143.3113133",
                "OPC ACTIONS": "135.0334714"
            },
            {
                "Date": "7/27/18",
                "Profil agressif": "146.286793",
                "OPC ACTIONS": "137.6685001"
            },
            {
                "Date": "8/3/18",
                "Profil agressif": "146.0407401",
                "OPC ACTIONS": "137.4227495"
            },
            {
                "Date": "8/10/18",
                "Profil agressif": "145.9380925",
                "OPC ACTIONS": "137.2045748"
            },
            {
                "Date": "8/17/18",
                "Profil agressif": "145.7941636",
                "OPC ACTIONS": "137.3966699"
            },
            {
                "Date": "8/24/18",
                "Profil agressif": "145.6196497",
                "OPC ACTIONS": "137.1256793"
            },
            {
                "Date": "8/31/18",
                "Profil agressif": "144.5191792",
                "OPC ACTIONS": "136.3849529"
            },
            {
                "Date": "9/7/18",
                "Profil agressif": "139.7119086",
                "OPC ACTIONS": "131.9938708"
            },
            {
                "Date": "9/14/18",
                "Profil agressif": "140.8304133",
                "OPC ACTIONS": "132.7683191"
            },
            {
                "Date": "9/21/18",
                "Profil agressif": "140.9508869",
                "OPC ACTIONS": "132.6209817"
            },
            {
                "Date": "9/28/18",
                "Profil agressif": "141.9304168",
                "OPC ACTIONS": "133.5828397"
            },
            {
                "Date": "10/5/18",
                "Profil agressif": "141.2639498",
                "OPC ACTIONS": "132.6505246"
            },
            {
                "Date": "10/12/18",
                "Profil agressif": "140.7681922",
                "OPC ACTIONS": "132.2037541"
            },
            {
                "Date": "10/19/18",
                "Profil agressif": "140.372956",
                "OPC ACTIONS": "131.6556802"
            },
            {
                "Date": "10/26/18",
                "Profil agressif": "140.1711669",
                "OPC ACTIONS": "131.1397522"
            },
            {
                "Date": "11/2/18",
                "Profil agressif": "138.3955623",
                "OPC ACTIONS": "129.4013136"
            },
            {
                "Date": "11/9/18",
                "Profil agressif": "139.4847812",
                "OPC ACTIONS": "130.7747218"
            },
            {
                "Date": "11/16/18",
                "Profil agressif": "139.7954411",
                "OPC ACTIONS": "130.9532771"
            },
            {
                "Date": "11/23/18",
                "Profil agressif": "139.572061",
                "OPC ACTIONS": "130.9530443"
            },
            {
                "Date": "11/30/18",
                "Profil agressif": "140.7123995",
                "OPC ACTIONS": "132.091822"
            },
            {
                "Date": "12/7/18",
                "Profil agressif": "140.4819768",
                "OPC ACTIONS": "131.7199615"
            },
            {
                "Date": "12/14/18",
                "Profil agressif": "139.9847199",
                "OPC ACTIONS": "131.326204"
            },
            {
                "Date": "12/21/18",
                "Profil agressif": "140.313766",
                "OPC ACTIONS": "131.313584"
            },
            {
                "Date": "12/28/18",
                "Profil agressif": "140.2940569",
                "OPC ACTIONS": "130.8121367"
            },
            {
                "Date": "1/4/19",
                "Profil agressif": "139.7497717",
                "OPC ACTIONS": "130.840431"
            },
            {
                "Date": "1/14/19",
                "Profil agressif": "139.4181102",
                "OPC ACTIONS": "130.5656252"
            },
            {
                "Date": "1/18/19",
                "Profil agressif": "140.5455431",
                "OPC ACTIONS": "131.7642105"
            },
            {
                "Date": "1/25/19",
                "Profil agressif": "141.6018751",
                "OPC ACTIONS": "132.1687598"
            },
            {
                "Date": "2/1/19",
                "Profil agressif": "142.47012",
                "OPC ACTIONS": "133.1595095"
            },
            {
                "Date": "2/8/19",
                "Profil agressif": "142.8134602",
                "OPC ACTIONS": "133.411407"
            },
            {
                "Date": "2/15/19",
                "Profil agressif": "142.9499553",
                "OPC ACTIONS": "133.7729994"
            },
            {
                "Date": "2/22/19",
                "Profil agressif": "142.3858763",
                "OPC ACTIONS": "133.3191589"
            },
            {
                "Date": "3/1/19",
                "Profil agressif": "140.9635075",
                "OPC ACTIONS": "132.280607"
            },
            {
                "Date": "3/8/19",
                "Profil agressif": "140.3730562",
                "OPC ACTIONS": "131.6953889"
            },
            {
                "Date": "3/15/19",
                "Profil agressif": "139.3335263",
                "OPC ACTIONS": "130.8467084"
            },
            {
                "Date": "3/22/19",
                "Profil agressif": "139.1875737",
                "OPC ACTIONS": "130.3816878"
            },
            {
                "Date": "3/29/19",
                "Profil agressif": "138.8250612",
                "OPC ACTIONS": "130.6217705"
            },
            {
                "Date": "4/5/19",
                "Profil agressif": "138.530888",
                "OPC ACTIONS": "130.6522625"
            },
            {
                "Date": "4/12/19",
                "Profil agressif": "139.4038193",
                "OPC ACTIONS": "131.5349142"
            },
            {
                "Date": "4/19/19",
                "Profil agressif": "140.3959533",
                "OPC ACTIONS": "132.2269132"
            },
            {
                "Date": "4/26/19",
                "Profil agressif": "140.7314803",
                "OPC ACTIONS": "132.7756583"
            },
            {
                "Date": "5/3/19",
                "Profil agressif": "141.1581711",
                "OPC ACTIONS": "133.0944345"
            },
            {
                "Date": "5/10/19",
                "Profil agressif": "142.0742921",
                "OPC ACTIONS": "133.6003068"
            },
            {
                "Date": "5/17/19",
                "Profil agressif": "143.1850976",
                "OPC ACTIONS": "134.5281029"
            },
            {
                "Date": "5/24/19",
                "Profil agressif": "141.9618628",
                "OPC ACTIONS": "133.3800326"
            },
            {
                "Date": "5/31/19",
                "Profil agressif": "141.826669",
                "OPC ACTIONS": "132.8717362"
            },
            {
                "Date": "6/10/19",
                "Profil agressif": "142.0551819",
                "OPC ACTIONS": "132.7205821"
            },
            {
                "Date": "6/14/19",
                "Profil agressif": "143.2874295",
                "OPC ACTIONS": "133.7778273"
            },
            {
                "Date": "6/21/19",
                "Profil agressif": "147.3062179",
                "OPC ACTIONS": "137.6313869"
            },
            {
                "Date": "6/28/19",
                "Profil agressif": "146.6362226",
                "OPC ACTIONS": "136.6841574"
            },
            {
                "Date": "7/5/19",
                "Profil agressif": "146.7422675",
                "OPC ACTIONS": "136.919659"
            },
            {
                "Date": "7/12/19",
                "Profil agressif": "147.6830584",
                "OPC ACTIONS": "137.9027138"
            },
            {
                "Date": "7/19/19",
                "Profil agressif": "149.7072513",
                "OPC ACTIONS": "139.5656463"
            },
            {
                "Date": "7/26/19",
                "Profil agressif": "150.6770238",
                "OPC ACTIONS": "140.6465904"
            },
            {
                "Date": "8/2/19",
                "Profil agressif": "151.2385811",
                "OPC ACTIONS": "141.0755256"
            },
            {
                "Date": "8/9/19",
                "Profil agressif": "150.7690714",
                "OPC ACTIONS": "140.3537179"
            },
            {
                "Date": "8/16/19",
                "Profil agressif": "151.3161795",
                "OPC ACTIONS": "140.754073"
            },
            {
                "Date": "8/23/19",
                "Profil agressif": "151.1100151",
                "OPC ACTIONS": "140.7652773"
            },
            {
                "Date": "8/30/19",
                "Profil agressif": "152.3984325",
                "OPC ACTIONS": "141.7088847"
            },
            {
                "Date": "9/6/19",
                "Profil agressif": "151.1844443",
                "OPC ACTIONS": "140.5472613"
            },
            {
                "Date": "9/13/19",
                "Profil agressif": "151.0827687",
                "OPC ACTIONS": "140.5956115"
            },
            {
                "Date": "9/20/19",
                "Profil agressif": "149.3152032",
                "OPC ACTIONS": "138.9831955"
            },
            {
                "Date": "9/27/19",
                "Profil agressif": "150.3950755",
                "OPC ACTIONS": "140.1313176"
            },
            {
                "Date": "10/4/19",
                "Profil agressif": "149.298287",
                "OPC ACTIONS": "139.2894924"
            },
            {
                "Date": "10/11/19",
                "Profil agressif": "148.2751489",
                "OPC ACTIONS": "138.1430729"
            },
            {
                "Date": "10/18/19",
                "Profil agressif": "149.7122068",
                "OPC ACTIONS": "139.9184299"
            },
            {
                "Date": "10/25/19",
                "Profil agressif": "150.4524071",
                "OPC ACTIONS": "140.3526863"
            },
            {
                "Date": "11/1/19",
                "Profil agressif": "150.3335715",
                "OPC ACTIONS": "140.0675996"
            },
            {
                "Date": "11/8/19",
                "Profil agressif": "150.3060752",
                "OPC ACTIONS": "140.2413803"
            },
            {
                "Date": "11/15/19",
                "Profil agressif": "151.0029785",
                "OPC ACTIONS": "140.7879015"
            },
            {
                "Date": "11/22/19",
                "Profil agressif": "152.9879661",
                "OPC ACTIONS": "142.7576158"
            },
            {
                "Date": "11/29/19",
                "Profil agressif": "152.9246819",
                "OPC ACTIONS": "142.554923"
            },
            {
                "Date": "12/6/19",
                "Profil agressif": "152.799771",
                "OPC ACTIONS": "141.9958615"
            },
            {
                "Date": "12/13/19",
                "Profil agressif": "154.8880669",
                "OPC ACTIONS": "144.283956"
            },
            {
                "Date": "12/20/19",
                "Profil agressif": "157.261462",
                "OPC ACTIONS": "146.4955847"
            },
            {
                "Date": "12/27/19",
                "Profil agressif": "157.3284619",
                "OPC ACTIONS": "146.6743418"
            },
            {
                "Date": "1/3/20",
                "Profil agressif": "157.4948795",
                "OPC ACTIONS": "146.2931167"
            },
            {
                "Date": "1/10/20",
                "Profil agressif": "159.593032",
                "OPC ACTIONS": "148.1040441"
            },
            {
                "Date": "1/17/20",
                "Profil agressif": "161.9470242",
                "OPC ACTIONS": "150.617599"
            },
            {
                "Date": "1/24/20",
                "Profil agressif": "162.9733739",
                "OPC ACTIONS": "151.2392285"
            },
            {
                "Date": "1/31/20",
                "Profil agressif": "162.344044",
                "OPC ACTIONS": "150.405952"
            },
            {
                "Date": "2/7/20",
                "Profil agressif": "158.0115178",
                "OPC ACTIONS": "146.49657"
            },
            {
                "Date": "2/14/20",
                "Profil agressif": "160.101503",
                "OPC ACTIONS": "148.1532686"
            },
            {
                "Date": "2/21/20",
                "Profil agressif": "162.3077384",
                "OPC ACTIONS": "149.749933"
            },
            {
                "Date": "2/28/20",
                "Profil agressif": "159.5652687",
                "OPC ACTIONS": "147.2517419"
            },
            {
                "Date": "3/6/20",
                "Profil agressif": "151.4455087",
                "OPC ACTIONS": "138.8575619"
            },
            {
                "Date": "3/13/20",
                "Profil agressif": "140.6237915",
                "OPC ACTIONS": "128.3411149"
            },
            {
                "Date": "3/20/20",
                "Profil agressif": "130.1929499",
                "OPC ACTIONS": "118.8790765"
            },
            {
                "Date": "3/27/20",
                "Profil agressif": "132.7732478",
                "OPC ACTIONS": "121.1462727"
            },
            {
                "Date": "4/3/20",
                "Profil agressif": "129.6728991",
                "OPC ACTIONS": "117.667683"
            },
            {
                "Date": "4/10/20",
                "Profil agressif": "128.9292929",
                "OPC ACTIONS": "116.715096"
            },
            {
                "Date": "4/17/20",
                "Profil agressif": "126.8846405",
                "OPC ACTIONS": "114.4436306"
            },
            {
                "Date": "4/24/20",
                "Profil agressif": "126.5302192",
                "OPC ACTIONS": "114.1504268"
            },
            {
                "Date": "5/4/20",
                "Profil agressif": "130.312927",
                "OPC ACTIONS": "117.9863301"
            },
            {
                "Date": "5/8/20",
                "Profil agressif": "131.6045959",
                "OPC ACTIONS": "119.2295869"
            },
            {
                "Date": "5/15/20",
                "Profil agressif": "130.9539792",
                "OPC ACTIONS": "118.4484784"
            },
            {
                "Date": "5/22/20",
                "Profil agressif": "134.2408538",
                "OPC ACTIONS": "121.3691256"
            },
            {
                "Date": "5/29/20",
                "Profil agressif": "136.5272082",
                "OPC ACTIONS": "124.1328492"
            },
            {
                "Date": "6/5/20",
                "Profil agressif": "139.1503386",
                "OPC ACTIONS": "127.0755509"
            },
            {
                "Date": "6/12/20",
                "Profil agressif": "137.7981948",
                "OPC ACTIONS": "125.3468325"
            },
            {
                "Date": "6/19/20",
                "Profil agressif": "142.4946589",
                "OPC ACTIONS": "129.9267178"
            },
            {
                "Date": "6/26/20",
                "Profil agressif": "140.1810379",
                "OPC ACTIONS": "127.2776805"
            },
            {
                "Date": "7/3/20",
                "Profil agressif": "141.3878681",
                "OPC ACTIONS": "128.3887614"
            },
            {
                "Date": "7/10/20",
                "Profil agressif": "140.8212964",
                "OPC ACTIONS": "127.7053204"
            },
            {
                "Date": "7/17/20",
                "Profil agressif": "142.4731454",
                "OPC ACTIONS": "129.2782541"
            },
            {
                "Date": "7/24/20",
                "Profil agressif": "142.85268",
                "OPC ACTIONS": "128.9610891"
            },
            {
                "Date": "8/3/20",
                "Profil agressif": "142.7801535",
                "OPC ACTIONS": "127.9732944"
            },
            {
                "Date": "8/7/20",
                "Profil agressif": "144.726276",
                "OPC ACTIONS": "129.2939655"
            },
            {
                "Date": "8/17/20",
                "Profil agressif": "144.6625109",
                "OPC ACTIONS": "129.8300061"
            },
            {
                "Date": "8/24/20",
                "Profil agressif": "144.5601246",
                "OPC ACTIONS": "130.0665514"
            },
            {
                "Date": "8/28/20",
                "Profil agressif": "142.9894091",
                "OPC ACTIONS": "128.6532782"
            },
            {
                "Date": "9/4/20",
                "Profil agressif": "143.9559443",
                "OPC ACTIONS": "129.6084578"
            },
            {
                "Date": "9/11/20",
                "Profil agressif": "144.5832557",
                "OPC ACTIONS": "129.8826086"
            },
            {
                "Date": "9/18/20",
                "Profil agressif": "143.1577619",
                "OPC ACTIONS": "128.4222981"
            },
            {
                "Date": "9/25/20",
                "Profil agressif": "141.5407349",
                "OPC ACTIONS": "127.3262635"
            },
            {
                "Date": "10/2/20",
                "Profil agressif": "143.1306779",
                "OPC ACTIONS": "128.7947386"
            },
            {
                "Date": "10/9/20",
                "Profil agressif": "144.4327818",
                "OPC ACTIONS": "129.8678938"
            },
            {
                "Date": "10/16/20",
                "Profil agressif": "145.6224524",
                "OPC ACTIONS": "131.0534273"
            },
            {
                "Date": "10/23/20",
                "Profil agressif": "146.9592789",
                "OPC ACTIONS": "132.3321069"
            },
            {
                "Date": "11/2/20",
                "Profil agressif": "145.8782946",
                "OPC ACTIONS": "131.4520271"
            },
            {
                "Date": "11/9/20",
                "Profil agressif": "147.4832723",
                "OPC ACTIONS": "133.3058605"
            },
            {
                "Date": "11/13/20",
                "Profil agressif": "148.616685",
                "OPC ACTIONS": "134.3182454"
            },
            {
                "Date": "11/20/20",
                "Profil agressif": "150.7866977",
                "OPC ACTIONS": "136.8088965"
            },
            {
                "Date": "11/27/20",
                "Profil agressif": "149.9654678",
                "OPC ACTIONS": "136.8186499"
            },
            {
                "Date": "12/4/20",
                "Profil agressif": "151.4570665",
                "OPC ACTIONS": "137.8663524"
            },
            {
                "Date": "12/11/20",
                "Profil agressif": "155.5972963",
                "OPC ACTIONS": "141.7756351"
            },
            {
                "Date": "12/18/20",
                "Profil agressif": "157.7038039",
                "OPC ACTIONS": "143.5348695"
            },
            {
                "Date": "12/25/20",
                "Profil agressif": "154.8828512",
                "OPC ACTIONS": "140.5668482"
            },
            {
                "Date": "1/4/21",
                "Profil agressif": "156.5556924",
                "OPC ACTIONS": "141.8730512"
            },
            {
                "Date": "1/8/21",
                "Profil agressif": "155.433683",
                "OPC ACTIONS": "141.6592764"
            },
            {
                "Date": "1/15/21",
                "Profil agressif": "157.0430636",
                "OPC ACTIONS": "143.0450444"
            },
            {
                "Date": "1/22/21",
                "Profil agressif": "158.7414169",
                "OPC ACTIONS": "144.6763202"
            },
            {
                "Date": "1/29/21",
                "Profil agressif": "159.6352075",
                "OPC ACTIONS": "145.4793774"
            },
            {
                "Date": "2/5/21",
                "Profil agressif": "160.4678955",
                "OPC ACTIONS": "146.6204627"
            },
            {
                "Date": "2/12/21",
                "Profil agressif": "158.7279307",
                "OPC ACTIONS": "144.7102625"
            },
            {
                "Date": "2/19/21",
                "Profil agressif": "157.5126403",
                "OPC ACTIONS": "143.7355089"
            },
            {
                "Date": "2/26/21",
                "Profil agressif": "155.8729455",
                "OPC ACTIONS": "142.7263808"
            },
            {
                "Date": "3/5/21",
                "Profil agressif": "155.9289452",
                "OPC ACTIONS": "142.8692432"
            },
            {
                "Date": "3/12/21",
                "Profil agressif": "156.5612678",
                "OPC ACTIONS": "143.7582863"
            },
            {
                "Date": "3/19/21",
                "Profil agressif": "156.9997051",
                "OPC ACTIONS": "144.2483887"
            },
            {
                "Date": "3/26/21",
                "Profil agressif": "157.5706771",
                "OPC ACTIONS": "144.5344992"
            },
            {
                "Date": "4/2/21",
                "Profil agressif": "158.0898351",
                "OPC ACTIONS": "145.2682813"
            },
            {
                "Date": "4/9/21",
                "Profil agressif": "158.3209021",
                "OPC ACTIONS": "145.694678"
            },
            {
                "Date": "4/16/21",
                "Profil agressif": "159.5113624",
                "OPC ACTIONS": "146.5188538"
            },
            {
                "Date": "4/23/21",
                "Profil agressif": "160.2337319",
                "OPC ACTIONS": "147.1713392"
            },
            {
                "Date": "4/30/21",
                "Profil agressif": "161.0594993",
                "OPC ACTIONS": "148.1490258"
            },
            {
                "Date": "5/7/21",
                "Profil agressif": "163.9738858",
                "OPC ACTIONS": "150.2601724"
            },
            {
                "Date": "5/17/21",
                "Profil agressif": "165.9217806",
                "OPC ACTIONS": "152.0604934"
            },
            {
                "Date": "5/21/21",
                "Profil agressif": "165.6851945",
                "OPC ACTIONS": "151.9901948"
            },
            {
                "Date": "5/28/21",
                "Profil agressif": "165.8708975",
                "OPC ACTIONS": "151.9037502"
            },
            {
                "Date": "6/4/21",
                "Profil agressif": "168.1301572",
                "OPC ACTIONS": "154.6811571"
            },
            {
                "Date": "6/11/21",
                "Profil agressif": "169.0883434",
                "OPC ACTIONS": "155.7866901"
            },
            {
                "Date": "6/18/21",
                "Profil agressif": "168.2471461",
                "OPC ACTIONS": "156.0361069"
            },
            {
                "Date": "6/25/21",
                "Profil agressif": "167.4376368",
                "OPC ACTIONS": "154.4608261"
            },
            {
                "Date": "7/2/21",
                "Profil agressif": "167.6077289",
                "OPC ACTIONS": "154.5147169"
            },
            {
                "Date": "7/9/21",
                "Profil agressif": "166.8455986",
                "OPC ACTIONS": "154.0829802"
            },
            {
                "Date": "7/16/21",
                "Profil agressif": "166.6180559",
                "OPC ACTIONS": "153.6885678"
            },
            {
                "Date": "7/23/21",
                "Profil agressif": "167.0479907",
                "OPC ACTIONS": "154.5728489"
            },
            {
                "Date": "8/2/21",
                "Profil agressif": "169.6064925",
                "OPC ACTIONS": "157.1955199"
            },
            {
                "Date": "8/6/21",
                "Profil agressif": "170.0114642",
                "OPC ACTIONS": "158.5924329"
            },
            {
                "Date": "8/13/21",
                "Profil agressif": "171.2144795",
                "OPC ACTIONS": "160.2563724"
            },
            {
                "Date": "8/23/21",
                "Profil agressif": "172.1548935",
                "OPC ACTIONS": "160.6138899"
            },
            {
                "Date": "8/27/21",
                "Profil agressif": "173.2233144",
                "OPC ACTIONS": "161.1091796"
            },
            {
                "Date": "9/3/21",
                "Profil agressif": "173.1635453",
                "OPC ACTIONS": "161.31736"
            },
            {
                "Date": "9/10/21",
                "Profil agressif": "174.8947684",
                "OPC ACTIONS": "163.2984198"
            },
            {
                "Date": "9/17/21",
                "Profil agressif": "176.3877152",
                "OPC ACTIONS": "164.6958207"
            },
            {
                "Date": "9/24/21",
                "Profil agressif": "177.6493684",
                "OPC ACTIONS": "165.4949478"
            },
            {
                "Date": "10/1/21",
                "Profil agressif": "177.3538297",
                "OPC ACTIONS": "164.3619955"
            },
            {
                "Date": "10/8/21",
                "Profil agressif": "178.6204491",
                "OPC ACTIONS": "165.6208742"
            },
            {
                "Date": "10/15/21",
                "Profil agressif": "179.8516155",
                "OPC ACTIONS": "166.8587978"
            },
            {
                "Date": "10/22/21",
                "Profil agressif": "180.1709792",
                "OPC ACTIONS": "166.82491"
            },
            {
                "Date": "10/29/21",
                "Profil agressif": "182.4560885",
                "OPC ACTIONS": "169.4044815"
            },
            {
                "Date": "11/5/21",
                "Profil agressif": "180.8947163",
                "OPC ACTIONS": "168.2469565"
            },
            {
                "Date": "11/12/21",
                "Profil agressif": "180.0721",
                "OPC ACTIONS": "166.6945216"
            },
            {
                "Date": "11/19/21",
                "Profil agressif": "180.3684114",
                "OPC ACTIONS": "167.0858012"
            },
            {
                "Date": "11/26/21",
                "Profil agressif": "178.7222632",
                "OPC ACTIONS": "166.1049368"
            },
            {
                "Date": "12/3/21",
                "Profil agressif": "178.3338409",
                "OPC ACTIONS": "165.7079648"
            },
            {
                "Date": "12/10/21",
                "Profil agressif": "179.9414209",
                "OPC ACTIONS": "167.1618708"
            },
            {
                "Date": "12/17/21",
                "Profil agressif": "179.682656",
                "OPC ACTIONS": "166.5318564"
            },
            {
                "Date": "12/24/21",
                "Profil agressif": "179.0254533",
                "OPC ACTIONS": "166.1389748"
            },
            {
                "Date": "12/31/21",
                "Profil agressif": "180.4928098",
                "OPC ACTIONS": "166.9629959"
            },
            {
                "Date": "1/7/22",
                "Profil agressif": "181.7780229",
                "OPC ACTIONS": "168.3723732"
            },
            {
                "Date": "1/14/22",
                "Profil agressif": "185.08866",
                "OPC ACTIONS": "171.69622"
            },
            {
                "Date": "1/21/22",
                "Profil agressif": "185.0684048",
                "OPC ACTIONS": "172.0377408"
            },
            {
                "Date": "1/28/22",
                "Profil agressif": "184.7013697",
                "OPC ACTIONS": "172.1332953"
            },
            {
                "Date": "2/4/22",
                "Profil agressif": "186.01433",
                "OPC ACTIONS": "173.7700852"
            },
            {
                "Date": "2/11/22",
                "Profil agressif": "186.2514373",
                "OPC ACTIONS": "173.7711443"
            },
            {
                "Date": "2/18/22",
                "Profil agressif": "184.3091748",
                "OPC ACTIONS": "171.0981927"
            },
            {
                "Date": "2/25/22",
                "Profil agressif": "177.9186194",
                "OPC ACTIONS": "164.816117"
            },
            {
                "Date": "3/4/22",
                "Profil agressif": "174.4148989",
                "OPC ACTIONS": "160.3563724"
            },
            {
                "Date": "3/11/22",
                "Profil agressif": "172.9768885",
                "OPC ACTIONS": "158.64111"
            },
            {
                "Date": "3/18/22",
                "Profil agressif": "175.7424575",
                "OPC ACTIONS": "161.3610381"
            },
            {
                "Date": "3/25/22",
                "Profil agressif": "172.947678",
                "OPC ACTIONS": "158.5574994"
            },
            {
                "Date": "4/1/22",
                "Profil agressif": "175.2109445",
                "OPC ACTIONS": "160.4018478"
            },
            {
                "Date": "4/8/22",
                "Profil agressif": "176.1296708",
                "OPC ACTIONS": "161.3268876"
            },
            {
                "Date": "4/15/22",
                "Profil agressif": "176.8150158",
                "OPC ACTIONS": "161.911796"
            },
            {
                "Date": "4/22/22",
                "Profil agressif": "178.8282963",
                "OPC ACTIONS": "164.1048356"
            },
            {
                "Date": "4/29/22",
                "Profil agressif": "178.4180277",
                "OPC ACTIONS": "163.8855011"
            },
            {
                "Date": "5/6/22",
                "Profil agressif": "177.4256203",
                "OPC ACTIONS": "163.2804445"
            },
            {
                "Date": "5/13/22",
                "Profil agressif": "172.8916973",
                "OPC ACTIONS": "159.226482"
            },
            {
                "Date": "5/20/22",
                "Profil agressif": "172.4154763",
                "OPC ACTIONS": "158.5574978"
            },
            {
                "Date": "5/27/22",
                "Profil agressif": "169.0212115",
                "OPC ACTIONS": "155.0652309"
            },
            {
                "Date": "6/3/22",
                "Profil agressif": "172.3938766",
                "OPC ACTIONS": "158.8471861"
            },
            {
                "Date": "6/10/22",
                "Profil agressif": "172.1972081",
                "OPC ACTIONS": "158.6501726"
            },
            {
                "Date": "6/17/22",
                "Profil agressif": "167.2707871",
                "OPC ACTIONS": "153.2704592"
            },
            {
                "Date": "6/24/22",
                "Profil agressif": "166.5674798",
                "OPC ACTIONS": "152.36915"
            },
            {
                "Date": "7/1/22",
                "Profil agressif": "163.1300711",
                "OPC ACTIONS": "149.7230583"
            },
            {
                "Date": "7/8/22",
                "Profil agressif": "163.4657614",
                "OPC ACTIONS": "150.2452334"
            },
            {
                "Date": "7/15/22",
                "Profil agressif": "165.3558377",
                "OPC ACTIONS": "152.1056793"
            },
            {
                "Date": "7/22/22",
                "Profil agressif": "166.7061327",
                "OPC ACTIONS": "153.5465825"
            },
            {
                "Date": "7/29/22",
                "Profil agressif": "165.617252",
                "OPC ACTIONS": "152.4506577"
            },
            {
                "Date": "8/5/22",
                "Profil agressif": "167.3347427",
                "OPC ACTIONS": "154.1549559"
            },
            {
                "Date": "8/12/22",
                "Profil agressif": "169.6592978",
                "OPC ACTIONS": "156.2570712"
            },
            {
                "Date": "8/19/22",
                "Profil agressif": "169.7663118",
                "OPC ACTIONS": "156.5480753"
            },
            {
                "Date": "8/26/22",
                "Profil agressif": "169.9064619",
                "OPC ACTIONS": "156.6401657"
            },
            {
                "Date": "9/2/22",
                "Profil agressif": "168.8874401",
                "OPC ACTIONS": "156.2366019"
            },
            {
                "Date": "9/9/22",
                "Profil agressif": "169.4744625",
                "OPC ACTIONS": "156.5329597"
            },
            {
                "Date": "9/16/22",
                "Profil agressif": "169.7022017",
                "OPC ACTIONS": "157.2050207"
            },
            {
                "Date": "9/23/22",
                "Profil agressif": "170.3583731",
                "OPC ACTIONS": "157.5764066"
            },
            {
                "Date": "9/30/22",
                "Profil agressif": "163.1059304",
                "OPC ACTIONS": "150.2888071"
            },
            {
                "Date": "10/7/22",
                "Profil agressif": "163.9217529",
                "OPC ACTIONS": "151.4309102"
            },
            {
                "Date": "10/14/22",
                "Profil agressif": "160.4677313",
                "OPC ACTIONS": "148.3286387"
            },
            {
                "Date": "10/21/22",
                "Profil agressif": "155.1409974",
                "OPC ACTIONS": "143.2805062"
            },
            {
                "Date": "10/28/22",
                "Profil agressif": "153.9110737",
                "OPC ACTIONS": "142.2362297"
            },
            {
                "Date": "11/4/22",
                "Profil agressif": "153.1044704",
                "OPC ACTIONS": "141.0049328"
            },
            {
                "Date": "11/11/22",
                "Profil agressif": "149.1614725",
                "OPC ACTIONS": "136.6201924"
            },
            {
                "Date": "11/21/22",
                "Profil agressif": "153.4164359",
                "OPC ACTIONS": "140.8270532"
            },
            {
                "Date": "11/25/22",
                "Profil agressif": "153.8983273",
                "OPC ACTIONS": "140.9197993"
            },
            {
                "Date": "12/2/22",
                "Profil agressif": "156.2492559",
                "OPC ACTIONS": "143.3918841"
            },
            {
                "Date": "12/9/22",
                "Profil agressif": "159.0888011",
                "OPC ACTIONS": "146.1503979"
            },
            {
                "Date": "12/16/22",
                "Profil agressif": "156.5037157",
                "OPC ACTIONS": "143.8188992"
            },
            {
                "Date": "12/23/22",
                "Profil agressif": "155.4711817",
                "OPC ACTIONS": "142.52055"
            },
            {
                "Date": "12/30/22",
                "Profil agressif": "152.1094898",
                "OPC ACTIONS": "140.0011994"
            },
            {
                "Date": "1/6/23",
                "Profil agressif": "140.6632636",
                "OPC ACTIONS": "128.3436361"
            },
            {
                "Date": "1/13/23",
                "Profil agressif": "147.385588",
                "OPC ACTIONS": "135.221819"
            },
            {
                "Date": "1/20/23",
                "Profil agressif": "146.4717295",
                "OPC ACTIONS": "133.6891222"
            },
            {
                "Date": "1/27/23",
                "Profil agressif": "145.0042311",
                "OPC ACTIONS": "132.2174967"
            },
            {
                "Date": "2/3/23",
                "Profil agressif": "148.1722803",
                "OPC ACTIONS": "134.916958"
            },
            {
                "Date": "2/10/23",
                "Profil agressif": "152.2408273",
                "OPC ACTIONS": "139.2400217"
            },
            {
                "Date": "2/17/23",
                "Profil agressif": "151.7392091",
                "OPC ACTIONS": "138.5339967"
            },
            {
                "Date": "2/24/23",
                "Profil agressif": "153.4678595",
                "OPC ACTIONS": "139.6999022"
            },
            {
                "Date": "3/3/23",
                "Profil agressif": "151.9687958",
                "OPC ACTIONS": "138.264598"
            },
            {
                "Date": "3/10/23",
                "Profil agressif": "150.4284106",
                "OPC ACTIONS": "136.7683641"
            },
            {
                "Date": "3/17/23",
                "Profil agressif": "149.6188235",
                "OPC ACTIONS": "134.8795108"
            },
            {
                "Date": "3/24/23",
                "Profil agressif": "149.5508613",
                "OPC ACTIONS": "135.0620498"
            },
            {
                "Date": "3/31/23",
                "Profil agressif": "149.1082127",
                "OPC ACTIONS": "134.2708222"
            },
            {
                "Date": "4/7/23",
                "Profil agressif": "150.0073444",
                "OPC ACTIONS": "135.0806985"
            },
            {
                "Date": "4/14/23",
                "Profil agressif": "150.17272",
                "OPC ACTIONS": "135.1624367"
            },
            {
                "Date": "4/21/23",
                "Profil agressif": "149.9687563",
                "OPC ACTIONS": "135.4794838"
            },
            {
                "Date": "4/28/23",
                "Profil agressif": "150.1206393",
                "OPC ACTIONS": "135.6773207"
            },
            {
                "Date": "5/5/23",
                "Profil agressif": "148.6499895",
                "OPC ACTIONS": "134.182525"
            },
            {
                "Date": "5/12/23",
                "Profil agressif": "152.6669666",
                "OPC ACTIONS": "137.9178186"
            },
            {
                "Date": "5/19/23",
                "Profil agressif": "152.9814907",
                "OPC ACTIONS": "138.3222165"
            },
            {
                "Date": "5/26/23",
                "Profil agressif": "155.0815183",
                "OPC ACTIONS": "140.214079"
            },
            {
                "Date": "6/2/23",
                "Profil agressif": "157.1778891",
                "OPC ACTIONS": "142.3579439"
            },
            {
                "Date": "6/9/23",
                "Profil agressif": "155.8501852",
                "OPC ACTIONS": "141.6668002"
            },
            {
                "Date": "6/16/23",
                "Profil agressif": "159.8688843",
                "OPC ACTIONS": "145.9060027"
            },
            {
                "Date": "6/23/23",
                "Profil agressif": "160.8541987",
                "OPC ACTIONS": "146.7899414"
            },
            {
                "Date": "7/3/23",
                "Profil agressif": "161.671464",
                "OPC ACTIONS": "147.8526998"
            },
            {
                "Date": "7/7/23",
                "Profil agressif": "162.4978417",
                "OPC ACTIONS": "149.1909156"
            },
            {
                "Date": "7/14/23",
                "Profil agressif": "163.2199453",
                "OPC ACTIONS": "149.9671254"
            },
            {
                "Date": "7/21/23",
                "Profil agressif": "166.3532805",
                "OPC ACTIONS": "152.6648477"
            },
            {
                "Date": "7/28/23",
                "Profil agressif": "169.435538",
                "OPC ACTIONS": "155.8041913"
            },
            {
                "Date": "8/4/23",
                "Profil agressif": "170.0962511",
                "OPC ACTIONS": "156.1575546"
            },
            {
                "Date": "8/11/23",
                "Profil agressif": "168.7533831",
                "OPC ACTIONS": "155.297897"
            },
            {
                "Date": "8/18/23",
                "Profil agressif": "168.4091371",
                "OPC ACTIONS": "154.6372655"
            },
            {
                "Date": "8/25/23",
                "Profil agressif": "167.9203965",
                "OPC ACTIONS": "153.9223196"
            },
            {
                "Date": "9/1/23",
                "Profil agressif": "167.758103",
                "OPC ACTIONS": "153.1212774"
            },
            {
                "Date": "9/8/23",
                "Profil agressif": "165.8013087",
                "OPC ACTIONS": "151.2876284"
            },
            {
                "Date": "9/15/23",
                "Profil agressif": "166.4509175",
                "OPC ACTIONS": "151.8977843"
            },
            {
                "Date": "9/22/23",
                "Profil agressif": "166.1618392",
                "OPC ACTIONS": "151.6647124"
            },
            {
                "Date": "10/2/23",
                "Profil agressif": "165.6123329",
                "OPC ACTIONS": "151.8457494"
            },
            {
                "Date": "10/6/23",
                "Profil agressif": "170.9652191",
                "OPC ACTIONS": "157.467432"
            },
            {
                "Date": "10/13/23",
                "Profil agressif": "170.8571967",
                "OPC ACTIONS": "157.1703276"
            },
            {
                "Date": "10/20/23",
                "Profil agressif": "171.4972174",
                "OPC ACTIONS": "156.9395468"
            },
            {
                "Date": "10/27/23",
                "Profil agressif": "168.6820715",
                "OPC ACTIONS": "154.3152621"
            },
            {
                "Date": "11/3/23",
                "Profil agressif": "168.6636415",
                "OPC ACTIONS": "154.2888136"
            },
            {
                "Date": "11/10/23",
                "Profil agressif": "167.8847501",
                "OPC ACTIONS": "154.060442"
            },
            {
                "Date": "11/17/23",
                "Profil agressif": "167.7742973",
                "OPC ACTIONS": "153.5720563"
            },
            {
                "Date": "11/24/23",
                "Profil agressif": "167.8369254",
                "OPC ACTIONS": "153.7201451"
            },
            {
                "Date": "12/1/23",
                "Profil agressif": "166.0423499",
                "OPC ACTIONS": "151.6126977"
            },
            {
                "Date": "12/8/23",
                "Profil agressif": "167.395551",
                "OPC ACTIONS": "151.5167953"
            },
            {
                "Date": "12/15/23",
                "Profil agressif": "170.1083194",
                "OPC ACTIONS": "154.1763759"
            },
            {
                "Date": "12/22/23",
                "Profil agressif": "169.6048462",
                "OPC ACTIONS": "153.8490989"
            },
            {
                "Date": "12/29/23",
                "Profil agressif": "168.7689274",
                "OPC ACTIONS": "152.3715734"
            },
            {
                "Date": "1/5/24",
                "Profil agressif": "171.5041163",
                "OPC ACTIONS": "155.09547"
            },
            {
                "Date": "1/12/24",
                "Profil agressif": "172.9277724",
                "OPC ACTIONS": "156.717561"
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
          setChartData(yourDataSet[0]);
        }
      }, [yourDataSet]);
    
      const getOption = () => {
        if (!chartData || chartData.length === 0) {
          return {}; 
        }
      
        const xAxisData = chartData.map((item) => item.Date);
      
        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');
      
        const series = legendData.map((key) => ({
          type: 'line',
          name: key,
          data: chartData.map((item) => {
            const value = item[key];
            const parsedValue = parseFloat(value.replace(',', ' '));
            return isNaN(parsedValue) ? null : parsedValue;
          }),
        }));
    
        return {
          tooltip: {
            trigger: 'axis',
          },
          title: {
            text: '',
            left: 'center',
         
          },
           legend: {
            data: legendData,
            bottom : 10,
           },
          xAxis: {
            type: 'category',
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: 100,
          },
          series,
        };
      };
    
      return (
        <Row className="g-3">
      <Col>
       
            <ReactEChartsCore echarts={echarts} option={getOption()} />
          
      </Col>
    </Row>
      );
    };
    
    export default LineChartComponent;
