import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil agressif": "100",
                "": ""
            },
            {
                "Date": "1/4/16",
                "Profil agressif": "100.1073853",
                "": ""
            },
            {
                "Date": "1/8/16",
                "Profil agressif": "99.82034649",
                "": ""
            },
            {
                "Date": "1/15/16",
                "Profil agressif": "99.41859524",
                "": ""
            },
            {
                "Date": "1/22/16",
                "Profil agressif": "99.72587284",
                "": ""
            },
            {
                "Date": "1/29/16",
                "Profil agressif": "100.2902399",
                "": ""
            },
            {
                "Date": "2/5/16",
                "Profil agressif": "101.1781322",
                "": ""
            },
            {
                "Date": "2/12/16",
                "Profil agressif": "101.9166119",
                "": ""
            },
            {
                "Date": "2/19/16",
                "Profil agressif": "103.7022032",
                "": ""
            },
            {
                "Date": "2/26/16",
                "Profil agressif": "102.0171507",
                "": ""
            },
            {
                "Date": "3/4/16",
                "Profil agressif": "103.2237212",
                "": ""
            },
            {
                "Date": "3/11/16",
                "Profil agressif": "103.8600106",
                "": ""
            },
            {
                "Date": "3/18/16",
                "Profil agressif": "104.7460034",
                "": ""
            },
            {
                "Date": "3/25/16",
                "Profil agressif": "106.131811",
                "": ""
            },
            {
                "Date": "4/1/16",
                "Profil agressif": "104.4133599",
                "": ""
            },
            {
                "Date": "4/8/16",
                "Profil agressif": "104.8272504",
                "": ""
            },
            {
                "Date": "4/15/16",
                "Profil agressif": "106.7327521",
                "": ""
            },
            {
                "Date": "4/22/16",
                "Profil agressif": "106.5290238",
                "": ""
            },
            {
                "Date": "4/29/16",
                "Profil agressif": "109.5805168",
                "": ""
            },
            {
                "Date": "5/6/16",
                "Profil agressif": "111.7396671",
                "": ""
            },
            {
                "Date": "5/13/16",
                "Profil agressif": "112.0670979",
                "": ""
            },
            {
                "Date": "5/20/16",
                "Profil agressif": "110.523051",
                "": ""
            },
            {
                "Date": "5/27/16",
                "Profil agressif": "109.6964497",
                "": ""
            },
            {
                "Date": "6/3/16",
                "Profil agressif": "110.8548478",
                "": ""
            },
            {
                "Date": "6/10/16",
                "Profil agressif": "110.4740881",
                "": ""
            },
            {
                "Date": "6/17/16",
                "Profil agressif": "110.0070453",
                "": ""
            },
            {
                "Date": "6/24/16",
                "Profil agressif": "110.5857127",
                "": ""
            },
            {
                "Date": "7/1/16",
                "Profil agressif": "110.8645094",
                "": ""
            },
            {
                "Date": "7/11/16",
                "Profil agressif": "111.5244114",
                "": ""
            },
            {
                "Date": "7/15/16",
                "Profil agressif": "113.2223156",
                "": ""
            },
            {
                "Date": "7/22/16",
                "Profil agressif": "114.2895442",
                "": ""
            },
            {
                "Date": "7/29/16",
                "Profil agressif": "115.0277746",
                "": ""
            },
            {
                "Date": "8/5/16",
                "Profil agressif": "115.3530558",
                "": ""
            },
            {
                "Date": "8/12/16",
                "Profil agressif": "115.2407105",
                "": ""
            },
            {
                "Date": "8/19/16",
                "Profil agressif": "115.2800387",
                "": ""
            },
            {
                "Date": "8/26/16",
                "Profil agressif": "115.1688361",
                "": ""
            },
            {
                "Date": "9/2/16",
                "Profil agressif": "115.1867235",
                "": ""
            },
            {
                "Date": "9/9/16",
                "Profil agressif": "115.3847264",
                "": ""
            },
            {
                "Date": "9/16/16",
                "Profil agressif": "115.1493347",
                "": ""
            },
            {
                "Date": "9/23/16",
                "Profil agressif": "115.6230042",
                "": ""
            },
            {
                "Date": "9/30/16",
                "Profil agressif": "116.7135951",
                "": ""
            },
            {
                "Date": "10/7/16",
                "Profil agressif": "117.344103",
                "": ""
            },
            {
                "Date": "10/14/16",
                "Profil agressif": "118.1311519",
                "": ""
            },
            {
                "Date": "10/21/16",
                "Profil agressif": "119.5436636",
                "": ""
            },
            {
                "Date": "10/28/16",
                "Profil agressif": "120.3739725",
                "": ""
            },
            {
                "Date": "11/4/16",
                "Profil agressif": "121.0203717",
                "": ""
            },
            {
                "Date": "11/11/16",
                "Profil agressif": "121.8927198",
                "": ""
            },
            {
                "Date": "11/21/16",
                "Profil agressif": "123.0544029",
                "": ""
            },
            {
                "Date": "11/25/16",
                "Profil agressif": "122.5451031",
                "": ""
            },
            {
                "Date": "12/2/16",
                "Profil agressif": "123.5924716",
                "": ""
            },
            {
                "Date": "12/9/16",
                "Profil agressif": "126.3637763",
                "": ""
            },
            {
                "Date": "12/16/16",
                "Profil agressif": "129.4666768",
                "": ""
            },
            {
                "Date": "12/23/16",
                "Profil agressif": "136.0860207",
                "": ""
            },
            {
                "Date": "12/30/16",
                "Profil agressif": "133.7966036",
                "": ""
            },
            {
                "Date": "1/6/17",
                "Profil agressif": "143.945249",
                "": ""
            },
            {
                "Date": "1/13/17",
                "Profil agressif": "144.7417124",
                "": ""
            },
            {
                "Date": "1/20/17",
                "Profil agressif": "143.7272328",
                "": ""
            },
            {
                "Date": "1/27/17",
                "Profil agressif": "143.3784633",
                "": ""
            },
            {
                "Date": "2/3/17",
                "Profil agressif": "141.3609536",
                "": ""
            },
            {
                "Date": "2/10/17",
                "Profil agressif": "141.8160538",
                "": ""
            },
            {
                "Date": "2/17/17",
                "Profil agressif": "141.1858771",
                "": ""
            },
            {
                "Date": "2/24/17",
                "Profil agressif": "137.5716914",
                "": ""
            },
            {
                "Date": "3/3/17",
                "Profil agressif": "138.9387693",
                "": ""
            },
            {
                "Date": "3/10/17",
                "Profil agressif": "138.9369691",
                "": ""
            },
            {
                "Date": "3/17/17",
                "Profil agressif": "139.8981885",
                "": ""
            },
            {
                "Date": "3/24/17",
                "Profil agressif": "135.9567421",
                "": ""
            },
            {
                "Date": "3/31/17",
                "Profil agressif": "135.1782163",
                "": ""
            },
            {
                "Date": "4/7/17",
                "Profil agressif": "134.6091921",
                "": ""
            },
            {
                "Date": "4/14/17",
                "Profil agressif": "134.0903332",
                "": ""
            },
            {
                "Date": "4/21/17",
                "Profil agressif": "135.3235929",
                "": ""
            },
            {
                "Date": "4/28/17",
                "Profil agressif": "137.7905703",
                "": ""
            },
            {
                "Date": "5/5/17",
                "Profil agressif": "138.4568683",
                "": ""
            },
            {
                "Date": "5/12/17",
                "Profil agressif": "138.878349",
                "": ""
            },
            {
                "Date": "5/19/17",
                "Profil agressif": "138.6901461",
                "": ""
            },
            {
                "Date": "5/26/17",
                "Profil agressif": "139.0171469",
                "": ""
            },
            {
                "Date": "6/2/17",
                "Profil agressif": "139.5358287",
                "": ""
            },
            {
                "Date": "6/9/17",
                "Profil agressif": "140.5030965",
                "": ""
            },
            {
                "Date": "6/16/17",
                "Profil agressif": "141.0785972",
                "": ""
            },
            {
                "Date": "6/23/17",
                "Profil agressif": "143.5040762",
                "": ""
            },
            {
                "Date": "6/30/17",
                "Profil agressif": "144.14775",
                "": ""
            },
            {
                "Date": "7/7/17",
                "Profil agressif": "147.9179238",
                "": ""
            },
            {
                "Date": "7/14/17",
                "Profil agressif": "147.8180582",
                "": ""
            },
            {
                "Date": "7/21/17",
                "Profil agressif": "145.9108091",
                "": ""
            },
            {
                "Date": "7/28/17",
                "Profil agressif": "148.1423994",
                "": ""
            },
            {
                "Date": "8/4/17",
                "Profil agressif": "146.7394223",
                "": ""
            },
            {
                "Date": "8/11/17",
                "Profil agressif": "148.6894466",
                "": ""
            },
            {
                "Date": "8/18/17",
                "Profil agressif": "148.7195234",
                "": ""
            },
            {
                "Date": "8/25/17",
                "Profil agressif": "148.2376424",
                "": ""
            },
            {
                "Date": "9/4/17",
                "Profil agressif": "150.2597218",
                "": ""
            },
            {
                "Date": "9/8/17",
                "Profil agressif": "152.2596784",
                "": ""
            },
            {
                "Date": "9/15/17",
                "Profil agressif": "153.2753626",
                "": ""
            },
            {
                "Date": "9/25/17",
                "Profil agressif": "150.4914039",
                "": ""
            },
            {
                "Date": "9/29/17",
                "Profil agressif": "148.4020925",
                "": ""
            },
            {
                "Date": "10/6/17",
                "Profil agressif": "150.7366764",
                "": ""
            },
            {
                "Date": "10/13/17",
                "Profil agressif": "152.1487569",
                "": ""
            },
            {
                "Date": "10/20/17",
                "Profil agressif": "150.2350614",
                "": ""
            },
            {
                "Date": "10/27/17",
                "Profil agressif": "151.2850464",
                "": ""
            },
            {
                "Date": "11/3/17",
                "Profil agressif": "151.8123421",
                "": ""
            },
            {
                "Date": "11/10/17",
                "Profil agressif": "153.7368896",
                "": ""
            },
            {
                "Date": "11/17/17",
                "Profil agressif": "153.5169301",
                "": ""
            },
            {
                "Date": "11/24/17",
                "Profil agressif": "153.084322",
                "": ""
            },
            {
                "Date": "12/4/17",
                "Profil agressif": "151.7395588",
                "": ""
            },
            {
                "Date": "12/8/17",
                "Profil agressif": "151.096979",
                "": ""
            },
            {
                "Date": "12/15/17",
                "Profil agressif": "150.880514",
                "": ""
            },
            {
                "Date": "12/22/17",
                "Profil agressif": "148.9047687",
                "": ""
            },
            {
                "Date": "12/29/17",
                "Profil agressif": "150.9410185",
                "": ""
            },
            {
                "Date": "1/5/18",
                "Profil agressif": "152.4838406",
                "": ""
            },
            {
                "Date": "1/12/18",
                "Profil agressif": "154.008867",
                "": ""
            },
            {
                "Date": "1/19/18",
                "Profil agressif": "156.6937425",
                "": ""
            },
            {
                "Date": "1/26/18",
                "Profil agressif": "157.6020091",
                "": ""
            },
            {
                "Date": "2/2/18",
                "Profil agressif": "159.2531919",
                "": ""
            },
            {
                "Date": "2/9/18",
                "Profil agressif": "157.8807263",
                "": ""
            },
            {
                "Date": "2/16/18",
                "Profil agressif": "158.3449626",
                "": ""
            },
            {
                "Date": "2/23/18",
                "Profil agressif": "158.4476699",
                "": ""
            },
            {
                "Date": "3/2/18",
                "Profil agressif": "158.2608837",
                "": ""
            },
            {
                "Date": "3/9/18",
                "Profil agressif": "158.8515953",
                "": ""
            },
            {
                "Date": "3/16/18",
                "Profil agressif": "157.9841963",
                "": ""
            },
            {
                "Date": "3/23/18",
                "Profil agressif": "156.9756722",
                "": ""
            },
            {
                "Date": "3/30/18",
                "Profil agressif": "156.8920844",
                "": ""
            },
            {
                "Date": "4/6/18",
                "Profil agressif": "155.7352517",
                "": ""
            },
            {
                "Date": "4/13/18",
                "Profil agressif": "156.483605",
                "": ""
            },
            {
                "Date": "4/20/18",
                "Profil agressif": "156.3173114",
                "": ""
            },
            {
                "Date": "4/27/18",
                "Profil agressif": "157.0330108",
                "": ""
            },
            {
                "Date": "5/4/18",
                "Profil agressif": "156.7861506",
                "": ""
            },
            {
                "Date": "5/11/18",
                "Profil agressif": "155.0460692",
                "": ""
            },
            {
                "Date": "5/18/18",
                "Profil agressif": "152.7262948",
                "": ""
            },
            {
                "Date": "5/25/18",
                "Profil agressif": "152.8221989",
                "": ""
            },
            {
                "Date": "6/1/18",
                "Profil agressif": "150.5814925",
                "": ""
            },
            {
                "Date": "6/8/18",
                "Profil agressif": "152.2175287",
                "": ""
            },
            {
                "Date": "6/18/18",
                "Profil agressif": "151.1934158",
                "": ""
            },
            {
                "Date": "6/22/18",
                "Profil agressif": "150.7922654",
                "": ""
            },
            {
                "Date": "6/29/18",
                "Profil agressif": "148.2710052",
                "": ""
            },
            {
                "Date": "7/6/18",
                "Profil agressif": "146.5148175",
                "": ""
            },
            {
                "Date": "7/13/18",
                "Profil agressif": "143.8470282",
                "": ""
            },
            {
                "Date": "7/20/18",
                "Profil agressif": "143.3113133",
                "": ""
            },
            {
                "Date": "7/27/18",
                "Profil agressif": "146.286793",
                "": ""
            },
            {
                "Date": "8/3/18",
                "Profil agressif": "146.0407401",
                "": ""
            },
            {
                "Date": "8/10/18",
                "Profil agressif": "145.9380925",
                "": ""
            },
            {
                "Date": "8/17/18",
                "Profil agressif": "145.7941636",
                "": ""
            },
            {
                "Date": "8/24/18",
                "Profil agressif": "145.6196497",
                "": ""
            },
            {
                "Date": "8/31/18",
                "Profil agressif": "144.5191792",
                "": ""
            },
            {
                "Date": "9/7/18",
                "Profil agressif": "139.7119086",
                "": ""
            },
            {
                "Date": "9/14/18",
                "Profil agressif": "140.8304133",
                "": ""
            },
            {
                "Date": "9/21/18",
                "Profil agressif": "140.9508869",
                "": ""
            },
            {
                "Date": "9/28/18",
                "Profil agressif": "141.9304168",
                "": ""
            },
            {
                "Date": "10/5/18",
                "Profil agressif": "141.2639498",
                "": ""
            },
            {
                "Date": "10/12/18",
                "Profil agressif": "140.7681922",
                "": ""
            },
            {
                "Date": "10/19/18",
                "Profil agressif": "140.372956",
                "": ""
            },
            {
                "Date": "10/26/18",
                "Profil agressif": "140.1711669",
                "": ""
            },
            {
                "Date": "11/2/18",
                "Profil agressif": "138.3955623",
                "": ""
            },
            {
                "Date": "11/9/18",
                "Profil agressif": "139.4847812",
                "": ""
            },
            {
                "Date": "11/16/18",
                "Profil agressif": "139.7954411",
                "": ""
            },
            {
                "Date": "11/23/18",
                "Profil agressif": "139.572061",
                "": ""
            },
            {
                "Date": "11/30/18",
                "Profil agressif": "140.7123995",
                "": ""
            },
            {
                "Date": "12/7/18",
                "Profil agressif": "140.4819768",
                "": ""
            },
            {
                "Date": "12/14/18",
                "Profil agressif": "139.9847199",
                "": ""
            },
            {
                "Date": "12/21/18",
                "Profil agressif": "140.313766",
                "": ""
            },
            {
                "Date": "12/28/18",
                "Profil agressif": "140.2940569",
                "": ""
            },
            {
                "Date": "1/4/19",
                "Profil agressif": "139.7497717",
                "": ""
            },
            {
                "Date": "1/14/19",
                "Profil agressif": "139.4181102",
                "": ""
            },
            {
                "Date": "1/18/19",
                "Profil agressif": "140.5455431",
                "": ""
            },
            {
                "Date": "1/25/19",
                "Profil agressif": "141.6018751",
                "": ""
            },
            {
                "Date": "2/1/19",
                "Profil agressif": "142.47012",
                "": ""
            },
            {
                "Date": "2/8/19",
                "Profil agressif": "142.8134602",
                "": ""
            },
            {
                "Date": "2/15/19",
                "Profil agressif": "142.9499553",
                "": ""
            },
            {
                "Date": "2/22/19",
                "Profil agressif": "142.3858763",
                "": ""
            },
            {
                "Date": "3/1/19",
                "Profil agressif": "140.9635075",
                "": ""
            },
            {
                "Date": "3/8/19",
                "Profil agressif": "140.3730562",
                "": ""
            },
            {
                "Date": "3/15/19",
                "Profil agressif": "139.3335263",
                "": ""
            },
            {
                "Date": "3/22/19",
                "Profil agressif": "139.1875737",
                "": ""
            },
            {
                "Date": "3/29/19",
                "Profil agressif": "138.8250612",
                "": ""
            },
            {
                "Date": "4/5/19",
                "Profil agressif": "138.530888",
                "": ""
            },
            {
                "Date": "4/12/19",
                "Profil agressif": "139.4038193",
                "": ""
            },
            {
                "Date": "4/19/19",
                "Profil agressif": "140.3959533",
                "": ""
            },
            {
                "Date": "4/26/19",
                "Profil agressif": "140.7314803",
                "": ""
            },
            {
                "Date": "5/3/19",
                "Profil agressif": "141.1581711",
                "": ""
            },
            {
                "Date": "5/10/19",
                "Profil agressif": "142.0742921",
                "": ""
            },
            {
                "Date": "5/17/19",
                "Profil agressif": "143.1850976",
                "": ""
            },
            {
                "Date": "5/24/19",
                "Profil agressif": "141.9618628",
                "": ""
            },
            {
                "Date": "5/31/19",
                "Profil agressif": "141.826669",
                "": ""
            },
            {
                "Date": "6/10/19",
                "Profil agressif": "142.0551819",
                "": ""
            },
            {
                "Date": "6/14/19",
                "Profil agressif": "143.2874295",
                "": ""
            },
            {
                "Date": "6/21/19",
                "Profil agressif": "147.3062179",
                "": ""
            },
            {
                "Date": "6/28/19",
                "Profil agressif": "146.6362226",
                "": ""
            },
            {
                "Date": "7/5/19",
                "Profil agressif": "146.7422675",
                "": ""
            },
            {
                "Date": "7/12/19",
                "Profil agressif": "147.6830584",
                "": ""
            },
            {
                "Date": "7/19/19",
                "Profil agressif": "149.7072513",
                "": ""
            },
            {
                "Date": "7/26/19",
                "Profil agressif": "150.6770238",
                "": ""
            },
            {
                "Date": "8/2/19",
                "Profil agressif": "151.2385811",
                "": ""
            },
            {
                "Date": "8/9/19",
                "Profil agressif": "150.7690714",
                "": ""
            },
            {
                "Date": "8/16/19",
                "Profil agressif": "151.3161795",
                "": ""
            },
            {
                "Date": "8/23/19",
                "Profil agressif": "151.1100151",
                "": ""
            },
            {
                "Date": "8/30/19",
                "Profil agressif": "152.3984325",
                "": ""
            },
            {
                "Date": "9/6/19",
                "Profil agressif": "151.1844443",
                "": ""
            },
            {
                "Date": "9/13/19",
                "Profil agressif": "151.0827687",
                "": ""
            },
            {
                "Date": "9/20/19",
                "Profil agressif": "149.3152032",
                "": ""
            },
            {
                "Date": "9/27/19",
                "Profil agressif": "150.3950755",
                "": ""
            },
            {
                "Date": "10/4/19",
                "Profil agressif": "149.298287",
                "": ""
            },
            {
                "Date": "10/11/19",
                "Profil agressif": "148.2751489",
                "": ""
            },
            {
                "Date": "10/18/19",
                "Profil agressif": "149.7122068",
                "": ""
            },
            {
                "Date": "10/25/19",
                "Profil agressif": "150.4524071",
                "": ""
            },
            {
                "Date": "11/1/19",
                "Profil agressif": "150.3335715",
                "": ""
            },
            {
                "Date": "11/8/19",
                "Profil agressif": "150.3060752",
                "": ""
            },
            {
                "Date": "11/15/19",
                "Profil agressif": "151.0029785",
                "": ""
            },
            {
                "Date": "11/22/19",
                "Profil agressif": "152.9879661",
                "": ""
            },
            {
                "Date": "11/29/19",
                "Profil agressif": "152.9246819",
                "": ""
            },
            {
                "Date": "12/6/19",
                "Profil agressif": "152.799771",
                "": ""
            },
            {
                "Date": "12/13/19",
                "Profil agressif": "154.8880669",
                "": ""
            },
            {
                "Date": "12/20/19",
                "Profil agressif": "157.261462",
                "": ""
            },
            {
                "Date": "12/27/19",
                "Profil agressif": "157.3284619",
                "": ""
            },
            {
                "Date": "1/3/20",
                "Profil agressif": "157.4948795",
                "": ""
            },
            {
                "Date": "1/10/20",
                "Profil agressif": "159.593032",
                "": ""
            },
            {
                "Date": "1/17/20",
                "Profil agressif": "161.9470242",
                "": ""
            },
            {
                "Date": "1/24/20",
                "Profil agressif": "162.9733739",
                "": ""
            },
            {
                "Date": "1/31/20",
                "Profil agressif": "162.344044",
                "": ""
            },
            {
                "Date": "2/7/20",
                "Profil agressif": "158.0115178",
                "": ""
            },
            {
                "Date": "2/14/20",
                "Profil agressif": "160.101503",
                "": ""
            },
            {
                "Date": "2/21/20",
                "Profil agressif": "162.3077384",
                "": ""
            },
            {
                "Date": "2/28/20",
                "Profil agressif": "159.5652687",
                "": ""
            },
            {
                "Date": "3/6/20",
                "Profil agressif": "151.4455087",
                "": ""
            },
            {
                "Date": "3/13/20",
                "Profil agressif": "140.6237915",
                "": ""
            },
            {
                "Date": "3/20/20",
                "Profil agressif": "130.1929499",
                "": ""
            },
            {
                "Date": "3/27/20",
                "Profil agressif": "132.7732478",
                "": ""
            },
            {
                "Date": "4/3/20",
                "Profil agressif": "129.6728991",
                "": ""
            },
            {
                "Date": "4/10/20",
                "Profil agressif": "128.9292929",
                "": ""
            },
            {
                "Date": "4/17/20",
                "Profil agressif": "126.8846405",
                "": ""
            },
            {
                "Date": "4/24/20",
                "Profil agressif": "126.5302192",
                "": ""
            },
            {
                "Date": "5/4/20",
                "Profil agressif": "130.312927",
                "": ""
            },
            {
                "Date": "5/8/20",
                "Profil agressif": "131.6045959",
                "": ""
            },
            {
                "Date": "5/15/20",
                "Profil agressif": "130.9539792",
                "": ""
            },
            {
                "Date": "5/22/20",
                "Profil agressif": "134.2408538",
                "": ""
            },
            {
                "Date": "5/29/20",
                "Profil agressif": "136.5272082",
                "": ""
            },
            {
                "Date": "6/5/20",
                "Profil agressif": "139.1503386",
                "": ""
            },
            {
                "Date": "6/12/20",
                "Profil agressif": "137.7981948",
                "": ""
            },
            {
                "Date": "6/19/20",
                "Profil agressif": "142.4946589",
                "": ""
            },
            {
                "Date": "6/26/20",
                "Profil agressif": "140.1810379",
                "": ""
            },
            {
                "Date": "7/3/20",
                "Profil agressif": "141.3878681",
                "": ""
            },
            {
                "Date": "7/10/20",
                "Profil agressif": "140.8212964",
                "": ""
            },
            {
                "Date": "7/17/20",
                "Profil agressif": "142.4731454",
                "": ""
            },
            {
                "Date": "7/24/20",
                "Profil agressif": "142.85268",
                "": ""
            },
            {
                "Date": "8/3/20",
                "Profil agressif": "142.7801535",
                "": ""
            },
            {
                "Date": "8/7/20",
                "Profil agressif": "144.726276",
                "": ""
            },
            {
                "Date": "8/17/20",
                "Profil agressif": "144.6625109",
                "": ""
            },
            {
                "Date": "8/24/20",
                "Profil agressif": "144.5601246",
                "": ""
            },
            {
                "Date": "8/28/20",
                "Profil agressif": "142.9894091",
                "": ""
            },
            {
                "Date": "9/4/20",
                "Profil agressif": "143.9559443",
                "": ""
            },
            {
                "Date": "9/11/20",
                "Profil agressif": "144.5832557",
                "": ""
            },
            {
                "Date": "9/18/20",
                "Profil agressif": "143.1577619",
                "": ""
            },
            {
                "Date": "9/25/20",
                "Profil agressif": "141.5407349",
                "": ""
            },
            {
                "Date": "10/2/20",
                "Profil agressif": "143.1306779",
                "": ""
            },
            {
                "Date": "10/9/20",
                "Profil agressif": "144.4327818",
                "": ""
            },
            {
                "Date": "10/16/20",
                "Profil agressif": "145.6224524",
                "": ""
            },
            {
                "Date": "10/23/20",
                "Profil agressif": "146.9592789",
                "": ""
            },
            {
                "Date": "11/2/20",
                "Profil agressif": "145.8782946",
                "": ""
            },
            {
                "Date": "11/9/20",
                "Profil agressif": "147.4832723",
                "": ""
            },
            {
                "Date": "11/13/20",
                "Profil agressif": "148.616685",
                "": ""
            },
            {
                "Date": "11/20/20",
                "Profil agressif": "150.7866977",
                "": ""
            },
            {
                "Date": "11/27/20",
                "Profil agressif": "149.9654678",
                "": ""
            },
            {
                "Date": "12/4/20",
                "Profil agressif": "151.4570665",
                "": ""
            },
            {
                "Date": "12/11/20",
                "Profil agressif": "155.5972963",
                "": ""
            },
            {
                "Date": "12/18/20",
                "Profil agressif": "157.7038039",
                "": ""
            },
            {
                "Date": "12/25/20",
                "Profil agressif": "154.8828512",
                "": ""
            },
            {
                "Date": "1/4/21",
                "Profil agressif": "156.5556924",
                "": ""
            },
            {
                "Date": "1/8/21",
                "Profil agressif": "155.433683",
                "": ""
            },
            {
                "Date": "1/15/21",
                "Profil agressif": "157.0430636",
                "": ""
            },
            {
                "Date": "1/22/21",
                "Profil agressif": "158.7414169",
                "": ""
            },
            {
                "Date": "1/29/21",
                "Profil agressif": "159.6352075",
                "": ""
            },
            {
                "Date": "2/5/21",
                "Profil agressif": "160.4678955",
                "": ""
            },
            {
                "Date": "2/12/21",
                "Profil agressif": "158.7279307",
                "": ""
            },
            {
                "Date": "2/19/21",
                "Profil agressif": "157.5126403",
                "": ""
            },
            {
                "Date": "2/26/21",
                "Profil agressif": "155.8729455",
                "": ""
            },
            {
                "Date": "3/5/21",
                "Profil agressif": "155.9289452",
                "": ""
            },
            {
                "Date": "3/12/21",
                "Profil agressif": "156.5612678",
                "": ""
            },
            {
                "Date": "3/19/21",
                "Profil agressif": "156.9997051",
                "": ""
            },
            {
                "Date": "3/26/21",
                "Profil agressif": "157.5706771",
                "": ""
            },
            {
                "Date": "4/2/21",
                "Profil agressif": "158.0898351",
                "": ""
            },
            {
                "Date": "4/9/21",
                "Profil agressif": "158.3209021",
                "": ""
            },
            {
                "Date": "4/16/21",
                "Profil agressif": "159.5113624",
                "": ""
            },
            {
                "Date": "4/23/21",
                "Profil agressif": "160.2337319",
                "": ""
            },
            {
                "Date": "4/30/21",
                "Profil agressif": "161.0594993",
                "": ""
            },
            {
                "Date": "5/7/21",
                "Profil agressif": "163.9738858",
                "": ""
            },
            {
                "Date": "5/17/21",
                "Profil agressif": "165.9217806",
                "": ""
            },
            {
                "Date": "5/21/21",
                "Profil agressif": "165.6851945",
                "": ""
            },
            {
                "Date": "5/28/21",
                "Profil agressif": "165.8708975",
                "": ""
            },
            {
                "Date": "6/4/21",
                "Profil agressif": "168.1301572",
                "": ""
            },
            {
                "Date": "6/11/21",
                "Profil agressif": "169.0883434",
                "": ""
            },
            {
                "Date": "6/18/21",
                "Profil agressif": "168.2471461",
                "": ""
            },
            {
                "Date": "6/25/21",
                "Profil agressif": "167.4376368",
                "": ""
            },
            {
                "Date": "7/2/21",
                "Profil agressif": "167.6077289",
                "": ""
            },
            {
                "Date": "7/9/21",
                "Profil agressif": "166.8455986",
                "": ""
            },
            {
                "Date": "7/16/21",
                "Profil agressif": "166.6180559",
                "": ""
            },
            {
                "Date": "7/23/21",
                "Profil agressif": "167.0479907",
                "": ""
            },
            {
                "Date": "8/2/21",
                "Profil agressif": "169.6064925",
                "": ""
            },
            {
                "Date": "8/6/21",
                "Profil agressif": "170.0114642",
                "": ""
            },
            {
                "Date": "8/13/21",
                "Profil agressif": "171.2144795",
                "": ""
            },
            {
                "Date": "8/23/21",
                "Profil agressif": "172.1548935",
                "": ""
            },
            {
                "Date": "8/27/21",
                "Profil agressif": "173.2233144",
                "": ""
            },
            {
                "Date": "9/3/21",
                "Profil agressif": "173.1635453",
                "": ""
            },
            {
                "Date": "9/10/21",
                "Profil agressif": "174.8947684",
                "": ""
            },
            {
                "Date": "9/17/21",
                "Profil agressif": "176.3877152",
                "": ""
            },
            {
                "Date": "9/24/21",
                "Profil agressif": "177.6493684",
                "": ""
            },
            {
                "Date": "10/1/21",
                "Profil agressif": "177.3538297",
                "": ""
            },
            {
                "Date": "10/8/21",
                "Profil agressif": "178.6204491",
                "": ""
            },
            {
                "Date": "10/15/21",
                "Profil agressif": "179.8516155",
                "": ""
            },
            {
                "Date": "10/22/21",
                "Profil agressif": "180.1709792",
                "": ""
            },
            {
                "Date": "10/29/21",
                "Profil agressif": "182.4560885",
                "": ""
            },
            {
                "Date": "11/5/21",
                "Profil agressif": "180.8947163",
                "": ""
            },
            {
                "Date": "11/12/21",
                "Profil agressif": "180.0721",
                "": ""
            },
            {
                "Date": "11/19/21",
                "Profil agressif": "180.3684114",
                "": ""
            },
            {
                "Date": "11/26/21",
                "Profil agressif": "178.7222632",
                "": ""
            },
            {
                "Date": "12/3/21",
                "Profil agressif": "178.3338409",
                "": ""
            },
            {
                "Date": "12/10/21",
                "Profil agressif": "179.9414209",
                "": ""
            },
            {
                "Date": "12/17/21",
                "Profil agressif": "179.682656",
                "": ""
            },
            {
                "Date": "12/24/21",
                "Profil agressif": "179.0254533",
                "": ""
            },
            {
                "Date": "12/31/21",
                "Profil agressif": "180.4928098",
                "": ""
            },
            {
                "Date": "1/7/22",
                "Profil agressif": "181.7780229",
                "": ""
            },
            {
                "Date": "1/14/22",
                "Profil agressif": "185.08866",
                "": ""
            },
            {
                "Date": "1/21/22",
                "Profil agressif": "185.0684048",
                "": ""
            },
            {
                "Date": "1/28/22",
                "Profil agressif": "184.7013697",
                "": ""
            },
            {
                "Date": "2/4/22",
                "Profil agressif": "186.01433",
                "": ""
            },
            {
                "Date": "2/11/22",
                "Profil agressif": "186.2514373",
                "": ""
            },
            {
                "Date": "2/18/22",
                "Profil agressif": "184.3091748",
                "": ""
            },
            {
                "Date": "2/25/22",
                "Profil agressif": "177.9186194",
                "": ""
            },
            {
                "Date": "3/4/22",
                "Profil agressif": "174.4148989",
                "": ""
            },
            {
                "Date": "3/11/22",
                "Profil agressif": "172.9768885",
                "": ""
            },
            {
                "Date": "3/18/22",
                "Profil agressif": "175.7424575",
                "": ""
            },
            {
                "Date": "3/25/22",
                "Profil agressif": "172.947678",
                "": ""
            },
            {
                "Date": "4/1/22",
                "Profil agressif": "175.2109445",
                "": ""
            },
            {
                "Date": "4/8/22",
                "Profil agressif": "176.1296708",
                "": ""
            },
            {
                "Date": "4/15/22",
                "Profil agressif": "176.8150158",
                "": ""
            },
            {
                "Date": "4/22/22",
                "Profil agressif": "178.8282963",
                "": ""
            },
            {
                "Date": "4/29/22",
                "Profil agressif": "178.4180277",
                "": ""
            },
            {
                "Date": "5/6/22",
                "Profil agressif": "177.4256203",
                "": ""
            },
            {
                "Date": "5/13/22",
                "Profil agressif": "172.8916973",
                "": ""
            },
            {
                "Date": "5/20/22",
                "Profil agressif": "172.4154763",
                "": ""
            },
            {
                "Date": "5/27/22",
                "Profil agressif": "169.0212115",
                "": ""
            },
            {
                "Date": "6/3/22",
                "Profil agressif": "172.3938766",
                "": ""
            },
            {
                "Date": "6/10/22",
                "Profil agressif": "172.1972081",
                "": ""
            },
            {
                "Date": "6/17/22",
                "Profil agressif": "167.2707871",
                "": ""
            },
            {
                "Date": "6/24/22",
                "Profil agressif": "166.5674798",
                "": ""
            },
            {
                "Date": "7/1/22",
                "Profil agressif": "163.1300711",
                "": ""
            },
            {
                "Date": "7/8/22",
                "Profil agressif": "163.4657614",
                "": ""
            },
            {
                "Date": "7/15/22",
                "Profil agressif": "165.3558377",
                "": ""
            },
            {
                "Date": "7/22/22",
                "Profil agressif": "166.7061327",
                "": ""
            },
            {
                "Date": "7/29/22",
                "Profil agressif": "165.617252",
                "": ""
            },
            {
                "Date": "8/5/22",
                "Profil agressif": "167.3347427",
                "": ""
            },
            {
                "Date": "8/12/22",
                "Profil agressif": "169.6592978",
                "": ""
            },
            {
                "Date": "8/19/22",
                "Profil agressif": "169.7663118",
                "": ""
            },
            {
                "Date": "8/26/22",
                "Profil agressif": "169.9064619",
                "": ""
            },
            {
                "Date": "9/2/22",
                "Profil agressif": "168.8874401",
                "": ""
            },
            {
                "Date": "9/9/22",
                "Profil agressif": "169.4744625",
                "": ""
            },
            {
                "Date": "9/16/22",
                "Profil agressif": "169.7022017",
                "": ""
            },
            {
                "Date": "9/23/22",
                "Profil agressif": "170.3583731",
                "": ""
            },
            {
                "Date": "9/30/22",
                "Profil agressif": "163.1059304",
                "": ""
            },
            {
                "Date": "10/7/22",
                "Profil agressif": "163.9217529",
                "": ""
            },
            {
                "Date": "10/14/22",
                "Profil agressif": "160.4677313",
                "": ""
            },
            {
                "Date": "10/21/22",
                "Profil agressif": "155.1409974",
                "": ""
            },
            {
                "Date": "10/28/22",
                "Profil agressif": "153.9110737",
                "": ""
            },
            {
                "Date": "11/4/22",
                "Profil agressif": "153.1044704",
                "": ""
            },
            {
                "Date": "11/11/22",
                "Profil agressif": "149.1614725",
                "": ""
            },
            {
                "Date": "11/21/22",
                "Profil agressif": "153.4164359",
                "": ""
            },
            {
                "Date": "11/25/22",
                "Profil agressif": "153.8983273",
                "": ""
            },
            {
                "Date": "12/2/22",
                "Profil agressif": "156.2492559",
                "": ""
            },
            {
                "Date": "12/9/22",
                "Profil agressif": "159.0888011",
                "": ""
            },
            {
                "Date": "12/16/22",
                "Profil agressif": "156.5037157",
                "": ""
            },
            {
                "Date": "12/23/22",
                "Profil agressif": "155.4711817",
                "": ""
            },
            {
                "Date": "12/30/22",
                "Profil agressif": "152.1094898",
                "": ""
            },
            {
                "Date": "1/6/23",
                "Profil agressif": "140.6632636",
                "": ""
            },
            {
                "Date": "1/13/23",
                "Profil agressif": "147.385588",
                "": ""
            },
            {
                "Date": "1/20/23",
                "Profil agressif": "146.4717295",
                "": ""
            },
            {
                "Date": "1/27/23",
                "Profil agressif": "145.0042311",
                "": ""
            },
            {
                "Date": "2/3/23",
                "Profil agressif": "148.1722803",
                "": ""
            },
            {
                "Date": "2/10/23",
                "Profil agressif": "152.2408273",
                "": ""
            },
            {
                "Date": "2/17/23",
                "Profil agressif": "151.7392091",
                "": ""
            },
            {
                "Date": "2/24/23",
                "Profil agressif": "153.4678595",
                "": ""
            },
            {
                "Date": "3/3/23",
                "Profil agressif": "151.9687958",
                "": ""
            },
            {
                "Date": "3/10/23",
                "Profil agressif": "150.4284106",
                "": ""
            },
            {
                "Date": "3/17/23",
                "Profil agressif": "149.6188235",
                "": ""
            },
            {
                "Date": "3/24/23",
                "Profil agressif": "149.5508613",
                "": ""
            },
            {
                "Date": "3/31/23",
                "Profil agressif": "149.1082127",
                "": ""
            },
            {
                "Date": "4/7/23",
                "Profil agressif": "150.0073444",
                "": ""
            },
            {
                "Date": "4/14/23",
                "Profil agressif": "150.17272",
                "": ""
            },
            {
                "Date": "4/21/23",
                "Profil agressif": "149.9687563",
                "": ""
            },
            {
                "Date": "4/28/23",
                "Profil agressif": "150.1206393",
                "": ""
            },
            {
                "Date": "5/5/23",
                "Profil agressif": "148.6499895",
                "": ""
            },
            {
                "Date": "5/12/23",
                "Profil agressif": "152.6669666",
                "": ""
            },
            {
                "Date": "5/19/23",
                "Profil agressif": "152.9814907",
                "": ""
            },
            {
                "Date": "5/26/23",
                "Profil agressif": "155.0815183",
                "": ""
            },
            {
                "Date": "6/2/23",
                "Profil agressif": "157.1778891",
                "": ""
            },
            {
                "Date": "6/9/23",
                "Profil agressif": "155.8501852",
                "": ""
            },
            {
                "Date": "6/16/23",
                "Profil agressif": "159.8688843",
                "": ""
            },
            {
                "Date": "6/23/23",
                "Profil agressif": "160.8541987",
                "": ""
            },
            {
                "Date": "7/3/23",
                "Profil agressif": "161.671464",
                "": ""
            },
            {
                "Date": "7/7/23",
                "Profil agressif": "162.4978417",
                "": ""
            },
            {
                "Date": "7/14/23",
                "Profil agressif": "163.2199453",
                "": ""
            },
            {
                "Date": "7/21/23",
                "Profil agressif": "166.3532805",
                "": ""
            },
            {
                "Date": "7/28/23",
                "Profil agressif": "169.435538",
                "": ""
            },
            {
                "Date": "8/4/23",
                "Profil agressif": "170.0962511",
                "": ""
            },
            {
                "Date": "8/11/23",
                "Profil agressif": "168.7533831",
                "": ""
            },
            {
                "Date": "8/18/23",
                "Profil agressif": "168.4091371",
                "": ""
            },
            {
                "Date": "8/25/23",
                "Profil agressif": "167.9203965",
                "": ""
            },
            {
                "Date": "9/1/23",
                "Profil agressif": "167.758103",
                "": ""
            },
            {
                "Date": "9/8/23",
                "Profil agressif": "165.8013087",
                "": ""
            },
            {
                "Date": "9/15/23",
                "Profil agressif": "166.4509175",
                "": ""
            },
            {
                "Date": "9/22/23",
                "Profil agressif": "166.1618392",
                "": ""
            },
            {
                "Date": "10/2/23",
                "Profil agressif": "165.6123329",
                "": ""
            },
            {
                "Date": "10/6/23",
                "Profil agressif": "170.9652191",
                "": ""
            },
            {
                "Date": "10/13/23",
                "Profil agressif": "170.8571967",
                "": ""
            },
            {
                "Date": "10/20/23",
                "Profil agressif": "171.4972174",
                "": ""
            },
            {
                "Date": "10/27/23",
                "Profil agressif": "168.6820715",
                "": ""
            },
            {
                "Date": "11/3/23",
                "Profil agressif": "168.6636415",
                "": ""
            },
            {
                "Date": "11/10/23",
                "Profil agressif": "167.8847501",
                "": ""
            },
            {
                "Date": "11/17/23",
                "Profil agressif": "167.7742973",
                "": ""
            },
            {
                "Date": "11/24/23",
                "Profil agressif": "167.8369254",
                "": ""
            },
            {
                "Date": "12/1/23",
                "Profil agressif": "166.0423499",
                "": ""
            },
            {
                "Date": "12/8/23",
                "Profil agressif": "167.395551",
                "": ""
            },
            {
                "Date": "12/15/23",
                "Profil agressif": "170.1083194",
                "": ""
            },
            {
                "Date": "12/22/23",
                "Profil agressif": "169.6048462",
                "": ""
            },
            {
                "Date": "12/29/23",
                "Profil agressif": "168.7689274",
                "": ""
            },
            {
                "Date": "1/5/24",
                "Profil agressif": "171.5041163",
                "": ""
            },
            {
                "Date": "1/12/24",
                "Profil agressif": "172.9277724",
                "": ""
            },
            {
                "Date": "",
                "Profil agressif": "",
                "": ""
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
            setChartData(yourDataSet[0]);
        }
    }, [yourDataSet]);

    const getOption = () => {
        if (!chartData || chartData.length === 0) {
            return {};
        }

        const xAxisData = chartData.map((item) => item.Date);

        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');

        const series = legendData.map((key) => ({
            type: 'line',
            name: key,
            data: chartData.map((item) => {
                const value = item[key];
                const parsedValue = parseFloat(value.replace(',', ' '));
                return isNaN(parsedValue) ? null : parsedValue;
            }),
        }));

        return {
            animationDuration: 10000,
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: '',
                left: 'center',

            },
            legend: {
                data: legendData,
                bottom: 10,
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                min: 100,
            },
            series,
        };
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactEChartsCore echarts={echarts} option={getOption()} />

            </Col>
        </Row>
    );
};

export default LineChartComponent;
