import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil prudent": "100",
                "": ""
            },
            {
                "Date": "1/4/16",
                "Profil prudent": "100.038397",
                "": ""
            },
            {
                "Date": "1/8/16",
                "Profil prudent": "100.0658718",
                "": ""
            },
            {
                "Date": "1/15/16",
                "Profil prudent": "100.1140676",
                "": ""
            },
            {
                "Date": "1/22/16",
                "Profil prudent": "100.1900439",
                "": ""
            },
            {
                "Date": "1/29/16",
                "Profil prudent": "100.2449858",
                "": ""
            },
            {
                "Date": "2/5/16",
                "Profil prudent": "100.2982652",
                "": ""
            },
            {
                "Date": "2/12/16",
                "Profil prudent": "100.3537864",
                "": ""
            },
            {
                "Date": "2/19/16",
                "Profil prudent": "100.4055134",
                "": ""
            },
            {
                "Date": "2/26/16",
                "Profil prudent": "100.4604225",
                "": ""
            },
            {
                "Date": "3/4/16",
                "Profil prudent": "100.5256143",
                "": ""
            },
            {
                "Date": "3/11/16",
                "Profil prudent": "100.5700006",
                "": ""
            },
            {
                "Date": "3/18/16",
                "Profil prudent": "100.633014",
                "": ""
            },
            {
                "Date": "3/25/16",
                "Profil prudent": "100.7004237",
                "": ""
            },
            {
                "Date": "4/1/16",
                "Profil prudent": "100.8338922",
                "": ""
            },
            {
                "Date": "4/8/16",
                "Profil prudent": "100.8687467",
                "": ""
            },
            {
                "Date": "4/15/16",
                "Profil prudent": "100.9570073",
                "": ""
            },
            {
                "Date": "4/22/16",
                "Profil prudent": "101.074815",
                "": ""
            },
            {
                "Date": "4/29/16",
                "Profil prudent": "101.1515121",
                "": ""
            },
            {
                "Date": "5/6/16",
                "Profil prudent": "101.1934756",
                "": ""
            },
            {
                "Date": "5/13/16",
                "Profil prudent": "101.2300058",
                "": ""
            },
            {
                "Date": "5/20/16",
                "Profil prudent": "101.2709347",
                "": ""
            },
            {
                "Date": "5/27/16",
                "Profil prudent": "101.3120863",
                "": ""
            },
            {
                "Date": "6/3/16",
                "Profil prudent": "101.3518075",
                "": ""
            },
            {
                "Date": "6/10/16",
                "Profil prudent": "101.4023068",
                "": ""
            },
            {
                "Date": "6/17/16",
                "Profil prudent": "101.4479633",
                "": ""
            },
            {
                "Date": "6/24/16",
                "Profil prudent": "101.4281162",
                "": ""
            },
            {
                "Date": "7/1/16",
                "Profil prudent": "101.3998731",
                "": ""
            },
            {
                "Date": "7/11/16",
                "Profil prudent": "101.4559388",
                "": ""
            },
            {
                "Date": "7/15/16",
                "Profil prudent": "101.397598",
                "": ""
            },
            {
                "Date": "7/22/16",
                "Profil prudent": "101.4041333",
                "": ""
            },
            {
                "Date": "7/29/16",
                "Profil prudent": "101.4311549",
                "": ""
            },
            {
                "Date": "8/5/16",
                "Profil prudent": "101.4706353",
                "": ""
            },
            {
                "Date": "8/12/16",
                "Profil prudent": "101.4960403",
                "": ""
            },
            {
                "Date": "8/19/16",
                "Profil prudent": "101.5449607",
                "": ""
            },
            {
                "Date": "8/26/16",
                "Profil prudent": "101.5804552",
                "": ""
            },
            {
                "Date": "9/2/16",
                "Profil prudent": "101.620396",
                "": ""
            },
            {
                "Date": "9/9/16",
                "Profil prudent": "101.6646374",
                "": ""
            },
            {
                "Date": "9/16/16",
                "Profil prudent": "101.7125053",
                "": ""
            },
            {
                "Date": "9/23/16",
                "Profil prudent": "101.764587",
                "": ""
            },
            {
                "Date": "9/30/16",
                "Profil prudent": "101.8103949",
                "": ""
            },
            {
                "Date": "10/7/16",
                "Profil prudent": "101.8679602",
                "": ""
            },
            {
                "Date": "10/14/16",
                "Profil prudent": "101.9267843",
                "": ""
            },
            {
                "Date": "10/21/16",
                "Profil prudent": "101.9833656",
                "": ""
            },
            {
                "Date": "10/28/16",
                "Profil prudent": "102.0324984",
                "": ""
            },
            {
                "Date": "11/4/16",
                "Profil prudent": "102.0691182",
                "": ""
            },
            {
                "Date": "11/11/16",
                "Profil prudent": "102.1046525",
                "": ""
            },
            {
                "Date": "11/21/16",
                "Profil prudent": "102.165164",
                "": ""
            },
            {
                "Date": "11/25/16",
                "Profil prudent": "102.1923824",
                "": ""
            },
            {
                "Date": "12/2/16",
                "Profil prudent": "102.2349757",
                "": ""
            },
            {
                "Date": "12/9/16",
                "Profil prudent": "102.267451",
                "": ""
            },
            {
                "Date": "12/16/16",
                "Profil prudent": "102.307172",
                "": ""
            },
            {
                "Date": "12/23/16",
                "Profil prudent": "102.3184212",
                "": ""
            },
            {
                "Date": "12/30/16",
                "Profil prudent": "102.3626724",
                "": ""
            },
            {
                "Date": "1/6/17",
                "Profil prudent": "102.3988915",
                "": ""
            },
            {
                "Date": "1/13/17",
                "Profil prudent": "102.4300558",
                "": ""
            },
            {
                "Date": "1/20/17",
                "Profil prudent": "102.4714585",
                "": ""
            },
            {
                "Date": "1/27/17",
                "Profil prudent": "102.5126488",
                "": ""
            },
            {
                "Date": "2/3/17",
                "Profil prudent": "102.5505726",
                "": ""
            },
            {
                "Date": "2/10/17",
                "Profil prudent": "102.6127072",
                "": ""
            },
            {
                "Date": "2/17/17",
                "Profil prudent": "102.6710509",
                "": ""
            },
            {
                "Date": "2/24/17",
                "Profil prudent": "102.7373039",
                "": ""
            },
            {
                "Date": "3/3/17",
                "Profil prudent": "102.7903544",
                "": ""
            },
            {
                "Date": "3/10/17",
                "Profil prudent": "102.8417263",
                "": ""
            },
            {
                "Date": "3/17/17",
                "Profil prudent": "102.8671703",
                "": ""
            },
            {
                "Date": "3/24/17",
                "Profil prudent": "102.9309927",
                "": ""
            },
            {
                "Date": "3/31/17",
                "Profil prudent": "102.9681972",
                "": ""
            },
            {
                "Date": "4/7/17",
                "Profil prudent": "103.0026336",
                "": ""
            },
            {
                "Date": "4/14/17",
                "Profil prudent": "103.0450451",
                "": ""
            },
            {
                "Date": "4/21/17",
                "Profil prudent": "103.092233",
                "": ""
            },
            {
                "Date": "4/28/17",
                "Profil prudent": "103.1421091",
                "": ""
            },
            {
                "Date": "5/5/17",
                "Profil prudent": "103.1777025",
                "": ""
            },
            {
                "Date": "5/12/17",
                "Profil prudent": "103.2317005",
                "": ""
            },
            {
                "Date": "5/19/17",
                "Profil prudent": "103.2747977",
                "": ""
            },
            {
                "Date": "5/26/17",
                "Profil prudent": "103.3212682",
                "": ""
            },
            {
                "Date": "6/2/17",
                "Profil prudent": "103.3563481",
                "": ""
            },
            {
                "Date": "6/9/17",
                "Profil prudent": "103.39708",
                "": ""
            },
            {
                "Date": "6/16/17",
                "Profil prudent": "103.4342076",
                "": ""
            },
            {
                "Date": "6/23/17",
                "Profil prudent": "103.4553551",
                "": ""
            },
            {
                "Date": "6/30/17",
                "Profil prudent": "103.4882425",
                "": ""
            },
            {
                "Date": "7/7/17",
                "Profil prudent": "103.5271945",
                "": ""
            },
            {
                "Date": "7/14/17",
                "Profil prudent": "103.5658108",
                "": ""
            },
            {
                "Date": "7/21/17",
                "Profil prudent": "103.6132627",
                "": ""
            },
            {
                "Date": "7/28/17",
                "Profil prudent": "103.6684117",
                "": ""
            },
            {
                "Date": "8/4/17",
                "Profil prudent": "103.7209034",
                "": ""
            },
            {
                "Date": "8/11/17",
                "Profil prudent": "103.7679136",
                "": ""
            },
            {
                "Date": "8/18/17",
                "Profil prudent": "103.8125956",
                "": ""
            },
            {
                "Date": "8/25/17",
                "Profil prudent": "103.863321",
                "": ""
            },
            {
                "Date": "9/4/17",
                "Profil prudent": "103.9277894",
                "": ""
            },
            {
                "Date": "9/8/17",
                "Profil prudent": "103.9582294",
                "": ""
            },
            {
                "Date": "9/15/17",
                "Profil prudent": "104.0065761",
                "": ""
            },
            {
                "Date": "9/25/17",
                "Profil prudent": "104.0782903",
                "": ""
            },
            {
                "Date": "9/29/17",
                "Profil prudent": "104.11252",
                "": ""
            },
            {
                "Date": "10/6/17",
                "Profil prudent": "104.1626811",
                "": ""
            },
            {
                "Date": "10/13/17",
                "Profil prudent": "104.2126241",
                "": ""
            },
            {
                "Date": "10/20/17",
                "Profil prudent": "104.2599755",
                "": ""
            },
            {
                "Date": "10/27/17",
                "Profil prudent": "104.3051995",
                "": ""
            },
            {
                "Date": "11/3/17",
                "Profil prudent": "104.3514896",
                "": ""
            },
            {
                "Date": "11/10/17",
                "Profil prudent": "104.3951326",
                "": ""
            },
            {
                "Date": "11/17/17",
                "Profil prudent": "104.4405455",
                "": ""
            },
            {
                "Date": "11/24/17",
                "Profil prudent": "104.4833522",
                "": ""
            },
            {
                "Date": "12/4/17",
                "Profil prudent": "104.5291758",
                "": ""
            },
            {
                "Date": "12/8/17",
                "Profil prudent": "104.5615742",
                "": ""
            },
            {
                "Date": "12/15/17",
                "Profil prudent": "104.597097",
                "": ""
            },
            {
                "Date": "12/22/17",
                "Profil prudent": "104.627036",
                "": ""
            },
            {
                "Date": "12/29/17",
                "Profil prudent": "104.6908407",
                "": ""
            },
            {
                "Date": "1/5/18",
                "Profil prudent": "104.7419619",
                "": ""
            },
            {
                "Date": "1/12/18",
                "Profil prudent": "104.7828846",
                "": ""
            },
            {
                "Date": "1/19/18",
                "Profil prudent": "104.8160811",
                "": ""
            },
            {
                "Date": "1/26/18",
                "Profil prudent": "104.8822887",
                "": ""
            },
            {
                "Date": "2/2/18",
                "Profil prudent": "104.9365108",
                "": ""
            },
            {
                "Date": "2/9/18",
                "Profil prudent": "104.9919559",
                "": ""
            },
            {
                "Date": "2/16/18",
                "Profil prudent": "105.0475639",
                "": ""
            },
            {
                "Date": "2/23/18",
                "Profil prudent": "105.0991778",
                "": ""
            },
            {
                "Date": "3/2/18",
                "Profil prudent": "105.1578536",
                "": ""
            },
            {
                "Date": "3/9/18",
                "Profil prudent": "105.2215727",
                "": ""
            },
            {
                "Date": "3/16/18",
                "Profil prudent": "105.2712138",
                "": ""
            },
            {
                "Date": "3/23/18",
                "Profil prudent": "105.3160611",
                "": ""
            },
            {
                "Date": "3/30/18",
                "Profil prudent": "105.3564044",
                "": ""
            },
            {
                "Date": "4/6/18",
                "Profil prudent": "105.3977812",
                "": ""
            },
            {
                "Date": "4/13/18",
                "Profil prudent": "105.4411873",
                "": ""
            },
            {
                "Date": "4/20/18",
                "Profil prudent": "105.4846527",
                "": ""
            },
            {
                "Date": "4/27/18",
                "Profil prudent": "105.5248914",
                "": ""
            },
            {
                "Date": "5/4/18",
                "Profil prudent": "105.5686248",
                "": ""
            },
            {
                "Date": "5/11/18",
                "Profil prudent": "105.6105895",
                "": ""
            },
            {
                "Date": "5/18/18",
                "Profil prudent": "105.6589079",
                "": ""
            },
            {
                "Date": "5/25/18",
                "Profil prudent": "105.6999021",
                "": ""
            },
            {
                "Date": "6/1/18",
                "Profil prudent": "105.7420852",
                "": ""
            },
            {
                "Date": "6/8/18",
                "Profil prudent": "105.8007128",
                "": ""
            },
            {
                "Date": "6/18/18",
                "Profil prudent": "105.8625634",
                "": ""
            },
            {
                "Date": "6/22/18",
                "Profil prudent": "105.8942664",
                "": ""
            },
            {
                "Date": "6/29/18",
                "Profil prudent": "105.9321325",
                "": ""
            },
            {
                "Date": "7/6/18",
                "Profil prudent": "105.9789044",
                "": ""
            },
            {
                "Date": "7/13/18",
                "Profil prudent": "106.0320855",
                "": ""
            },
            {
                "Date": "7/20/18",
                "Profil prudent": "106.0742134",
                "": ""
            },
            {
                "Date": "7/27/18",
                "Profil prudent": "106.1216493",
                "": ""
            },
            {
                "Date": "8/3/18",
                "Profil prudent": "106.1714795",
                "": ""
            },
            {
                "Date": "8/10/18",
                "Profil prudent": "106.2216823",
                "": ""
            },
            {
                "Date": "8/17/18",
                "Profil prudent": "106.2559154",
                "": ""
            },
            {
                "Date": "8/24/18",
                "Profil prudent": "106.3027524",
                "": ""
            },
            {
                "Date": "8/31/18",
                "Profil prudent": "106.3512304",
                "": ""
            },
            {
                "Date": "9/7/18",
                "Profil prudent": "106.399476",
                "": ""
            },
            {
                "Date": "9/14/18",
                "Profil prudent": "106.443919",
                "": ""
            },
            {
                "Date": "9/21/18",
                "Profil prudent": "106.4867343",
                "": ""
            },
            {
                "Date": "9/28/18",
                "Profil prudent": "106.5352455",
                "": ""
            },
            {
                "Date": "10/5/18",
                "Profil prudent": "106.5830928",
                "": ""
            },
            {
                "Date": "10/12/18",
                "Profil prudent": "106.6329294",
                "": ""
            },
            {
                "Date": "10/19/18",
                "Profil prudent": "106.6838747",
                "": ""
            },
            {
                "Date": "10/26/18",
                "Profil prudent": "106.7312811",
                "": ""
            },
            {
                "Date": "11/2/18",
                "Profil prudent": "106.7822337",
                "": ""
            },
            {
                "Date": "11/9/18",
                "Profil prudent": "106.8304189",
                "": ""
            },
            {
                "Date": "11/16/18",
                "Profil prudent": "106.8790993",
                "": ""
            },
            {
                "Date": "11/23/18",
                "Profil prudent": "106.9263253",
                "": ""
            },
            {
                "Date": "11/30/18",
                "Profil prudent": "106.9742438",
                "": ""
            },
            {
                "Date": "12/7/18",
                "Profil prudent": "107.0227983",
                "": ""
            },
            {
                "Date": "12/14/18",
                "Profil prudent": "107.0727333",
                "": ""
            },
            {
                "Date": "12/21/18",
                "Profil prudent": "107.1197199",
                "": ""
            },
            {
                "Date": "12/28/18",
                "Profil prudent": "107.1687751",
                "": ""
            },
            {
                "Date": "1/4/19",
                "Profil prudent": "107.2153733",
                "": ""
            },
            {
                "Date": "1/14/19",
                "Profil prudent": "107.2879368",
                "": ""
            },
            {
                "Date": "1/18/19",
                "Profil prudent": "107.3146479",
                "": ""
            },
            {
                "Date": "1/25/19",
                "Profil prudent": "107.3679504",
                "": ""
            },
            {
                "Date": "2/1/19",
                "Profil prudent": "107.4168621",
                "": ""
            },
            {
                "Date": "2/8/19",
                "Profil prudent": "107.4708229",
                "": ""
            },
            {
                "Date": "2/15/19",
                "Profil prudent": "107.5385705",
                "": ""
            },
            {
                "Date": "2/22/19",
                "Profil prudent": "107.5861637",
                "": ""
            },
            {
                "Date": "3/1/19",
                "Profil prudent": "107.6442868",
                "": ""
            },
            {
                "Date": "3/8/19",
                "Profil prudent": "107.6892349",
                "": ""
            },
            {
                "Date": "3/15/19",
                "Profil prudent": "107.7318702",
                "": ""
            },
            {
                "Date": "3/22/19",
                "Profil prudent": "107.783866",
                "": ""
            },
            {
                "Date": "3/29/19",
                "Profil prudent": "107.830558",
                "": ""
            },
            {
                "Date": "4/5/19",
                "Profil prudent": "107.8740599",
                "": ""
            },
            {
                "Date": "4/12/19",
                "Profil prudent": "107.9248698",
                "": ""
            },
            {
                "Date": "4/19/19",
                "Profil prudent": "107.971344",
                "": ""
            },
            {
                "Date": "4/26/19",
                "Profil prudent": "108.0287555",
                "": ""
            },
            {
                "Date": "5/3/19",
                "Profil prudent": "108.0771185",
                "": ""
            },
            {
                "Date": "5/10/19",
                "Profil prudent": "108.1276763",
                "": ""
            },
            {
                "Date": "5/17/19",
                "Profil prudent": "108.1828113",
                "": ""
            },
            {
                "Date": "5/24/19",
                "Profil prudent": "108.2339812",
                "": ""
            },
            {
                "Date": "5/31/19",
                "Profil prudent": "108.286282",
                "": ""
            },
            {
                "Date": "6/10/19",
                "Profil prudent": "108.3462406",
                "": ""
            },
            {
                "Date": "6/14/19",
                "Profil prudent": "108.3651908",
                "": ""
            },
            {
                "Date": "6/21/19",
                "Profil prudent": "108.4098079",
                "": ""
            },
            {
                "Date": "6/28/19",
                "Profil prudent": "108.4555937",
                "": ""
            },
            {
                "Date": "7/5/19",
                "Profil prudent": "108.4994102",
                "": ""
            },
            {
                "Date": "7/12/19",
                "Profil prudent": "108.5479449",
                "": ""
            },
            {
                "Date": "7/19/19",
                "Profil prudent": "108.5917789",
                "": ""
            },
            {
                "Date": "7/26/19",
                "Profil prudent": "108.6385404",
                "": ""
            },
            {
                "Date": "8/2/19",
                "Profil prudent": "108.6805076",
                "": ""
            },
            {
                "Date": "8/9/19",
                "Profil prudent": "108.7260948",
                "": ""
            },
            {
                "Date": "8/16/19",
                "Profil prudent": "108.7775418",
                "": ""
            },
            {
                "Date": "8/23/19",
                "Profil prudent": "108.8330751",
                "": ""
            },
            {
                "Date": "8/30/19",
                "Profil prudent": "108.8900046",
                "": ""
            },
            {
                "Date": "9/6/19",
                "Profil prudent": "108.9316725",
                "": ""
            },
            {
                "Date": "9/13/19",
                "Profil prudent": "108.9765111",
                "": ""
            },
            {
                "Date": "9/20/19",
                "Profil prudent": "109.0180891",
                "": ""
            },
            {
                "Date": "9/27/19",
                "Profil prudent": "109.0704931",
                "": ""
            },
            {
                "Date": "10/4/19",
                "Profil prudent": "109.1159108",
                "": ""
            },
            {
                "Date": "10/11/19",
                "Profil prudent": "109.1651418",
                "": ""
            },
            {
                "Date": "10/18/19",
                "Profil prudent": "109.2109867",
                "": ""
            },
            {
                "Date": "10/25/19",
                "Profil prudent": "109.2616758",
                "": ""
            },
            {
                "Date": "11/1/19",
                "Profil prudent": "109.3012163",
                "": ""
            },
            {
                "Date": "11/8/19",
                "Profil prudent": "109.3421924",
                "": ""
            },
            {
                "Date": "11/15/19",
                "Profil prudent": "109.3877805",
                "": ""
            },
            {
                "Date": "11/22/19",
                "Profil prudent": "109.4369643",
                "": ""
            },
            {
                "Date": "11/29/19",
                "Profil prudent": "109.4762957",
                "": ""
            },
            {
                "Date": "12/6/19",
                "Profil prudent": "109.5191533",
                "": ""
            },
            {
                "Date": "12/13/19",
                "Profil prudent": "109.5649702",
                "": ""
            },
            {
                "Date": "12/20/19",
                "Profil prudent": "109.6175629",
                "": ""
            },
            {
                "Date": "12/27/19",
                "Profil prudent": "109.6830739",
                "": ""
            },
            {
                "Date": "1/3/20",
                "Profil prudent": "109.7161688",
                "": ""
            },
            {
                "Date": "1/10/20",
                "Profil prudent": "109.7625985",
                "": ""
            },
            {
                "Date": "1/17/20",
                "Profil prudent": "109.7965152",
                "": ""
            },
            {
                "Date": "1/24/20",
                "Profil prudent": "109.8301681",
                "": ""
            },
            {
                "Date": "1/31/20",
                "Profil prudent": "109.8701991",
                "": ""
            },
            {
                "Date": "2/7/20",
                "Profil prudent": "109.9134972",
                "": ""
            },
            {
                "Date": "2/14/20",
                "Profil prudent": "109.9694058",
                "": ""
            },
            {
                "Date": "2/21/20",
                "Profil prudent": "110.0136776",
                "": ""
            },
            {
                "Date": "2/28/20",
                "Profil prudent": "110.0628089",
                "": ""
            },
            {
                "Date": "3/6/20",
                "Profil prudent": "110.1071874",
                "": ""
            },
            {
                "Date": "3/13/20",
                "Profil prudent": "110.1637472",
                "": ""
            },
            {
                "Date": "3/20/20",
                "Profil prudent": "110.2326383",
                "": ""
            },
            {
                "Date": "3/27/20",
                "Profil prudent": "110.2476548",
                "": ""
            },
            {
                "Date": "4/3/20",
                "Profil prudent": "110.2850863",
                "": ""
            },
            {
                "Date": "4/10/20",
                "Profil prudent": "110.3768299",
                "": ""
            },
            {
                "Date": "4/17/20",
                "Profil prudent": "110.4176741",
                "": ""
            },
            {
                "Date": "4/24/20",
                "Profil prudent": "110.4772356",
                "": ""
            },
            {
                "Date": "5/4/20",
                "Profil prudent": "110.5676555",
                "": ""
            },
            {
                "Date": "5/8/20",
                "Profil prudent": "110.5987127",
                "": ""
            },
            {
                "Date": "5/15/20",
                "Profil prudent": "110.6469893",
                "": ""
            },
            {
                "Date": "5/22/20",
                "Profil prudent": "110.7180384",
                "": ""
            },
            {
                "Date": "5/29/20",
                "Profil prudent": "110.7682821",
                "": ""
            },
            {
                "Date": "6/5/20",
                "Profil prudent": "110.8383236",
                "": ""
            },
            {
                "Date": "6/12/20",
                "Profil prudent": "110.8952423",
                "": ""
            },
            {
                "Date": "6/19/20",
                "Profil prudent": "110.9497925",
                "": ""
            },
            {
                "Date": "6/26/20",
                "Profil prudent": "111.2451522",
                "": ""
            },
            {
                "Date": "7/3/20",
                "Profil prudent": "111.2774846",
                "": ""
            },
            {
                "Date": "7/10/20",
                "Profil prudent": "111.3266812",
                "": ""
            },
            {
                "Date": "7/17/20",
                "Profil prudent": "111.3488388",
                "": ""
            },
            {
                "Date": "7/24/20",
                "Profil prudent": "111.3905292",
                "": ""
            },
            {
                "Date": "8/3/20",
                "Profil prudent": "111.4470833",
                "": ""
            },
            {
                "Date": "8/7/20",
                "Profil prudent": "111.4576824",
                "": ""
            },
            {
                "Date": "8/17/20",
                "Profil prudent": "111.4992253",
                "": ""
            },
            {
                "Date": "8/24/20",
                "Profil prudent": "111.5345695",
                "": ""
            },
            {
                "Date": "8/28/20",
                "Profil prudent": "111.5536967",
                "": ""
            },
            {
                "Date": "9/4/20",
                "Profil prudent": "111.5925438",
                "": ""
            },
            {
                "Date": "9/11/20",
                "Profil prudent": "111.6310002",
                "": ""
            },
            {
                "Date": "9/18/20",
                "Profil prudent": "111.6824076",
                "": ""
            },
            {
                "Date": "9/25/20",
                "Profil prudent": "111.726515",
                "": ""
            },
            {
                "Date": "10/2/20",
                "Profil prudent": "111.7603837",
                "": ""
            },
            {
                "Date": "10/9/20",
                "Profil prudent": "111.794521",
                "": ""
            },
            {
                "Date": "10/16/20",
                "Profil prudent": "111.8234149",
                "": ""
            },
            {
                "Date": "10/23/20",
                "Profil prudent": "111.8653391",
                "": ""
            },
            {
                "Date": "11/2/20",
                "Profil prudent": "111.9191894",
                "": ""
            },
            {
                "Date": "11/9/20",
                "Profil prudent": "111.9320131",
                "": ""
            },
            {
                "Date": "11/13/20",
                "Profil prudent": "111.9483881",
                "": ""
            },
            {
                "Date": "11/20/20",
                "Profil prudent": "111.9272691",
                "": ""
            },
            {
                "Date": "11/27/20",
                "Profil prudent": "111.9292467",
                "": ""
            },
            {
                "Date": "12/4/20",
                "Profil prudent": "111.935332",
                "": ""
            },
            {
                "Date": "12/11/20",
                "Profil prudent": "111.988463",
                "": ""
            },
            {
                "Date": "12/18/20",
                "Profil prudent": "112.1332513",
                "": ""
            },
            {
                "Date": "12/25/20",
                "Profil prudent": "112.2407583",
                "": ""
            },
            {
                "Date": "1/4/21",
                "Profil prudent": "112.2897513",
                "": ""
            },
            {
                "Date": "1/8/21",
                "Profil prudent": "112.3133112",
                "": ""
            },
            {
                "Date": "1/15/21",
                "Profil prudent": "112.3566632",
                "": ""
            },
            {
                "Date": "1/22/21",
                "Profil prudent": "112.4105325",
                "": ""
            },
            {
                "Date": "1/29/21",
                "Profil prudent": "112.4578882",
                "": ""
            },
            {
                "Date": "2/5/21",
                "Profil prudent": "112.496817",
                "": ""
            },
            {
                "Date": "2/12/21",
                "Profil prudent": "112.5328223",
                "": ""
            },
            {
                "Date": "2/19/21",
                "Profil prudent": "112.5631567",
                "": ""
            },
            {
                "Date": "2/26/21",
                "Profil prudent": "112.6029682",
                "": ""
            },
            {
                "Date": "3/5/21",
                "Profil prudent": "112.6374383",
                "": ""
            },
            {
                "Date": "3/12/21",
                "Profil prudent": "112.6718223",
                "": ""
            },
            {
                "Date": "3/19/21",
                "Profil prudent": "112.7029682",
                "": ""
            },
            {
                "Date": "3/26/21",
                "Profil prudent": "112.7331597",
                "": ""
            },
            {
                "Date": "4/2/21",
                "Profil prudent": "112.7535761",
                "": ""
            },
            {
                "Date": "4/9/21",
                "Profil prudent": "112.7820365",
                "": ""
            },
            {
                "Date": "4/16/21",
                "Profil prudent": "112.8090611",
                "": ""
            },
            {
                "Date": "4/23/21",
                "Profil prudent": "112.8486458",
                "": ""
            },
            {
                "Date": "4/30/21",
                "Profil prudent": "112.8800191",
                "": ""
            },
            {
                "Date": "5/7/21",
                "Profil prudent": "112.9139305",
                "": ""
            },
            {
                "Date": "5/17/21",
                "Profil prudent": "112.9555834",
                "": ""
            },
            {
                "Date": "5/21/21",
                "Profil prudent": "112.9804966",
                "": ""
            },
            {
                "Date": "5/28/21",
                "Profil prudent": "113.0128871",
                "": ""
            },
            {
                "Date": "6/4/21",
                "Profil prudent": "113.0447004",
                "": ""
            },
            {
                "Date": "6/11/21",
                "Profil prudent": "113.0748101",
                "": ""
            },
            {
                "Date": "6/18/21",
                "Profil prudent": "113.1097693",
                "": ""
            },
            {
                "Date": "6/25/21",
                "Profil prudent": "113.1367118",
                "": ""
            },
            {
                "Date": "7/2/21",
                "Profil prudent": "113.1711614",
                "": ""
            },
            {
                "Date": "7/9/21",
                "Profil prudent": "113.2037168",
                "": ""
            },
            {
                "Date": "7/16/21",
                "Profil prudent": "113.2281951",
                "": ""
            },
            {
                "Date": "7/23/21",
                "Profil prudent": "113.2562559",
                "": ""
            },
            {
                "Date": "8/2/21",
                "Profil prudent": "113.3119766",
                "": ""
            },
            {
                "Date": "8/6/21",
                "Profil prudent": "113.3288755",
                "": ""
            },
            {
                "Date": "8/13/21",
                "Profil prudent": "113.3695017",
                "": ""
            },
            {
                "Date": "8/23/21",
                "Profil prudent": "113.4186117",
                "": ""
            },
            {
                "Date": "8/27/21",
                "Profil prudent": "113.4376402",
                "": ""
            },
            {
                "Date": "9/3/21",
                "Profil prudent": "113.4711197",
                "": ""
            },
            {
                "Date": "9/10/21",
                "Profil prudent": "113.5042249",
                "": ""
            },
            {
                "Date": "9/17/21",
                "Profil prudent": "113.5349873",
                "": ""
            },
            {
                "Date": "9/24/21",
                "Profil prudent": "113.5612491",
                "": ""
            },
            {
                "Date": "10/1/21",
                "Profil prudent": "113.5951755",
                "": ""
            },
            {
                "Date": "10/8/21",
                "Profil prudent": "113.6268584",
                "": ""
            },
            {
                "Date": "10/15/21",
                "Profil prudent": "113.6636883",
                "": ""
            },
            {
                "Date": "10/22/21",
                "Profil prudent": "113.705206",
                "": ""
            },
            {
                "Date": "10/29/21",
                "Profil prudent": "113.7296101",
                "": ""
            },
            {
                "Date": "11/5/21",
                "Profil prudent": "113.7707491",
                "": ""
            },
            {
                "Date": "11/12/21",
                "Profil prudent": "113.8065573",
                "": ""
            },
            {
                "Date": "11/19/21",
                "Profil prudent": "113.8388886",
                "": ""
            },
            {
                "Date": "11/26/21",
                "Profil prudent": "113.8768185",
                "": ""
            },
            {
                "Date": "12/3/21",
                "Profil prudent": "113.8995311",
                "": ""
            },
            {
                "Date": "12/10/21",
                "Profil prudent": "113.9322237",
                "": ""
            },
            {
                "Date": "12/17/21",
                "Profil prudent": "113.9582938",
                "": ""
            },
            {
                "Date": "12/24/21",
                "Profil prudent": "113.9905786",
                "": ""
            },
            {
                "Date": "12/31/21",
                "Profil prudent": "114.0179233",
                "": ""
            },
            {
                "Date": "1/7/22",
                "Profil prudent": "114.0552588",
                "": ""
            },
            {
                "Date": "1/14/22",
                "Profil prudent": "114.0911424",
                "": ""
            },
            {
                "Date": "1/21/22",
                "Profil prudent": "114.1189674",
                "": ""
            },
            {
                "Date": "1/28/22",
                "Profil prudent": "114.1410285",
                "": ""
            },
            {
                "Date": "2/4/22",
                "Profil prudent": "114.1675362",
                "": ""
            },
            {
                "Date": "2/11/22",
                "Profil prudent": "114.2058182",
                "": ""
            },
            {
                "Date": "2/18/22",
                "Profil prudent": "114.2544647",
                "": ""
            },
            {
                "Date": "2/25/22",
                "Profil prudent": "114.2832492",
                "": ""
            },
            {
                "Date": "3/4/22",
                "Profil prudent": "114.3190132",
                "": ""
            },
            {
                "Date": "3/11/22",
                "Profil prudent": "114.3620847",
                "": ""
            },
            {
                "Date": "3/18/22",
                "Profil prudent": "114.3869204",
                "": ""
            },
            {
                "Date": "3/25/22",
                "Profil prudent": "114.418624",
                "": ""
            },
            {
                "Date": "4/1/22",
                "Profil prudent": "114.4504192",
                "": ""
            },
            {
                "Date": "4/8/22",
                "Profil prudent": "114.4808681",
                "": ""
            },
            {
                "Date": "4/15/22",
                "Profil prudent": "114.5137273",
                "": ""
            },
            {
                "Date": "4/22/22",
                "Profil prudent": "114.5365274",
                "": ""
            },
            {
                "Date": "4/29/22",
                "Profil prudent": "114.5762804",
                "": ""
            },
            {
                "Date": "5/6/22",
                "Profil prudent": "114.5992853",
                "": ""
            },
            {
                "Date": "5/13/22",
                "Profil prudent": "114.6040666",
                "": ""
            },
            {
                "Date": "5/20/22",
                "Profil prudent": "114.6508294",
                "": ""
            },
            {
                "Date": "5/27/22",
                "Profil prudent": "114.6771916",
                "": ""
            },
            {
                "Date": "6/3/22",
                "Profil prudent": "114.7036413",
                "": ""
            },
            {
                "Date": "6/10/22",
                "Profil prudent": "114.7360078",
                "": ""
            },
            {
                "Date": "6/17/22",
                "Profil prudent": "114.7681",
                "": ""
            },
            {
                "Date": "6/24/22",
                "Profil prudent": "114.7937337",
                "": ""
            },
            {
                "Date": "7/1/22",
                "Profil prudent": "114.8385288",
                "": ""
            },
            {
                "Date": "7/8/22",
                "Profil prudent": "114.872703",
                "": ""
            },
            {
                "Date": "7/15/22",
                "Profil prudent": "114.8741824",
                "": ""
            },
            {
                "Date": "7/22/22",
                "Profil prudent": "114.9172584",
                "": ""
            },
            {
                "Date": "7/29/22",
                "Profil prudent": "114.9450288",
                "": ""
            },
            {
                "Date": "8/5/22",
                "Profil prudent": "114.9617761",
                "": ""
            },
            {
                "Date": "8/12/22",
                "Profil prudent": "115.0120803",
                "": ""
            },
            {
                "Date": "8/19/22",
                "Profil prudent": "115.0261824",
                "": ""
            },
            {
                "Date": "8/26/22",
                "Profil prudent": "115.0139691",
                "": ""
            },
            {
                "Date": "9/2/22",
                "Profil prudent": "115.0560686",
                "": ""
            },
            {
                "Date": "9/9/22",
                "Profil prudent": "115.0498415",
                "": ""
            },
            {
                "Date": "9/16/22",
                "Profil prudent": "115.0892723",
                "": ""
            },
            {
                "Date": "9/23/22",
                "Profil prudent": "115.1269489",
                "": ""
            },
            {
                "Date": "9/30/22",
                "Profil prudent": "115.0715628",
                "": ""
            },
            {
                "Date": "10/7/22",
                "Profil prudent": "115.0768595",
                "": ""
            },
            {
                "Date": "10/14/22",
                "Profil prudent": "115.1094587",
                "": ""
            },
            {
                "Date": "10/21/22",
                "Profil prudent": "115.1687393",
                "": ""
            },
            {
                "Date": "10/28/22",
                "Profil prudent": "115.1838439",
                "": ""
            },
            {
                "Date": "11/4/22",
                "Profil prudent": "115.2117396",
                "": ""
            },
            {
                "Date": "11/11/22",
                "Profil prudent": "115.1866146",
                "": ""
            },
            {
                "Date": "11/21/22",
                "Profil prudent": "115.1904177",
                "": ""
            },
            {
                "Date": "11/25/22",
                "Profil prudent": "115.2019793",
                "": ""
            },
            {
                "Date": "12/2/22",
                "Profil prudent": "115.2499281",
                "": ""
            },
            {
                "Date": "12/9/22",
                "Profil prudent": "115.2809194",
                "": ""
            },
            {
                "Date": "12/16/22",
                "Profil prudent": "115.327253",
                "": ""
            },
            {
                "Date": "12/23/22",
                "Profil prudent": "115.3421689",
                "": ""
            },
            {
                "Date": "12/30/22",
                "Profil prudent": "115.3546482",
                "": ""
            },
            {
                "Date": "1/6/23",
                "Profil prudent": "115.1494597",
                "": ""
            },
            {
                "Date": "1/13/23",
                "Profil prudent": "115.2278898",
                "": ""
            },
            {
                "Date": "1/20/23",
                "Profil prudent": "115.3042014",
                "": ""
            },
            {
                "Date": "1/27/23",
                "Profil prudent": "115.3982831",
                "": ""
            },
            {
                "Date": "2/3/23",
                "Profil prudent": "115.4610982",
                "": ""
            },
            {
                "Date": "2/10/23",
                "Profil prudent": "115.5462443",
                "": ""
            },
            {
                "Date": "2/17/23",
                "Profil prudent": "115.6195676",
                "": ""
            },
            {
                "Date": "2/24/23",
                "Profil prudent": "115.6905481",
                "": ""
            },
            {
                "Date": "3/3/23",
                "Profil prudent": "115.7600724",
                "": ""
            },
            {
                "Date": "3/10/23",
                "Profil prudent": "115.8316928",
                "": ""
            },
            {
                "Date": "3/17/23",
                "Profil prudent": "115.8997861",
                "": ""
            },
            {
                "Date": "3/24/23",
                "Profil prudent": "115.9617201",
                "": ""
            },
            {
                "Date": "3/31/23",
                "Profil prudent": "116.0058438",
                "": ""
            },
            {
                "Date": "4/7/23",
                "Profil prudent": "116.056521",
                "": ""
            },
            {
                "Date": "4/14/23",
                "Profil prudent": "116.1241283",
                "": ""
            },
            {
                "Date": "4/21/23",
                "Profil prudent": "116.2167746",
                "": ""
            },
            {
                "Date": "4/28/23",
                "Profil prudent": "116.2992816",
                "": ""
            },
            {
                "Date": "5/5/23",
                "Profil prudent": "116.3823533",
                "": ""
            },
            {
                "Date": "5/12/23",
                "Profil prudent": "116.4692588",
                "": ""
            },
            {
                "Date": "5/19/23",
                "Profil prudent": "116.5946429",
                "": ""
            },
            {
                "Date": "5/26/23",
                "Profil prudent": "116.6627846",
                "": ""
            },
            {
                "Date": "6/2/23",
                "Profil prudent": "116.7332882",
                "": ""
            },
            {
                "Date": "6/9/23",
                "Profil prudent": "116.7955737",
                "": ""
            },
            {
                "Date": "6/16/23",
                "Profil prudent": "116.8493578",
                "": ""
            },
            {
                "Date": "6/23/23",
                "Profil prudent": "117.0692424",
                "": ""
            },
            {
                "Date": "7/3/23",
                "Profil prudent": "117.1748983",
                "": ""
            },
            {
                "Date": "7/7/23",
                "Profil prudent": "117.1643502",
                "": ""
            },
            {
                "Date": "7/14/23",
                "Profil prudent": "117.2353812",
                "": ""
            },
            {
                "Date": "7/21/23",
                "Profil prudent": "117.306106",
                "": ""
            },
            {
                "Date": "7/28/23",
                "Profil prudent": "117.3842908",
                "": ""
            },
            {
                "Date": "8/4/23",
                "Profil prudent": "117.4909463",
                "": ""
            },
            {
                "Date": "8/11/23",
                "Profil prudent": "117.5492568",
                "": ""
            },
            {
                "Date": "8/18/23",
                "Profil prudent": "117.5958977",
                "": ""
            },
            {
                "Date": "8/25/23",
                "Profil prudent": "117.6639494",
                "": ""
            },
            {
                "Date": "9/1/23",
                "Profil prudent": "117.7307702",
                "": ""
            },
            {
                "Date": "9/8/23",
                "Profil prudent": "117.8002719",
                "": ""
            },
            {
                "Date": "9/15/23",
                "Profil prudent": "117.8681794",
                "": ""
            },
            {
                "Date": "9/22/23",
                "Profil prudent": "117.9180538",
                "": ""
            },
            {
                "Date": "10/2/23",
                "Profil prudent": "118.0448586",
                "": ""
            },
            {
                "Date": "10/6/23",
                "Profil prudent": "118.1179541",
                "": ""
            },
            {
                "Date": "10/13/23",
                "Profil prudent": "118.1694313",
                "": ""
            },
            {
                "Date": "10/20/23",
                "Profil prudent": "118.2378721",
                "": ""
            },
            {
                "Date": "10/27/23",
                "Profil prudent": "118.3333129",
                "": ""
            },
            {
                "Date": "11/3/23",
                "Profil prudent": "118.3898976",
                "": ""
            },
            {
                "Date": "11/10/23",
                "Profil prudent": "118.4599647",
                "": ""
            },
            {
                "Date": "11/17/23",
                "Profil prudent": "118.5373897",
                "": ""
            },
            {
                "Date": "11/24/23",
                "Profil prudent": "118.6054672",
                "": ""
            },
            {
                "Date": "12/1/23",
                "Profil prudent": "118.6780474",
                "": ""
            },
            {
                "Date": "12/8/23",
                "Profil prudent": "118.7388081",
                "": ""
            },
            {
                "Date": "12/15/23",
                "Profil prudent": "118.8234543",
                "": ""
            },
            {
                "Date": "12/22/23",
                "Profil prudent": "118.8946725",
                "": ""
            },
            {
                "Date": "12/29/23",
                "Profil prudent": "118.9480052",
                "": ""
            },
            {
                "Date": "1/5/24",
                "Profil prudent": "119.0710234",
                "": ""
            },
            {
                "Date": "1/12/24",
                "Profil prudent": "119.1555598",
                "": ""
            },
            {
                "Date": "",
                "Profil prudent": "",
                "": ""
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
            setChartData(yourDataSet[0]);
        }
    }, [yourDataSet]);

    const getOption = () => {
        if (!chartData || chartData.length === 0) {
            return {};
        }

        const xAxisData = chartData.map((item) => item.Date);

        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');

        const series = legendData.map((key) => ({
            type: 'line',
            name: key,
            data: chartData.map((item) => {
                const value = item[key];
                const parsedValue = parseFloat(value.replace(',', ' '));
                return isNaN(parsedValue) ? null : parsedValue;
            }),
            itemStyle: {
                color: '#fac858' // Set the color for the line
            }
        }));

        return {
            animationDuration: 10000,
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: '',
                left: 'center',

            },
            legend: {
                data: legendData,
                bottom: 10,
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                min: 100,
            },
            series,
        };
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactEChartsCore echarts={echarts} option={getOption()} />

            </Col>
        </Row>
    );
};

export default LineChartComponent;
