import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Card, Col, Row } from 'react-bootstrap';

const ConfidenceBandChart = () => {
    const data = [
        {
            Année: '2024',
            Min: '100',
            Estimation: '100',
            Max: '100',
        },
        {
            Année: '2025',
            Min: '77.93289039',
            Estimation: '106.3465423',
            Max: '145.002214',
        },
        {
            Année: '2026',
            Min: '80.94446993',
            Estimation: '110.0293673',
            Max: '158.7923133',
        },
        {
            Année: '2027',
            Min: '88.97418496',
            Estimation: '114.3524235',
            Max: '142.0578115',
        },
        {
            Année: '2028',
            Min: '100.6536625',
            Estimation: '117.3590056',
            Max: '163.4213562',
        },
        {
            Année: '2029',
            Min: '92.00658796',
            Estimation: '125.2714637',
            Max: '159.1777664',
        },
        {
            Année: '2030',
            Min: '100.8884874',
            Estimation: '131.4037658',
            Max: '186.1506938',
        },
    ];

    const option = {
        title: {
            text: '',
            left: 'center',
          },
          legend: {
            data: ['Estimation', 'Min', 'Max'],
            bottom: 10,
          },
        
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map(item => item.Année),
        },
        yAxis: {
            type: 'value',
            min: 75,
        },
        series: [
            {
                name: 'Min',
                type: 'line',
                data: data.map(item => parseFloat(item.Min)),
                smooth: true,
                lineStyle: {
                    opacity: 0, // Make the line invisible
                },
                areaStyle: {
                    color: 'blue', // Fill color for the area below the line
                    opacity: 0.8,
                },
            },
            {
                name: 'Max',
                type: 'line',
                data: data.map(item => [item.Année, parseFloat(item.Max)]),
                smooth: true,
                lineStyle: {
                    opacity: 0, // Make the line invisible
                },
                areaStyle: {
                    color: 'green',
                    opacity: 0.5,
                },
            },
            {
                name: 'Estimation',
                type: 'line',
                data: data.map(item => [item.Année, parseFloat(item.Estimation)]),
                smooth: true,
                itemStyle: {
                    color: 'red', // Adjust color as needed
                },
            },
        ],
    };

    return (
        <Row className="g-3">
            <Col>
               
                        <ReactECharts option={option} />
                   
            </Col>
        </Row>
    );
};

export default ConfidenceBandChart;
