const questionsData = [
    {
        id: 1,
        title: 'Situation Professionnelle',
        totalQuestions: 24,
        questionNumber: 1,
        question: 'Quel est votre âge ?',
        options: [
            {
                value: 'Moins de 30 ans',
                label: 'Moins de 30 ans',
                profiles: {
                    prudent: 5,
                    modéré: 5,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 31 et 40 ans',
                label: 'Entre 31 et 40 ans',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 15,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 41 et 50 ans',
                label: 'Entre 41 et 50 ans',
                profiles: {
                    prudent: 10,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 51 et 60 ans',
                label: 'Entre 51 et 60 ans',
                profiles: {
                    prudent: 15,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Plus de 60 ans',
                label: 'Plus de 60 ans',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 2,
        title: 'Situation Professionnelle',
        totalQuestions: 24,
        questionNumber: 2,
        question: 'Quel est votre statut matrimonial ?',
        options: [
            {
                value: 'Célibataire',
                label: ' Célibataire',
                profiles: {
                    prudent: 5,
                    modéré: 5,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Marié',
                label: 'Marié',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Divorcé',
                label: 'Divorcé',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "",
            },
            {
                value: 'Veuf',
                label: 'Veuf',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 0,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 3,
        title: 'Situation Professionnelle',
        totalQuestions: 24,
        questionNumber: 3,
        question: 'Avez-vous des personnes à charge ?',
        options: [
            {
                value: 'Oui',
                label: ' Oui',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Non',
                label: 'Non',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 4,
        title: 'Situation Professionnelle',
        totalQuestions: 24,
        questionNumber: 4,
        question: "Quel est votre niveau d'éducation ?",
        options: [
            {
                value: 'Secondaire',
                label: ' Secondaire',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "",
            },
            {
                value: 'Baccalauréat',
                label: 'Baccalauréat',
                profiles: {
                    prudent: 15,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Licence',
                label: 'Licence',
                profiles: {
                    prudent: 10,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Master',
                label: 'Master',
                profiles: {
                    prudent: 10,
                    modéré: 20,
                    agressif: 15,
                },
                textAfterSelection: "",
            },
            {
                value: 'Doctorat / Ingénieur',
                label: 'Doctorat / Ingénieur',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 20,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 5,
        title: 'Situation Professionnelle',
        totalQuestions: 24,
        questionNumber: 5,
        question: "Quel est votre statut d'emploi actuel ?",
        options: [
            {
                value: 'Employé à temps plein',
                label: 'Employé à temps plein',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Employé à temps partiel',
                label: 'Employé à temps partiel',
                profiles: {
                    prudent: 20,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Auto-Entrepreneur / Indépendant',
                label: 'Auto-Entrepreneur / Indépendant',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Retraité',
                label: 'Retraité',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Sans emploi',
                label: 'Sans emploi',
                profiles: {
                    prudent: 20,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 6,
        title: 'Situation financière',
        totalQuestions: 24,
        questionNumber: 6,
        question: "Quel est votre revenu annuel ?",
        options: [
            {
                value: 'Moins de 100 000 dhs',
                label: 'Moins de 100 000 dhs',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 100 000 dhs et 250 000 dhs',
                label: 'Entre 100 000 dhs et 250 000 dhs',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 250 000 dhs et 400 000 dhs',
                label: 'Entre 250 000 dhs et 400 000 dhs',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 400 000 dhs et 600 000 dhs',
                label: 'Entre 400 000 dhs et 600 000 dhs',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Plus de 600 000 dhs',
                label: 'Plus de 600 000 dhs',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 7,
        title: 'Situation financière',
        totalQuestions: 24,
        questionNumber: 7,
        question: "Quelle est votre principale source de revenu ?",
        options: [
            {
                value: 'Salaire',
                label: 'Salaire',
                profiles: {
                    prudent: 5,
                    modéré: 5,
                    agressif: 20,
                },
                textAfterSelection: "Revenu gagné en tant qu'employé ou entrepreneur individuel.",
            },
            {
                value: 'Dividendes',
                label: 'Dividendes',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Distribution de bénéfices d'une entreprise dans laquelle vous détenez des actions.",
            },
            {
                value: 'Pension',
                label: 'Pension',
                profiles: {
                    prudent: 15,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Revenu reçu après la retraite, qui peut provenir d'un régime de retraite.",
            },
            {
                value: 'Rentes Immobilières',
                label: 'Rentes Immobilières',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Revenu provenant de la location de propriétés immobilières.",
            },
            {
                value: 'Revenues diverses',
                label: 'Revenues diverses',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "Sources de revenus diverses (Salaire + Dividendes) ou (Salaire + D'autres Revenus ).",
            }
        ],
    },
    {
        id: 8,
        title: 'Situation financière',
        totalQuestions: 24,
        questionNumber: 8,
        question: "Quelle est la principale source de votre patrimoine?",
        options: [
            {
                value: 'Economies accumulées',
                label: 'Economies accumulées',
                profiles: {
                    prudent: 20,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Argent économisé au fil du temps, y compris les comptes d'épargne, les comptes carnet et autres économies personnelles.",
            },
            {
                value: 'Héritage et dons',
                label: 'Héritage et dons',
                profiles: {
                    prudent: 15,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Richesse acquise à travers l'héritage familial et les cadeaux substantiels.",
            },
            {
                value: 'Pension anticipée',
                label: 'Pension anticipée',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 5,
                },
                textAfterSelection: "Revenu reçu suite à une retraite prise avant l'âge légal, incluant pensions privées et publiques.",
            },
            {
                value: "Gains d'investissements et de spéculation",
                label: "Gains d'investissements et de spéculation",
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Revenus et plus-values issus d'investissements à long terme et de transactions spéculatives, incluant les actions, les obligations, le Forex, les cryptomonnaies et les options.",
            },
            {
                value: 'Gains de loterie',
                label: 'Gains de loterie',
                profiles: {
                    prudent: 0,
                    modéré: 5,
                    agressif: 20,
                },
                textAfterSelection: "Sommes d'argent remportées à travers des jeux de hasard comme la loterie.",
            }
        ],
    },
    {
        id: 9,
        title: 'Situation financière',
        totalQuestions: 24,
        questionNumber: 9,
        question: "Quelle est la valeur de votre patrimoine net?",
        options: [
            {
                value: 'Moins de 250 000 dhs',
                label: 'Moins de 250 000 dhs',
                profiles: {
                    prudent: 20,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 250 000 dhs et 500 000 dhs',
                label: 'Entre 250 000 dhs et 500 000 dhs',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 500 000 dhs et 750 000 dhs',
                label: 'Entre 500 000 dhs et 750 000 dhs',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "",
            },
            {
                value: 'Entre 750 000 dhs et 1 000 000 dhs',
                label: "Entre 750 000 dhs et 1 000 000 dhs",
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            },
            {
                value: 'Plus de 1 000 000 dhs',
                label: 'Plus de 1 000 000 dhs',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "",
            }
        ],
    },
    {
        id: 10,
        title: 'Situation financière',
        totalQuestions: 24,
        questionNumber: 10,
        question: "Quelle est votre situation financière actuelle ?",
        options: [
            {
                value: 'Stabilité financière',
                label: 'Stabilité financière',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Je vis confortablement avec des économies substantielles et j'ai la capacité d'investir.",
            },
            {
                value: 'Confort sans épargne',
                label: 'Confort sans épargne',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Je vis confortablement mais je n'ai qu'une faible épargne et peu de marge de manœuvre financière.",
            },
            {
                value: 'Épargne non régulier',
                label: 'Épargne non régulier',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 5,
                },
                textAfterSelection: "Je gère mes finances mois par mois, sans capacité d'épargne significative.",
            },
            {
                value: 'Aucun épargne',
                label: "Aucun épargne",
                profiles: {
                    prudent: 20,
                    modéré: 5,
                    agressif: 0,
                },
                textAfterSelection: "Je rencontre des défis pour couvrir les dépenses de base et j'ai peu ou pas d'épargne.",
            },
            {
                value: 'Progression financière',
                label: 'Progression financière',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Je ne vis pas confortablement mais je suis en train de construire activement mon épargne et d'améliorer ma situation financière.",
            }
        ],
    },
    {
        id: 11,
        title: "Objectif d'investissement",
        totalQuestions: 24,
        questionNumber: 11,
        question: "Quelle partie de votre revenu êtes-vous prêt à investir ?",
        options: [
            {
                value: 'Moins de 10%',
                label: 'Moins de 10%',
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Prêt à investir moins de 10% du revenu.",
            },
            {
                value: 'Entre 10% et 30%',
                label: 'Entre 10% et 30%',
                profiles: {
                    prudent: 10,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Prêt à investir entre 10% et 30% du revenu.",
            },
            {
                value: 'Entre 30% et 50%',
                label: 'Entre 30% et 50%',
                profiles: {
                    prudent: 15,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Prêt à investir entre 30% et 50% du revenu.",
            },
            {
                value: 'Entre 50% et 75%',
                label: "Entre 50% et 75%",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Prêt à investir entre 50% et 75% du revenu.",
            },
            {
                value: 'Plus de 75%',
                label: 'Plus de 75%',
                profiles: {
                    prudent: 10,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "Prêt à investir plus de 75% du revenu.",
            }
        ],
    },
    {
        id: 12,
        title: "Objectif d'investissement",
        totalQuestions: 24,
        questionNumber: 12,
        question: "Quel est votre objectif d'investissement principal ?",
        options: [
            {
                value: 'Croissance du capital',
                label: 'Croissance du capital',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Recherche d'une augmentation significative de la valeur du capital investi sur le long terme.",
            },
            {
                value: 'Revenu régulier',
                label: 'Revenu régulier',
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Investissement à court terme pour profiter des fluctuations du marché.",
            },
            {
                value: 'Préservation du capital',
                label: 'Préservation du capital',
                profiles: {
                    prudent: 20,
                    modéré: 15,
                    agressif: 0,
                },
                textAfterSelection: "Génération d'un flux de revenus constant, par exemple à travers des dividendes ou des intérêts.",
            },
            {
                value: 'Spéculation',
                label: "Spéculation",
                profiles: {
                    prudent: 20,
                    modéré: 5,
                    agressif: 0,
                },
                textAfterSelection: "Focus sur la sécurité de l'investissement initial.",
            },
            {
                value: 'Equilibre',
                label: 'Equilibre',
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 0,
                },
                textAfterSelection: "Combinaison de croissance, de revenus et de sécurité.",
            }
        ],
    },
    {
        id: 13,
        title: "Objectif d'investissement",
        totalQuestions: 24,
        questionNumber: 13,
        question: "Quel est votre horizon d'investissement ?",
        options: [
            {
                value: 'Moins de 3 mois',
                label: 'Moins de 3 mois',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Horizon d'investissement à très court terme.",
            },
            {
                value: 'Entre 3 mois et 1 an',
                label: 'Entre 3 mois et 1 an',
                profiles: {
                    prudent: 10,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Horizon d'investissement à court terme.",
            },
            {
                value: 'Entre 1 an et 3 ans',
                label: 'Entre 1 an et 3 ans',
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Horizon d'investissement à moyen terme.",
            },
            {
                value: 'Entre 3 ans et 5 ans',
                label: "Entre 3 ans et 5 ans",
                profiles: {
                    prudent: 0,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Horizon d'investissement à moyen-long terme.",
            },
            {
                value: 'Plus de 5 ans',
                label: 'Plus de 5 ans',
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Horizon d'investissement à long terme.",
            }
        ],
    },
    {
        id: 14,
        title: "Connaissance et Expérience en investissement",
        totalQuestions: 24,
        questionNumber: 14,
        question: "Quelle est votre connaissance des produits financiers ?",
        options: [
            {
                value: 'Aucune connaissance',
                label: 'Aucune connaissance',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Pas de familiarité avec les produits financiers.",
            },
            {
                value: 'connaissance de base',
                label: 'connaissance de base',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 5,
                },
                textAfterSelection: "Compréhension élémentaire des différences entre actions, obligations et dépôts bancaires.",
            },
            {
                value: 'Connaissance Intérmédiaire',
                label: 'Connaissance Intérmédiaire',
                profiles: {
                    prudent: 15,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Bonne compréhension des principaux produits financiers et expérience de l'investissement dans des produits standards.",
            },
            {
                value: 'Connaissance avancé',
                label: "Connaissance avancé",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Bonne compréhension des produits financiers, y compris des instruments plus complexes.",
            },
            {
                value: 'Expertise',
                label: 'Expertise',
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 20,
                },
                textAfterSelection: "Connaissance approfondie des produits financiers, incluant des stratégies d'investissement sophistiquées.",
            }
        ],
    },
    {
        id: 15,
        title: "Connaissance et Expérience en investissement",
        totalQuestions: 24,
        questionNumber: 15,
        question: "Quel est votre expérience en matière d'investissement ?",
        options: [
            {
                value: 'Débutant',
                label: 'Débutant',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Pas d'expérience ou début récent dans l'investissement.",
            },
            {
                value: 'Novice avec des bases',
                label: 'Novice avec des bases',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 5,
                },
                textAfterSelection: "Connaissances de base avec quelques investissements réalisés.",
            },
            {
                value: 'Intermédiaire',
                label: 'Intermédiaire',
                profiles: {
                    prudent: 15,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Bonne compréhension et expérience pratique avec différents types d'actifs.",
            },
            {
                value: 'Avancé',
                label: "Avancé",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Expérience considérable et confort avec des stratégies d'investissement complexes.",
            },
            {
                value: 'Expert',
                label: 'Expert',
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 20,
                },
                textAfterSelection: "Expertise approfondie en matière d'investissement, compréhension des marchés avancés.",
            }
        ],
    },
    {
        id: 16,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 16,
        question: "Quelle est votre tolérance au risque ?",
        options: [
            {
                value: 'Très Faible',
                label: 'Très Faible',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Évitement du risque et privilège de la sécurité du capital.",
            },
            {
                value: 'Faible',
                label: 'Faible',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Acceptation d'un risque limité pour un retour légèrement plus élevé.",
            },
            {
                value: 'Modérée',
                label: 'Modérée',
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 0,
                },
                textAfterSelection: "Ouverture à un équilibre entre le risque et le retour.",
            },
            {
                value: 'Elevée',
                label: "Elevée",
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Disposition à accepter un risque significatif pour un rendement potentiel plus élevé.",
            },
            {
                value: 'Très Elevée',
                label: 'Très Elevée',
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Recherche active d'opportunités à haut risque.",
            }
        ],
    },
    {
        id: 17,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 17,
        question: "Quel pourcentage de perte sur une période d'un an vous inciterait à vendre vos investissements ?",
        options: [
            {
                value: 'De 0% à 10%',
                label: 'De 0% à 10%',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Réaction à une perte minimale.",
            },
            {
                value: 'De 10% à 20%',
                label: 'De 10% à 20%',
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Tolérance à une perte modérée.",
            },
            {
                value: 'De 20% à 30%',
                label: 'De 20% à 30%',
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Acceptation d'une perte assez élevée.",
            },
            {
                value: 'De 30% à 50%',
                label: "De 30% à 50%",
                profiles: {
                    prudent: 0,
                    modéré: 15,
                    agressif: 15,
                },
                textAfterSelection: "Disposition à supporter une perte importante.",
            },
            {
                value: 'Plus de 50%',
                label: 'Plus de 50%',
                profiles: {
                    prudent: 0,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Tolérance à une perte très importante.",
            }
        ],
    },
    {
        id: 18,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 18,
        question: "Quelle est votre expérience avec les pertes en capital dans le passé ?",
        options: [
            {
                value: 'Aucune expérience',
                label: 'Aucune expérience',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Jamais subi de pertes en capital.",
            },
            {
                value: 'Pertes mineures',
                label: 'Pertes mineures',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Pertes minimes avec impact limité sur le portefeuille.",
            },
            {
                value: "Expérience d'apprentissage",
                label: "Expérience d'apprentissage",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 0,
                },
                textAfterSelection: "Pertes servant d'apprentissage pour améliorer les stratégies d'investissement.",
            },
            {
                value: 'Récupération réussie',
                label: "Récupération réussie",
                profiles: {
                    prudent: 0,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Récupération après des pertes significatives.",
            },
            {
                value: 'Expérience récurrente',
                label: 'Expérience récurrente',
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Pertes régulières en capital dans le cadre d'une stratégie à haut risque.",
            }
        ],
    },
    {
        id: 19,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 19,
        question: "Quelle serait votre réaction si votre investissement ne réalisait pas de profit dans l'année suivant votre investissement ?",
        options: [
            {
                value: 'Patienter',
                label: 'Patienter',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Attente, conscient que les investissements peuvent nécessiter plus de temps pour générer des profits.",
            },
            {
                value: 'Réévaluer',
                label: 'Réévaluer',
                profiles: {
                    prudent: 10,
                    modéré: 15,
                    agressif: 0,
                },
                textAfterSelection: "Analyse de la situation et décision sur la continuation de l'investissement.",
            },
            {
                value: 'Diversifier',
                label: "Diversifier",
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Recherche de diversification pour réduire le risque.",
            },
            {
                value: 'Vendre',
                label: "Vendre",
                profiles: {
                    prudent: 5,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Vente de l'investissement en l'absence de perspectives d'amélioration.",
            },
            {
                value: "Investir d'avantage",
                label: "Investir d'avantage",
                profiles: {
                    prudent: 0,
                    modéré: 5,
                    agressif: 20,
                },
                textAfterSelection: "Investissement supplémentaire si la croyance en l'investissement à long terme persiste.",
            }
        ],
    },
    {
        id: 20,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 20,
        question: "Quelle est votre attitude face à la perte potentielle en capital?",
        options: [
            {
                value: 'Evitement total',
                label: 'Evitement total',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Intolérance à toute perte de capital.",
            },
            {
                value: 'Prudence',
                label: 'Prudence',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Acceptation de pertes minimes avec prudence.",
            },
            {
                value: 'Tolérance modérée',
                label: "Tolérance modérée",
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Disposition à accepter un certain niveau de perte pour un rendement potentiel plus élevé.",
            },
            {
                value: 'Résilience',
                label: "Résilience",
                profiles: {
                    prudent: 0,
                    modéré: 10,
                    agressif: 15,
                },
                textAfterSelection: "Prise de risques significatifs pour de meilleurs rendements.",
            },
            {
                value: 'Agressif',
                label: "Agressif",
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Acceptation pleine des hauts niveaux de risque et des pertes potentielles importantes.",
            }
        ],
    },
    {
        id: 21,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 21,
        question: "Comment réagiriez-vous si la valeur de votre portefeuille diminuait de 20% en un court laps de temps ?",
        options: [
            {
                value: 'Venture panique',
                label: 'Venture panique',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Vente immédiate pour éviter d'autres pertes.",
            },
            {
                value: 'Inquiétude, mais patience',
                label: 'Inquiétude, mais patience',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Attente en espérant un rétablissement avant de prendre des mesures.",
            },
            {
                value: 'Analyse stratégique',
                label: "Analyse stratégique",
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 5,
                },
                textAfterSelection: "Évaluation des causes et envisagement de rééquilibrage ou d'ajustement.",
            },
            {
                value: "Opportunité d'achat",
                label: "Opportunité d'achat",
                profiles: {
                    prudent: 0,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "Considération comme une opportunité d'achat pour des actifs sous-évalués.",
            },
            {
                value: 'Planification à long terme',
                label: "Planification à long terme",
                profiles: {
                    prudent: 0,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Fidélité à la stratégie d'investissement à long terme.",
            }
        ],
    },
    {
        id: 22,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 22,
        question: "Quelle est votre réaction à une baisse d'investissements dans les 12 prochains mois ?",
        options: [
            {
                value: 'Inquiétude immédiate',
                label: 'Inquiétude immédiate',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Réaction rapide à toute perte.",
            },
            {
                value: 'Surveillance accrue',
                label: 'Surveillance accrue',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Surveillance sans actions précipitées, en espérant un rebond.",
            },
            {
                value: 'Réévaluation stratégique',
                label: "Réévaluation stratégique",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 0,
                },
                textAfterSelection: "Réévaluation de l'investissement pour ajuster la stratégie.",
            },
            {
                value: 'Opportunisme',
                label: "Opportunisme",
                profiles: {
                    prudent: 0,
                    modéré: 15,
                    agressif: 20,
                },
                textAfterSelection: "Vue de la baisse comme une opportunité d'achat.",
            },
            {
                value: 'Perspective à long terme',
                label: "Perspective à long terme",
                profiles: {
                    prudent: 0,
                    modéré: 10,
                    agressif: 20,
                },
                textAfterSelection: "Calme et maintien des investissements, en acceptant la volatilité.",
            }
        ],
    },
    {
        id: 23,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 23,
        question: "Avez-vous déjà réalisé des gains sur vos placements et quelle a été votre stratégie ?",
        options: [
            {
                value: 'Non',
                label: 'Non',
                profiles: {
                    prudent: 20,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Pas encore de gains réalisés.",
            },
            {
                value: 'Oui - Vendre la totalité',
                label: 'Oui - Vendre la totalité',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Vente de l'intégralité des placements après réalisation des gains.",
            },
            {
                value: 'Oui - Réinvestir',
                label: "Oui - Réinvestir",
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Réinvestissement des bénéfices pour augmenter le potentiel de gains futurs.",
            },
            {
                value: "Oui - Conserver l'ensemble des supports",
                label: "Oui - Conserver l'ensemble des supports",
                profiles: {
                    prudent: 10,
                    modéré: 20,
                    agressif: 0,
                },
                textAfterSelection: "Conservation de tous les supports d'investissement pour une appréciation continue.",
            },
            {
                value: 'Oui - Ecrêter les plus-values',
                label: "Oui - Ecrêter les plus-values",
                profiles: {
                    prudent: 5,
                    modéré: 20,
                    agressif: 0,
                },
                textAfterSelection: "Vente partielle des placements pour sécuriser les bénéfices tout en laissant le reste investi.",
            }
        ],
    },
    {
        id: 24,
        title: "Tolérance au risque et réactions aux pertes",
        totalQuestions: 24,
        questionNumber: 24,
        question: "Parmi les descriptifs suivants, lequel correspond le mieux à votre profil d'investisseur ?",
        options: [
            {
                value: 'Prudent',
                label: 'Prudent',
                profiles: {
                    prudent: 20,
                    modéré: 0,
                    agressif: 0,
                },
                textAfterSelection: "Emphase sur la préservation du capital et la réduction du risque.",
            },
            {
                value: 'Conservateur',
                label: 'Conservateur',
                profiles: {
                    prudent: 15,
                    modéré: 10,
                    agressif: 0,
                },
                textAfterSelection: "Acceptation d'un faible niveau de risque pour une meilleure rentabilité.",
            },
            {
                value: 'Modéré',
                label: "Modéré",
                profiles: {
                    prudent: 5,
                    modéré: 15,
                    agressif: 5,
                },
                textAfterSelection: "Recherche d'un équilibre entre croissance et sécurité.",
            },
            {
                value: 'Dynamique',
                label: "Dynamique",
                profiles: {
                    prudent: 0,
                    modéré: 20,
                    agressif: 10,
                },
                textAfterSelection: "Privilège de la croissance du capital sur le long terme.",
            },
            {
                value: 'Agressif',
                label: "Agressif",
                profiles: {
                    prudent: 0,
                    modéré: 0,
                    agressif: 20,
                },
                textAfterSelection: "Vise un rendement maximal, prêt à prendre des risques élevés.",
            }
        ],
    }
];

export default questionsData;