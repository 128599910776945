import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
  ]);
  
  const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {"seance":"14/09/2018","P0":"100","P1":"100","P2":"100","P3":"100","P4":"100","P5":"100","P6":"100","P7":"100","P8":"100","P9":"100", },
            {"seance":"21/09/2018","P0":"100,1007947","P1":"100,0774381","P2":"100,0539515","P3":"100,035508","P4":"100,0577564","P5":"100,0801281","P6":"100,0826295","P7":"100,0672418","P8":"100,0731715","P9":"100,0572509"},
            {"seance":"28/09/2018","P0":"100,3208528","P1":"100,3362091","P2":"100,351651","P3":"100,3694192","P4":"100,4049518","P5":"100,4406814","P6":"100,4538178","P7":"100,4466195","P8":"100,4965969","P9":"100,5770001"},
            {"seance":"05/10/2018","P0":"100,1868641","P1":"100,2172678","P2":"100,2478407","P3":"100,2768044","P4":"100,2918999","P5":"100,3070791","P6":"100,2937052","P7":"100,2546153","P8":"100,2659579","P9":"100,2532116" },
            {"seance":"12/10/2018","P0":"99,95584966","P1":"99,97499375","P2":"99,99424442","P3":"100,0105829","P4":"100,0032063","P5":"99,99578884","P6":"99,9561403","P7":"99,88744937","P8":"99,87701233","P9":"99,75683741" },
            {"seance":"19/10/2018","P0":"99,83904001","P1":"99,88158154","P2":"99,9243599","P3":"99,96084403","P4":"99,94602629","P5":"99,93112639","P6":"99,8772798","P7":"99,78833615","P8":"99,77996088","P9":"99,51010683" },
            {"seance":"26/10/2018","P0":"99,74882576","P1":"99,78600038","P2":"99,82338195","P3":"99,85490216","P4":"99,83874258","P5":"99,82249341","P6":"99,76986703","P7":"99,68445776","P8":"99,64638688","P9":"99,32595177" },
            {"seance":"02/11/2018","P0":"99,36031093","P1":"99,39927723","P2":"99,43846046","P3":"99,46676704","P4":"99,40775211","P5":"99,34841001","P6":"99,23258965","P7":"99,065852","P8":"98,95259615","P9":"98,33690593" },
            {"seance":"09/11/2018","P0":"99,71004394","P1":"99,74140518","P2":"99,772941","P3":"99,79751466","P4":"99,76594646","P5":"99,73420326","P6":"99,6487224","P7":"99,51480914","P8":"99,49998665","P9":"99,12765891" },
            {"seance":"16/11/2018","P0":"99,73168378","P1":"99,77323265","P2":"99,81501282","P3":"99,84694645","P4":"99,79958703","P5":"99,75196506","P6":"99,66029623","P7":"99,52891674","P8":"99,51723936","P9":"99,12450534" },
            {"seance":"23/11/2018","P0":"99,68233378","P1":"99,70129178","P2":"99,72035531","P3":"99,73231879","P4":"99,68752992","P5":"99,64249275","P6":"99,55256466","P7":"99,42216692","P8":"99,41433369","P9":"99,02631936" },
            {"seance":"30/11/2018","P0":"100,0461093","P1":"100,0923623","P2":"100,1388727","P3":"100,1776238","P4":"100,1533697","P5":"100,1289812","P6":"100,059941","P7":"99,95065845","P8":"100,0135364","P9":"99,82310491"},

            {"seance":"07/12/2018","P0":"99,82226129","P1":"99,86015484","P2":"99,89825936","P3":"99,92845002","P4":"99,89473822","P5":"99,86083952","P6":"99,77487607","P7":"99,64198616","P8":"99,70826639","P9":"99,45415267"},
            {"seance":"14/12/2018","P0":"99,80098927","P1":"99,84289135","P2":"99,8850267","P3":"99,91886733","P4":"99,88564603","P5":"99,85224056","P6":"99,75854791","P7":"99,6105212","P8":"99,67501476","P9":"99,34974245"},
            {"seance":"21/12/2018","P0":"99,86820141","P1":"99,92759502","P2":"99,98731929","P3":"100,0379318","P4":"100,0143621","P5":"99,99066183","P6":"99,90571241","P7":"99,76556785","P8":"99,82617029","P9":"99,47746116"},
            {"seance":"28/12/2018","P0":"99,74099118","P1":"99,81982573","P2":"99,89909916","P3":"99,96165003","P4":"99,88921778","P5":"99,81638399","P6":"99,69684867","P7":"99,53519659","P8":"99,56647318","P9":"99,13134965"},
            {"seance":"04/01/2019","P0":"99,79956532","P1":"99,85263744","P2":"99,90600501","P3":"99,94925725","P4":"99,91065207","P5":"99,87183286","P6":"99,76314138","P7":"99,59147716","P8":"99,63031473","P9":"99,19569288"},
            {"seance":"14/01/2019","P0":"99,72383","P1":"99,78534375","P2":"99,84719995","P3":"99,89524449","P4":"99,83194103","P5":"99,76828664","P6":"99,62891424","P7":"99,4212883","P8":"99,40435293","P9":"98,82061399"},
            {"seance":"18/01/2019","P0":"99,96879922","P1":"100,0053873","P2":"100,0421791","P3":"100,071301","P4":"100,0384953","P5":"100,0055077","P6":"99,9045521","P7":"99,7423428","P8":"99,80398911","P9":"99,42037921"},
            {"seance":"25/01/2019","P0":"100,2294611","P1":"100,2595133","P2":"100,2897327","P3":"100,316843","P4":"100,318264","P5":"100,3196929","P6":"100,2346935","P7":"100,0718283","P8":"100,182814","P9":"99,86967267"},
            {"seance":"01/02/2019","P0":"100,5102997","P1":"100,5745183","P2":"100,6390943","P3":"100,6994257","P4":"100,7237934","P5":"100,7482962","P6":"100,6886429","P7":"100,5531844","P8":"100,6732195","P9":"100,4750888"},
            {"seance":"08/02/2019","P0":"100,6845756","P1":"100,7586064","P2":"100,8330494","P3":"100,901335","P4":"100,9181863","P5":"100,935131","P6":"100,8788938","P7":"100,7567342","P8":"100,883817","P9":"100,6980919"},
            {"seance":"15/02/2019","P0":"100,9481628","P1":"101,0070327","P2":"101,0662303","P3":"101,1213083","P4":"101,1416135","P5":"101,1620313","P6":"101,1052716","P7":"100,9789917","P8":"101,1347534","P9":"100,876242"},
            {"seance":"22/02/2019","P0":"100,9616259","P1":"100,9908654","P2":"101,0202677","P3":"101,0462733","P4":"101,0443516","P5":"101,0424193","P6":"100,9536284","P7":"100,7865823","P8":"100,8688401","P9":"100,337649"},
            {"seance":"01/03/2019","P0":"100,7126482","P1":"100,725261","P2":"100,7379441","P3":"100,7443548","P4":"100,7007886","P5":"100,6569808","P6":"100,5299937","P7":"100,3280422","P8":"100,3626008","P9":"99,68260931"}
           

        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
          setChartData(yourDataSet[0]);
        }
      }, [yourDataSet]);
    
      const getOption = () => {
        if (!chartData || chartData.length === 0) {
          return {}; // Return an empty option if chartData is empty or undefined
        }
      
        const xAxisData = chartData.map((item) => item.seance);
      
        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'seance');
      
        const series = legendData.map((key) => ({
          type: 'line',
          name: key,
          data: chartData.map((item) => {
            const value = item[key];
            // Replace commas with dots and parse as float
            const parsedValue = parseFloat(value.replace(',', '.'));
            return isNaN(parsedValue) ? null : parsedValue;
          }),
        }));
    
        return {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: legendData,
          },
          xAxis: {
            type: 'category',
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: 80,
          },
          series,
        };
      };
    
      return (
        <Row className="g-3">
      <Col>
        <Card dir="ltr" className="h-100">
          <Card.Body>
            <ReactEChartsCore echarts={echarts} option={getOption()} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
      );
    };
    
    export default LineChartComponent;
