import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import Table from 'react-bootstrap/Table';
import StatP from '../../../components/statistiques/StatD';
import StackedP from '../../../components/statistiques/StackedD';
import LineP from '../../../components/statistiques/lineM';
import AreaP from '../../../components/statistiques/AreaD';
import Accordion from 'react-bootstrap/Accordion';
import Comparaison from '../../../components/statistiques/comparaisonG';
import Ac from '../../charts/dynamique/piecharts/ac';
import Sg from '../../charts/dynamique/piecharts/sg';
import Titres from '../../charts/dynamique/piecharts/titres';
import '../style.css'



const SunburstChartP = () => {

    const data = [
        {
            name: 'OMLT',
            itemStyle: {
                color: '#ff729f',
                fontSize: 10
            },
            children: [
                {
                    name: 'VALORIS \n MANAGEMENT',
                    itemStyle: {
                        color: '#006cff',
                        fontSize: 10
                    },
                    children: [
                        { name: 'EMERGENCE \n CASH', value: 8.00, itemStyle: { color: '#e85412', fontSize: 10 } }

                    ]
                },
                {
                    name: 'WINEO \n GESTION',
                    itemStyle: {
                        color: '#ba181b',
                        fontSize: 10
                    },
                    children: [
                        { name: 'WINEO \n OBLIG', value: 8.00, itemStyle: { color: '#0b090a', fontSize: 10 } },
                        { name: 'WINEO \n OBLIG \n SECURITE', value: 8.00, itemStyle: { color: '#0b090a', fontSize: 10 } },
                    ]
                },
                {
                    name: 'WAFA \n GESTION',
                    itemStyle: {
                        color: '#ba181b',
                        fontSize: 10
                    },
                    children: [
                        { name: 'UNIVERS \n OBLIGATIONS', value: 8.00, itemStyle: { color: '#0b090a', fontSize: 10 } }
                    ]
                },


            ]
        },
        {
            name: 'ACTIONS',
            itemStyle: {
                color: '#81f4e1',
                fontSize: 5,

            },
            children: [
                {
                    name: 'WAFA \n GESTION',
                    itemStyle: {
                        color: '#fc4633',
                        fontSize: 10
                    },
                    children: [
                        { name: 'PATRIMOINE \n ACTIONS', value: 8.00, itemStyle: { color: '#ffb200', fontSize: 10 } },
                        { name: 'PROFIL \n DYNAMIQUE', value: 4.00, itemStyle: { color: '#ffb200', fontSize: 10 } },

                    ]
                },
                {
                    name: 'WINEO \n GESTION',
                    itemStyle: {
                        color: '#fc4633',
                        fontSize: 10
                    },
                    children: [
                        { name: 'WINEO \n ACTIONS', value: 8.00, itemStyle: { color: '#ffb200', fontSize: 10 } }

                    ]
                },
                {
                    name: 'VALORIS \n MANAGEMENT',
                    itemStyle: {
                        color: '#fc4633',
                        fontSize: 10
                    },
                    children: [
                        { name: 'EMERGENCE \n PERFERMANCE', value: 8.00, itemStyle: { color: '#ffb200', fontSize: 10 } }

                    ]
                },

            ]
        },


        {
            name: 'OCT',
            itemStyle: {
                color: '#d3c4d1',
                fontSize: 10
            },
            children: [
                {
                    name: 'VALORIS \n MANAGEMENT',
                    itemStyle: {
                        color: '#be9f3b',
                        fontSize: 10
                    },
                    children: [
                        { name: 'EMERGENCE \n BANK', value: 8, itemStyle: { color: '#a4906d', fontSize: 10 } },
                        { name: 'EMERGENCE \n MONETAIRE \n PLUS', value: 8, itemStyle: { color: '#a4906d', fontSize: 10 } },



                    ]
                },

            ]
        },
        {
            name: 'CONTRACTUEL',
            itemStyle: {
                color: '#a5243d',
                fontSize: 10
            },
            children: [
                {
                    name: 'WAFA \n GESTION',
                    itemStyle: {
                        color: '#660708',
                        fontSize: 10
                    },
                    children: [
                        { name: 'FCP \n ATTIJARI \n GOLD', value: 8.00, itemStyle: { color: '#6c757d', fontSize: 10 } },

                    ]
                },

            ]
        },
        {
            name: 'DIVERSIFIE',
            itemStyle: {
                color: '#56cbf9',
                fontSize: 10
            },
            children: [
                {
                    name: 'WINEO \n GESTION',
                    itemStyle: {
                        color: '#fc4633',
                        fontSize: 10
                    },
                    children: [
                        { name: 'WINEO \n DELTA', value: 8.00, itemStyle: { color: '#ffb200', fontSize: 10 } },
                        { name: 'ATTIJARI \n DIVERSIFIE', value: 8.00, itemStyle: { color: '#ffb200', fontSize: 10 } },

                    ]
                },

            ]
        },



    ];

    const chartStyle = {
        height: '600px',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };

    const tableStyle = {
        height: '600px',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        fontSize: '10px',
    };

    const getOption = () => {
        return {
            series: {
                type: 'sunburst',
                data: data,
                radius: [0, '95%'],
                label: {
                    fontSize: 8,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        return `${params.name} \n ${params.value} %`;
                    },
                },
                sort: undefined,
                emphasis: {
                    focus: 'ancestor'
                },
                levels: [
                    {},
                    {
                        r0: '10%',
                        r: '30%',
                        itemStyle: {
                            borderWidth: 2
                        },
                        label: {
                            rotate: 'tangential'
                        }
                    },
                    {
                        r0: '30%',
                        r: '60%',
                        label: {
                            align: 'right'
                        }
                    },
                    {
                        r0: '60%',
                        r: '65%',
                        label: {
                            position: 'outside',
                            padding: 3,
                            silent: false
                        },
                        itemStyle: {
                            borderWidth: 3
                        }
                    }
                ]
            }
        };
    };


    return (
        <>
            <div className="animated-content">
                <div className="icon">🌟</div>
                <div className="text">Investisseur Dynamique</div> <br />
                <div class="box">
                    <p><span className="highlight">Tolérance au risque :</span> Élevée</p>
                    <p><span className="highlight">Objectifs :</span> Croissance active du capital.</p>
                    <p><span className="highlight">Stratégie d'investissement :</span> Portefeuille composé principalement d'actions, y compris des actions de croissance et des investissements dans des secteurs émergents ou volatils.</p>
                    <p><span className="highlight">Horizon d'investissement :</span> Long terme.</p>
                    <p><span className="highlight">Profil :</span> Convient aux investisseurs qui sont prêts à accepter des fluctuations de marché importantes pour réaliser des gains potentiels plus élevés.</p>
                </div>
            </div>
            <p>

            </p>


            <Row>
                <Col lg={6} md={12}>

                    <div>
                        <ReactECharts option={getOption()} style={chartStyle} />
                    </div>
                </Col>
                <Col lg={6} md={12}>

                    <div className="table-responsive">
                        <Table striped bordered hover responsive style={tableStyle}>
                            <thead>
                                <tr>
                                    <th>Société de Gestion</th>
                                    <th>Classification</th>
                                    <th>Titre</th>
                                    <th>Poids (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>DIVERSIFIE</td>
                                    <td>ATTIJARI DIVERSIFIE</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>DIVERSIFIE</td>
                                    <td>WINEO DELTA</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>ACTIONS</td>
                                    <td>PATRIMOINE ACTIONS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>ACTIONS</td>
                                    <td>WINEO ACTIONS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>ACTIONS</td>
                                    <td>PROFIL DYNAMIQUE</td>
                                    <td>4.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>ACTIONS</td>
                                    <td>EMERGENCE PERFORMANCE</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>CONTRACTUEL</td>
                                    <td>FCP ATTIJARI GOLD</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE BANK</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE MONETAIRE PLUS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>OMLT</td>
                                    <td>WINEO OBLIG</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>OMLT</td>
                                    <td>WINEO OBLIG SECURITE</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>OMLT</td>
                                    <td>UNIVERS OBLIGATIONS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OMLT</td>
                                    <td>EMERGENCE CASH</td>
                                    <td>8.00</td>
                                </tr>
                            </tbody>
                        </Table>

                    </div>

                </Col>
            </Row>
            <Row>
                <Col lg={4} md={12}>

                    <div>
                        <Ac />
                    </div>
                </Col>
                <Col lg={4} md={12}>

                    <div>
                        <Sg />
                    </div>
                </Col>
                <Col lg={4} md={12}>

                    <div>
                        <Titres />
                    </div>
                </Col>

            </Row >
            <br />
            <Row>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-percent" viewBox="0 0 16 16">
                            <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                        </svg></Button>  &nbsp; Rendement annuel profil Dynamique</Accordion.Header>
                        <Accordion.Body>
                            <StackedP />
                            <StatP />

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                        </svg></Button>  &nbsp; Historique et projection du profil Dynamique</Accordion.Header>
                        <Accordion.Body>
                            <LineP />
                            <AreaP />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                        </svg></Button>  &nbsp; Comparaison du profil dynamique aux autres profils investisseurs</Accordion.Header>
                        <Accordion.Body>

                            <Comparaison />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br />
            </Row>
            <br />
            {/*     <Row>
                <Col>

                    <div className="table-responsive">
                        <Table striped bordered hover responsive style={tableStyle}>
                            <thead>
                                <tr>
                                    <th>Société de Gestion</th>
                                    <th>Classification</th>
                                    <th>Titre</th>
                                    <th>Poids (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>OCT</td>
                                    <td>CDM OBLIG PLUS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE BANK</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>OCT</td>
                                    <td>FCP CAP INSTITUTIONS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE BOND FUND</td>
                                    <td>7.07</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>ATTIJARI TRESORERIE</td>
                                    <td>6.79</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>EMERGENCE MONEY MARKET FUND</td>
                                    <td>6.58</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>ATTIJARI LIQUIDITE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>CAPITAL TRUST GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CAPITAL TRUST MONETAIRE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CDM CASH</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>IRG ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>FCP IRGAM MONETAIRE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>AFRICAPITAL MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>AFRICAPITAL LIQUIDITY</td>
                                    <td>6.27</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>WINEO CASH</td>
                                    <td>6.22</td>
                                </tr>
                                <tr>
                                    <td>BMCI ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>BMCI TRESO PLUS</td>
                                    <td>5.92</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CAP MONETAIRE PREMIERE</td>
                                    <td>5.65</td>
                                </tr>
                                <tr>
                                    <td>BMCI ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>BMCI PREMIUM MONEY MARKET</td>
                                    <td>5.57</td>
                                </tr>
                            </tbody>
                        </Table>

                    </div>

                </Col>
            </Row>
            <br /> */}

        </>

    )
};

export default SunburstChartP;




