import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Row, Col, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import StatP from '../../components/statistiques/prudent';
import StackedP from '../../components/statistiques/StackedP';
import LineP from '../statistiques/lineP';
import AreaP from '../../components/statistiques/areaP';
import Accordion from 'react-bootstrap/Accordion';
import Comparaison from '../../components/statistiques/comparaisonG';


const SunburstChart = () => {
    const data = [
        {
            name: 'MONETAIRE',
            itemStyle: {
                color: '#a5243d',
                label: {
                    fontSize: 20
                }
            },
            children: [
                {
                    name: 'BMCI \n ASSET \n MANAGEMENT',
                    itemStyle: {
                        color: '#006cff',
                        fontSize: 2
                    },
                    children: [
                        { name: 'BMCI \n TRESO \n PLUS', value: 6, itemStyle: { color: '#e85412', fontSize: 5 } },
                        { name: 'BMCI \n PREMIUM \n MONEY \n MARKET', value: 6, itemStyle: { color: '#e85412', fontSize: 10 } },
                    ]
                },
                {
                    name: 'WAFA \n GESTION',
                    itemStyle: {
                        color: '#fc4633',
                        fontSize: 10
                    },
                    children: [
                        { name: 'ATTIJARI \n LIQUIDITE', value: 6, itemStyle: { color: '#ffb200', fontSize: 10 } },
                        { name: 'CDM  \n CASH', value: 6, itemStyle: { color: '#ffb200', fontSize: 10 } },
                        { name: 'ATTIJARI \n TRESORERIE', value: 7, itemStyle: { color: '#ffb200', fontSize: 10 } },
                        { name: 'CAP \n MONETAIRE \n PREMIERE', value: 7, itemStyle: { color: '#ffb200', fontSize: 10 } }
                    ]
                },
                {
                    name: 'CAPITAL \n TRUST \n GESTION',
                    itemStyle: {
                        color: '#be9f3b',
                        fontSize: 10
                    },
                    children: [
                        { name: 'CAPITAL \n TRUST \n MONETAIRE', value: 6, itemStyle: { color: '#a4906d', fontSize: 10 } }
                    ]
                },
                {
                    name: 'WINEO \n GESTION',
                    itemStyle: {
                        color: '#ba181b',
                        fontSize: 10
                    },
                    children: [
                        { name: 'WINEO \n CASH', value: 6, itemStyle: { color: '#0b090a', fontSize: 10 } }
                    ]
                },
                {
                    name: 'IRG \n ASSET \n MANAGEMENT',
                    itemStyle: {
                        color: '#660708',
                        fontSize: 10
                    },
                    children: [
                        { name: 'FCP \n IRGAM \n MONETAIRE', value: 6, itemStyle: { color: '#6c757d', fontSize: 10 } }
                    ]
                },
                {
                    name: 'AFRICAPITAL \n MANAGEMENT',
                    itemStyle: {
                        color: '#660708',
                        fontSize: 10
                    },
                    children: [
                        { name: 'AFRICAPITAL \n LIQUIDITY', value: 6, itemStyle: { color: '#6c757d', fontSize: 10 } }
                    ]
                },
                {
                    name: 'VALORIS \n MANAGEMENT',
                    itemStyle: {
                        color: '#660708',
                        fontSize: 10
                    },
                    children: [
                        { name: 'EMERGENCE \n MONEY \n MARKET \n FUND', value: 7, itemStyle: { color: '#6c757d', fontSize: 10 } }
                    ]
                }
            ]
        },
        {
            name: 'OCT',
            itemStyle: {
                color: '#d3c4d1',
                fontSize: 10
            },
            children: [
                {
                    name: 'WAFA \n GESTION',
                    itemStyle: {
                        color: '#da0d68',
                        fontSize: 10
                    },
                    children: [
                        { name: 'FCP \n CAP \n INSTITUTIONS', value: 8, itemStyle: { color: '#6d975e', fontSize: 10 } },
                        { name: 'CDM \n OBLIG \n PLUS', value: 8, itemStyle: { color: '#6d975e', fontSize: 10 } }
                    ]
                },
                {
                    name: 'VALORIS \n MANAGEMENT',
                    itemStyle: {
                        color: '#da0d68',
                        fontSize: 10
                    },
                    children: [
                        { name: 'EMERGENCE \n BANK', value: 8, itemStyle: { color: '#6d975e', fontSize: 10 } },
                        { name: 'EMERGENCE \n BOND \n FUND', value: 7, itemStyle: { color: '#6d975e', fontSize: 10 } }
                    ]
                },

            ]
        }
    ];

    const chartStyle = {
        height: '600px',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };
    const tableStyle = {
        height: '600px',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        fontSize: '10px',
    };

    const getOption = () => {
        return {
            title: {
                text: 'PROFIL PRUDENT',
                subtext: 'Votre profil correspond à un profil prudent',
                textStyle: {
                    fontSize: 14,
                    align: 'center'
                },
                subtextStyle: {
                    align: 'center'
                },
            },
            series: {
                type: 'sunburst',
                data: data,
                radius: [0, '95%'],
                label: {
                    fontSize: 8,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        return `${params.name} \n ${params.value} %`;
                    },
                },
                sort: undefined,
                emphasis: {
                    focus: 'ancestor'
                },
                levels: [
                    {},
                    {
                        r0: '10%',
                        r: '30%',
                        itemStyle: {
                            borderWidth: 2
                        },
                        label: {
                            rotate: 'tangential'
                        }
                    },
                    {
                        r0: '30%',
                        r: '60%',
                        label: {
                            align: 'right'
                        }
                    },
                    {
                        r0: '60%',
                        r: '65%',
                        label: {
                            position: 'outside',
                            padding: 3,
                            silent: false
                        },
                        itemStyle: {
                            borderWidth: 3
                        }
                    }
                ]
            }
        };
    };

    return (
        <>
            <Row>
                <Col lg={6} md={12}>

                    <div>
                        <ReactECharts option={getOption()} style={chartStyle} />
                    </div>
                </Col>
                <Col lg={6} md={12}>

                    <div className="table-responsive">
                        <Table striped bordered hover responsive style={tableStyle}>
                            <thead>
                                <tr>
                                    <th>Société de Gestion</th>
                                    <th>Classification</th>
                                    <th>Titre</th>
                                    <th>Poids (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>OCT</td>
                                    <td>CDM OBLIG PLUS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE BANK</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>OCT</td>
                                    <td>FCP CAP INSTITUTIONS</td>
                                    <td>8.00</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>OCT</td>
                                    <td>EMERGENCE BOND FUND</td>
                                    <td>7.07</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>ATTIJARI TRESORERIE</td>
                                    <td>6.79</td>
                                </tr>
                                <tr>
                                    <td>VALORIS MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>EMERGENCE MONEY MARKET FUND</td>
                                    <td>6.58</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>ATTIJARI LIQUIDITE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>CAPITAL TRUST GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CAPITAL TRUST MONETAIRE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CDM CASH</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>IRG ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>FCP IRGAM MONETAIRE</td>
                                    <td>6.48</td>
                                </tr>
                                <tr>
                                    <td>AFRICAPITAL MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>AFRICAPITAL LIQUIDITY</td>
                                    <td>6.27</td>
                                </tr>
                                <tr>
                                    <td>WINEO GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>WINEO CASH</td>
                                    <td>6.22</td>
                                </tr>
                                <tr>
                                    <td>BMCI ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>BMCI TRESO PLUS</td>
                                    <td>5.92</td>
                                </tr>
                                <tr>
                                    <td>WAFA GESTION</td>
                                    <td>MONETAIRE</td>
                                    <td>CAP MONETAIRE PREMIERE</td>
                                    <td>5.65</td>
                                </tr>
                                <tr>
                                    <td>BMCI ASSET MANAGEMENT</td>
                                    <td>MONETAIRE</td>
                                    <td>BMCI PREMIUM MONEY MARKET</td>
                                    <td>5.57</td>
                                </tr>
                            </tbody>
                        </Table>

                    </div>

                </Col>
            </Row >
            {/*  <Row>
                <Col lg={6} md={12}>
                    <StatP />
                </Col>
                <Col lg={6} md={12}>
                    <StackedP />
                </Col>

            </Row >
            <br />
            <Row>
                <Col lg={6} md={12}>
                    <AreaP />
                </Col>
                <Col lg={6} md={12}>

                    <LineP />
                </Col>

            </Row > */}
            <br />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-percent" viewBox="0 0 16 16">
  <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
</svg></Button>  &nbsp; Rendement annuel profil Prudent</Accordion.Header>
                    <Accordion.Body>
                        <StackedP />
                        <StatP />

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
  <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
  <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
</svg></Button>  &nbsp; Historique et projection du profil prudent</Accordion.Header>
                    <Accordion.Body>
                        <LineP />
                        <AreaP />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><Button disabled><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5"/>
</svg></Button>  &nbsp; Comparaison du profil prudent aux autres profils investisseurs</Accordion.Header>
                    <Accordion.Body>
                        
                        <Comparaison />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
        </>

    )
};

export default SunburstChart;







/* {"Société de Gestion":"BMCI ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"BMCI TRESORERIE","Poids (%)":8},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"CDM SECURITE PLUS","Poids (%)":8},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"ATTIJARI LIQUIDITE","Poids (%)":8},
{"Société de Gestion":"BMCI ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"BMCI PREMIUM MONEY MARKET","Poids (%)":8},
{"Société de Gestion":"WINEO GESTION","Classification":"MONETAIRE","Titre":"WINEO CASH","Poids (%)":8},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"ATTIJARI MONETAIRE JOUR","Poids (%)":8},
{"Société de Gestion":"BMCI ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"BMCI TRESO PLUS","Poids (%)":8},
{"Société de Gestion":"BMCI ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"BMCI MONETAIRE PLUS","Poids (%)":8},
{"Société de Gestion":"CAPITAL TRUST GESTION","Classification":"MONETAIRE","Titre":"CAPITAL TRUST MONETAIRE","Poids (%)":7},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"CDM CASH","Poids (%)":7},
{"Société de Gestion":"IRG ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"FCP IRGAM MONETAIRE","Poids (%)":6},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"ATTIJARI TRESORERIE","Poids (%)":6},
{"Société de Gestion":"WAFA GESTION","Classification":"MONETAIRE","Titre":"CAP MONETAIRE PREMIERE","Poids (%)":5},
{"Société de Gestion":"RED MED ASSET MANAGEMENT","Classification":"MONETAIRE","Titre":"ALPHA MONETAIRE","Poids (%)":5},
{"Société de Gestion":"WINEO GESTION","Classification":"OCT","Titre":"WINEO OBLIG CT","Poids (%)":0} */