// Import the necessary libraries and components
import React from 'react';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import Piechart from '../components/echarts/piechart';
import Linechart from '../components/echarts/linechart';
import StackedChart from '../components/echarts/stackedchart';
import BasicScatterChart from '../components/echarts/basicscatterchart';
import BubbleChart from '../components/echarts/bubbleChart';
import QuartetScatterChart from '../components/echarts/quartetChart';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { Col, Row } from 'react-bootstrap';
import BarLineMixedChart from '../components/echarts/echarts';

// Apply ECharts components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const PieEdgeAlignChart = () => {
  

  return (
    <div>
      <Row className="gx-3">
        <Col lg={6}>
        <StackedChart />
             
            
        </Col>
        <Col lg={6}>
         
             <BarLineMixedChart />
            
        </Col>
        <Col lg={6}>
          
              <Linechart />
            
        </Col>
        <Col lg={6}>
        <Piechart />
            
            
        </Col>
        <Col lg={6}>
          
             <BasicScatterChart />
            
        </Col>
        <Col lg={6}>
         
          <BubbleChart />
            
        </Col>
        <Col lg={12}>
         
          <QuartetScatterChart />
            
        </Col>
      </Row>
      
    </div>
  );
};

export default PieEdgeAlignChart;
