import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Section from '../components/tools/Section';
import bg1 from '../assets/robo/bg-2.jpg';
import dashboardDark from '../assets/robo/Next-Gen-Operating-.png';


const Hero = () => {
  return (
    <>
    <Section
      className="py-0 overflow-hidden light" 
      image={bg1}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
       
       
        <Col
          md={11}
          lg={8}
          xl={5}
          className="pb-7 pb-xl-9 text-xl-start"
        >
         {/*  <Button
            as={Link}
            variant="outline-danger"
            className="mb-4 fs--1 border-2 rounded-pill"
            to="#!"
          >
            <span className="me-2" role="img" aria-label="Gift">
              🎁
            </span>
            Become a pro
          </Button> */}
          <h1 className="fw-light">
            Investissez en toute confiance

          </h1>
          <p className="lead opacity-75">
            Fintech RoboAdvisor, seule plateforme au Maroc qui crée des placements sur-mesure pour chacun de vos projets de vie.
            Définissez vos objectifs, Fintech RoboAdvisor s'occupe du reste. Votre épargne est investie en OPCVM, nous la pilotons en continu pour vous aider à réaliser vos projets.
            Faites votre simulation en quelques clics et laissez-vous guider.
          </p>
          <Button
            as={Link}
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="/roboadvisor"
          >
            Commencer ma simulation
          </Button>
          <p></p>
          
        </Col>
        <Col
          xl={{ span: 6, offset: 1 }}
          className="mt-4"
        >
            <img
            className='img-fluid'
              src={dashboardDark}
              alt=""
            />
        </Col>
      </Row>
    </Section>
    </>
  );
};

export default Hero;
