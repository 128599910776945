import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const BarChart = () => {
    const data = [
        {
            "Année": "2015",
            "Rendement annuel": "0"
        },
        {
            "Année": "2016",
            "Rendement annuel": "15,46702038"
        },
        {
            "Année": "2017",
            "Rendement annuel": "7,924352491"
        },
        {
            "Année": "2018",
            "Rendement annuel": "-1,810106182"
        },
        {
            "Année": "2019",
            "Rendement annuel": "8,437807841"
        },
        {
            "Année": "2020",
            "Rendement annuel": "2,653165371"
        },
        {
            "Année": "2021",
            "Rendement annuel": "8,593313488"
        },
        {
            "Année": "2022",
            "Rendement annuel": "-6,162774754"
        },
        {
            "Année": "2023",
            "Rendement annuel": "7,688179017"
        },
        {
            "Année": "2024",
            "Rendement annuel": "2,654445451"
        }
    ];

    const option = {
        title: {
            text: '',
            left: 'center',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            top: 80,
            bottom: 30,
        },
        xAxis: {
            type: 'category',
            position: 'left',
            axisLine: { show: false },
            axisLabel: { show: true, rotate: 45 },
            axisTick: { show: false },
            data: data.map(item => item["Année"]),
        },
        yAxis: {
            type: 'value',
            position: 'bottom',
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                },
            },
        },
        series: [
            {
                name: 'Rendement annuel',
                type: 'bar',
                stack: 'Total',
                
                data: data.map(item => ({
                    value: parseFloat(item["Rendement annuel"]),
                    label:  { rotate: 90,position: 'inside', formatter: '{c}%', 
                    emphasis: { 
                        formatter: '{c}%' 
                    }},
                    itemStyle: {
                        color: parseFloat(item["Rendement annuel"]) < 0 ? '#FA5F55' : '#91cc75',
                      },
                })),
            },
        ],
    };

    useEffect(() => {
        // Optionally, you can handle the initialization and cleanup here
        // If you need to update the chart dynamically, you can use a state variable
    }, []);

    return (<Row className="g-3">
        <Col>
           
                    <ReactEChartsCore echarts={echarts} option={option} />
                
        </Col>
    </Row>
    )
};

export default BarChart;
