import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil prudent": "100",
                "OPC MONETAIRE": "100"
            },
            {
                "Date": "1/4/16",
                "Profil prudent": "100.038397",
                "OPC MONETAIRE": "100.0403134"
            },
            {
                "Date": "1/8/16",
                "Profil prudent": "100.0658718",
                "OPC MONETAIRE": "100.0675706"
            },
            {
                "Date": "1/15/16",
                "Profil prudent": "100.1140676",
                "OPC MONETAIRE": "100.1129559"
            },
            {
                "Date": "1/22/16",
                "Profil prudent": "100.1900439",
                "OPC MONETAIRE": "100.1796291"
            },
            {
                "Date": "1/29/16",
                "Profil prudent": "100.2449858",
                "OPC MONETAIRE": "100.2294"
            },
            {
                "Date": "2/5/16",
                "Profil prudent": "100.2982652",
                "OPC MONETAIRE": "100.2807843"
            },
            {
                "Date": "2/12/16",
                "Profil prudent": "100.3537864",
                "OPC MONETAIRE": "100.3322946"
            },
            {
                "Date": "2/19/16",
                "Profil prudent": "100.4055134",
                "OPC MONETAIRE": "100.3828663"
            },
            {
                "Date": "2/26/16",
                "Profil prudent": "100.4604225",
                "OPC MONETAIRE": "100.4334687"
            },
            {
                "Date": "3/4/16",
                "Profil prudent": "100.5256143",
                "OPC MONETAIRE": "100.4896968"
            },
            {
                "Date": "3/11/16",
                "Profil prudent": "100.5700006",
                "OPC MONETAIRE": "100.5384542"
            },
            {
                "Date": "3/18/16",
                "Profil prudent": "100.633014",
                "OPC MONETAIRE": "100.5962137"
            },
            {
                "Date": "3/25/16",
                "Profil prudent": "100.7004237",
                "OPC MONETAIRE": "100.64482"
            },
            {
                "Date": "4/1/16",
                "Profil prudent": "100.8338922",
                "OPC MONETAIRE": "100.7457624"
            },
            {
                "Date": "4/8/16",
                "Profil prudent": "100.8687467",
                "OPC MONETAIRE": "100.7762824"
            },
            {
                "Date": "4/15/16",
                "Profil prudent": "100.9570073",
                "OPC MONETAIRE": "100.8379959"
            },
            {
                "Date": "4/22/16",
                "Profil prudent": "101.074815",
                "OPC MONETAIRE": "100.9315015"
            },
            {
                "Date": "4/29/16",
                "Profil prudent": "101.1515121",
                "OPC MONETAIRE": "101.0002053"
            },
            {
                "Date": "5/6/16",
                "Profil prudent": "101.1934756",
                "OPC MONETAIRE": "101.0332966"
            },
            {
                "Date": "5/13/16",
                "Profil prudent": "101.2300058",
                "OPC MONETAIRE": "101.0691081"
            },
            {
                "Date": "5/20/16",
                "Profil prudent": "101.2709347",
                "OPC MONETAIRE": "101.1079858"
            },
            {
                "Date": "5/27/16",
                "Profil prudent": "101.3120863",
                "OPC MONETAIRE": "101.1476965"
            },
            {
                "Date": "6/3/16",
                "Profil prudent": "101.3518075",
                "OPC MONETAIRE": "101.1871645"
            },
            {
                "Date": "6/10/16",
                "Profil prudent": "101.4023068",
                "OPC MONETAIRE": "101.2321833"
            },
            {
                "Date": "6/17/16",
                "Profil prudent": "101.4479633",
                "OPC MONETAIRE": "101.2696835"
            },
            {
                "Date": "6/24/16",
                "Profil prudent": "101.4281162",
                "OPC MONETAIRE": "101.2743615"
            },
            {
                "Date": "7/1/16",
                "Profil prudent": "101.3998731",
                "OPC MONETAIRE": "101.261466"
            },
            {
                "Date": "7/11/16",
                "Profil prudent": "101.4559388",
                "OPC MONETAIRE": "101.3120548"
            },
            {
                "Date": "7/15/16",
                "Profil prudent": "101.397598",
                "OPC MONETAIRE": "101.2911494"
            },
            {
                "Date": "7/22/16",
                "Profil prudent": "101.4041333",
                "OPC MONETAIRE": "101.3117978"
            },
            {
                "Date": "7/29/16",
                "Profil prudent": "101.4311549",
                "OPC MONETAIRE": "101.3415671"
            },
            {
                "Date": "8/5/16",
                "Profil prudent": "101.4706353",
                "OPC MONETAIRE": "101.3819242"
            },
            {
                "Date": "8/12/16",
                "Profil prudent": "101.4960403",
                "OPC MONETAIRE": "101.4124202"
            },
            {
                "Date": "8/19/16",
                "Profil prudent": "101.5449607",
                "OPC MONETAIRE": "101.4551725"
            },
            {
                "Date": "8/26/16",
                "Profil prudent": "101.5804552",
                "OPC MONETAIRE": "101.4896177"
            },
            {
                "Date": "9/2/16",
                "Profil prudent": "101.620396",
                "OPC MONETAIRE": "101.5260718"
            },
            {
                "Date": "9/9/16",
                "Profil prudent": "101.6646374",
                "OPC MONETAIRE": "101.5663216"
            },
            {
                "Date": "9/16/16",
                "Profil prudent": "101.7125053",
                "OPC MONETAIRE": "101.608127"
            },
            {
                "Date": "9/23/16",
                "Profil prudent": "101.764587",
                "OPC MONETAIRE": "101.6539014"
            },
            {
                "Date": "9/30/16",
                "Profil prudent": "101.8103949",
                "OPC MONETAIRE": "101.678249"
            },
            {
                "Date": "10/7/16",
                "Profil prudent": "101.8679602",
                "OPC MONETAIRE": "101.7266226"
            },
            {
                "Date": "10/14/16",
                "Profil prudent": "101.9267843",
                "OPC MONETAIRE": "101.7780118"
            },
            {
                "Date": "10/21/16",
                "Profil prudent": "101.9833656",
                "OPC MONETAIRE": "101.8234352"
            },
            {
                "Date": "10/28/16",
                "Profil prudent": "102.0324984",
                "OPC MONETAIRE": "101.8652438"
            },
            {
                "Date": "11/4/16",
                "Profil prudent": "102.0691182",
                "OPC MONETAIRE": "101.9020341"
            },
            {
                "Date": "11/11/16",
                "Profil prudent": "102.1046525",
                "OPC MONETAIRE": "101.9395168"
            },
            {
                "Date": "11/21/16",
                "Profil prudent": "102.165164",
                "OPC MONETAIRE": "101.9962495"
            },
            {
                "Date": "11/25/16",
                "Profil prudent": "102.1923824",
                "OPC MONETAIRE": "102.0228728"
            },
            {
                "Date": "12/2/16",
                "Profil prudent": "102.2349757",
                "OPC MONETAIRE": "102.0607581"
            },
            {
                "Date": "12/9/16",
                "Profil prudent": "102.267451",
                "OPC MONETAIRE": "102.0942161"
            },
            {
                "Date": "12/16/16",
                "Profil prudent": "102.307172",
                "OPC MONETAIRE": "102.1314315"
            },
            {
                "Date": "12/23/16",
                "Profil prudent": "102.3184212",
                "OPC MONETAIRE": "102.151193"
            },
            {
                "Date": "12/30/16",
                "Profil prudent": "102.3626724",
                "OPC MONETAIRE": "102.1924236"
            },
            {
                "Date": "1/6/17",
                "Profil prudent": "102.3988915",
                "OPC MONETAIRE": "102.2277325"
            },
            {
                "Date": "1/13/17",
                "Profil prudent": "102.4300558",
                "OPC MONETAIRE": "102.2618656"
            },
            {
                "Date": "1/20/17",
                "Profil prudent": "102.4714585",
                "OPC MONETAIRE": "102.3041013"
            },
            {
                "Date": "1/27/17",
                "Profil prudent": "102.5126488",
                "OPC MONETAIRE": "102.3423201"
            },
            {
                "Date": "2/3/17",
                "Profil prudent": "102.5505726",
                "OPC MONETAIRE": "102.3799018"
            },
            {
                "Date": "2/10/17",
                "Profil prudent": "102.6127072",
                "OPC MONETAIRE": "102.4348895"
            },
            {
                "Date": "2/17/17",
                "Profil prudent": "102.6710509",
                "OPC MONETAIRE": "102.4847424"
            },
            {
                "Date": "2/24/17",
                "Profil prudent": "102.7373039",
                "OPC MONETAIRE": "102.5382644"
            },
            {
                "Date": "3/3/17",
                "Profil prudent": "102.7903544",
                "OPC MONETAIRE": "102.5830827"
            },
            {
                "Date": "3/10/17",
                "Profil prudent": "102.8417263",
                "OPC MONETAIRE": "102.6268808"
            },
            {
                "Date": "3/17/17",
                "Profil prudent": "102.8671703",
                "OPC MONETAIRE": "102.6564679"
            },
            {
                "Date": "3/24/17",
                "Profil prudent": "102.9309927",
                "OPC MONETAIRE": "102.708599"
            },
            {
                "Date": "3/31/17",
                "Profil prudent": "102.9681972",
                "OPC MONETAIRE": "102.7423461"
            },
            {
                "Date": "4/7/17",
                "Profil prudent": "103.0026336",
                "OPC MONETAIRE": "102.7721326"
            },
            {
                "Date": "4/14/17",
                "Profil prudent": "103.0450451",
                "OPC MONETAIRE": "102.812965"
            },
            {
                "Date": "4/21/17",
                "Profil prudent": "103.092233",
                "OPC MONETAIRE": "102.8550104"
            },
            {
                "Date": "4/28/17",
                "Profil prudent": "103.1421091",
                "OPC MONETAIRE": "102.9007512"
            },
            {
                "Date": "5/5/17",
                "Profil prudent": "103.1777025",
                "OPC MONETAIRE": "102.9362369"
            },
            {
                "Date": "5/12/17",
                "Profil prudent": "103.2317005",
                "OPC MONETAIRE": "102.9866759"
            },
            {
                "Date": "5/19/17",
                "Profil prudent": "103.2747977",
                "OPC MONETAIRE": "103.0269054"
            },
            {
                "Date": "5/26/17",
                "Profil prudent": "103.3212682",
                "OPC MONETAIRE": "103.0691589"
            },
            {
                "Date": "6/2/17",
                "Profil prudent": "103.3563481",
                "OPC MONETAIRE": "103.1048377"
            },
            {
                "Date": "6/9/17",
                "Profil prudent": "103.39708",
                "OPC MONETAIRE": "103.143065"
            },
            {
                "Date": "6/16/17",
                "Profil prudent": "103.4342076",
                "OPC MONETAIRE": "103.1802268"
            },
            {
                "Date": "6/23/17",
                "Profil prudent": "103.4553551",
                "OPC MONETAIRE": "103.2057388"
            },
            {
                "Date": "6/30/17",
                "Profil prudent": "103.4882425",
                "OPC MONETAIRE": "103.2395053"
            },
            {
                "Date": "7/7/17",
                "Profil prudent": "103.5271945",
                "OPC MONETAIRE": "103.280646"
            },
            {
                "Date": "7/14/17",
                "Profil prudent": "103.5658108",
                "OPC MONETAIRE": "103.3167137"
            },
            {
                "Date": "7/21/17",
                "Profil prudent": "103.6132627",
                "OPC MONETAIRE": "103.3582889"
            },
            {
                "Date": "7/28/17",
                "Profil prudent": "103.6684117",
                "OPC MONETAIRE": "103.4050422"
            },
            {
                "Date": "8/4/17",
                "Profil prudent": "103.7209034",
                "OPC MONETAIRE": "103.4501098"
            },
            {
                "Date": "8/11/17",
                "Profil prudent": "103.7679136",
                "OPC MONETAIRE": "103.4924316"
            },
            {
                "Date": "8/18/17",
                "Profil prudent": "103.8125956",
                "OPC MONETAIRE": "103.5350899"
            },
            {
                "Date": "8/25/17",
                "Profil prudent": "103.863321",
                "OPC MONETAIRE": "103.5811783"
            },
            {
                "Date": "9/4/17",
                "Profil prudent": "103.9277894",
                "OPC MONETAIRE": "103.6413337"
            },
            {
                "Date": "9/8/17",
                "Profil prudent": "103.9582294",
                "OPC MONETAIRE": "103.6648322"
            },
            {
                "Date": "9/15/17",
                "Profil prudent": "104.0065761",
                "OPC MONETAIRE": "103.7064196"
            },
            {
                "Date": "9/25/17",
                "Profil prudent": "104.0782903",
                "OPC MONETAIRE": "103.7674547"
            },
            {
                "Date": "9/29/17",
                "Profil prudent": "104.11252",
                "OPC MONETAIRE": "103.7785466"
            },
            {
                "Date": "10/6/17",
                "Profil prudent": "104.1626811",
                "OPC MONETAIRE": "103.8233325"
            },
            {
                "Date": "10/13/17",
                "Profil prudent": "104.2126241",
                "OPC MONETAIRE": "103.8673192"
            },
            {
                "Date": "10/20/17",
                "Profil prudent": "104.2599755",
                "OPC MONETAIRE": "103.9093692"
            },
            {
                "Date": "10/27/17",
                "Profil prudent": "104.3051995",
                "OPC MONETAIRE": "103.9499727"
            },
            {
                "Date": "11/3/17",
                "Profil prudent": "104.3514896",
                "OPC MONETAIRE": "103.9949283"
            },
            {
                "Date": "11/10/17",
                "Profil prudent": "104.3951326",
                "OPC MONETAIRE": "104.0343565"
            },
            {
                "Date": "11/17/17",
                "Profil prudent": "104.4405455",
                "OPC MONETAIRE": "104.0754203"
            },
            {
                "Date": "11/24/17",
                "Profil prudent": "104.4833522",
                "OPC MONETAIRE": "104.1154164"
            },
            {
                "Date": "12/4/17",
                "Profil prudent": "104.5291758",
                "OPC MONETAIRE": "104.1689314"
            },
            {
                "Date": "12/8/17",
                "Profil prudent": "104.5615742",
                "OPC MONETAIRE": "104.1911123"
            },
            {
                "Date": "12/15/17",
                "Profil prudent": "104.597097",
                "OPC MONETAIRE": "104.2257626"
            },
            {
                "Date": "12/22/17",
                "Profil prudent": "104.627036",
                "OPC MONETAIRE": "104.2437643"
            },
            {
                "Date": "12/29/17",
                "Profil prudent": "104.6908407",
                "OPC MONETAIRE": "104.2820685"
            },
            {
                "Date": "1/5/18",
                "Profil prudent": "104.7419619",
                "OPC MONETAIRE": "104.3258599"
            },
            {
                "Date": "1/12/18",
                "Profil prudent": "104.7828846",
                "OPC MONETAIRE": "104.365765"
            },
            {
                "Date": "1/19/18",
                "Profil prudent": "104.8160811",
                "OPC MONETAIRE": "104.4006226"
            },
            {
                "Date": "1/26/18",
                "Profil prudent": "104.8822887",
                "OPC MONETAIRE": "104.4538081"
            },
            {
                "Date": "2/2/18",
                "Profil prudent": "104.9365108",
                "OPC MONETAIRE": "104.4989759"
            },
            {
                "Date": "2/9/18",
                "Profil prudent": "104.9919559",
                "OPC MONETAIRE": "104.5436856"
            },
            {
                "Date": "2/16/18",
                "Profil prudent": "105.0475639",
                "OPC MONETAIRE": "104.5910185"
            },
            {
                "Date": "2/23/18",
                "Profil prudent": "105.0991778",
                "OPC MONETAIRE": "104.6379933"
            },
            {
                "Date": "3/2/18",
                "Profil prudent": "105.1578536",
                "OPC MONETAIRE": "104.6845698"
            },
            {
                "Date": "3/9/18",
                "Profil prudent": "105.2215727",
                "OPC MONETAIRE": "104.7413904"
            },
            {
                "Date": "3/16/18",
                "Profil prudent": "105.2712138",
                "OPC MONETAIRE": "104.7857718"
            },
            {
                "Date": "3/23/18",
                "Profil prudent": "105.3160611",
                "OPC MONETAIRE": "104.8160493"
            },
            {
                "Date": "3/30/18",
                "Profil prudent": "105.3564044",
                "OPC MONETAIRE": "104.8536149"
            },
            {
                "Date": "4/6/18",
                "Profil prudent": "105.3977812",
                "OPC MONETAIRE": "104.8911803"
            },
            {
                "Date": "4/13/18",
                "Profil prudent": "105.4411873",
                "OPC MONETAIRE": "104.9297683"
            },
            {
                "Date": "4/20/18",
                "Profil prudent": "105.4846527",
                "OPC MONETAIRE": "104.9703832"
            },
            {
                "Date": "4/27/18",
                "Profil prudent": "105.5248914",
                "OPC MONETAIRE": "105.0093283"
            },
            {
                "Date": "5/4/18",
                "Profil prudent": "105.5686248",
                "OPC MONETAIRE": "105.0502039"
            },
            {
                "Date": "5/11/18",
                "Profil prudent": "105.6105895",
                "OPC MONETAIRE": "105.0900368"
            },
            {
                "Date": "5/18/18",
                "Profil prudent": "105.6589079",
                "OPC MONETAIRE": "105.1321464"
            },
            {
                "Date": "5/25/18",
                "Profil prudent": "105.6999021",
                "OPC MONETAIRE": "105.1710645"
            },
            {
                "Date": "6/1/18",
                "Profil prudent": "105.7420852",
                "OPC MONETAIRE": "105.2063288"
            },
            {
                "Date": "6/8/18",
                "Profil prudent": "105.8007128",
                "OPC MONETAIRE": "105.2660097"
            },
            {
                "Date": "6/18/18",
                "Profil prudent": "105.8625634",
                "OPC MONETAIRE": "105.3243735"
            },
            {
                "Date": "6/22/18",
                "Profil prudent": "105.8942664",
                "OPC MONETAIRE": "105.3507141"
            },
            {
                "Date": "6/29/18",
                "Profil prudent": "105.9321325",
                "OPC MONETAIRE": "105.3878715"
            },
            {
                "Date": "7/6/18",
                "Profil prudent": "105.9789044",
                "OPC MONETAIRE": "105.4304351"
            },
            {
                "Date": "7/13/18",
                "Profil prudent": "106.0320855",
                "OPC MONETAIRE": "105.4761228"
            },
            {
                "Date": "7/20/18",
                "Profil prudent": "106.0742134",
                "OPC MONETAIRE": "105.5160321"
            },
            {
                "Date": "7/27/18",
                "Profil prudent": "106.1216493",
                "OPC MONETAIRE": "105.5604451"
            },
            {
                "Date": "8/3/18",
                "Profil prudent": "106.1714795",
                "OPC MONETAIRE": "105.6055151"
            },
            {
                "Date": "8/10/18",
                "Profil prudent": "106.2216823",
                "OPC MONETAIRE": "105.6553097"
            },
            {
                "Date": "8/17/18",
                "Profil prudent": "106.2559154",
                "OPC MONETAIRE": "105.6885129"
            },
            {
                "Date": "8/24/18",
                "Profil prudent": "106.3027524",
                "OPC MONETAIRE": "105.7325553"
            },
            {
                "Date": "8/31/18",
                "Profil prudent": "106.3512304",
                "OPC MONETAIRE": "105.7758088"
            },
            {
                "Date": "9/7/18",
                "Profil prudent": "106.399476",
                "OPC MONETAIRE": "105.821083"
            },
            {
                "Date": "9/14/18",
                "Profil prudent": "106.443919",
                "OPC MONETAIRE": "105.8652538"
            },
            {
                "Date": "9/21/18",
                "Profil prudent": "106.4867343",
                "OPC MONETAIRE": "105.9050433"
            },
            {
                "Date": "9/28/18",
                "Profil prudent": "106.5352455",
                "OPC MONETAIRE": "105.9503008"
            },
            {
                "Date": "10/5/18",
                "Profil prudent": "106.5830928",
                "OPC MONETAIRE": "105.9947236"
            },
            {
                "Date": "10/12/18",
                "Profil prudent": "106.6329294",
                "OPC MONETAIRE": "106.0401012"
            },
            {
                "Date": "10/19/18",
                "Profil prudent": "106.6838747",
                "OPC MONETAIRE": "106.0868305"
            },
            {
                "Date": "10/26/18",
                "Profil prudent": "106.7312811",
                "OPC MONETAIRE": "106.1306698"
            },
            {
                "Date": "11/2/18",
                "Profil prudent": "106.7822337",
                "OPC MONETAIRE": "106.1758957"
            },
            {
                "Date": "11/9/18",
                "Profil prudent": "106.8304189",
                "OPC MONETAIRE": "106.221497"
            },
            {
                "Date": "11/16/18",
                "Profil prudent": "106.8790993",
                "OPC MONETAIRE": "106.2672319"
            },
            {
                "Date": "11/23/18",
                "Profil prudent": "106.9263253",
                "OPC MONETAIRE": "106.3117512"
            },
            {
                "Date": "11/30/18",
                "Profil prudent": "106.9742438",
                "OPC MONETAIRE": "106.356782"
            },
            {
                "Date": "12/7/18",
                "Profil prudent": "107.0227983",
                "OPC MONETAIRE": "106.4023137"
            },
            {
                "Date": "12/14/18",
                "Profil prudent": "107.0727333",
                "OPC MONETAIRE": "106.4486088"
            },
            {
                "Date": "12/21/18",
                "Profil prudent": "107.1197199",
                "OPC MONETAIRE": "106.491967"
            },
            {
                "Date": "12/28/18",
                "Profil prudent": "107.1687751",
                "OPC MONETAIRE": "106.538183"
            },
            {
                "Date": "1/4/19",
                "Profil prudent": "107.2153733",
                "OPC MONETAIRE": "106.5823935"
            },
            {
                "Date": "1/14/19",
                "Profil prudent": "107.2879368",
                "OPC MONETAIRE": "106.6496189"
            },
            {
                "Date": "1/18/19",
                "Profil prudent": "107.3146479",
                "OPC MONETAIRE": "106.6735877"
            },
            {
                "Date": "1/25/19",
                "Profil prudent": "107.3679504",
                "OPC MONETAIRE": "106.7221955"
            },
            {
                "Date": "2/1/19",
                "Profil prudent": "107.4168621",
                "OPC MONETAIRE": "106.7676058"
            },
            {
                "Date": "2/8/19",
                "Profil prudent": "107.4708229",
                "OPC MONETAIRE": "106.8186847"
            },
            {
                "Date": "2/15/19",
                "Profil prudent": "107.5385705",
                "OPC MONETAIRE": "106.8801839"
            },
            {
                "Date": "2/22/19",
                "Profil prudent": "107.5861637",
                "OPC MONETAIRE": "106.9202216"
            },
            {
                "Date": "3/1/19",
                "Profil prudent": "107.6442868",
                "OPC MONETAIRE": "106.9612795"
            },
            {
                "Date": "3/8/19",
                "Profil prudent": "107.6892349",
                "OPC MONETAIRE": "107.0166684"
            },
            {
                "Date": "3/15/19",
                "Profil prudent": "107.7318702",
                "OPC MONETAIRE": "107.0599579"
            },
            {
                "Date": "3/22/19",
                "Profil prudent": "107.783866",
                "OPC MONETAIRE": "107.1085967"
            },
            {
                "Date": "3/29/19",
                "Profil prudent": "107.830558",
                "OPC MONETAIRE": "107.1524353"
            },
            {
                "Date": "4/5/19",
                "Profil prudent": "107.8740599",
                "OPC MONETAIRE": "107.1945922"
            },
            {
                "Date": "4/12/19",
                "Profil prudent": "107.9248698",
                "OPC MONETAIRE": "107.2407991"
            },
            {
                "Date": "4/19/19",
                "Profil prudent": "107.971344",
                "OPC MONETAIRE": "107.28449"
            },
            {
                "Date": "4/26/19",
                "Profil prudent": "108.0287555",
                "OPC MONETAIRE": "107.3352195"
            },
            {
                "Date": "5/3/19",
                "Profil prudent": "108.0771185",
                "OPC MONETAIRE": "107.379478"
            },
            {
                "Date": "5/10/19",
                "Profil prudent": "108.1276763",
                "OPC MONETAIRE": "107.4247116"
            },
            {
                "Date": "5/17/19",
                "Profil prudent": "108.1828113",
                "OPC MONETAIRE": "107.4255865"
            },
            {
                "Date": "5/24/19",
                "Profil prudent": "108.2339812",
                "OPC MONETAIRE": "107.4759945"
            },
            {
                "Date": "5/31/19",
                "Profil prudent": "108.286282",
                "OPC MONETAIRE": "107.5165097"
            },
            {
                "Date": "6/10/19",
                "Profil prudent": "108.3462406",
                "OPC MONETAIRE": "107.5779133"
            },
            {
                "Date": "6/14/19",
                "Profil prudent": "108.3651908",
                "OPC MONETAIRE": "107.5960219"
            },
            {
                "Date": "6/21/19",
                "Profil prudent": "108.4098079",
                "OPC MONETAIRE": "107.6411878"
            },
            {
                "Date": "6/28/19",
                "Profil prudent": "108.4555937",
                "OPC MONETAIRE": "107.6850256"
            },
            {
                "Date": "7/5/19",
                "Profil prudent": "108.4994102",
                "OPC MONETAIRE": "107.7302502"
            },
            {
                "Date": "7/12/19",
                "Profil prudent": "108.5479449",
                "OPC MONETAIRE": "107.7739749"
            },
            {
                "Date": "7/19/19",
                "Profil prudent": "108.5917789",
                "OPC MONETAIRE": "107.8172577"
            },
            {
                "Date": "7/26/19",
                "Profil prudent": "108.6385404",
                "OPC MONETAIRE": "107.8653833"
            },
            {
                "Date": "8/2/19",
                "Profil prudent": "108.6805076",
                "OPC MONETAIRE": "107.9056646"
            },
            {
                "Date": "8/9/19",
                "Profil prudent": "108.7260948",
                "OPC MONETAIRE": "107.948415"
            },
            {
                "Date": "8/16/19",
                "Profil prudent": "108.7775418",
                "OPC MONETAIRE": "107.9931675"
            },
            {
                "Date": "8/23/19",
                "Profil prudent": "108.8330751",
                "OPC MONETAIRE": "108.047995"
            },
            {
                "Date": "8/30/19",
                "Profil prudent": "108.8900046",
                "OPC MONETAIRE": "108.1006195"
            },
            {
                "Date": "9/6/19",
                "Profil prudent": "108.9316725",
                "OPC MONETAIRE": "108.1433712"
            },
            {
                "Date": "9/13/19",
                "Profil prudent": "108.9765111",
                "OPC MONETAIRE": "108.1877613"
            },
            {
                "Date": "9/20/19",
                "Profil prudent": "109.0180891",
                "OPC MONETAIRE": "108.2278245"
            },
            {
                "Date": "9/27/19",
                "Profil prudent": "109.0704931",
                "OPC MONETAIRE": "108.272054"
            },
            {
                "Date": "10/4/19",
                "Profil prudent": "109.1159108",
                "OPC MONETAIRE": "108.3153547"
            },
            {
                "Date": "10/11/19",
                "Profil prudent": "109.1651418",
                "OPC MONETAIRE": "108.3627926"
            },
            {
                "Date": "10/18/19",
                "Profil prudent": "109.2109867",
                "OPC MONETAIRE": "108.4068561"
            },
            {
                "Date": "10/25/19",
                "Profil prudent": "109.2616758",
                "OPC MONETAIRE": "108.4544406"
            },
            {
                "Date": "11/1/19",
                "Profil prudent": "109.3012163",
                "OPC MONETAIRE": "108.4903668"
            },
            {
                "Date": "11/8/19",
                "Profil prudent": "109.3421924",
                "OPC MONETAIRE": "108.529377"
            },
            {
                "Date": "11/15/19",
                "Profil prudent": "109.3877805",
                "OPC MONETAIRE": "108.5744986"
            },
            {
                "Date": "11/22/19",
                "Profil prudent": "109.4369643",
                "OPC MONETAIRE": "108.6221282"
            },
            {
                "Date": "11/29/19",
                "Profil prudent": "109.4762957",
                "OPC MONETAIRE": "108.663229"
            },
            {
                "Date": "12/6/19",
                "Profil prudent": "109.5191533",
                "OPC MONETAIRE": "108.7043034"
            },
            {
                "Date": "12/13/19",
                "Profil prudent": "109.5649702",
                "OPC MONETAIRE": "108.7501878"
            },
            {
                "Date": "12/20/19",
                "Profil prudent": "109.6175629",
                "OPC MONETAIRE": "108.798163"
            },
            {
                "Date": "12/27/19",
                "Profil prudent": "109.6830739",
                "OPC MONETAIRE": "108.8631181"
            },
            {
                "Date": "1/3/20",
                "Profil prudent": "109.7161688",
                "OPC MONETAIRE": "108.8936725"
            },
            {
                "Date": "1/10/20",
                "Profil prudent": "109.7625985",
                "OPC MONETAIRE": "108.9357356"
            },
            {
                "Date": "1/17/20",
                "Profil prudent": "109.7965152",
                "OPC MONETAIRE": "108.972499"
            },
            {
                "Date": "1/24/20",
                "Profil prudent": "109.8301681",
                "OPC MONETAIRE": "109.0087127"
            },
            {
                "Date": "1/31/20",
                "Profil prudent": "109.8701991",
                "OPC MONETAIRE": "109.0456898"
            },
            {
                "Date": "2/7/20",
                "Profil prudent": "109.9134972",
                "OPC MONETAIRE": "109.0908868"
            },
            {
                "Date": "2/14/20",
                "Profil prudent": "109.9694058",
                "OPC MONETAIRE": "109.1400213"
            },
            {
                "Date": "2/21/20",
                "Profil prudent": "110.0136776",
                "OPC MONETAIRE": "109.1832583"
            },
            {
                "Date": "2/28/20",
                "Profil prudent": "110.0628089",
                "OPC MONETAIRE": "109.2289292"
            },
            {
                "Date": "3/6/20",
                "Profil prudent": "110.1071874",
                "OPC MONETAIRE": "109.2715568"
            },
            {
                "Date": "3/13/20",
                "Profil prudent": "110.1637472",
                "OPC MONETAIRE": "109.321574"
            },
            {
                "Date": "3/20/20",
                "Profil prudent": "110.2326383",
                "OPC MONETAIRE": "109.3792682"
            },
            {
                "Date": "3/27/20",
                "Profil prudent": "110.2476548",
                "OPC MONETAIRE": "109.3992184"
            },
            {
                "Date": "4/3/20",
                "Profil prudent": "110.2850863",
                "OPC MONETAIRE": "109.4348212"
            },
            {
                "Date": "4/10/20",
                "Profil prudent": "110.3768299",
                "OPC MONETAIRE": "109.5218726"
            },
            {
                "Date": "4/17/20",
                "Profil prudent": "110.4176741",
                "OPC MONETAIRE": "109.5565936"
            },
            {
                "Date": "4/24/20",
                "Profil prudent": "110.4772356",
                "OPC MONETAIRE": "109.6065705"
            },
            {
                "Date": "5/4/20",
                "Profil prudent": "110.5676555",
                "OPC MONETAIRE": "109.6900184"
            },
            {
                "Date": "5/8/20",
                "Profil prudent": "110.5987127",
                "OPC MONETAIRE": "109.7140584"
            },
            {
                "Date": "5/15/20",
                "Profil prudent": "110.6469893",
                "OPC MONETAIRE": "109.7584637"
            },
            {
                "Date": "5/22/20",
                "Profil prudent": "110.7180384",
                "OPC MONETAIRE": "109.8204987"
            },
            {
                "Date": "5/29/20",
                "Profil prudent": "110.7682821",
                "OPC MONETAIRE": "109.865643"
            },
            {
                "Date": "6/5/20",
                "Profil prudent": "110.8383236",
                "OPC MONETAIRE": "109.9264967"
            },
            {
                "Date": "6/12/20",
                "Profil prudent": "110.8952423",
                "OPC MONETAIRE": "109.9799479"
            },
            {
                "Date": "6/19/20",
                "Profil prudent": "110.9497925",
                "OPC MONETAIRE": "110.0280252"
            },
            {
                "Date": "6/26/20",
                "Profil prudent": "111.2451522",
                "OPC MONETAIRE": "110.2550428"
            },
            {
                "Date": "7/3/20",
                "Profil prudent": "111.2774846",
                "OPC MONETAIRE": "110.2829051"
            },
            {
                "Date": "7/10/20",
                "Profil prudent": "111.3266812",
                "OPC MONETAIRE": "110.3235502"
            },
            {
                "Date": "7/17/20",
                "Profil prudent": "111.3488388",
                "OPC MONETAIRE": "110.3514628"
            },
            {
                "Date": "7/24/20",
                "Profil prudent": "111.3905292",
                "OPC MONETAIRE": "110.3847715"
            },
            {
                "Date": "8/3/20",
                "Profil prudent": "111.4470833",
                "OPC MONETAIRE": "110.4372551"
            },
            {
                "Date": "8/7/20",
                "Profil prudent": "111.4576824",
                "OPC MONETAIRE": "110.4466508"
            },
            {
                "Date": "8/17/20",
                "Profil prudent": "111.4992253",
                "OPC MONETAIRE": "110.4894678"
            },
            {
                "Date": "8/24/20",
                "Profil prudent": "111.5345695",
                "OPC MONETAIRE": "110.522317"
            },
            {
                "Date": "8/28/20",
                "Profil prudent": "111.5536967",
                "OPC MONETAIRE": "110.5393289"
            },
            {
                "Date": "9/4/20",
                "Profil prudent": "111.5925438",
                "OPC MONETAIRE": "110.5752599"
            },
            {
                "Date": "9/11/20",
                "Profil prudent": "111.6310002",
                "OPC MONETAIRE": "110.6118859"
            },
            {
                "Date": "9/18/20",
                "Profil prudent": "111.6824076",
                "OPC MONETAIRE": "110.6564216"
            },
            {
                "Date": "9/25/20",
                "Profil prudent": "111.726515",
                "OPC MONETAIRE": "110.699648"
            },
            {
                "Date": "10/2/20",
                "Profil prudent": "111.7603837",
                "OPC MONETAIRE": "110.7281051"
            },
            {
                "Date": "10/9/20",
                "Profil prudent": "111.794521",
                "OPC MONETAIRE": "110.7617911"
            },
            {
                "Date": "10/16/20",
                "Profil prudent": "111.8234149",
                "OPC MONETAIRE": "110.7894683"
            },
            {
                "Date": "10/23/20",
                "Profil prudent": "111.8653391",
                "OPC MONETAIRE": "110.8243217"
            },
            {
                "Date": "11/2/20",
                "Profil prudent": "111.9191894",
                "OPC MONETAIRE": "110.8725949"
            },
            {
                "Date": "11/9/20",
                "Profil prudent": "111.9320131",
                "OPC MONETAIRE": "110.8896329"
            },
            {
                "Date": "11/13/20",
                "Profil prudent": "111.9483881",
                "OPC MONETAIRE": "110.9049831"
            },
            {
                "Date": "11/20/20",
                "Profil prudent": "111.9272691",
                "OPC MONETAIRE": "110.9018371"
            },
            {
                "Date": "11/27/20",
                "Profil prudent": "111.9292467",
                "OPC MONETAIRE": "110.9206352"
            },
            {
                "Date": "12/4/20",
                "Profil prudent": "111.935332",
                "OPC MONETAIRE": "110.9422186"
            },
            {
                "Date": "12/11/20",
                "Profil prudent": "111.988463",
                "OPC MONETAIRE": "110.9881327"
            },
            {
                "Date": "12/18/20",
                "Profil prudent": "112.1332513",
                "OPC MONETAIRE": "111.0801132"
            },
            {
                "Date": "12/25/20",
                "Profil prudent": "112.2407583",
                "OPC MONETAIRE": "111.1678711"
            },
            {
                "Date": "1/4/21",
                "Profil prudent": "112.2897513",
                "OPC MONETAIRE": "111.2186921"
            },
            {
                "Date": "1/8/21",
                "Profil prudent": "112.3133112",
                "OPC MONETAIRE": "111.2400342"
            },
            {
                "Date": "1/15/21",
                "Profil prudent": "112.3566632",
                "OPC MONETAIRE": "111.2760289"
            },
            {
                "Date": "1/22/21",
                "Profil prudent": "112.4105325",
                "OPC MONETAIRE": "111.3127345"
            },
            {
                "Date": "1/29/21",
                "Profil prudent": "112.4578882",
                "OPC MONETAIRE": "111.3504668"
            },
            {
                "Date": "2/5/21",
                "Profil prudent": "112.496817",
                "OPC MONETAIRE": "111.3840471"
            },
            {
                "Date": "2/12/21",
                "Profil prudent": "112.5328223",
                "OPC MONETAIRE": "111.4157185"
            },
            {
                "Date": "2/19/21",
                "Profil prudent": "112.5631567",
                "OPC MONETAIRE": "111.4468199"
            },
            {
                "Date": "2/26/21",
                "Profil prudent": "112.6029682",
                "OPC MONETAIRE": "111.4827561"
            },
            {
                "Date": "3/5/21",
                "Profil prudent": "112.6374383",
                "OPC MONETAIRE": "111.5156708"
            },
            {
                "Date": "3/12/21",
                "Profil prudent": "112.6718223",
                "OPC MONETAIRE": "111.5449239"
            },
            {
                "Date": "3/19/21",
                "Profil prudent": "112.7029682",
                "OPC MONETAIRE": "111.5744929"
            },
            {
                "Date": "3/26/21",
                "Profil prudent": "112.7331597",
                "OPC MONETAIRE": "111.6023835"
            },
            {
                "Date": "4/2/21",
                "Profil prudent": "112.7535761",
                "OPC MONETAIRE": "111.62735"
            },
            {
                "Date": "4/9/21",
                "Profil prudent": "112.7820365",
                "OPC MONETAIRE": "111.6788134"
            },
            {
                "Date": "4/16/21",
                "Profil prudent": "112.8090611",
                "OPC MONETAIRE": "111.7056106"
            },
            {
                "Date": "4/23/21",
                "Profil prudent": "112.8486458",
                "OPC MONETAIRE": "111.741265"
            },
            {
                "Date": "4/30/21",
                "Profil prudent": "112.8800191",
                "OPC MONETAIRE": "111.7699525"
            },
            {
                "Date": "5/7/21",
                "Profil prudent": "112.9139305",
                "OPC MONETAIRE": "111.7987047"
            },
            {
                "Date": "5/17/21",
                "Profil prudent": "112.9555834",
                "OPC MONETAIRE": "111.8406585"
            },
            {
                "Date": "5/21/21",
                "Profil prudent": "112.9804966",
                "OPC MONETAIRE": "111.8639765"
            },
            {
                "Date": "5/28/21",
                "Profil prudent": "113.0128871",
                "OPC MONETAIRE": "111.8952532"
            },
            {
                "Date": "6/4/21",
                "Profil prudent": "113.0447004",
                "OPC MONETAIRE": "111.9347512"
            },
            {
                "Date": "6/11/21",
                "Profil prudent": "113.0748101",
                "OPC MONETAIRE": "111.964082"
            },
            {
                "Date": "6/18/21",
                "Profil prudent": "113.1097693",
                "OPC MONETAIRE": "111.9992766"
            },
            {
                "Date": "6/25/21",
                "Profil prudent": "113.1367118",
                "OPC MONETAIRE": "112.025024"
            },
            {
                "Date": "7/2/21",
                "Profil prudent": "113.1711614",
                "OPC MONETAIRE": "112.0592423"
            },
            {
                "Date": "7/9/21",
                "Profil prudent": "113.2037168",
                "OPC MONETAIRE": "112.0877995"
            },
            {
                "Date": "7/16/21",
                "Profil prudent": "113.2281951",
                "OPC MONETAIRE": "112.1121231"
            },
            {
                "Date": "7/23/21",
                "Profil prudent": "113.2562559",
                "OPC MONETAIRE": "112.1452885"
            },
            {
                "Date": "8/2/21",
                "Profil prudent": "113.3119766",
                "OPC MONETAIRE": "112.1888538"
            },
            {
                "Date": "8/6/21",
                "Profil prudent": "113.3288755",
                "OPC MONETAIRE": "112.2046188"
            },
            {
                "Date": "8/13/21",
                "Profil prudent": "113.3695017",
                "OPC MONETAIRE": "112.2427269"
            },
            {
                "Date": "8/23/21",
                "Profil prudent": "113.4186117",
                "OPC MONETAIRE": "112.3225312"
            },
            {
                "Date": "8/27/21",
                "Profil prudent": "113.4376402",
                "OPC MONETAIRE": "112.3395714"
            },
            {
                "Date": "9/3/21",
                "Profil prudent": "113.4711197",
                "OPC MONETAIRE": "112.3938805"
            },
            {
                "Date": "9/10/21",
                "Profil prudent": "113.5042249",
                "OPC MONETAIRE": "112.423823"
            },
            {
                "Date": "9/17/21",
                "Profil prudent": "113.5349873",
                "OPC MONETAIRE": "112.4526913"
            },
            {
                "Date": "9/24/21",
                "Profil prudent": "113.5612491",
                "OPC MONETAIRE": "112.4778328"
            },
            {
                "Date": "10/1/21",
                "Profil prudent": "113.5951755",
                "OPC MONETAIRE": "112.509513"
            },
            {
                "Date": "10/8/21",
                "Profil prudent": "113.6268584",
                "OPC MONETAIRE": "112.5343153"
            },
            {
                "Date": "10/15/21",
                "Profil prudent": "113.6636883",
                "OPC MONETAIRE": "112.5686063"
            },
            {
                "Date": "10/22/21",
                "Profil prudent": "113.705206",
                "OPC MONETAIRE": "112.6077389"
            },
            {
                "Date": "10/29/21",
                "Profil prudent": "113.7296101",
                "OPC MONETAIRE": "112.6294312"
            },
            {
                "Date": "11/5/21",
                "Profil prudent": "113.7707491",
                "OPC MONETAIRE": "112.6718539"
            },
            {
                "Date": "11/12/21",
                "Profil prudent": "113.8065573",
                "OPC MONETAIRE": "112.703674"
            },
            {
                "Date": "11/19/21",
                "Profil prudent": "113.8388886",
                "OPC MONETAIRE": "112.7338776"
            },
            {
                "Date": "11/26/21",
                "Profil prudent": "113.8768185",
                "OPC MONETAIRE": "112.7688068"
            },
            {
                "Date": "12/3/21",
                "Profil prudent": "113.8995311",
                "OPC MONETAIRE": "112.7925108"
            },
            {
                "Date": "12/10/21",
                "Profil prudent": "113.9322237",
                "OPC MONETAIRE": "112.82233"
            },
            {
                "Date": "12/17/21",
                "Profil prudent": "113.9582938",
                "OPC MONETAIRE": "112.8484193"
            },
            {
                "Date": "12/24/21",
                "Profil prudent": "113.9905786",
                "OPC MONETAIRE": "112.8757461"
            },
            {
                "Date": "12/31/21",
                "Profil prudent": "114.0179233",
                "OPC MONETAIRE": "112.9022778"
            },
            {
                "Date": "1/7/22",
                "Profil prudent": "114.0552588",
                "OPC MONETAIRE": "112.9364839"
            },
            {
                "Date": "1/14/22",
                "Profil prudent": "114.0911424",
                "OPC MONETAIRE": "112.969938"
            },
            {
                "Date": "1/21/22",
                "Profil prudent": "114.1189674",
                "OPC MONETAIRE": "112.9972853"
            },
            {
                "Date": "1/28/22",
                "Profil prudent": "114.1410285",
                "OPC MONETAIRE": "113.0210365"
            },
            {
                "Date": "2/4/22",
                "Profil prudent": "114.1675362",
                "OPC MONETAIRE": "113.0503156"
            },
            {
                "Date": "2/11/22",
                "Profil prudent": "114.2058182",
                "OPC MONETAIRE": "113.0860864"
            },
            {
                "Date": "2/18/22",
                "Profil prudent": "114.2544647",
                "OPC MONETAIRE": "113.1272219"
            },
            {
                "Date": "2/25/22",
                "Profil prudent": "114.2832492",
                "OPC MONETAIRE": "113.1572295"
            },
            {
                "Date": "3/4/22",
                "Profil prudent": "114.3190132",
                "OPC MONETAIRE": "113.1964327"
            },
            {
                "Date": "3/11/22",
                "Profil prudent": "114.3620847",
                "OPC MONETAIRE": "113.2346166"
            },
            {
                "Date": "3/18/22",
                "Profil prudent": "114.3869204",
                "OPC MONETAIRE": "113.2607877"
            },
            {
                "Date": "3/25/22",
                "Profil prudent": "114.418624",
                "OPC MONETAIRE": "113.2954225"
            },
            {
                "Date": "4/1/22",
                "Profil prudent": "114.4504192",
                "OPC MONETAIRE": "113.338867"
            },
            {
                "Date": "4/8/22",
                "Profil prudent": "114.4808681",
                "OPC MONETAIRE": "113.367698"
            },
            {
                "Date": "4/15/22",
                "Profil prudent": "114.5137273",
                "OPC MONETAIRE": "113.3993179"
            },
            {
                "Date": "4/22/22",
                "Profil prudent": "114.5365274",
                "OPC MONETAIRE": "113.4290841"
            },
            {
                "Date": "4/29/22",
                "Profil prudent": "114.5762804",
                "OPC MONETAIRE": "113.4629085"
            },
            {
                "Date": "5/6/22",
                "Profil prudent": "114.5992853",
                "OPC MONETAIRE": "113.4881794"
            },
            {
                "Date": "5/13/22",
                "Profil prudent": "114.6040666",
                "OPC MONETAIRE": "113.5134334"
            },
            {
                "Date": "5/20/22",
                "Profil prudent": "114.6508294",
                "OPC MONETAIRE": "113.5558105"
            },
            {
                "Date": "5/27/22",
                "Profil prudent": "114.6771916",
                "OPC MONETAIRE": "113.5793196"
            },
            {
                "Date": "6/3/22",
                "Profil prudent": "114.7036413",
                "OPC MONETAIRE": "113.606606"
            },
            {
                "Date": "6/10/22",
                "Profil prudent": "114.7360078",
                "OPC MONETAIRE": "113.6395467"
            },
            {
                "Date": "6/17/22",
                "Profil prudent": "114.7681",
                "OPC MONETAIRE": "113.672646"
            },
            {
                "Date": "6/24/22",
                "Profil prudent": "114.7937337",
                "OPC MONETAIRE": "113.7015707"
            },
            {
                "Date": "7/1/22",
                "Profil prudent": "114.8385288",
                "OPC MONETAIRE": "113.7360096"
            },
            {
                "Date": "7/8/22",
                "Profil prudent": "114.872703",
                "OPC MONETAIRE": "113.7710838"
            },
            {
                "Date": "7/15/22",
                "Profil prudent": "114.8741824",
                "OPC MONETAIRE": "113.784121"
            },
            {
                "Date": "7/22/22",
                "Profil prudent": "114.9172584",
                "OPC MONETAIRE": "113.8205786"
            },
            {
                "Date": "7/29/22",
                "Profil prudent": "114.9450288",
                "OPC MONETAIRE": "113.8479937"
            },
            {
                "Date": "8/5/22",
                "Profil prudent": "114.9617761",
                "OPC MONETAIRE": "113.8624679"
            },
            {
                "Date": "8/12/22",
                "Profil prudent": "115.0120803",
                "OPC MONETAIRE": "113.8993898"
            },
            {
                "Date": "8/19/22",
                "Profil prudent": "115.0261824",
                "OPC MONETAIRE": "113.910472"
            },
            {
                "Date": "8/26/22",
                "Profil prudent": "115.0139691",
                "OPC MONETAIRE": "113.9228339"
            },
            {
                "Date": "9/2/22",
                "Profil prudent": "115.0560686",
                "OPC MONETAIRE": "113.9593398"
            },
            {
                "Date": "9/9/22",
                "Profil prudent": "115.0498415",
                "OPC MONETAIRE": "113.9760983"
            },
            {
                "Date": "9/16/22",
                "Profil prudent": "115.0892723",
                "OPC MONETAIRE": "114.0115265"
            },
            {
                "Date": "9/23/22",
                "Profil prudent": "115.1269489",
                "OPC MONETAIRE": "114.0466853"
            },
            {
                "Date": "9/30/22",
                "Profil prudent": "115.0715628",
                "OPC MONETAIRE": "114.0139966"
            },
            {
                "Date": "10/7/22",
                "Profil prudent": "115.0768595",
                "OPC MONETAIRE": "114.0203329"
            },
            {
                "Date": "10/14/22",
                "Profil prudent": "115.1094587",
                "OPC MONETAIRE": "114.0548031"
            },
            {
                "Date": "10/21/22",
                "Profil prudent": "115.1687393",
                "OPC MONETAIRE": "114.1041634"
            },
            {
                "Date": "10/28/22",
                "Profil prudent": "115.1838439",
                "OPC MONETAIRE": "114.1282934"
            },
            {
                "Date": "11/4/22",
                "Profil prudent": "115.2117396",
                "OPC MONETAIRE": "114.1746829"
            },
            {
                "Date": "11/11/22",
                "Profil prudent": "115.1866146",
                "OPC MONETAIRE": "114.1588033"
            },
            {
                "Date": "11/21/22",
                "Profil prudent": "115.1904177",
                "OPC MONETAIRE": "114.221344"
            },
            {
                "Date": "11/25/22",
                "Profil prudent": "115.2019793",
                "OPC MONETAIRE": "114.2257935"
            },
            {
                "Date": "12/2/22",
                "Profil prudent": "115.2499281",
                "OPC MONETAIRE": "114.2730708"
            },
            {
                "Date": "12/9/22",
                "Profil prudent": "115.2809194",
                "OPC MONETAIRE": "114.3094187"
            },
            {
                "Date": "12/16/22",
                "Profil prudent": "115.327253",
                "OPC MONETAIRE": "114.3495228"
            },
            {
                "Date": "12/23/22",
                "Profil prudent": "115.3421689",
                "OPC MONETAIRE": "114.3721475"
            },
            {
                "Date": "12/30/22",
                "Profil prudent": "115.3546482",
                "OPC MONETAIRE": "114.3890072"
            },
            {
                "Date": "1/6/23",
                "Profil prudent": "115.1494597",
                "OPC MONETAIRE": "114.3157929"
            },
            {
                "Date": "1/13/23",
                "Profil prudent": "115.2278898",
                "OPC MONETAIRE": "114.3788502"
            },
            {
                "Date": "1/20/23",
                "Profil prudent": "115.3042014",
                "OPC MONETAIRE": "114.460698"
            },
            {
                "Date": "1/27/23",
                "Profil prudent": "115.3982831",
                "OPC MONETAIRE": "114.5485556"
            },
            {
                "Date": "2/3/23",
                "Profil prudent": "115.4610982",
                "OPC MONETAIRE": "114.6208953"
            },
            {
                "Date": "2/10/23",
                "Profil prudent": "115.5462443",
                "OPC MONETAIRE": "114.6949252"
            },
            {
                "Date": "2/17/23",
                "Profil prudent": "115.6195676",
                "OPC MONETAIRE": "114.7673644"
            },
            {
                "Date": "2/24/23",
                "Profil prudent": "115.6905481",
                "OPC MONETAIRE": "114.8370364"
            },
            {
                "Date": "3/3/23",
                "Profil prudent": "115.7600724",
                "OPC MONETAIRE": "114.9049032"
            },
            {
                "Date": "3/10/23",
                "Profil prudent": "115.8316928",
                "OPC MONETAIRE": "114.9720227"
            },
            {
                "Date": "3/17/23",
                "Profil prudent": "115.8997861",
                "OPC MONETAIRE": "115.0373214"
            },
            {
                "Date": "3/24/23",
                "Profil prudent": "115.9617201",
                "OPC MONETAIRE": "115.0980563"
            },
            {
                "Date": "3/31/23",
                "Profil prudent": "116.0058438",
                "OPC MONETAIRE": "115.1615494"
            },
            {
                "Date": "4/7/23",
                "Profil prudent": "116.056521",
                "OPC MONETAIRE": "115.2246299"
            },
            {
                "Date": "4/14/23",
                "Profil prudent": "116.1241283",
                "OPC MONETAIRE": "115.2929099"
            },
            {
                "Date": "4/21/23",
                "Profil prudent": "116.2167746",
                "OPC MONETAIRE": "115.3805132"
            },
            {
                "Date": "4/28/23",
                "Profil prudent": "116.2992816",
                "OPC MONETAIRE": "115.4508806"
            },
            {
                "Date": "5/5/23",
                "Profil prudent": "116.3823533",
                "OPC MONETAIRE": "115.5282123"
            },
            {
                "Date": "5/12/23",
                "Profil prudent": "116.4692588",
                "OPC MONETAIRE": "115.6036661"
            },
            {
                "Date": "5/19/23",
                "Profil prudent": "116.5946429",
                "OPC MONETAIRE": "115.6960696"
            },
            {
                "Date": "5/26/23",
                "Profil prudent": "116.6627846",
                "OPC MONETAIRE": "115.7661693"
            },
            {
                "Date": "6/2/23",
                "Profil prudent": "116.7332882",
                "OPC MONETAIRE": "115.833564"
            },
            {
                "Date": "6/9/23",
                "Profil prudent": "116.7955737",
                "OPC MONETAIRE": "115.8978526"
            },
            {
                "Date": "6/16/23",
                "Profil prudent": "116.8493578",
                "OPC MONETAIRE": "115.9566296"
            },
            {
                "Date": "6/23/23",
                "Profil prudent": "117.0692424",
                "OPC MONETAIRE": "116.088152"
            },
            {
                "Date": "7/3/23",
                "Profil prudent": "117.1748983",
                "OPC MONETAIRE": "116.1948292"
            },
            {
                "Date": "7/7/23",
                "Profil prudent": "117.1643502",
                "OPC MONETAIRE": "116.2048162"
            },
            {
                "Date": "7/14/23",
                "Profil prudent": "117.2353812",
                "OPC MONETAIRE": "116.2756493"
            },
            {
                "Date": "7/21/23",
                "Profil prudent": "117.306106",
                "OPC MONETAIRE": "116.3448115"
            },
            {
                "Date": "7/28/23",
                "Profil prudent": "117.3842908",
                "OPC MONETAIRE": "116.4218265"
            },
            {
                "Date": "8/4/23",
                "Profil prudent": "117.4909463",
                "OPC MONETAIRE": "116.5115814"
            },
            {
                "Date": "8/11/23",
                "Profil prudent": "117.5492568",
                "OPC MONETAIRE": "116.5718552"
            },
            {
                "Date": "8/18/23",
                "Profil prudent": "117.5958977",
                "OPC MONETAIRE": "116.6320925"
            },
            {
                "Date": "8/25/23",
                "Profil prudent": "117.6639494",
                "OPC MONETAIRE": "116.7003391"
            },
            {
                "Date": "9/1/23",
                "Profil prudent": "117.7307702",
                "OPC MONETAIRE": "116.7688182"
            },
            {
                "Date": "9/8/23",
                "Profil prudent": "117.8002719",
                "OPC MONETAIRE": "116.8376761"
            },
            {
                "Date": "9/15/23",
                "Profil prudent": "117.8681794",
                "OPC MONETAIRE": "116.9061562"
            },
            {
                "Date": "9/22/23",
                "Profil prudent": "117.9180538",
                "OPC MONETAIRE": "116.9703808"
            },
            {
                "Date": "10/2/23",
                "Profil prudent": "118.0448586",
                "OPC MONETAIRE": "117.0884473"
            },
            {
                "Date": "10/6/23",
                "Profil prudent": "118.1179541",
                "OPC MONETAIRE": "117.1522109"
            },
            {
                "Date": "10/13/23",
                "Profil prudent": "118.1694313",
                "OPC MONETAIRE": "117.211959"
            },
            {
                "Date": "10/20/23",
                "Profil prudent": "118.2378721",
                "OPC MONETAIRE": "117.381618"
            },
            {
                "Date": "10/27/23",
                "Profil prudent": "118.3333129",
                "OPC MONETAIRE": "117.4002632"
            },
            {
                "Date": "11/3/23",
                "Profil prudent": "118.3898976",
                "OPC MONETAIRE": "117.462007"
            },
            {
                "Date": "11/10/23",
                "Profil prudent": "118.4599647",
                "OPC MONETAIRE": "117.5287608"
            },
            {
                "Date": "11/17/23",
                "Profil prudent": "118.5373897",
                "OPC MONETAIRE": "117.5997514"
            },
            {
                "Date": "11/24/23",
                "Profil prudent": "118.6054672",
                "OPC MONETAIRE": "117.6691617"
            },
            {
                "Date": "12/1/23",
                "Profil prudent": "118.6780474",
                "OPC MONETAIRE": "117.7380228"
            },
            {
                "Date": "12/8/23",
                "Profil prudent": "118.7388081",
                "OPC MONETAIRE": "117.8050878"
            },
            {
                "Date": "12/15/23",
                "Profil prudent": "118.8234543",
                "OPC MONETAIRE": "117.8801777"
            },
            {
                "Date": "12/22/23",
                "Profil prudent": "118.8946725",
                "OPC MONETAIRE": "117.9520068"
            },
            {
                "Date": "12/29/23",
                "Profil prudent": "118.9480052",
                "OPC MONETAIRE": "118.01123"
            },
            {
                "Date": "1/5/24",
                "Profil prudent": "119.0710234",
                "OPC MONETAIRE": "118.1292643"
            },
            {
                "Date": "1/12/24",
                "Profil prudent": "119.1555598",
                "OPC MONETAIRE": "118.2093818"
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
            setChartData(yourDataSet[0]);
        }
    }, [yourDataSet]);

    const getOption = () => {
        if (!chartData || chartData.length === 0) {
            return {};
        }

        const xAxisData = chartData.map((item) => item.Date);

        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');

        const series = legendData.map((key) => ({
            type: 'line',
            name: key,
            data: chartData.map((item) => {
                const value = item[key];
                const parsedValue = parseFloat(value.replace(',', ' '));
                return isNaN(parsedValue) ? null : parsedValue;
            }),
        }));

        return {
            animationDuration: 10000,
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: '',
                left: 'center',

            },
            legend: {
                data: legendData,
                bottom: 10,
            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                min: 100,
            },
            series,
        };
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactEChartsCore echarts={echarts} option={getOption()} />

            </Col>
        </Row>
    );
};

export default LineChartComponent;
