import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, LegendComponent]);

const LineChartComponent = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Fetch data from your server
    Axios.get('https://192.168.1.15:8001/api/chartData/graph')
      .then((response) => {
        const data = response.data;

        setChartData(data);
      })
      .catch((error) => {
        console.error('Error fetching data', error);
      });
  }, []);

  const getOption = () => {
    const xAxisData = chartData.map((item) => item.seance);
    const seriesData = [];

    for (let i = 0; i < 10; i++) {
      const seriesItem = {
        name: `P${i}`,
        type: 'line',
        data: chartData.map((item) => {
          const pValue = parseFloat(item[`P${i}`].replace(',', '.'));
          return isNaN(pValue) ? null : pValue;
        }),
      };
      seriesData.push(seriesItem);
    }

    return {
      tooltip: {
        trigger: 'axis',
        // ... tooltip configuration ...
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        data: chartData.map((item) => item.seance),
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
        min : 80,
      },
      series: seriesData,
      grid: { right: 10, left: 5, bottom: 5, top: 40, containLabel: true },
    };
  };

  return (
    <Row className="g-3">
      <Col>
        <Card dir="ltr" className="h-100">
          <Card.Body>
            <ReactEChartsCore echarts={echarts} option={getOption()} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default LineChartComponent;
