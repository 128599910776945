import React, { Fragment } from "react";

import Hero from "../components/Hero";
import '../assets/css/Home.css';

const Home = () => (
  <Fragment>
    <Hero />
    <hr />
    {/* <Content /> */}
  </Fragment>
);

export default Home;
