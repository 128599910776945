import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "28/12/2015",
                "Profil Dynamique": "100"
            },
            {
                "Date": "04/01/2016",
                "Profil Dynamique": "100,0365654"
            },
            {
                "Date": "08/01/2016",
                "Profil Dynamique": "99,99601653"
            },
            {
                "Date": "15/01/2016",
                "Profil Dynamique": "99,89428816"
            },
            {
                "Date": "22/01/2016",
                "Profil Dynamique": "100,0868895"
            },
            {
                "Date": "29/01/2016",
                "Profil Dynamique": "100,3724224"
            },
            {
                "Date": "05/02/2016",
                "Profil Dynamique": "100,9967306"
            },
            {
                "Date": "12/02/2016",
                "Profil Dynamique": "101,6795812"
            },
            {
                "Date": "19/02/2016",
                "Profil Dynamique": "102,3945174"
            },
            {
                "Date": "26/02/2016",
                "Profil Dynamique": "102,0651396"
            },
            {
                "Date": "04/03/2016",
                "Profil Dynamique": "102,8378656"
            },
            {
                "Date": "11/03/2016",
                "Profil Dynamique": "103,0157203"
            },
            {
                "Date": "18/03/2016",
                "Profil Dynamique": "103,2621914"
            },
            {
                "Date": "25/03/2016",
                "Profil Dynamique": "103,6985396"
            },
            {
                "Date": "01/04/2016",
                "Profil Dynamique": "103,2160248"
            },
            {
                "Date": "08/04/2016",
                "Profil Dynamique": "103,6309009"
            },
            {
                "Date": "15/04/2016",
                "Profil Dynamique": "104,4521782"
            },
            {
                "Date": "22/04/2016",
                "Profil Dynamique": "104,7369696"
            },
            {
                "Date": "29/04/2016",
                "Profil Dynamique": "106,1271648"
            },
            {
                "Date": "06/05/2016",
                "Profil Dynamique": "106,934654"
            },
            {
                "Date": "13/05/2016",
                "Profil Dynamique": "107,1381854"
            },
            {
                "Date": "20/05/2016",
                "Profil Dynamique": "106,5868494"
            },
            {
                "Date": "27/05/2016",
                "Profil Dynamique": "106,1123077"
            },
            {
                "Date": "03/06/2016",
                "Profil Dynamique": "106,6304324"
            },
            {
                "Date": "10/06/2016",
                "Profil Dynamique": "106,7341385"
            },
            {
                "Date": "17/06/2016",
                "Profil Dynamique": "106,7086452"
            },
            {
                "Date": "24/06/2016",
                "Profil Dynamique": "107,0406107"
            },
            {
                "Date": "01/07/2016",
                "Profil Dynamique": "107,2373132"
            },
            {
                "Date": "11/07/2016",
                "Profil Dynamique": "107,6325517"
            },
            {
                "Date": "15/07/2016",
                "Profil Dynamique": "107,9248651"
            },
            {
                "Date": "22/07/2016",
                "Profil Dynamique": "108,1898696"
            },
            {
                "Date": "29/07/2016",
                "Profil Dynamique": "108,4771495"
            },
            {
                "Date": "05/08/2016",
                "Profil Dynamique": "108,6034548"
            },
            {
                "Date": "12/08/2016",
                "Profil Dynamique": "108,5018903"
            },
            {
                "Date": "19/08/2016",
                "Profil Dynamique": "108,4422724"
            },
            {
                "Date": "26/08/2016",
                "Profil Dynamique": "108,2825304"
            },
            {
                "Date": "02/09/2016",
                "Profil Dynamique": "108,3891443"
            },
            {
                "Date": "09/09/2016",
                "Profil Dynamique": "108,5061772"
            },
            {
                "Date": "16/09/2016",
                "Profil Dynamique": "108,344216"
            },
            {
                "Date": "23/09/2016",
                "Profil Dynamique": "108,6708512"
            },
            {
                "Date": "30/09/2016",
                "Profil Dynamique": "109,0905537"
            },
            {
                "Date": "07/10/2016",
                "Profil Dynamique": "109,0467288"
            },
            {
                "Date": "14/10/2016",
                "Profil Dynamique": "109,4564082"
            },
            {
                "Date": "21/10/2016",
                "Profil Dynamique": "110,3421824"
            },
            {
                "Date": "28/10/2016",
                "Profil Dynamique": "110,7138686"
            },
            {
                "Date": "04/11/2016",
                "Profil Dynamique": "111,0669909"
            },
            {
                "Date": "11/11/2016",
                "Profil Dynamique": "111,1785971"
            },
            {
                "Date": "21/11/2016",
                "Profil Dynamique": "111,7617304"
            },
            {
                "Date": "25/11/2016",
                "Profil Dynamique": "111,3486016"
            },
            {
                "Date": "02/12/2016",
                "Profil Dynamique": "111,7691123"
            },
            {
                "Date": "09/12/2016",
                "Profil Dynamique": "112,7946457"
            },
            {
                "Date": "16/12/2016",
                "Profil Dynamique": "113,8340552"
            },
            {
                "Date": "23/12/2016",
                "Profil Dynamique": "116,3986963"
            },
            {
                "Date": "30/12/2016",
                "Profil Dynamique": "115,4670204"
            },
            {
                "Date": "06/01/2017",
                "Profil Dynamique": "119,3480107"
            },
            {
                "Date": "13/01/2017",
                "Profil Dynamique": "119,7307935"
            },
            {
                "Date": "20/01/2017",
                "Profil Dynamique": "119,2318293"
            },
            {
                "Date": "27/01/2017",
                "Profil Dynamique": "119,0682581"
            },
            {
                "Date": "03/02/2017",
                "Profil Dynamique": "118,5571302"
            },
            {
                "Date": "10/02/2017",
                "Profil Dynamique": "118,7985261"
            },
            {
                "Date": "17/02/2017",
                "Profil Dynamique": "118,586875"
            },
            {
                "Date": "24/02/2017",
                "Profil Dynamique": "117,5612488"
            },
            {
                "Date": "03/03/2017",
                "Profil Dynamique": "117,8551661"
            },
            {
                "Date": "10/03/2017",
                "Profil Dynamique": "117,7588148"
            },
            {
                "Date": "17/03/2017",
                "Profil Dynamique": "118,1899958"
            },
            {
                "Date": "24/03/2017",
                "Profil Dynamique": "117,0135923"
            },
            {
                "Date": "31/03/2017",
                "Profil Dynamique": "116,8082402"
            },
            {
                "Date": "07/04/2017",
                "Profil Dynamique": "116,7954516"
            },
            {
                "Date": "14/04/2017",
                "Profil Dynamique": "116,5903983"
            },
            {
                "Date": "21/04/2017",
                "Profil Dynamique": "117,0643577"
            },
            {
                "Date": "28/04/2017",
                "Profil Dynamique": "117,9420769"
            },
            {
                "Date": "05/05/2017",
                "Profil Dynamique": "118,004187"
            },
            {
                "Date": "12/05/2017",
                "Profil Dynamique": "118,1362937"
            },
            {
                "Date": "19/05/2017",
                "Profil Dynamique": "118,1733173"
            },
            {
                "Date": "26/05/2017",
                "Profil Dynamique": "118,3517832"
            },
            {
                "Date": "02/06/2017",
                "Profil Dynamique": "118,6497279"
            },
            {
                "Date": "09/06/2017",
                "Profil Dynamique": "119,1141474"
            },
            {
                "Date": "16/06/2017",
                "Profil Dynamique": "119,3021178"
            },
            {
                "Date": "23/06/2017",
                "Profil Dynamique": "120,2995747"
            },
            {
                "Date": "30/06/2017",
                "Profil Dynamique": "120,4227825"
            },
            {
                "Date": "07/07/2017",
                "Profil Dynamique": "121,6386769"
            },
            {
                "Date": "14/07/2017",
                "Profil Dynamique": "121,6703647"
            },
            {
                "Date": "21/07/2017",
                "Profil Dynamique": "121,0967963"
            },
            {
                "Date": "28/07/2017",
                "Profil Dynamique": "122,1387238"
            },
            {
                "Date": "04/08/2017",
                "Profil Dynamique": "121,6760125"
            },
            {
                "Date": "11/08/2017",
                "Profil Dynamique": "122,5685565"
            },
            {
                "Date": "18/08/2017",
                "Profil Dynamique": "122,7043535"
            },
            {
                "Date": "25/08/2017",
                "Profil Dynamique": "122,4907116"
            },
            {
                "Date": "04/09/2017",
                "Profil Dynamique": "123,3466154"
            },
            {
                "Date": "08/09/2017",
                "Profil Dynamique": "124,1315257"
            },
            {
                "Date": "15/09/2017",
                "Profil Dynamique": "124,5901759"
            },
            {
                "Date": "25/09/2017",
                "Profil Dynamique": "123,5207653"
            },
            {
                "Date": "29/09/2017",
                "Profil Dynamique": "122,9976184"
            },
            {
                "Date": "06/10/2017",
                "Profil Dynamique": "123,8992936"
            },
            {
                "Date": "13/10/2017",
                "Profil Dynamique": "124,8300909"
            },
            {
                "Date": "20/10/2017",
                "Profil Dynamique": "124,0182224"
            },
            {
                "Date": "27/10/2017",
                "Profil Dynamique": "124,4506739"
            },
            {
                "Date": "03/11/2017",
                "Profil Dynamique": "124,6505268"
            },
            {
                "Date": "10/11/2017",
                "Profil Dynamique": "125,5129647"
            },
            {
                "Date": "17/11/2017",
                "Profil Dynamique": "125,4405179"
            },
            {
                "Date": "24/11/2017",
                "Profil Dynamique": "125,2453632"
            },
            {
                "Date": "04/12/2017",
                "Profil Dynamique": "124,7240539"
            },
            {
                "Date": "08/12/2017",
                "Profil Dynamique": "124,417123"
            },
            {
                "Date": "15/12/2017",
                "Profil Dynamique": "124,3557302"
            },
            {
                "Date": "22/12/2017",
                "Profil Dynamique": "123,6644666"
            },
            {
                "Date": "29/12/2017",
                "Profil Dynamique": "124,6170341"
            },
            {
                "Date": "05/01/2018",
                "Profil Dynamique": "125,1698191"
            },
            {
                "Date": "12/01/2018",
                "Profil Dynamique": "125,82292"
            },
            {
                "Date": "19/01/2018",
                "Profil Dynamique": "126,8221147"
            },
            {
                "Date": "26/01/2018",
                "Profil Dynamique": "127,4686993"
            },
            {
                "Date": "02/02/2018",
                "Profil Dynamique": "127,9091339"
            },
            {
                "Date": "09/02/2018",
                "Profil Dynamique": "127,5671529"
            },
            {
                "Date": "16/02/2018",
                "Profil Dynamique": "127,9103358"
            },
            {
                "Date": "23/02/2018",
                "Profil Dynamique": "127,9809406"
            },
            {
                "Date": "02/03/2018",
                "Profil Dynamique": "127,9282175"
            },
            {
                "Date": "09/03/2018",
                "Profil Dynamique": "128,0741304"
            },
            {
                "Date": "16/03/2018",
                "Profil Dynamique": "127,6160448"
            },
            {
                "Date": "23/03/2018",
                "Profil Dynamique": "127,5208216"
            },
            {
                "Date": "30/03/2018",
                "Profil Dynamique": "127,3585154"
            },
            {
                "Date": "06/04/2018",
                "Profil Dynamique": "126,9476695"
            },
            {
                "Date": "13/04/2018",
                "Profil Dynamique": "127,2108971"
            },
            {
                "Date": "20/04/2018",
                "Profil Dynamique": "127,2389164"
            },
            {
                "Date": "27/04/2018",
                "Profil Dynamique": "127,4573981"
            },
            {
                "Date": "04/05/2018",
                "Profil Dynamique": "127,2819831"
            },
            {
                "Date": "11/05/2018",
                "Profil Dynamique": "126,7038492"
            },
            {
                "Date": "18/05/2018",
                "Profil Dynamique": "125,7436204"
            },
            {
                "Date": "25/05/2018",
                "Profil Dynamique": "125,9926398"
            },
            {
                "Date": "01/06/2018",
                "Profil Dynamique": "125,171067"
            },
            {
                "Date": "08/06/2018",
                "Profil Dynamique": "125,8481854"
            },
            {
                "Date": "18/06/2018",
                "Profil Dynamique": "125,5247185"
            },
            {
                "Date": "22/06/2018",
                "Profil Dynamique": "125,3627133"
            },
            {
                "Date": "29/06/2018",
                "Profil Dynamique": "124,2966677"
            },
            {
                "Date": "06/07/2018",
                "Profil Dynamique": "123,6032243"
            },
            {
                "Date": "13/07/2018",
                "Profil Dynamique": "122,6460882"
            },
            {
                "Date": "20/07/2018",
                "Profil Dynamique": "122,4604435"
            },
            {
                "Date": "27/07/2018",
                "Profil Dynamique": "123,5498382"
            },
            {
                "Date": "03/08/2018",
                "Profil Dynamique": "123,3587708"
            },
            {
                "Date": "10/08/2018",
                "Profil Dynamique": "123,3800319"
            },
            {
                "Date": "17/08/2018",
                "Profil Dynamique": "123,1283141"
            },
            {
                "Date": "24/08/2018",
                "Profil Dynamique": "123,1466952"
            },
            {
                "Date": "31/08/2018",
                "Profil Dynamique": "122,692837"
            },
            {
                "Date": "07/09/2018",
                "Profil Dynamique": "120,8915222"
            },
            {
                "Date": "14/09/2018",
                "Profil Dynamique": "121,447066"
            },
            {
                "Date": "21/09/2018",
                "Profil Dynamique": "121,4927722"
            },
            {
                "Date": "28/09/2018",
                "Profil Dynamique": "121,90711"
            },
            {
                "Date": "05/10/2018",
                "Profil Dynamique": "121,9406892"
            },
            {
                "Date": "12/10/2018",
                "Profil Dynamique": "121,7315633"
            },
            {
                "Date": "19/10/2018",
                "Profil Dynamique": "121,8282184"
            },
            {
                "Date": "26/10/2018",
                "Profil Dynamique": "121,8105785"
            },
            {
                "Date": "02/11/2018",
                "Profil Dynamique": "121,2711176"
            },
            {
                "Date": "09/11/2018",
                "Profil Dynamique": "121,5643276"
            },
            {
                "Date": "16/11/2018",
                "Profil Dynamique": "121,6929002"
            },
            {
                "Date": "23/11/2018",
                "Profil Dynamique": "121,5757566"
            },
            {
                "Date": "30/11/2018",
                "Profil Dynamique": "122,0690491"
            },
            {
                "Date": "07/12/2018",
                "Profil Dynamique": "122,0780269"
            },
            {
                "Date": "14/12/2018",
                "Profil Dynamique": "122,0689459"
            },
            {
                "Date": "21/12/2018",
                "Profil Dynamique": "122,3594898"
            },
            {
                "Date": "28/12/2018",
                "Profil Dynamique": "122,3613334"
            },
            {
                "Date": "04/01/2019",
                "Profil Dynamique": "122,3168523"
            },
            {
                "Date": "14/01/2019",
                "Profil Dynamique": "122,3050398"
            },
            {
                "Date": "18/01/2019",
                "Profil Dynamique": "122,5939702"
            },
            {
                "Date": "25/01/2019",
                "Profil Dynamique": "123,1199455"
            },
            {
                "Date": "01/02/2019",
                "Profil Dynamique": "123,6716724"
            },
            {
                "Date": "08/02/2019",
                "Profil Dynamique": "123,8045205"
            },
            {
                "Date": "15/02/2019",
                "Profil Dynamique": "124,0839534"
            },
            {
                "Date": "22/02/2019",
                "Profil Dynamique": "124,0320971"
            },
            {
                "Date": "01/03/2019",
                "Profil Dynamique": "123,4808607"
            },
            {
                "Date": "08/03/2019",
                "Profil Dynamique": "123,2589781"
            },
            {
                "Date": "15/03/2019",
                "Profil Dynamique": "122,8067525"
            },
            {
                "Date": "22/03/2019",
                "Profil Dynamique": "122,8691535"
            },
            {
                "Date": "29/03/2019",
                "Profil Dynamique": "122,709833"
            },
            {
                "Date": "05/04/2019",
                "Profil Dynamique": "122,712459"
            },
            {
                "Date": "12/04/2019",
                "Profil Dynamique": "123,1213365"
            },
            {
                "Date": "19/04/2019",
                "Profil Dynamique": "123,4182536"
            },
            {
                "Date": "26/04/2019",
                "Profil Dynamique": "123,7075345"
            },
            {
                "Date": "03/05/2019",
                "Profil Dynamique": "123,9440578"
            },
            {
                "Date": "10/05/2019",
                "Profil Dynamique": "124,3547151"
            },
            {
                "Date": "17/05/2019",
                "Profil Dynamique": "124,8806006"
            },
            {
                "Date": "24/05/2019",
                "Profil Dynamique": "124,4656377"
            },
            {
                "Date": "31/05/2019",
                "Profil Dynamique": "124,5696266"
            },
            {
                "Date": "10/06/2019",
                "Profil Dynamique": "124,7846089"
            },
            {
                "Date": "14/06/2019",
                "Profil Dynamique": "125,4828702"
            },
            {
                "Date": "21/06/2019",
                "Profil Dynamique": "127,1548519"
            },
            {
                "Date": "28/06/2019",
                "Profil Dynamique": "127,0606185"
            },
            {
                "Date": "05/07/2019",
                "Profil Dynamique": "127,0139088"
            },
            {
                "Date": "12/07/2019",
                "Profil Dynamique": "127,5606947"
            },
            {
                "Date": "19/07/2019",
                "Profil Dynamique": "128,4340022"
            },
            {
                "Date": "26/07/2019",
                "Profil Dynamique": "128,666096"
            },
            {
                "Date": "02/08/2019",
                "Profil Dynamique": "129,0431097"
            },
            {
                "Date": "09/08/2019",
                "Profil Dynamique": "129,1111629"
            },
            {
                "Date": "16/08/2019",
                "Profil Dynamique": "129,5042793"
            },
            {
                "Date": "23/08/2019",
                "Profil Dynamique": "129,4132179"
            },
            {
                "Date": "30/08/2019",
                "Profil Dynamique": "130,0693252"
            },
            {
                "Date": "06/09/2019",
                "Profil Dynamique": "129,7203747"
            },
            {
                "Date": "13/09/2019",
                "Profil Dynamique": "129,5786596"
            },
            {
                "Date": "20/09/2019",
                "Profil Dynamique": "129,0306269"
            },
            {
                "Date": "27/09/2019",
                "Profil Dynamique": "129,5880097"
            },
            {
                "Date": "04/10/2019",
                "Profil Dynamique": "129,2152312"
            },
            {
                "Date": "11/10/2019",
                "Profil Dynamique": "128,8253441"
            },
            {
                "Date": "18/10/2019",
                "Profil Dynamique": "129,4535761"
            },
            {
                "Date": "25/10/2019",
                "Profil Dynamique": "129,8363211"
            },
            {
                "Date": "01/11/2019",
                "Profil Dynamique": "129,8830775"
            },
            {
                "Date": "08/11/2019",
                "Profil Dynamique": "129,7780447"
            },
            {
                "Date": "15/11/2019",
                "Profil Dynamique": "130,1286463"
            },
            {
                "Date": "22/11/2019",
                "Profil Dynamique": "130,8446396"
            },
            {
                "Date": "29/11/2019",
                "Profil Dynamique": "130,9351119"
            },
            {
                "Date": "06/12/2019",
                "Profil Dynamique": "130,8979104"
            },
            {
                "Date": "13/12/2019",
                "Profil Dynamique": "131,6232741"
            },
            {
                "Date": "20/12/2019",
                "Profil Dynamique": "132,6130447"
            },
            {
                "Date": "27/12/2019",
                "Profil Dynamique": "132,6859476"
            },
            {
                "Date": "03/01/2020",
                "Profil Dynamique": "133,0350566"
            },
            {
                "Date": "10/01/2020",
                "Profil Dynamique": "133,8886691"
            },
            {
                "Date": "17/01/2020",
                "Profil Dynamique": "134,9254666"
            },
            {
                "Date": "24/01/2020",
                "Profil Dynamique": "135,4374145"
            },
            {
                "Date": "31/01/2020",
                "Profil Dynamique": "135,2331266"
            },
            {
                "Date": "07/02/2020",
                "Profil Dynamique": "133,78284"
            },
            {
                "Date": "14/02/2020",
                "Profil Dynamique": "134,5878107"
            },
            {
                "Date": "21/02/2020",
                "Profil Dynamique": "135,68348"
            },
            {
                "Date": "28/02/2020",
                "Profil Dynamique": "134,4804495"
            },
            {
                "Date": "06/03/2020",
                "Profil Dynamique": "131,718024"
            },
            {
                "Date": "13/03/2020",
                "Profil Dynamique": "127,1662163"
            },
            {
                "Date": "20/03/2020",
                "Profil Dynamique": "123,1497545"
            },
            {
                "Date": "27/03/2020",
                "Profil Dynamique": "124,7928147"
            },
            {
                "Date": "03/04/2020",
                "Profil Dynamique": "123,8951052"
            },
            {
                "Date": "10/04/2020",
                "Profil Dynamique": "123,7987504"
            },
            {
                "Date": "17/04/2020",
                "Profil Dynamique": "123,1233041"
            },
            {
                "Date": "24/04/2020",
                "Profil Dynamique": "122,9612743"
            },
            {
                "Date": "04/05/2020",
                "Profil Dynamique": "124,4509719"
            },
            {
                "Date": "08/05/2020",
                "Profil Dynamique": "124,8175986"
            },
            {
                "Date": "15/05/2020",
                "Profil Dynamique": "124,7661332"
            },
            {
                "Date": "22/05/2020",
                "Profil Dynamique": "126,0896993"
            },
            {
                "Date": "29/05/2020",
                "Profil Dynamique": "127,2046318"
            },
            {
                "Date": "05/06/2020",
                "Profil Dynamique": "128,0655336"
            },
            {
                "Date": "12/06/2020",
                "Profil Dynamique": "127,8269156"
            },
            {
                "Date": "19/06/2020",
                "Profil Dynamique": "129,8307355"
            },
            {
                "Date": "26/06/2020",
                "Profil Dynamique": "129,7886601"
            },
            {
                "Date": "03/07/2020",
                "Profil Dynamique": "130,4115445"
            },
            {
                "Date": "10/07/2020",
                "Profil Dynamique": "130,2358"
            },
            {
                "Date": "17/07/2020",
                "Profil Dynamique": "130,7959196"
            },
            {
                "Date": "24/07/2020",
                "Profil Dynamique": "131,2707738"
            },
            {
                "Date": "03/08/2020",
                "Profil Dynamique": "131,425144"
            },
            {
                "Date": "07/08/2020",
                "Profil Dynamique": "132,3744045"
            },
            {
                "Date": "17/08/2020",
                "Profil Dynamique": "132,101532"
            },
            {
                "Date": "24/08/2020",
                "Profil Dynamique": "131,9585886"
            },
            {
                "Date": "28/08/2020",
                "Profil Dynamique": "131,295864"
            },
            {
                "Date": "04/09/2020",
                "Profil Dynamique": "131,6783588"
            },
            {
                "Date": "11/09/2020",
                "Profil Dynamique": "132,032637"
            },
            {
                "Date": "18/09/2020",
                "Profil Dynamique": "131,4950496"
            },
            {
                "Date": "25/09/2020",
                "Profil Dynamique": "130,5703936"
            },
            {
                "Date": "02/10/2020",
                "Profil Dynamique": "131,3557697"
            },
            {
                "Date": "09/10/2020",
                "Profil Dynamique": "131,9780157"
            },
            {
                "Date": "16/10/2020",
                "Profil Dynamique": "132,3953505"
            },
            {
                "Date": "23/10/2020",
                "Profil Dynamique": "132,9330104"
            },
            {
                "Date": "02/11/2020",
                "Profil Dynamique": "132,5284527"
            },
            {
                "Date": "09/11/2020",
                "Profil Dynamique": "132,9977715"
            },
            {
                "Date": "13/11/2020",
                "Profil Dynamique": "133,5053389"
            },
            {
                "Date": "20/11/2020",
                "Profil Dynamique": "134,2710901"
            },
            {
                "Date": "27/11/2020",
                "Profil Dynamique": "133,4257095"
            },
            {
                "Date": "04/12/2020",
                "Profil Dynamique": "134,1003603"
            },
            {
                "Date": "11/12/2020",
                "Profil Dynamique": "135,8255004"
            },
            {
                "Date": "18/12/2020",
                "Profil Dynamique": "137,1408362"
            },
            {
                "Date": "25/12/2020",
                "Profil Dynamique": "136,2063253"
            },
            {
                "Date": "04/01/2021",
                "Profil Dynamique": "137,087502"
            },
            {
                "Date": "08/01/2021",
                "Profil Dynamique": "136,3698625"
            },
            {
                "Date": "15/01/2021",
                "Profil Dynamique": "137,1002769"
            },
            {
                "Date": "22/01/2021",
                "Profil Dynamique": "138,0240361"
            },
            {
                "Date": "29/01/2021",
                "Profil Dynamique": "138,5832442"
            },
            {
                "Date": "05/02/2021",
                "Profil Dynamique": "138,8019924"
            },
            {
                "Date": "12/02/2021",
                "Profil Dynamique": "138,1622137"
            },
            {
                "Date": "19/02/2021",
                "Profil Dynamique": "137,6059573"
            },
            {
                "Date": "26/02/2021",
                "Profil Dynamique": "136,8293319"
            },
            {
                "Date": "05/03/2021",
                "Profil Dynamique": "136,7065496"
            },
            {
                "Date": "12/03/2021",
                "Profil Dynamique": "137,0080614"
            },
            {
                "Date": "19/03/2021",
                "Profil Dynamique": "137,2443905"
            },
            {
                "Date": "26/03/2021",
                "Profil Dynamique": "137,4862002"
            },
            {
                "Date": "02/04/2021",
                "Profil Dynamique": "137,6676826"
            },
            {
                "Date": "09/04/2021",
                "Profil Dynamique": "137,7332496"
            },
            {
                "Date": "16/04/2021",
                "Profil Dynamique": "138,3209397"
            },
            {
                "Date": "23/04/2021",
                "Profil Dynamique": "138,6575539"
            },
            {
                "Date": "30/04/2021",
                "Profil Dynamique": "138,9670041"
            },
            {
                "Date": "07/05/2021",
                "Profil Dynamique": "140,4068515"
            },
            {
                "Date": "17/05/2021",
                "Profil Dynamique": "141,2066355"
            },
            {
                "Date": "21/05/2021",
                "Profil Dynamique": "141,2188884"
            },
            {
                "Date": "28/05/2021",
                "Profil Dynamique": "141,443007"
            },
            {
                "Date": "04/06/2021",
                "Profil Dynamique": "142,2222482"
            },
            {
                "Date": "11/06/2021",
                "Profil Dynamique": "142,5757912"
            },
            {
                "Date": "18/06/2021",
                "Profil Dynamique": "141,9117094"
            },
            {
                "Date": "25/06/2021",
                "Profil Dynamique": "141,6488177"
            },
            {
                "Date": "02/07/2021",
                "Profil Dynamique": "141,781834"
            },
            {
                "Date": "09/07/2021",
                "Profil Dynamique": "141,6229023"
            },
            {
                "Date": "16/07/2021",
                "Profil Dynamique": "141,7048806"
            },
            {
                "Date": "23/07/2021",
                "Profil Dynamique": "141,7623612"
            },
            {
                "Date": "02/08/2021",
                "Profil Dynamique": "142,6448821"
            },
            {
                "Date": "06/08/2021",
                "Profil Dynamique": "142,7961607"
            },
            {
                "Date": "13/08/2021",
                "Profil Dynamique": "143,3302832"
            },
            {
                "Date": "23/08/2021",
                "Profil Dynamique": "143,8751173"
            },
            {
                "Date": "27/08/2021",
                "Profil Dynamique": "144,2449138"
            },
            {
                "Date": "03/09/2021",
                "Profil Dynamique": "144,2730994"
            },
            {
                "Date": "10/09/2021",
                "Profil Dynamique": "144,7890476"
            },
            {
                "Date": "17/09/2021",
                "Profil Dynamique": "145,2273179"
            },
            {
                "Date": "24/09/2021",
                "Profil Dynamique": "145,7036443"
            },
            {
                "Date": "01/10/2021",
                "Profil Dynamique": "145,6480948"
            },
            {
                "Date": "08/10/2021",
                "Profil Dynamique": "146,3183939"
            },
            {
                "Date": "15/10/2021",
                "Profil Dynamique": "146,771972"
            },
            {
                "Date": "22/10/2021",
                "Profil Dynamique": "147,0775689"
            },
            {
                "Date": "29/10/2021",
                "Profil Dynamique": "147,8763941"
            },
            {
                "Date": "05/11/2021",
                "Profil Dynamique": "147,4764763"
            },
            {
                "Date": "12/11/2021",
                "Profil Dynamique": "147,4626106"
            },
            {
                "Date": "19/11/2021",
                "Profil Dynamique": "147,6531528"
            },
            {
                "Date": "26/11/2021",
                "Profil Dynamique": "146,9069379"
            },
            {
                "Date": "03/12/2021",
                "Profil Dynamique": "146,5510459"
            },
            {
                "Date": "10/12/2021",
                "Profil Dynamique": "147,3637198"
            },
            {
                "Date": "17/12/2021",
                "Profil Dynamique": "147,3864897"
            },
            {
                "Date": "24/12/2021",
                "Profil Dynamique": "147,2345021"
            },
            {
                "Date": "31/12/2021",
                "Profil Dynamique": "147,9109618"
            },
            {
                "Date": "07/01/2022",
                "Profil Dynamique": "148,383174"
            },
            {
                "Date": "14/01/2022",
                "Profil Dynamique": "149,6683771"
            },
            {
                "Date": "21/01/2022",
                "Profil Dynamique": "149,8257608"
            },
            {
                "Date": "28/01/2022",
                "Profil Dynamique": "149,5467893"
            },
            {
                "Date": "04/02/2022",
                "Profil Dynamique": "150,052215"
            },
            {
                "Date": "11/02/2022",
                "Profil Dynamique": "150,3241054"
            },
            {
                "Date": "18/02/2022",
                "Profil Dynamique": "149,988223"
            },
            {
                "Date": "25/02/2022",
                "Profil Dynamique": "147,6485485"
            },
            {
                "Date": "04/03/2022",
                "Profil Dynamique": "146,7197821"
            },
            {
                "Date": "11/03/2022",
                "Profil Dynamique": "146,2959021"
            },
            {
                "Date": "18/03/2022",
                "Profil Dynamique": "147,2777078"
            },
            {
                "Date": "25/03/2022",
                "Profil Dynamique": "146,2530454"
            },
            {
                "Date": "01/04/2022",
                "Profil Dynamique": "147,1203224"
            },
            {
                "Date": "08/04/2022",
                "Profil Dynamique": "147,6470279"
            },
            {
                "Date": "15/04/2022",
                "Profil Dynamique": "148,0443084"
            },
            {
                "Date": "22/04/2022",
                "Profil Dynamique": "148,741515"
            },
            {
                "Date": "29/04/2022",
                "Profil Dynamique": "148,6292864"
            },
            {
                "Date": "06/05/2022",
                "Profil Dynamique": "148,1873637"
            },
            {
                "Date": "13/05/2022",
                "Profil Dynamique": "146,1945478"
            },
            {
                "Date": "20/05/2022",
                "Profil Dynamique": "146,0256395"
            },
            {
                "Date": "27/05/2022",
                "Profil Dynamique": "144,7488485"
            },
            {
                "Date": "03/06/2022",
                "Profil Dynamique": "146,0696055"
            },
            {
                "Date": "10/06/2022",
                "Profil Dynamique": "146,0340299"
            },
            {
                "Date": "17/06/2022",
                "Profil Dynamique": "144,3570632"
            },
            {
                "Date": "24/06/2022",
                "Profil Dynamique": "143,8637676"
            },
            {
                "Date": "01/07/2022",
                "Profil Dynamique": "142,4983928"
            },
            {
                "Date": "08/07/2022",
                "Profil Dynamique": "142,5085342"
            },
            {
                "Date": "15/07/2022",
                "Profil Dynamique": "143,4110687"
            },
            {
                "Date": "22/07/2022",
                "Profil Dynamique": "144,0122019"
            },
            {
                "Date": "29/07/2022",
                "Profil Dynamique": "143,6754866"
            },
            {
                "Date": "05/08/2022",
                "Profil Dynamique": "144,5186523"
            },
            {
                "Date": "12/08/2022",
                "Profil Dynamique": "145,6309033"
            },
            {
                "Date": "19/08/2022",
                "Profil Dynamique": "145,6030256"
            },
            {
                "Date": "26/08/2022",
                "Profil Dynamique": "145,6955267"
            },
            {
                "Date": "02/09/2022",
                "Profil Dynamique": "145,236884"
            },
            {
                "Date": "09/09/2022",
                "Profil Dynamique": "145,5012087"
            },
            {
                "Date": "16/09/2022",
                "Profil Dynamique": "145,3038447"
            },
            {
                "Date": "23/09/2022",
                "Profil Dynamique": "145,4976979"
            },
            {
                "Date": "30/09/2022",
                "Profil Dynamique": "142,4593302"
            },
            {
                "Date": "07/10/2022",
                "Profil Dynamique": "142,7419513"
            },
            {
                "Date": "14/10/2022",
                "Profil Dynamique": "141,3320105"
            },
            {
                "Date": "21/10/2022",
                "Profil Dynamique": "139,3025797"
            },
            {
                "Date": "28/10/2022",
                "Profil Dynamique": "138,8333922"
            },
            {
                "Date": "04/11/2022",
                "Profil Dynamique": "138,7780072"
            },
            {
                "Date": "11/11/2022",
                "Profil Dynamique": "137,5309672"
            },
            {
                "Date": "21/11/2022",
                "Profil Dynamique": "138,9336538"
            },
            {
                "Date": "25/11/2022",
                "Profil Dynamique": "139,1273912"
            },
            {
                "Date": "02/12/2022",
                "Profil Dynamique": "140,1601072"
            },
            {
                "Date": "09/12/2022",
                "Profil Dynamique": "141,1126095"
            },
            {
                "Date": "16/12/2022",
                "Profil Dynamique": "140,1123749"
            },
            {
                "Date": "23/12/2022",
                "Profil Dynamique": "139,8398483"
            },
            {
                "Date": "30/12/2022",
                "Profil Dynamique": "138,7955424"
            },
            {
                "Date": "06/01/2023",
                "Profil Dynamique": "133,2491339"
            },
            {
                "Date": "13/01/2023",
                "Profil Dynamique": "135,7677644"
            },
            {
                "Date": "20/01/2023",
                "Profil Dynamique": "135,8041797"
            },
            {
                "Date": "27/01/2023",
                "Profil Dynamique": "135,3112085"
            },
            {
                "Date": "03/02/2023",
                "Profil Dynamique": "136,464588"
            },
            {
                "Date": "10/02/2023",
                "Profil Dynamique": "137,8510971"
            },
            {
                "Date": "17/02/2023",
                "Profil Dynamique": "137,5393268"
            },
            {
                "Date": "24/02/2023",
                "Profil Dynamique": "138,2105159"
            },
            {
                "Date": "03/03/2023",
                "Profil Dynamique": "137,8567771"
            },
            {
                "Date": "10/03/2023",
                "Profil Dynamique": "137,3957925"
            },
            {
                "Date": "17/03/2023",
                "Profil Dynamique": "137,6411293"
            },
            {
                "Date": "24/03/2023",
                "Profil Dynamique": "137,7102766"
            },
            {
                "Date": "31/03/2023",
                "Profil Dynamique": "137,3228995"
            },
            {
                "Date": "07/04/2023",
                "Profil Dynamique": "137,6630511"
            },
            {
                "Date": "14/04/2023",
                "Profil Dynamique": "137,9470993"
            },
            {
                "Date": "21/04/2023",
                "Profil Dynamique": "137,6248312"
            },
            {
                "Date": "28/04/2023",
                "Profil Dynamique": "137,7970149"
            },
            {
                "Date": "05/05/2023",
                "Profil Dynamique": "137,3296953"
            },
            {
                "Date": "12/05/2023",
                "Profil Dynamique": "139,0175065"
            },
            {
                "Date": "19/05/2023",
                "Profil Dynamique": "139,2078921"
            },
            {
                "Date": "26/05/2023",
                "Profil Dynamique": "140,0416254"
            },
            {
                "Date": "02/06/2023",
                "Profil Dynamique": "140,9923756"
            },
            {
                "Date": "09/06/2023",
                "Profil Dynamique": "140,5472769"
            },
            {
                "Date": "16/06/2023",
                "Profil Dynamique": "141,860058"
            },
            {
                "Date": "23/06/2023",
                "Profil Dynamique": "142,8451636"
            },
            {
                "Date": "03/07/2023",
                "Profil Dynamique": "143,2991212"
            },
            {
                "Date": "07/07/2023",
                "Profil Dynamique": "143,5078008"
            },
            {
                "Date": "14/07/2023",
                "Profil Dynamique": "143,9466489"
            },
            {
                "Date": "21/07/2023",
                "Profil Dynamique": "145,323856"
            },
            {
                "Date": "28/07/2023",
                "Profil Dynamique": "146,5200954"
            },
            {
                "Date": "04/08/2023",
                "Profil Dynamique": "146,8623579"
            },
            {
                "Date": "11/08/2023",
                "Profil Dynamique": "146,3040562"
            },
            {
                "Date": "18/08/2023",
                "Profil Dynamique": "146,2276361"
            },
            {
                "Date": "25/08/2023",
                "Profil Dynamique": "146,268102"
            },
            {
                "Date": "01/09/2023",
                "Profil Dynamique": "146,5019075"
            },
            {
                "Date": "08/09/2023",
                "Profil Dynamique": "145,7142237"
            },
            {
                "Date": "15/09/2023",
                "Profil Dynamique": "146,1094528"
            },
            {
                "Date": "22/09/2023",
                "Profil Dynamique": "145,8927481"
            },
            {
                "Date": "02/10/2023",
                "Profil Dynamique": "145,6820418"
            },
            {
                "Date": "06/10/2023",
                "Profil Dynamique": "147,9231955"
            },
            {
                "Date": "13/10/2023",
                "Profil Dynamique": "148,3676722"
            },
            {
                "Date": "20/10/2023",
                "Profil Dynamique": "149,1631756"
            },
            {
                "Date": "27/10/2023",
                "Profil Dynamique": "148,1490885"
            },
            {
                "Date": "03/11/2023",
                "Profil Dynamique": "148,1667929"
            },
            {
                "Date": "10/11/2023",
                "Profil Dynamique": "147,6724972"
            },
            {
                "Date": "17/11/2023",
                "Profil Dynamique": "147,998434"
            },
            {
                "Date": "24/11/2023",
                "Profil Dynamique": "148,2648723"
            },
            {
                "Date": "01/12/2023",
                "Profil Dynamique": "148,0054603"
            },
            {
                "Date": "08/12/2023",
                "Profil Dynamique": "148,4198439"
            },
            {
                "Date": "15/12/2023",
                "Profil Dynamique": "149,7966622"
            },
            {
                "Date": "22/12/2023",
                "Profil Dynamique": "149,6740653"
            },
            {
                "Date": "29/12/2023",
                "Profil Dynamique": "149,4663921"
            },
            {
                "Date": "05/01/2024",
                "Profil Dynamique": "150,7074251"
            },
            {
                "Date": "12/01/2024",
                "Profil Dynamique": "151,8441996"
            },
            {
                "Date": "19/01/2024",
                "Profil Dynamique": "152,3466305"
            },
            {
                "Date": "26/01/2024",
                "Profil Dynamique": "151,9193653"
            },
            {
                "Date": "02/02/2024",
                "Profil Dynamique": "151,7190072"
            },
            {
                "Date": "09/02/2024",
                "Profil Dynamique": "152,6905412"
            },
            {
                "Date": "16/02/2024",
                "Profil Dynamique": "153,433896"
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
            setChartData(yourDataSet[0]);
        }
    }, [yourDataSet]);

    const getOption = () => {
        if (!chartData || chartData.length === 0) {
            return {};
        }

        const xAxisData = chartData.map((item) => item.Date);

        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');

        const series = legendData.map((key) => ({
            type: 'line',
            name: key,
            data: chartData.map((item) => {
                const value = item[key];
                const parsedValue = parseFloat(value.replace(',', ' '));
                return isNaN(parsedValue) ? null : parsedValue;
            }),
            itemStyle: {
                color: '#91cc75' // Set the color for the line
            }
        }));

        return {
            animationDuration: 10000,
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: '',
                left: 'center',

            },
            legend: {
                data: legendData,
                bottom: 10,


            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                min: 100,
            },
            series,
        };
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactEChartsCore echarts={echarts} option={getOption()} />

            </Col>
        </Row>
    );
};

export default LineChartComponent;
