import React, { useState } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import questionsData from '../data/RoboMatrice';

const Step19 = ({ selections, setSelections }) => {
  const currentQuestionData = questionsData.find((question) => question.id === 19);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (value) => {
    const selectedOption = currentQuestionData.options.find(option => option.value === value);
    setSelections(prevSelections => ({
      ...prevSelections,
      réaction: value,
      selectedProfiles: {
        prudent: prevSelections.selectedProfiles.prudent + selectedOption.profiles.prudent,
        modéré: prevSelections.selectedProfiles.modéré + selectedOption.profiles.modéré,
        agressif: prevSelections.selectedProfiles.agressif + selectedOption.profiles.agressif,
      },
    }));
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      <div className="centered-radio-buttons">
        <Row>
          <div className="d-flex justify-content-center align-items-center">
            <Card className="funnel__box text-center" style={{ border: '1px solid #ccc', borderRadius: '5px', borderColor: '#1ea3dc', borderWidth: 5, boxShadow: 3 }}>
              <Card.Header className="funnel__box-header">
                <h2 className="funnel__box-title" style={{ color: '#05a0e1', fontWeight: '500', fontSize: '16px' }}>{currentQuestionData.title}</h2>
                <div className="funnel__box-title">
                  {`Question ${currentQuestionData.questionNumber}/${currentQuestionData.totalQuestions}`}
                </div>
              </Card.Header>
              <Card.Body>
                <p className="funnel__form-label">{currentQuestionData.question}</p>
                {currentQuestionData.options.map((option) => (
                  <Col className="radio-col" key={option.value}>
                    <input
                      type="radio"
                      id={`réaction-${option.value}`}
                      name="réaction"
                      value={option.value}
                      checked={selections.réaction === option.value}
                      onChange={() => handleRadioChange(option.value)}
                    />
                    <label htmlFor={`réaction-${option.value}`} className="radio-label">
                      {option.label}
                    </label>
                  </Col>
                ))}
                <Col>
                  {selectedOption && (
                    <p>{selectedOption.textAfterSelection}</p>
                  )}
                </Col>
              </Card.Body>
            </Card>
          </div>
        </Row>

      </div>
    </div>
  );
};

export default Step19;