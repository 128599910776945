import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Card, Col, Row } from 'react-bootstrap';

const LineChart = () => {
  const data = [
    {
        "Date": "28/12/2015",
        "Profil Agressif": "100",
        "Profil Prudent": "100",
        "Profil Modéré": "100",
        "Profil Dynamique": "100"
    },
    {
        "Date": "04/01/2016",
        "Profil Agressif": "99,93783171",
        "Profil Prudent": "100,0411387",
        "Profil Modéré": "100,06991",
        "Profil Dynamique": "100,0365654"
    },
    {
        "Date": "08/01/2016",
        "Profil Agressif": "99,7122834",
        "Profil Prudent": "100,0680571",
        "Profil Modéré": "100,1810637",
        "Profil Dynamique": "99,99601653"
    },
    {
        "Date": "15/01/2016",
        "Profil Agressif": "99,33870575",
        "Profil Prudent": "100,1142384",
        "Profil Modéré": "100,1200491",
        "Profil Dynamique": "99,89428816"
    },
    {
        "Date": "22/01/2016",
        "Profil Agressif": "99,59389593",
        "Profil Prudent": "100,1799909",
        "Profil Modéré": "100,3136014",
        "Profil Dynamique": "100,0868895"
    },
    {
        "Date": "29/01/2016",
        "Profil Agressif": "100,1519517",
        "Profil Prudent": "100,2313224",
        "Profil Modéré": "100,5034712",
        "Profil Dynamique": "100,3724224"
    },
    {
        "Date": "05/02/2016",
        "Profil Agressif": "101,05707",
        "Profil Prudent": "100,2813892",
        "Profil Modéré": "100,8127802",
        "Profil Dynamique": "100,9967306"
    },
    {
        "Date": "12/02/2016",
        "Profil Agressif": "101,7830285",
        "Profil Prudent": "100,3310635",
        "Profil Modéré": "101,4194992",
        "Profil Dynamique": "101,6795812"
    },
    {
        "Date": "19/02/2016",
        "Profil Agressif": "103,4499483",
        "Profil Prudent": "100,3816879",
        "Profil Modéré": "101,6009986",
        "Profil Dynamique": "102,3945174"
    },
    {
        "Date": "26/02/2016",
        "Profil Agressif": "101,8586329",
        "Profil Prudent": "100,4328692",
        "Profil Modéré": "101,6065797",
        "Profil Dynamique": "102,0651396"
    },
    {
        "Date": "04/03/2016",
        "Profil Agressif": "102,9306282",
        "Profil Prudent": "100,4863184",
        "Profil Modéré": "102,0738468",
        "Profil Dynamique": "102,8378656"
    },
    {
        "Date": "11/03/2016",
        "Profil Agressif": "103,532612",
        "Profil Prudent": "100,5330185",
        "Profil Modéré": "102,0528058",
        "Profil Dynamique": "103,0157203"
    },
    {
        "Date": "18/03/2016",
        "Profil Agressif": "104,3845081",
        "Profil Prudent": "100,5897833",
        "Profil Modéré": "102,0800668",
        "Profil Dynamique": "103,2621914"
    },
    {
        "Date": "25/03/2016",
        "Profil Agressif": "105,5498413",
        "Profil Prudent": "100,6075868",
        "Profil Modéré": "102,1480044",
        "Profil Dynamique": "103,6985396"
    },
    {
        "Date": "01/04/2016",
        "Profil Agressif": "103,905361",
        "Profil Prudent": "100,7109487",
        "Profil Modéré": "102,1527521",
        "Profil Dynamique": "103,2160248"
    },
    {
        "Date": "08/04/2016",
        "Profil Agressif": "104,2776651",
        "Profil Prudent": "100,7370062",
        "Profil Modéré": "102,4864899",
        "Profil Dynamique": "103,6309009"
    },
    {
        "Date": "15/04/2016",
        "Profil Agressif": "106,2056959",
        "Profil Prudent": "100,7966987",
        "Profil Modéré": "102,8601389",
        "Profil Dynamique": "104,4521782"
    },
    {
        "Date": "22/04/2016",
        "Profil Agressif": "106,0011868",
        "Profil Prudent": "100,8883591",
        "Profil Modéré": "103,2225175",
        "Profil Dynamique": "104,7369696"
    },
    {
        "Date": "29/04/2016",
        "Profil Agressif": "108,9319232",
        "Profil Prudent": "100,9587694",
        "Profil Modéré": "103,7423942",
        "Profil Dynamique": "106,1271648"
    },
    {
        "Date": "06/05/2016",
        "Profil Agressif": "111,0410782",
        "Profil Prudent": "100,9942087",
        "Profil Modéré": "103,9943389",
        "Profil Dynamique": "106,934654"
    },
    {
        "Date": "13/05/2016",
        "Profil Agressif": "111,369984",
        "Profil Prudent": "101,0277236",
        "Profil Modéré": "104,0112352",
        "Profil Dynamique": "107,1381854"
    },
    {
        "Date": "20/05/2016",
        "Profil Agressif": "109,8690673",
        "Profil Prudent": "101,0669467",
        "Profil Modéré": "103,8779289",
        "Profil Dynamique": "106,5868494"
    },
    {
        "Date": "27/05/2016",
        "Profil Agressif": "109,123072",
        "Profil Prudent": "101,1074651",
        "Profil Modéré": "103,6273575",
        "Profil Dynamique": "106,1123077"
    },
    {
        "Date": "03/06/2016",
        "Profil Agressif": "110,2103323",
        "Profil Prudent": "101,1489195",
        "Profil Modéré": "103,8338539",
        "Profil Dynamique": "106,6304324"
    },
    {
        "Date": "10/06/2016",
        "Profil Agressif": "109,8780109",
        "Profil Prudent": "101,1946768",
        "Profil Modéré": "104,0625802",
        "Profil Dynamique": "106,7341385"
    },
    {
        "Date": "17/06/2016",
        "Profil Agressif": "109,4132847",
        "Profil Prudent": "101,2296462",
        "Profil Modéré": "104,218424",
        "Profil Dynamique": "106,7086452"
    },
    {
        "Date": "24/06/2016",
        "Profil Agressif": "109,9801508",
        "Profil Prudent": "101,2366211",
        "Profil Modéré": "104,4027672",
        "Profil Dynamique": "107,0406107"
    },
    {
        "Date": "01/07/2016",
        "Profil Agressif": "110,1829748",
        "Profil Prudent": "101,2204262",
        "Profil Modéré": "104,5339466",
        "Profil Dynamique": "107,2373132"
    },
    {
        "Date": "11/07/2016",
        "Profil Agressif": "110,8076581",
        "Profil Prudent": "101,2662479",
        "Profil Modéré": "104,7810819",
        "Profil Dynamique": "107,6325517"
    },
    {
        "Date": "15/07/2016",
        "Profil Agressif": "112,4011748",
        "Profil Prudent": "101,2469571",
        "Profil Modéré": "104,5357332",
        "Profil Dynamique": "107,9248651"
    },
    {
        "Date": "22/07/2016",
        "Profil Agressif": "113,3694458",
        "Profil Prudent": "101,2692572",
        "Profil Modéré": "104,539181",
        "Profil Dynamique": "108,1898696"
    },
    {
        "Date": "29/07/2016",
        "Profil Agressif": "114,0435431",
        "Profil Prudent": "101,2974248",
        "Profil Modéré": "104,6717814",
        "Profil Dynamique": "108,4771495"
    },
    {
        "Date": "05/08/2016",
        "Profil Agressif": "114,2851887",
        "Profil Prudent": "101,3362093",
        "Profil Modéré": "104,7131142",
        "Profil Dynamique": "108,6034548"
    },
    {
        "Date": "12/08/2016",
        "Profil Agressif": "114,1629937",
        "Profil Prudent": "101,3674741",
        "Profil Modéré": "104,6512371",
        "Profil Dynamique": "108,5018903"
    },
    {
        "Date": "19/08/2016",
        "Profil Agressif": "114,2227083",
        "Profil Prudent": "101,411614",
        "Profil Modéré": "104,59853",
        "Profil Dynamique": "108,4422724"
    },
    {
        "Date": "26/08/2016",
        "Profil Agressif": "114,0945987",
        "Profil Prudent": "101,4455052",
        "Profil Modéré": "104,4506309",
        "Profil Dynamique": "108,2825304"
    },
    {
        "Date": "02/09/2016",
        "Profil Agressif": "114,1552227",
        "Profil Prudent": "101,4841182",
        "Profil Modéré": "104,5604149",
        "Profil Dynamique": "108,3891443"
    },
    {
        "Date": "09/09/2016",
        "Profil Agressif": "114,3481085",
        "Profil Prudent": "101,5252765",
        "Profil Modéré": "104,627308",
        "Profil Dynamique": "108,5061772"
    },
    {
        "Date": "16/09/2016",
        "Profil Agressif": "114,0747774",
        "Profil Prudent": "101,5688581",
        "Profil Modéré": "104,5400993",
        "Profil Dynamique": "108,344216"
    },
    {
        "Date": "23/09/2016",
        "Profil Agressif": "114,5783589",
        "Profil Prudent": "101,6143234",
        "Profil Modéré": "104,7978003",
        "Profil Dynamique": "108,6708512"
    },
    {
        "Date": "30/09/2016",
        "Profil Agressif": "115,6435019",
        "Profil Prudent": "101,6547059",
        "Profil Modéré": "104,8923627",
        "Profil Dynamique": "109,0905537"
    },
    {
        "Date": "07/10/2016",
        "Profil Agressif": "116,2512638",
        "Profil Prudent": "101,7032006",
        "Profil Modéré": "104,6321693",
        "Profil Dynamique": "109,0467288"
    },
    {
        "Date": "14/10/2016",
        "Profil Agressif": "116,9423509",
        "Profil Prudent": "101,7544303",
        "Profil Modéré": "104,8400183",
        "Profil Dynamique": "109,4564082"
    },
    {
        "Date": "21/10/2016",
        "Profil Agressif": "118,38252",
        "Profil Prudent": "101,800822",
        "Profil Modéré": "105,2786849",
        "Profil Dynamique": "110,3421824"
    },
    {
        "Date": "28/10/2016",
        "Profil Agressif": "119,2104011",
        "Profil Prudent": "101,8439857",
        "Profil Modéré": "105,4273581",
        "Profil Dynamique": "110,7138686"
    },
    {
        "Date": "04/11/2016",
        "Profil Agressif": "119,8039058",
        "Profil Prudent": "101,8780786",
        "Profil Modéré": "105,615112",
        "Profil Dynamique": "111,0669909"
    },
    {
        "Date": "11/11/2016",
        "Profil Agressif": "120,5556763",
        "Profil Prudent": "101,9142434",
        "Profil Modéré": "105,4241724",
        "Profil Dynamique": "111,1785971"
    },
    {
        "Date": "21/11/2016",
        "Profil Agressif": "121,7451306",
        "Profil Prudent": "101,9699045",
        "Profil Modéré": "105,657402",
        "Profil Dynamique": "111,7617304"
    },
    {
        "Date": "25/11/2016",
        "Profil Agressif": "121,1948793",
        "Profil Prudent": "101,9972179",
        "Profil Modéré": "105,3915457",
        "Profil Dynamique": "111,3486016"
    },
    {
        "Date": "02/12/2016",
        "Profil Agressif": "122,1402512",
        "Profil Prudent": "102,0357098",
        "Profil Modéré": "105,4704337",
        "Profil Dynamique": "111,7691123"
    },
    {
        "Date": "09/12/2016",
        "Profil Agressif": "124,8075604",
        "Profil Prudent": "102,0684031",
        "Profil Modéré": "105,6600803",
        "Profil Dynamique": "112,7946457"
    },
    {
        "Date": "16/12/2016",
        "Profil Agressif": "127,7767723",
        "Profil Prudent": "102,1060973",
        "Profil Modéré": "105,783994",
        "Profil Dynamique": "113,8340552"
    },
    {
        "Date": "23/12/2016",
        "Profil Agressif": "134,390787",
        "Profil Prudent": "102,1233278",
        "Profil Modéré": "106,4104355",
        "Profil Dynamique": "116,3986963"
    },
    {
        "Date": "30/12/2016",
        "Profil Agressif": "132,31075",
        "Profil Prudent": "102,1663863",
        "Profil Modéré": "106,246063",
        "Profil Dynamique": "115,4670204"
    },
    {
        "Date": "06/01/2017",
        "Profil Agressif": "142,0120709",
        "Profil Prudent": "102,2004702",
        "Profil Modéré": "107,3413561",
        "Profil Dynamique": "119,3480107"
    },
    {
        "Date": "13/01/2017",
        "Profil Agressif": "142,866625",
        "Profil Prudent": "102,2345939",
        "Profil Modéré": "107,4333613",
        "Profil Dynamique": "119,7307935"
    },
    {
        "Date": "20/01/2017",
        "Profil Agressif": "141,6547329",
        "Profil Prudent": "102,2758623",
        "Profil Modéré": "107,3686356",
        "Profil Dynamique": "119,2318293"
    },
    {
        "Date": "27/01/2017",
        "Profil Agressif": "141,2643283",
        "Profil Prudent": "102,3108883",
        "Profil Modéré": "107,2900674",
        "Profil Dynamique": "119,0682581"
    },
    {
        "Date": "03/02/2017",
        "Profil Agressif": "139,281719",
        "Profil Prudent": "102,3500861",
        "Profil Modéré": "107,3279619",
        "Profil Dynamique": "118,5571302"
    },
    {
        "Date": "10/02/2017",
        "Profil Agressif": "139,5767713",
        "Profil Prudent": "102,4062202",
        "Profil Modéré": "107,5324787",
        "Profil Dynamique": "118,7985261"
    },
    {
        "Date": "17/02/2017",
        "Profil Agressif": "138,8419272",
        "Profil Prudent": "102,4586196",
        "Profil Modéré": "107,5716142",
        "Profil Dynamique": "118,586875"
    },
    {
        "Date": "24/02/2017",
        "Profil Agressif": "135,4599987",
        "Profil Prudent": "102,513335",
        "Profil Modéré": "107,5400919",
        "Profil Dynamique": "117,5612488"
    },
    {
        "Date": "03/03/2017",
        "Profil Agressif": "136,7244576",
        "Profil Prudent": "102,559662",
        "Profil Modéré": "107,5473732",
        "Profil Dynamique": "117,8551661"
    },
    {
        "Date": "10/03/2017",
        "Profil Agressif": "136,6683585",
        "Profil Prudent": "102,6044739",
        "Profil Modéré": "107,4572935",
        "Profil Dynamique": "117,7588148"
    },
    {
        "Date": "17/03/2017",
        "Profil Agressif": "137,5086598",
        "Profil Prudent": "102,6369646",
        "Profil Modéré": "107,6656967",
        "Profil Dynamique": "118,1899958"
    },
    {
        "Date": "24/03/2017",
        "Profil Agressif": "133,7197618",
        "Profil Prudent": "102,6868977",
        "Profil Modéré": "107,5589818",
        "Profil Dynamique": "117,0135923"
    },
    {
        "Date": "31/03/2017",
        "Profil Agressif": "132,8251919",
        "Profil Prudent": "102,7221251",
        "Profil Modéré": "107,5585745",
        "Profil Dynamique": "116,8082402"
    },
    {
        "Date": "07/04/2017",
        "Profil Agressif": "132,2366383",
        "Profil Prudent": "102,753001",
        "Profil Modéré": "107,6975691",
        "Profil Dynamique": "116,7954516"
    },
    {
        "Date": "14/04/2017",
        "Profil Agressif": "131,7650945",
        "Profil Prudent": "102,7935787",
        "Profil Modéré": "107,7845287",
        "Profil Dynamique": "116,5903983"
    },
    {
        "Date": "21/04/2017",
        "Profil Agressif": "132,8975386",
        "Profil Prudent": "102,8360139",
        "Profil Modéré": "107,898435",
        "Profil Dynamique": "117,0643577"
    },
    {
        "Date": "28/04/2017",
        "Profil Agressif": "135,3249876",
        "Profil Prudent": "102,8811428",
        "Profil Modéré": "107,998477",
        "Profil Dynamique": "117,9420769"
    },
    {
        "Date": "05/05/2017",
        "Profil Agressif": "136,0117721",
        "Profil Prudent": "102,9155323",
        "Profil Modéré": "107,8325",
        "Profil Dynamique": "118,004187"
    },
    {
        "Date": "12/05/2017",
        "Profil Agressif": "136,4041625",
        "Profil Prudent": "102,9646104",
        "Profil Modéré": "107,9069868",
        "Profil Dynamique": "118,1362937"
    },
    {
        "Date": "19/05/2017",
        "Profil Agressif": "136,249832",
        "Profil Prudent": "103,0048523",
        "Profil Modéré": "107,9880206",
        "Profil Dynamique": "118,1733173"
    },
    {
        "Date": "26/05/2017",
        "Profil Agressif": "136,5137522",
        "Profil Prudent": "103,0482102",
        "Profil Modéré": "108,138577",
        "Profil Dynamique": "118,3517832"
    },
    {
        "Date": "02/06/2017",
        "Profil Agressif": "137,0578069",
        "Profil Prudent": "103,0839458",
        "Profil Modéré": "108,2660723",
        "Profil Dynamique": "118,6497279"
    },
    {
        "Date": "09/06/2017",
        "Profil Agressif": "138,0154477",
        "Profil Prudent": "103,122463",
        "Profil Modéré": "108,3994794",
        "Profil Dynamique": "119,1141474"
    },
    {
        "Date": "16/06/2017",
        "Profil Agressif": "138,548212",
        "Profil Prudent": "103,1582282",
        "Profil Modéré": "108,4904792",
        "Profil Dynamique": "119,3021178"
    },
    {
        "Date": "23/06/2017",
        "Profil Agressif": "141,0078066",
        "Profil Prudent": "103,1850146",
        "Profil Modéré": "108,6586103",
        "Profil Dynamique": "120,2995747"
    },
    {
        "Date": "30/06/2017",
        "Profil Agressif": "141,6134634",
        "Profil Prudent": "103,216337",
        "Profil Modéré": "108,5657054",
        "Profil Dynamique": "120,4227825"
    },
    {
        "Date": "07/07/2017",
        "Profil Agressif": "145,3120957",
        "Profil Prudent": "103,2563443",
        "Profil Modéré": "108,6624539",
        "Profil Dynamique": "121,6386769"
    },
    {
        "Date": "14/07/2017",
        "Profil Agressif": "145,0367167",
        "Profil Prudent": "103,2950163",
        "Profil Modéré": "108,7380905",
        "Profil Dynamique": "121,6703647"
    },
    {
        "Date": "21/07/2017",
        "Profil Agressif": "143,214501",
        "Profil Prudent": "103,3384475",
        "Profil Modéré": "108,6931016",
        "Profil Dynamique": "121,0967963"
    },
    {
        "Date": "28/07/2017",
        "Profil Agressif": "145,4294257",
        "Profil Prudent": "103,3883937",
        "Profil Modéré": "109,0270977",
        "Profil Dynamique": "122,1387238"
    },
    {
        "Date": "04/08/2017",
        "Profil Agressif": "144,0457056",
        "Profil Prudent": "103,4356022",
        "Profil Modéré": "108,9311537",
        "Profil Dynamique": "121,6760125"
    },
    {
        "Date": "11/08/2017",
        "Profil Agressif": "145,7859286",
        "Profil Prudent": "103,479723",
        "Profil Modéré": "109,2574154",
        "Profil Dynamique": "122,5685565"
    },
    {
        "Date": "18/08/2017",
        "Profil Agressif": "145,7629679",
        "Profil Prudent": "103,5242256",
        "Profil Modéré": "109,4107638",
        "Profil Dynamique": "122,7043535"
    },
    {
        "Date": "25/08/2017",
        "Profil Agressif": "145,2756199",
        "Profil Prudent": "103,5725127",
        "Profil Modéré": "109,3360644",
        "Profil Dynamique": "122,4907116"
    },
    {
        "Date": "04/09/2017",
        "Profil Agressif": "147,298885",
        "Profil Prudent": "103,6350486",
        "Profil Modéré": "109,7531568",
        "Profil Dynamique": "123,3466154"
    },
    {
        "Date": "08/09/2017",
        "Profil Agressif": "149,2760808",
        "Profil Prudent": "103,6597465",
        "Profil Modéré": "109,9780087",
        "Profil Dynamique": "124,1315257"
    },
    {
        "Date": "15/09/2017",
        "Profil Agressif": "150,3120992",
        "Profil Prudent": "103,7037392",
        "Profil Modéré": "110,0821889",
        "Profil Dynamique": "124,5901759"
    },
    {
        "Date": "25/09/2017",
        "Profil Agressif": "147,6815817",
        "Profil Prudent": "103,7686642",
        "Profil Modéré": "109,8397658",
        "Profil Dynamique": "123,5207653"
    },
    {
        "Date": "29/09/2017",
        "Profil Agressif": "145,7747567",
        "Profil Prudent": "103,7975272",
        "Profil Modéré": "109,8717583",
        "Profil Dynamique": "122,9976184"
    },
    {
        "Date": "06/10/2017",
        "Profil Agressif": "148,0040046",
        "Profil Prudent": "103,8433701",
        "Profil Modéré": "110,0506512",
        "Profil Dynamique": "123,8992936"
    },
    {
        "Date": "13/10/2017",
        "Profil Agressif": "149,408264",
        "Profil Prudent": "103,8884493",
        "Profil Modéré": "110,5036456",
        "Profil Dynamique": "124,8300909"
    },
    {
        "Date": "20/10/2017",
        "Profil Agressif": "147,5617729",
        "Profil Prudent": "103,9314449",
        "Profil Modéré": "110,2732425",
        "Profil Dynamique": "124,0182224"
    },
    {
        "Date": "27/10/2017",
        "Profil Agressif": "148,5332561",
        "Profil Prudent": "103,973743",
        "Profil Modéré": "110,4048205",
        "Profil Dynamique": "124,4506739"
    },
    {
        "Date": "03/11/2017",
        "Profil Agressif": "149,0274717",
        "Profil Prudent": "104,0193733",
        "Profil Modéré": "110,4613922",
        "Profil Dynamique": "124,6505268"
    },
    {
        "Date": "10/11/2017",
        "Profil Agressif": "150,884717",
        "Profil Prudent": "104,0606842",
        "Profil Modéré": "110,776576",
        "Profil Dynamique": "125,5129647"
    },
    {
        "Date": "17/11/2017",
        "Profil Agressif": "150,7168843",
        "Profil Prudent": "104,1038795",
        "Profil Modéré": "110,7286795",
        "Profil Dynamique": "125,4405179"
    },
    {
        "Date": "24/11/2017",
        "Profil Agressif": "150,272783",
        "Profil Prudent": "104,1452008",
        "Profil Modéré": "110,6948242",
        "Profil Dynamique": "125,2453632"
    },
    {
        "Date": "04/12/2017",
        "Profil Agressif": "148,9445673",
        "Profil Prudent": "104,2007916",
        "Profil Modéré": "110,5604394",
        "Profil Dynamique": "124,7240539"
    },
    {
        "Date": "08/12/2017",
        "Profil Agressif": "148,3849597",
        "Profil Prudent": "104,2215727",
        "Profil Modéré": "110,4247387",
        "Profil Dynamique": "124,417123"
    },
    {
        "Date": "15/12/2017",
        "Profil Agressif": "148,1290934",
        "Profil Prudent": "104,2572849",
        "Profil Modéré": "110,4611724",
        "Profil Dynamique": "124,3557302"
    },
    {
        "Date": "22/12/2017",
        "Profil Agressif": "146,2578362",
        "Profil Prudent": "104,2863522",
        "Profil Modéré": "110,3441276",
        "Profil Dynamique": "123,6644666"
    },
    {
        "Date": "29/12/2017",
        "Profil Agressif": "148,3057289",
        "Profil Prudent": "104,3618692",
        "Profil Modéré": "110,6723569",
        "Profil Dynamique": "124,6170341"
    },
    {
        "Date": "05/01/2018",
        "Profil Agressif": "149,6900778",
        "Profil Prudent": "104,4107741",
        "Profil Modéré": "110,9002544",
        "Profil Dynamique": "125,1698191"
    },
    {
        "Date": "12/01/2018",
        "Profil Agressif": "151,2488189",
        "Profil Prudent": "104,4529102",
        "Profil Modéré": "111,1170527",
        "Profil Dynamique": "125,82292"
    },
    {
        "Date": "19/01/2018",
        "Profil Agressif": "153,867499",
        "Profil Prudent": "104,4891784",
        "Profil Modéré": "111,3046799",
        "Profil Dynamique": "126,8221147"
    },
    {
        "Date": "26/01/2018",
        "Profil Agressif": "154,7518134",
        "Profil Prudent": "104,545152",
        "Profil Modéré": "111,639472",
        "Profil Dynamique": "127,4686993"
    },
    {
        "Date": "02/02/2018",
        "Profil Agressif": "156,3168894",
        "Profil Prudent": "104,5926094",
        "Profil Modéré": "111,6715363",
        "Profil Dynamique": "127,9091339"
    },
    {
        "Date": "09/02/2018",
        "Profil Agressif": "154,9775105",
        "Profil Prudent": "104,6386424",
        "Profil Modéré": "111,6426247",
        "Profil Dynamique": "127,5671529"
    },
    {
        "Date": "16/02/2018",
        "Profil Agressif": "155,4812153",
        "Profil Prudent": "104,6883326",
        "Profil Modéré": "111,8858476",
        "Profil Dynamique": "127,9103358"
    },
    {
        "Date": "23/02/2018",
        "Profil Agressif": "155,6309265",
        "Profil Prudent": "104,7350863",
        "Profil Modéré": "111,9271274",
        "Profil Dynamique": "127,9809406"
    },
    {
        "Date": "02/03/2018",
        "Profil Agressif": "155,3376315",
        "Profil Prudent": "104,782886",
        "Profil Modéré": "111,9397761",
        "Profil Dynamique": "127,9282175"
    },
    {
        "Date": "09/03/2018",
        "Profil Agressif": "155,7971199",
        "Profil Prudent": "104,8406228",
        "Profil Modéré": "112,0170438",
        "Profil Dynamique": "128,0741304"
    },
    {
        "Date": "16/03/2018",
        "Profil Agressif": "154,9593646",
        "Profil Prudent": "104,8869772",
        "Profil Modéré": "111,8603195",
        "Profil Dynamique": "127,6160448"
    },
    {
        "Date": "23/03/2018",
        "Profil Agressif": "153,9379898",
        "Profil Prudent": "104,9298802",
        "Profil Modéré": "112,0574483",
        "Profil Dynamique": "127,5208216"
    },
    {
        "Date": "30/03/2018",
        "Profil Agressif": "153,8109401",
        "Profil Prudent": "104,9677615",
        "Profil Modéré": "111,9535108",
        "Profil Dynamique": "127,3585154"
    },
    {
        "Date": "06/04/2018",
        "Profil Agressif": "152,7271097",
        "Profil Prudent": "105,0068042",
        "Profil Modéré": "111,9038939",
        "Profil Dynamique": "126,9476695"
    },
    {
        "Date": "13/04/2018",
        "Profil Agressif": "153,5156394",
        "Profil Prudent": "105,0469141",
        "Profil Modéré": "111,9724474",
        "Profil Dynamique": "127,2108971"
    },
    {
        "Date": "20/04/2018",
        "Profil Agressif": "153,3174379",
        "Profil Prudent": "105,0896246",
        "Profil Modéré": "112,0219782",
        "Profil Dynamique": "127,2389164"
    },
    {
        "Date": "27/04/2018",
        "Profil Agressif": "153,9781554",
        "Profil Prudent": "105,1290478",
        "Profil Modéré": "112,0778056",
        "Profil Dynamique": "127,4573981"
    },
    {
        "Date": "04/05/2018",
        "Profil Agressif": "153,7084351",
        "Profil Prudent": "105,1706143",
        "Profil Modéré": "112,0595965",
        "Profil Dynamique": "127,2819831"
    },
    {
        "Date": "11/05/2018",
        "Profil Agressif": "151,9183418",
        "Profil Prudent": "105,2121052",
        "Profil Modéré": "112,0239615",
        "Profil Dynamique": "126,7038492"
    },
    {
        "Date": "18/05/2018",
        "Profil Agressif": "149,6815942",
        "Profil Prudent": "105,2541729",
        "Profil Modéré": "111,6984807",
        "Profil Dynamique": "125,7436204"
    },
    {
        "Date": "25/05/2018",
        "Profil Agressif": "149,7987391",
        "Profil Prudent": "105,2944182",
        "Profil Modéré": "111,8856148",
        "Profil Dynamique": "125,9926398"
    },
    {
        "Date": "01/06/2018",
        "Profil Agressif": "147,540229",
        "Profil Prudent": "105,335529",
        "Profil Modéré": "111,6493433",
        "Profil Dynamique": "125,171067"
    },
    {
        "Date": "08/06/2018",
        "Profil Agressif": "149,0920173",
        "Profil Prudent": "105,3891643",
        "Profil Modéré": "111,8546127",
        "Profil Dynamique": "125,8481854"
    },
    {
        "Date": "18/06/2018",
        "Profil Agressif": "148,0718026",
        "Profil Prudent": "105,447788",
        "Profil Modéré": "111,8416634",
        "Profil Dynamique": "125,5247185"
    },
    {
        "Date": "22/06/2018",
        "Profil Agressif": "147,7137299",
        "Profil Prudent": "105,4749158",
        "Profil Modéré": "111,7449348",
        "Profil Dynamique": "125,3627133"
    },
    {
        "Date": "29/06/2018",
        "Profil Agressif": "145,2028415",
        "Profil Prudent": "105,5119172",
        "Profil Modéré": "111,4319845",
        "Profil Dynamique": "124,2966677"
    },
    {
        "Date": "06/07/2018",
        "Profil Agressif": "143,5089299",
        "Profil Prudent": "105,5553302",
        "Profil Modéré": "111,2501787",
        "Profil Dynamique": "123,6032243"
    },
    {
        "Date": "13/07/2018",
        "Profil Agressif": "140,9012671",
        "Profil Prudent": "105,6018791",
        "Profil Modéré": "111,0414641",
        "Profil Dynamique": "122,6460882"
    },
    {
        "Date": "20/07/2018",
        "Profil Agressif": "140,4069113",
        "Profil Prudent": "105,6410514",
        "Profil Modéré": "110,9676739",
        "Profil Dynamique": "122,4604435"
    },
    {
        "Date": "27/07/2018",
        "Profil Agressif": "143,3538232",
        "Profil Prudent": "105,6854519",
        "Profil Modéré": "111,2773724",
        "Profil Dynamique": "123,5498382"
    },
    {
        "Date": "03/08/2018",
        "Profil Agressif": "143,1334529",
        "Profil Prudent": "105,7303537",
        "Profil Modéré": "111,2191449",
        "Profil Dynamique": "123,3587708"
    },
    {
        "Date": "10/08/2018",
        "Profil Agressif": "142,9627243",
        "Profil Prudent": "105,779127",
        "Profil Modéré": "111,2938235",
        "Profil Dynamique": "123,3800319"
    },
    {
        "Date": "17/08/2018",
        "Profil Agressif": "142,7839654",
        "Profil Prudent": "105,8134627",
        "Profil Modéré": "111,1581176",
        "Profil Dynamique": "123,1283141"
    },
    {
        "Date": "24/08/2018",
        "Profil Agressif": "142,699944",
        "Profil Prudent": "105,857122",
        "Profil Modéré": "111,21526",
        "Profil Dynamique": "123,1466952"
    },
    {
        "Date": "31/08/2018",
        "Profil Agressif": "141,5126646",
        "Profil Prudent": "105,9002649",
        "Profil Modéré": "111,1480531",
        "Profil Dynamique": "122,692837"
    },
    {
        "Date": "07/09/2018",
        "Profil Agressif": "136,8742172",
        "Profil Prudent": "105,9461458",
        "Profil Modéré": "110,7204091",
        "Profil Dynamique": "120,8915222"
    },
    {
        "Date": "14/09/2018",
        "Profil Agressif": "137,8323974",
        "Profil Prudent": "105,9903716",
        "Profil Modéré": "110,8798312",
        "Profil Dynamique": "121,447066"
    },
    {
        "Date": "21/09/2018",
        "Profil Agressif": "137,9909398",
        "Profil Prudent": "106,0298391",
        "Profil Modéré": "110,8787089",
        "Profil Dynamique": "121,4927722"
    },
    {
        "Date": "28/09/2018",
        "Profil Agressif": "138,9563784",
        "Profil Prudent": "106,0756153",
        "Profil Modéré": "111,0455847",
        "Profil Dynamique": "121,90711"
    },
    {
        "Date": "05/10/2018",
        "Profil Agressif": "138,38019",
        "Profil Prudent": "106,1209704",
        "Profil Modéré": "111,1911409",
        "Profil Dynamique": "121,9406892"
    },
    {
        "Date": "12/10/2018",
        "Profil Agressif": "137,8636294",
        "Profil Prudent": "106,1671862",
        "Profil Modéré": "111,2215353",
        "Profil Dynamique": "121,7315633"
    },
    {
        "Date": "19/10/2018",
        "Profil Agressif": "137,5225627",
        "Profil Prudent": "106,2149577",
        "Profil Modéré": "111,3779677",
        "Profil Dynamique": "121,8282184"
    },
    {
        "Date": "26/10/2018",
        "Profil Agressif": "137,279414",
        "Profil Prudent": "106,2595104",
        "Profil Modéré": "111,4492052",
        "Profil Dynamique": "121,8105785"
    },
    {
        "Date": "02/11/2018",
        "Profil Agressif": "135,6148701",
        "Profil Prudent": "106,3060554",
        "Profil Modéré": "111,3470931",
        "Profil Dynamique": "121,2711176"
    },
    {
        "Date": "09/11/2018",
        "Profil Agressif": "136,7058495",
        "Profil Prudent": "106,3520776",
        "Profil Modéré": "111,3856527",
        "Profil Dynamique": "121,5643276"
    },
    {
        "Date": "16/11/2018",
        "Profil Agressif": "136,9592382",
        "Profil Prudent": "106,3982344",
        "Profil Modéré": "111,5334391",
        "Profil Dynamique": "121,6929002"
    },
    {
        "Date": "23/11/2018",
        "Profil Agressif": "136,7804239",
        "Profil Prudent": "106,4431718",
        "Profil Modéré": "111,5329903",
        "Profil Dynamique": "121,5757566"
    },
    {
        "Date": "30/11/2018",
        "Profil Agressif": "137,9310219",
        "Profil Prudent": "106,4886927",
        "Profil Modéré": "111,6472458",
        "Profil Dynamique": "122,0690491"
    },
    {
        "Date": "07/12/2018",
        "Profil Agressif": "137,7198834",
        "Profil Prudent": "106,5342",
        "Profil Modéré": "111,7993161",
        "Profil Dynamique": "122,0780269"
    },
    {
        "Date": "14/12/2018",
        "Profil Agressif": "137,2332194",
        "Profil Prudent": "106,5817423",
        "Profil Modéré": "111,8609679",
        "Profil Dynamique": "122,0689459"
    },
    {
        "Date": "21/12/2018",
        "Profil Agressif": "137,605135",
        "Profil Prudent": "106,6263811",
        "Profil Modéré": "112,0230494",
        "Profil Dynamique": "122,3594898"
    },
    {
        "Date": "28/12/2018",
        "Profil Agressif": "137,4700307",
        "Profil Prudent": "106,6731979",
        "Profil Modéré": "112,2290453",
        "Profil Dynamique": "122,3613334"
    },
    {
        "Date": "04/01/2019",
        "Profil Agressif": "136,9801722",
        "Profil Prudent": "106,717064",
        "Profil Modéré": "112,1879007",
        "Profil Dynamique": "122,3168523"
    },
    {
        "Date": "14/01/2019",
        "Profil Agressif": "136,6361965",
        "Profil Prudent": "106,7835397",
        "Profil Modéré": "112,3287405",
        "Profil Dynamique": "122,3050398"
    },
    {
        "Date": "18/01/2019",
        "Profil Agressif": "137,6982429",
        "Profil Prudent": "106,8080287",
        "Profil Modéré": "112,387167",
        "Profil Dynamique": "122,5939702"
    },
    {
        "Date": "25/01/2019",
        "Profil Agressif": "138,6831447",
        "Profil Prudent": "106,8543688",
        "Profil Modéré": "112,6261444",
        "Profil Dynamique": "123,1199455"
    },
    {
        "Date": "01/02/2019",
        "Profil Agressif": "139,5507211",
        "Profil Prudent": "106,898312",
        "Profil Modéré": "112,8750772",
        "Profil Dynamique": "123,6716724"
    },
    {
        "Date": "08/02/2019",
        "Profil Agressif": "139,8068523",
        "Profil Prudent": "106,9482207",
        "Profil Modéré": "112,9512266",
        "Profil Dynamique": "123,8045205"
    },
    {
        "Date": "15/02/2019",
        "Profil Agressif": "140,010368",
        "Profil Prudent": "107,0061159",
        "Profil Modéré": "113,1456891",
        "Profil Dynamique": "124,0839534"
    },
    {
        "Date": "22/02/2019",
        "Profil Agressif": "139,4437395",
        "Profil Prudent": "107,045724",
        "Profil Modéré": "113,2921363",
        "Profil Dynamique": "124,0320971"
    },
    {
        "Date": "01/03/2019",
        "Profil Agressif": "138,0746223",
        "Profil Prudent": "107,0969031",
        "Profil Modéré": "113,1575303",
        "Profil Dynamique": "123,4808607"
    },
    {
        "Date": "08/03/2019",
        "Profil Agressif": "137,4815782",
        "Profil Prudent": "107,1388238",
        "Profil Modéré": "113,1191023",
        "Profil Dynamique": "123,2589781"
    },
    {
        "Date": "15/03/2019",
        "Profil Agressif": "136,3495672",
        "Profil Prudent": "107,179902",
        "Profil Modéré": "113,0424673",
        "Profil Dynamique": "122,8067525"
    },
    {
        "Date": "22/03/2019",
        "Profil Agressif": "136,2235378",
        "Profil Prudent": "107,2271915",
        "Profil Modéré": "113,1652926",
        "Profil Dynamique": "122,8691535"
    },
    {
        "Date": "29/03/2019",
        "Profil Agressif": "135,7632258",
        "Profil Prudent": "107,2694393",
        "Profil Modéré": "113,1329613",
        "Profil Dynamique": "122,709833"
    },
    {
        "Date": "05/04/2019",
        "Profil Agressif": "135,5543982",
        "Profil Prudent": "107,310892",
        "Profil Modéré": "113,1363098",
        "Profil Dynamique": "122,712459"
    },
    {
        "Date": "12/04/2019",
        "Profil Agressif": "136,4191103",
        "Profil Prudent": "107,3559506",
        "Profil Modéré": "113,2876923",
        "Profil Dynamique": "123,1213365"
    },
    {
        "Date": "19/04/2019",
        "Profil Agressif": "137,4081592",
        "Profil Prudent": "107,3983482",
        "Profil Modéré": "113,3563322",
        "Profil Dynamique": "123,4182536"
    },
    {
        "Date": "26/04/2019",
        "Profil Agressif": "137,7165887",
        "Profil Prudent": "107,445891",
        "Profil Modéré": "113,5763202",
        "Profil Dynamique": "123,7075345"
    },
    {
        "Date": "03/05/2019",
        "Profil Agressif": "138,154948",
        "Profil Prudent": "107,4895466",
        "Profil Modéré": "113,6975042",
        "Profil Dynamique": "123,9440578"
    },
    {
        "Date": "10/05/2019",
        "Profil Agressif": "139,01975",
        "Profil Prudent": "107,5335357",
        "Profil Modéré": "113,8759687",
        "Profil Dynamique": "124,3547151"
    },
    {
        "Date": "17/05/2019",
        "Profil Agressif": "140,1653874",
        "Profil Prudent": "107,582167",
        "Profil Modéré": "114,0099039",
        "Profil Dynamique": "124,8806006"
    },
    {
        "Date": "24/05/2019",
        "Profil Agressif": "138,9619468",
        "Profil Prudent": "107,6307157",
        "Profil Modéré": "113,9771821",
        "Profil Dynamique": "124,4656377"
    },
    {
        "Date": "31/05/2019",
        "Profil Agressif": "138,8802492",
        "Profil Prudent": "107,677324",
        "Profil Modéré": "114,1290215",
        "Profil Dynamique": "124,5696266"
    },
    {
        "Date": "10/06/2019",
        "Profil Agressif": "139,0674864",
        "Profil Prudent": "107,7359562",
        "Profil Modéré": "114,3178156",
        "Profil Dynamique": "124,7846089"
    },
    {
        "Date": "14/06/2019",
        "Profil Agressif": "140,3061516",
        "Profil Prudent": "107,7551001",
        "Profil Modéré": "114,6652378",
        "Profil Dynamique": "125,4828702"
    },
    {
        "Date": "21/06/2019",
        "Profil Agressif": "144,2509637",
        "Profil Prudent": "107,797148",
        "Profil Modéré": "115,2178646",
        "Profil Dynamique": "127,1548519"
    },
    {
        "Date": "28/06/2019",
        "Profil Agressif": "143,5935308",
        "Profil Prudent": "107,8397429",
        "Profil Modéré": "115,3362906",
        "Profil Dynamique": "127,0606185"
    },
    {
        "Date": "05/07/2019",
        "Profil Agressif": "143,7148239",
        "Profil Prudent": "107,8823457",
        "Profil Modéré": "115,2431404",
        "Profil Dynamique": "127,0139088"
    },
    {
        "Date": "12/07/2019",
        "Profil Agressif": "144,6412085",
        "Profil Prudent": "107,9261723",
        "Profil Modéré": "115,513943",
        "Profil Dynamique": "127,5606947"
    },
    {
        "Date": "19/07/2019",
        "Profil Agressif": "146,5893747",
        "Profil Prudent": "107,9673767",
        "Profil Modéré": "115,9480792",
        "Profil Dynamique": "128,4340022"
    },
    {
        "Date": "26/07/2019",
        "Profil Agressif": "147,4443659",
        "Profil Prudent": "108,0125829",
        "Profil Modéré": "115,9816751",
        "Profil Dynamique": "128,666096"
    },
    {
        "Date": "02/08/2019",
        "Profil Agressif": "148,0225666",
        "Profil Prudent": "108,0529058",
        "Profil Modéré": "116,2246406",
        "Profil Dynamique": "129,0431097"
    },
    {
        "Date": "09/08/2019",
        "Profil Agressif": "147,5768578",
        "Profil Prudent": "108,0938793",
        "Profil Modéré": "116,5003311",
        "Profil Dynamique": "129,1111629"
    },
    {
        "Date": "16/08/2019",
        "Profil Agressif": "148,1171964",
        "Profil Prudent": "108,1363893",
        "Profil Modéré": "116,7870977",
        "Profil Dynamique": "129,5042793"
    },
    {
        "Date": "23/08/2019",
        "Profil Agressif": "147,9334128",
        "Profil Prudent": "108,1851801",
        "Profil Modéré": "116,7243683",
        "Profil Dynamique": "129,4132179"
    },
    {
        "Date": "30/08/2019",
        "Profil Agressif": "149,2286363",
        "Profil Prudent": "108,2351557",
        "Profil Modéré": "117,0758235",
        "Profil Dynamique": "130,0693252"
    },
    {
        "Date": "06/09/2019",
        "Profil Agressif": "148,0762065",
        "Profil Prudent": "108,275186",
        "Profil Modéré": "117,0080835",
        "Profil Dynamique": "129,7203747"
    },
    {
        "Date": "13/09/2019",
        "Profil Agressif": "147,9335753",
        "Profil Prudent": "108,3171706",
        "Profil Modéré": "116,9100633",
        "Profil Dynamique": "129,5786596"
    },
    {
        "Date": "20/09/2019",
        "Profil Agressif": "146,2832311",
        "Profil Prudent": "108,3549118",
        "Profil Modéré": "116,8316125",
        "Profil Dynamique": "129,0306269"
    },
    {
        "Date": "27/09/2019",
        "Profil Agressif": "147,2829625",
        "Profil Prudent": "108,3993892",
        "Profil Modéré": "117,0601815",
        "Profil Dynamique": "129,5880097"
    },
    {
        "Date": "04/10/2019",
        "Profil Agressif": "146,2171802",
        "Profil Prudent": "108,441159",
        "Profil Modéré": "117,0216225",
        "Profil Dynamique": "129,2152312"
    },
    {
        "Date": "11/10/2019",
        "Profil Agressif": "145,2668449",
        "Profil Prudent": "108,4843706",
        "Profil Modéré": "116,8578947",
        "Profil Dynamique": "128,8253441"
    },
    {
        "Date": "18/10/2019",
        "Profil Agressif": "146,683097",
        "Profil Prudent": "108,52617",
        "Profil Modéré": "117,1262148",
        "Profil Dynamique": "129,4535761"
    },
    {
        "Date": "25/10/2019",
        "Profil Agressif": "147,3646268",
        "Profil Prudent": "108,5705928",
        "Profil Modéré": "117,3866863",
        "Profil Dynamique": "129,8363211"
    },
    {
        "Date": "01/11/2019",
        "Profil Agressif": "147,2510604",
        "Profil Prudent": "108,6081648",
        "Profil Modéré": "117,445159",
        "Profil Dynamique": "129,8830775"
    },
    {
        "Date": "08/11/2019",
        "Profil Agressif": "147,2067124",
        "Profil Prudent": "108,6456132",
        "Profil Modéré": "117,2926584",
        "Profil Dynamique": "129,7780447"
    },
    {
        "Date": "15/11/2019",
        "Profil Agressif": "147,841534",
        "Profil Prudent": "108,6890256",
        "Profil Modéré": "117,4685202",
        "Profil Dynamique": "130,1286463"
    },
    {
        "Date": "22/11/2019",
        "Profil Agressif": "149,7407426",
        "Profil Prudent": "108,7352008",
        "Profil Modéré": "117,6590336",
        "Profil Dynamique": "130,8446396"
    },
    {
        "Date": "29/11/2019",
        "Profil Agressif": "149,6951636",
        "Profil Prudent": "108,7752488",
        "Profil Modéré": "117,7058497",
        "Profil Dynamique": "130,9351119"
    },
    {
        "Date": "06/12/2019",
        "Profil Agressif": "149,4709515",
        "Profil Prudent": "108,8158193",
        "Profil Modéré": "117,7264504",
        "Profil Dynamique": "130,8979104"
    },
    {
        "Date": "13/12/2019",
        "Profil Agressif": "151,3941704",
        "Profil Prudent": "108,8593128",
        "Profil Modéré": "117,956398",
        "Profil Dynamique": "131,6232741"
    },
    {
        "Date": "20/12/2019",
        "Profil Agressif": "153,6221976",
        "Profil Prudent": "108,9067951",
        "Profil Modéré": "118,3600615",
        "Profil Dynamique": "132,6130447"
    },
    {
        "Date": "27/12/2019",
        "Profil Agressif": "153,646116",
        "Profil Prudent": "108,9694744",
        "Profil Modéré": "118,5494328",
        "Profil Dynamique": "132,6859476"
    },
    {
        "Date": "03/01/2020",
        "Profil Agressif": "153,7886786",
        "Profil Prudent": "109,0002574",
        "Profil Modéré": "118,8459179",
        "Profil Dynamique": "133,0350566"
    },
    {
        "Date": "10/01/2020",
        "Profil Agressif": "155,8575005",
        "Profil Prudent": "109,0398742",
        "Profil Modéré": "119,1029349",
        "Profil Dynamique": "133,8886691"
    },
    {
        "Date": "17/01/2020",
        "Profil Agressif": "158,1759494",
        "Profil Prudent": "109,0753657",
        "Profil Modéré": "119,4386387",
        "Profil Dynamique": "134,9254666"
    },
    {
        "Date": "24/01/2020",
        "Profil Agressif": "159,2333216",
        "Profil Prudent": "109,1109512",
        "Profil Modéré": "119,6332218",
        "Profil Dynamique": "135,4374145"
    },
    {
        "Date": "31/01/2020",
        "Profil Agressif": "158,5787612",
        "Profil Prudent": "109,1517844",
        "Profil Modéré": "119,6737949",
        "Profil Dynamique": "135,2331266"
    },
    {
        "Date": "07/02/2020",
        "Profil Agressif": "154,4711533",
        "Profil Prudent": "109,1893635",
        "Profil Modéré": "119,3508789",
        "Profil Dynamique": "133,78284"
    },
    {
        "Date": "14/02/2020",
        "Profil Agressif": "156,4843724",
        "Profil Prudent": "109,2404615",
        "Profil Modéré": "119,6351177",
        "Profil Dynamique": "134,5878107"
    },
    {
        "Date": "21/02/2020",
        "Profil Agressif": "158,6238399",
        "Profil Prudent": "109,2846027",
        "Profil Modéré": "120,2274349",
        "Profil Dynamique": "135,68348"
    },
    {
        "Date": "28/02/2020",
        "Profil Agressif": "155,8930495",
        "Profil Prudent": "109,3305039",
        "Profil Modéré": "119,7144549",
        "Profil Dynamique": "134,4804495"
    },
    {
        "Date": "06/03/2020",
        "Profil Agressif": "148,0354294",
        "Profil Prudent": "109,3735444",
        "Profil Modéré": "119,2568268",
        "Profil Dynamique": "131,718024"
    },
    {
        "Date": "13/03/2020",
        "Profil Agressif": "137,5055098",
        "Profil Prudent": "109,4249552",
        "Profil Modéré": "117,6837582",
        "Profil Dynamique": "127,1662163"
    },
    {
        "Date": "20/03/2020",
        "Profil Agressif": "127,5413413",
        "Profil Prudent": "109,4834955",
        "Profil Modéré": "116,6243719",
        "Profil Dynamique": "123,1497545"
    },
    {
        "Date": "27/03/2020",
        "Profil Agressif": "129,9693426",
        "Profil Prudent": "109,5039199",
        "Profil Modéré": "117,6244732",
        "Profil Dynamique": "124,7928147"
    },
    {
        "Date": "03/04/2020",
        "Profil Agressif": "126,9873238",
        "Profil Prudent": "109,5408318",
        "Profil Modéré": "117,6756741",
        "Profil Dynamique": "123,8951052"
    },
    {
        "Date": "10/04/2020",
        "Profil Agressif": "126,1729609",
        "Profil Prudent": "109,6173032",
        "Profil Modéré": "117,943766",
        "Profil Dynamique": "123,7987504"
    },
    {
        "Date": "17/04/2020",
        "Profil Agressif": "124,2486594",
        "Profil Prudent": "109,6536857",
        "Profil Modéré": "117,8691441",
        "Profil Dynamique": "123,1233041"
    },
    {
        "Date": "24/04/2020",
        "Profil Agressif": "123,9340109",
        "Profil Prudent": "109,7060304",
        "Profil Modéré": "117,7999036",
        "Profil Dynamique": "122,9612743"
    },
    {
        "Date": "04/05/2020",
        "Profil Agressif": "127,5764809",
        "Profil Prudent": "109,7892093",
        "Profil Modéré": "118,1847588",
        "Profil Dynamique": "124,4509719"
    },
    {
        "Date": "08/05/2020",
        "Profil Agressif": "128,6894525",
        "Profil Prudent": "109,8147387",
        "Profil Modéré": "118,254295",
        "Profil Dynamique": "124,8175986"
    },
    {
        "Date": "15/05/2020",
        "Profil Agressif": "128,0940668",
        "Profil Prudent": "109,8596111",
        "Profil Modéré": "118,4560326",
        "Profil Dynamique": "124,7661332"
    },
    {
        "Date": "22/05/2020",
        "Profil Agressif": "131,3172243",
        "Profil Prudent": "109,920301",
        "Profil Modéré": "118,8571093",
        "Profil Dynamique": "126,0896993"
    },
    {
        "Date": "29/05/2020",
        "Profil Agressif": "133,8450898",
        "Profil Prudent": "109,9654946",
        "Profil Modéré": "119,2386625",
        "Profil Dynamique": "127,2046318"
    },
    {
        "Date": "05/06/2020",
        "Profil Agressif": "136,3966236",
        "Profil Prudent": "110,0248682",
        "Profil Modéré": "119,290732",
        "Profil Dynamique": "128,0655336"
    },
    {
        "Date": "12/06/2020",
        "Profil Agressif": "135,0661757",
        "Profil Prudent": "110,0772457",
        "Profil Modéré": "119,4916706",
        "Profil Dynamique": "127,8269156"
    },
    {
        "Date": "19/06/2020",
        "Profil Agressif": "139,6266087",
        "Profil Prudent": "110,1218417",
        "Profil Modéré": "120,0890169",
        "Profil Dynamique": "129,8307355"
    },
    {
        "Date": "26/06/2020",
        "Profil Agressif": "137,4444031",
        "Profil Prudent": "110,3380354",
        "Profil Modéré": "120,7457725",
        "Profil Dynamique": "129,7886601"
    },
    {
        "Date": "03/07/2020",
        "Profil Agressif": "138,7674235",
        "Profil Prudent": "110,3666971",
        "Profil Modéré": "121,063041",
        "Profil Dynamique": "130,4115445"
    },
    {
        "Date": "10/07/2020",
        "Profil Agressif": "138,2676893",
        "Profil Prudent": "110,4096932",
        "Profil Modéré": "121,1337913",
        "Profil Dynamique": "130,2358"
    },
    {
        "Date": "17/07/2020",
        "Profil Agressif": "139,6868663",
        "Profil Prudent": "110,4375494",
        "Profil Modéré": "121,2535646",
        "Profil Dynamique": "130,7959196"
    },
    {
        "Date": "24/07/2020",
        "Profil Agressif": "140,0646243",
        "Profil Prudent": "110,4713838",
        "Profil Modéré": "121,7142133",
        "Profil Dynamique": "131,2707738"
    },
    {
        "Date": "03/08/2020",
        "Profil Agressif": "139,9870245",
        "Profil Prudent": "110,5284667",
        "Profil Modéré": "121,9466798",
        "Profil Dynamique": "131,425144"
    },
    {
        "Date": "07/08/2020",
        "Profil Agressif": "141,8565189",
        "Profil Prudent": "110,5383373",
        "Profil Modéré": "122,4252733",
        "Profil Dynamique": "132,3744045"
    },
    {
        "Date": "17/08/2020",
        "Profil Agressif": "141,8455536",
        "Profil Prudent": "110,5799883",
        "Profil Modéré": "122,0898136",
        "Profil Dynamique": "132,101532"
    },
    {
        "Date": "24/08/2020",
        "Profil Agressif": "141,6951649",
        "Profil Prudent": "110,6127181",
        "Profil Modéré": "121,9675719",
        "Profil Dynamique": "131,9585886"
    },
    {
        "Date": "28/08/2020",
        "Profil Agressif": "140,1478086",
        "Profil Prudent": "110,6296919",
        "Profil Modéré": "121,8304268",
        "Profil Dynamique": "131,295864"
    },
    {
        "Date": "04/09/2020",
        "Profil Agressif": "141,1542888",
        "Profil Prudent": "110,6656859",
        "Profil Modéré": "121,8545037",
        "Profil Dynamique": "131,6783588"
    },
    {
        "Date": "11/09/2020",
        "Profil Agressif": "141,7594512",
        "Profil Prudent": "110,7011871",
        "Profil Modéré": "122,0242265",
        "Profil Dynamique": "132,032637"
    },
    {
        "Date": "18/09/2020",
        "Profil Agressif": "140,3469347",
        "Profil Prudent": "110,7461603",
        "Profil Modéré": "122,0095357",
        "Profil Dynamique": "131,4950496"
    },
    {
        "Date": "25/09/2020",
        "Profil Agressif": "138,7535268",
        "Profil Prudent": "110,7889747",
        "Profil Modéré": "121,4589323",
        "Profil Dynamique": "130,5703936"
    },
    {
        "Date": "02/10/2020",
        "Profil Agressif": "140,2770428",
        "Profil Prudent": "110,8189961",
        "Profil Modéré": "121,8324669",
        "Profil Dynamique": "131,3557697"
    },
    {
        "Date": "09/10/2020",
        "Profil Agressif": "141,5981195",
        "Profil Prudent": "110,8505241",
        "Profil Modéré": "122,0620731",
        "Profil Dynamique": "131,9780157"
    },
    {
        "Date": "16/10/2020",
        "Profil Agressif": "142,7226213",
        "Profil Prudent": "110,8773219",
        "Profil Modéré": "122,1167853",
        "Profil Dynamique": "132,3953505"
    },
    {
        "Date": "23/10/2020",
        "Profil Agressif": "144,0383",
        "Profil Prudent": "110,9136154",
        "Profil Modéré": "122,2416695",
        "Profil Dynamique": "132,9330104"
    },
    {
        "Date": "02/11/2020",
        "Profil Agressif": "142,9683741",
        "Profil Prudent": "110,9627885",
        "Profil Modéré": "122,2026739",
        "Profil Dynamique": "132,5284527"
    },
    {
        "Date": "09/11/2020",
        "Profil Agressif": "144,5184283",
        "Profil Prudent": "110,9799799",
        "Profil Modéré": "122,1243311",
        "Profil Dynamique": "132,9977715"
    },
    {
        "Date": "13/11/2020",
        "Profil Agressif": "145,58011",
        "Profil Prudent": "110,99334",
        "Profil Modéré": "122,3561006",
        "Profil Dynamique": "133,5053389"
    },
    {
        "Date": "20/11/2020",
        "Profil Agressif": "147,671959",
        "Profil Prudent": "110,9890893",
        "Profil Modéré": "122,4233754",
        "Profil Dynamique": "134,2710901"
    },
    {
        "Date": "27/11/2020",
        "Profil Agressif": "146,8608485",
        "Profil Prudent": "111,0051833",
        "Profil Modéré": "121,6882128",
        "Profil Dynamique": "133,4257095"
    },
    {
        "Date": "04/12/2020",
        "Profil Agressif": "148,3239003",
        "Profil Prudent": "111,0222184",
        "Profil Modéré": "121,9470685",
        "Profil Dynamique": "134,1003603"
    },
    {
        "Date": "11/12/2020",
        "Profil Agressif": "152,3015802",
        "Profil Prudent": "111,0683685",
        "Profil Modéré": "122,4489425",
        "Profil Dynamique": "135,8255004"
    },
    {
        "Date": "18/12/2020",
        "Profil Agressif": "154,384156",
        "Profil Prudent": "111,159933",
        "Profil Modéré": "123,156384",
        "Profil Dynamique": "137,1408362"
    },
    {
        "Date": "25/12/2020",
        "Profil Agressif": "151,6964706",
        "Profil Prudent": "111,2390157",
        "Profil Modéré": "123,1323702",
        "Profil Dynamique": "136,2063253"
    },
    {
        "Date": "04/01/2021",
        "Profil Agressif": "153,3416032",
        "Profil Prudent": "111,2865135",
        "Profil Modéré": "123,545568",
        "Profil Dynamique": "137,087502"
    },
    {
        "Date": "08/01/2021",
        "Profil Agressif": "152,2297252",
        "Profil Prudent": "111,3080322",
        "Profil Modéré": "123,0645329",
        "Profil Dynamique": "136,3698625"
    },
    {
        "Date": "15/01/2021",
        "Profil Agressif": "153,7481195",
        "Profil Prudent": "111,3434531",
        "Profil Modéré": "123,2844287",
        "Profil Dynamique": "137,1002769"
    },
    {
        "Date": "22/01/2021",
        "Profil Agressif": "155,3304732",
        "Profil Prudent": "111,3794624",
        "Profil Modéré": "123,7221623",
        "Profil Dynamique": "138,0240361"
    },
    {
        "Date": "29/01/2021",
        "Profil Agressif": "156,110746",
        "Profil Prudent": "111,4184797",
        "Profil Modéré": "124,0203581",
        "Profil Dynamique": "138,5832442"
    },
    {
        "Date": "05/02/2021",
        "Profil Agressif": "156,8803105",
        "Profil Prudent": "111,4553846",
        "Profil Modéré": "123,9120885",
        "Profil Dynamique": "138,8019924"
    },
    {
        "Date": "12/02/2021",
        "Profil Agressif": "155,2253682",
        "Profil Prudent": "111,4870231",
        "Profil Modéré": "123,804988",
        "Profil Dynamique": "138,1622137"
    },
    {
        "Date": "19/02/2021",
        "Profil Agressif": "154,1114171",
        "Profil Prudent": "111,5180593",
        "Profil Modéré": "123,5211778",
        "Profil Dynamique": "137,6059573"
    },
    {
        "Date": "26/02/2021",
        "Profil Agressif": "152,5874777",
        "Profil Prudent": "111,5523012",
        "Profil Modéré": "123,2269053",
        "Profil Dynamique": "136,8293319"
    },
    {
        "Date": "05/03/2021",
        "Profil Agressif": "152,519212",
        "Profil Prudent": "111,5849136",
        "Profil Modéré": "123,0866586",
        "Profil Dynamique": "136,7065496"
    },
    {
        "Date": "12/03/2021",
        "Profil Agressif": "153,1187198",
        "Profil Prudent": "111,614068",
        "Profil Modéré": "123,2346319",
        "Profil Dynamique": "137,0080614"
    },
    {
        "Date": "19/03/2021",
        "Profil Agressif": "153,5011976",
        "Profil Prudent": "111,643107",
        "Profil Modéré": "123,397983",
        "Profil Dynamique": "137,2443905"
    },
    {
        "Date": "26/03/2021",
        "Profil Agressif": "153,9212663",
        "Profil Prudent": "111,6722945",
        "Profil Modéré": "123,5155353",
        "Profil Dynamique": "137,4862002"
    },
    {
        "Date": "02/04/2021",
        "Profil Agressif": "154,426371",
        "Profil Prudent": "111,693472",
        "Profil Modéré": "123,5326941",
        "Profil Dynamique": "137,6676826"
    },
    {
        "Date": "09/04/2021",
        "Profil Agressif": "154,6509088",
        "Profil Prudent": "111,7185821",
        "Profil Modéré": "123,57218",
        "Profil Dynamique": "137,7332496"
    },
    {
        "Date": "16/04/2021",
        "Profil Agressif": "155,7697676",
        "Profil Prudent": "111,7443248",
        "Profil Modéré": "123,8716282",
        "Profil Dynamique": "138,3209397"
    },
    {
        "Date": "23/04/2021",
        "Profil Agressif": "156,4776919",
        "Profil Prudent": "111,7790153",
        "Profil Modéré": "123,9965371",
        "Profil Dynamique": "138,6575539"
    },
    {
        "Date": "30/04/2021",
        "Profil Agressif": "157,3105647",
        "Profil Prudent": "111,8077711",
        "Profil Modéré": "124,0682042",
        "Profil Dynamique": "138,9670041"
    },
    {
        "Date": "07/05/2021",
        "Profil Agressif": "160,1307318",
        "Profil Prudent": "111,8375447",
        "Profil Modéré": "124,7225448",
        "Profil Dynamique": "140,4068515"
    },
    {
        "Date": "17/05/2021",
        "Profil Agressif": "161,9634889",
        "Profil Prudent": "111,8775446",
        "Profil Modéré": "124,9625696",
        "Profil Dynamique": "141,2066355"
    },
    {
        "Date": "21/05/2021",
        "Profil Agressif": "161,7483579",
        "Profil Prudent": "111,8997671",
        "Profil Modéré": "125,0640698",
        "Profil Dynamique": "141,2188884"
    },
    {
        "Date": "28/05/2021",
        "Profil Agressif": "161,9446729",
        "Profil Prudent": "111,9301877",
        "Profil Modéré": "125,2825063",
        "Profil Dynamique": "141,443007"
    },
    {
        "Date": "04/06/2021",
        "Profil Agressif": "164,1069455",
        "Profil Prudent": "111,9599385",
        "Profil Modéré": "125,4369852",
        "Profil Dynamique": "142,2222482"
    },
    {
        "Date": "11/06/2021",
        "Profil Agressif": "165,0520838",
        "Profil Prudent": "111,9883589",
        "Profil Modéré": "125,4880263",
        "Profil Dynamique": "142,5757912"
    },
    {
        "Date": "18/06/2021",
        "Profil Agressif": "164,2132594",
        "Profil Prudent": "112,0214625",
        "Profil Modéré": "124,9570311",
        "Profil Dynamique": "141,9117094"
    },
    {
        "Date": "25/06/2021",
        "Profil Agressif": "163,4733205",
        "Profil Prudent": "112,0464194",
        "Profil Modéré": "124,9592685",
        "Profil Dynamique": "141,6488177"
    },
    {
        "Date": "02/07/2021",
        "Profil Agressif": "163,6365195",
        "Profil Prudent": "112,0795037",
        "Profil Modéré": "125,0549637",
        "Profil Dynamique": "141,781834"
    },
    {
        "Date": "09/07/2021",
        "Profil Agressif": "162,963996",
        "Profil Prudent": "112,1082906",
        "Profil Modéré": "125,1216287",
        "Profil Dynamique": "141,6229023"
    },
    {
        "Date": "16/07/2021",
        "Profil Agressif": "162,7288233",
        "Profil Prudent": "112,1335637",
        "Profil Modéré": "125,2739037",
        "Profil Dynamique": "141,7048806"
    },
    {
        "Date": "23/07/2021",
        "Profil Agressif": "163,1320424",
        "Profil Prudent": "112,1643907",
        "Profil Modéré": "125,1925175",
        "Profil Dynamique": "141,7623612"
    },
    {
        "Date": "02/08/2021",
        "Profil Agressif": "165,6028596",
        "Profil Prudent": "112,2135437",
        "Profil Modéré": "125,3697578",
        "Profil Dynamique": "142,6448821"
    },
    {
        "Date": "06/08/2021",
        "Profil Agressif": "166,004326",
        "Profil Prudent": "112,2296437",
        "Profil Modéré": "125,3212417",
        "Profil Dynamique": "142,7961607"
    },
    {
        "Date": "13/08/2021",
        "Profil Agressif": "167,151473",
        "Profil Prudent": "112,2664894",
        "Profil Modéré": "125,5651914",
        "Profil Dynamique": "143,3302832"
    },
    {
        "Date": "23/08/2021",
        "Profil Agressif": "168,0243405",
        "Profil Prudent": "112,3117091",
        "Profil Modéré": "125,8715759",
        "Profil Dynamique": "143,8751173"
    },
    {
        "Date": "27/08/2021",
        "Profil Agressif": "169,0175653",
        "Profil Prudent": "112,3280161",
        "Profil Modéré": "125,9553722",
        "Profil Dynamique": "144,2449138"
    },
    {
        "Date": "03/09/2021",
        "Profil Agressif": "168,9429984",
        "Profil Prudent": "112,3590671",
        "Profil Modéré": "126,0539562",
        "Profil Dynamique": "144,2730994"
    },
    {
        "Date": "10/09/2021",
        "Profil Agressif": "170,5212461",
        "Profil Prudent": "112,3886593",
        "Profil Modéré": "126,1110807",
        "Profil Dynamique": "144,7890476"
    },
    {
        "Date": "17/09/2021",
        "Profil Agressif": "171,9861823",
        "Profil Prudent": "112,4178622",
        "Profil Modéré": "126,1085229",
        "Profil Dynamique": "145,2273179"
    },
    {
        "Date": "24/09/2021",
        "Profil Agressif": "173,1343013",
        "Profil Prudent": "112,4426393",
        "Profil Modéré": "126,2390899",
        "Profil Dynamique": "145,7036443"
    },
    {
        "Date": "01/10/2021",
        "Profil Agressif": "172,8581361",
        "Profil Prudent": "112,4730033",
        "Profil Modéré": "126,3425985",
        "Profil Dynamique": "145,6480948"
    },
    {
        "Date": "08/10/2021",
        "Profil Agressif": "174,1285587",
        "Profil Prudent": "112,5027752",
        "Profil Modéré": "126,6150658",
        "Profil Dynamique": "146,3183939"
    },
    {
        "Date": "15/10/2021",
        "Profil Agressif": "175,2851342",
        "Profil Prudent": "112,5352177",
        "Profil Modéré": "126,7144994",
        "Profil Dynamique": "146,771972"
    },
    {
        "Date": "22/10/2021",
        "Profil Agressif": "175,5503121",
        "Profil Prudent": "112,5716766",
        "Profil Modéré": "126,9860886",
        "Profil Dynamique": "147,0775689"
    },
    {
        "Date": "29/10/2021",
        "Profil Agressif": "177,7690794",
        "Profil Prudent": "112,5947251",
        "Profil Modéré": "127,0624719",
        "Profil Dynamique": "147,8763941"
    },
    {
        "Date": "05/11/2021",
        "Profil Agressif": "176,3325513",
        "Profil Prudent": "112,6302182",
        "Profil Modéré": "127,159504",
        "Profil Dynamique": "147,4764763"
    },
    {
        "Date": "12/11/2021",
        "Profil Agressif": "175,5755687",
        "Profil Prudent": "112,6620013",
        "Profil Modéré": "127,4871294",
        "Profil Dynamique": "147,4626106"
    },
    {
        "Date": "19/11/2021",
        "Profil Agressif": "175,9005528",
        "Profil Prudent": "112,6911286",
        "Profil Modéré": "127,6057786",
        "Profil Dynamique": "147,6531528"
    },
    {
        "Date": "26/11/2021",
        "Profil Agressif": "174,3270891",
        "Profil Prudent": "112,7258413",
        "Profil Modéré": "127,2553064",
        "Profil Dynamique": "146,9069379"
    },
    {
        "Date": "03/12/2021",
        "Profil Agressif": "173,9447537",
        "Profil Prudent": "112,7477519",
        "Profil Modéré": "127,0305462",
        "Profil Dynamique": "146,5510459"
    },
    {
        "Date": "10/12/2021",
        "Profil Agressif": "175,5126365",
        "Profil Prudent": "112,7768295",
        "Profil Modéré": "127,3699954",
        "Profil Dynamique": "147,3637198"
    },
    {
        "Date": "17/12/2021",
        "Profil Agressif": "175,2485485",
        "Profil Prudent": "112,8017588",
        "Profil Modéré": "127,5050013",
        "Profil Dynamique": "147,3864897"
    },
    {
        "Date": "24/12/2021",
        "Profil Agressif": "174,6391397",
        "Profil Prudent": "112,8296913",
        "Profil Modéré": "127,476752",
        "Profil Dynamique": "147,2345021"
    },
    {
        "Date": "31/12/2021",
        "Profil Agressif": "176,0994644",
        "Profil Prudent": "112,8556394",
        "Profil Modéré": "127,7077314",
        "Profil Dynamique": "147,9109618"
    },
    {
        "Date": "07/01/2022",
        "Profil Agressif": "177,2762386",
        "Profil Prudent": "112,8883342",
        "Profil Modéré": "127,8085479",
        "Profil Dynamique": "148,383174"
    },
    {
        "Date": "14/01/2022",
        "Profil Agressif": "180,4733074",
        "Profil Prudent": "112,9203993",
        "Profil Modéré": "128,1579481",
        "Profil Dynamique": "149,6683771"
    },
    {
        "Date": "21/01/2022",
        "Profil Agressif": "180,3697867",
        "Profil Prudent": "112,9474967",
        "Profil Modéré": "128,3254666",
        "Profil Dynamique": "149,8257608"
    },
    {
        "Date": "28/01/2022",
        "Profil Agressif": "180,0001585",
        "Profil Prudent": "112,9710841",
        "Profil Modéré": "128,1613017",
        "Profil Dynamique": "149,5467893"
    },
    {
        "Date": "04/02/2022",
        "Profil Agressif": "181,2862166",
        "Profil Prudent": "112,9981478",
        "Profil Modéré": "128,2655842",
        "Profil Dynamique": "150,052215"
    },
    {
        "Date": "11/02/2022",
        "Profil Agressif": "181,5113278",
        "Profil Prudent": "113,0305226",
        "Profil Modéré": "128,5122127",
        "Profil Dynamique": "150,3241054"
    },
    {
        "Date": "18/02/2022",
        "Profil Agressif": "179,7645112",
        "Profil Prudent": "113,0681359",
        "Profil Modéré": "128,8236056",
        "Profil Dynamique": "149,988223"
    },
    {
        "Date": "25/02/2022",
        "Profil Agressif": "173,6235479",
        "Profil Prudent": "113,0953184",
        "Profil Modéré": "128,3327634",
        "Profil Dynamique": "147,6485485"
    },
    {
        "Date": "04/03/2022",
        "Profil Agressif": "170,3808899",
        "Profil Prudent": "113,1265234",
        "Profil Modéré": "128,5409231",
        "Profil Dynamique": "146,7197821"
    },
    {
        "Date": "11/03/2022",
        "Profil Agressif": "169,0460343",
        "Profil Prudent": "113,1625392",
        "Profil Modéré": "128,6360476",
        "Profil Dynamique": "146,2959021"
    },
    {
        "Date": "18/03/2022",
        "Profil Agressif": "171,6981045",
        "Profil Prudent": "113,1861901",
        "Profil Modéré": "128,7778155",
        "Profil Dynamique": "147,2777078"
    },
    {
        "Date": "25/03/2022",
        "Profil Agressif": "169,0706405",
        "Profil Prudent": "113,2173682",
        "Profil Modéré": "128,5359075",
        "Profil Dynamique": "146,2530454"
    },
    {
        "Date": "01/04/2022",
        "Profil Agressif": "171,2483632",
        "Profil Prudent": "113,2452825",
        "Profil Modéré": "128,7100867",
        "Profil Dynamique": "147,1203224"
    },
    {
        "Date": "08/04/2022",
        "Profil Agressif": "172,1429303",
        "Profil Prudent": "113,2726539",
        "Profil Modéré": "128,9529178",
        "Profil Dynamique": "147,6470279"
    },
    {
        "Date": "15/04/2022",
        "Profil Agressif": "172,8537779",
        "Profil Prudent": "113,302129",
        "Profil Modéré": "129,2185087",
        "Profil Dynamique": "148,0443084"
    },
    {
        "Date": "22/04/2022",
        "Profil Agressif": "174,7682217",
        "Profil Prudent": "113,3273241",
        "Profil Modéré": "129,3101151",
        "Profil Dynamique": "148,741515"
    },
    {
        "Date": "29/04/2022",
        "Profil Agressif": "174,3108607",
        "Profil Prudent": "113,35923",
        "Profil Modéré": "129,3226878",
        "Profil Dynamique": "148,6292864"
    },
    {
        "Date": "06/05/2022",
        "Profil Agressif": "173,4278722",
        "Profil Prudent": "113,3817861",
        "Profil Modéré": "129,0888085",
        "Profil Dynamique": "148,1873637"
    },
    {
        "Date": "13/05/2022",
        "Profil Agressif": "169,1034279",
        "Profil Prudent": "113,399513",
        "Profil Modéré": "128,3774027",
        "Profil Dynamique": "146,1945478"
    },
    {
        "Date": "20/05/2022",
        "Profil Agressif": "168,6047844",
        "Profil Prudent": "113,4398647",
        "Profil Modéré": "128,3721693",
        "Profil Dynamique": "146,0256395"
    },
    {
        "Date": "27/05/2022",
        "Profil Agressif": "165,5030779",
        "Profil Prudent": "113,4638092",
        "Profil Modéré": "128,0738945",
        "Profil Dynamique": "144,7488485"
    },
    {
        "Date": "03/06/2022",
        "Profil Agressif": "168,723913",
        "Profil Prudent": "113,4868007",
        "Profil Modéré": "128,3670917",
        "Profil Dynamique": "146,0696055"
    },
    {
        "Date": "10/06/2022",
        "Profil Agressif": "168,5159577",
        "Profil Prudent": "113,520969",
        "Profil Modéré": "128,3620528",
        "Profil Dynamique": "146,0340299"
    },
    {
        "Date": "17/06/2022",
        "Profil Agressif": "163,7559251",
        "Profil Prudent": "113,5513691",
        "Profil Modéré": "128,1685612",
        "Profil Dynamique": "144,3570632"
    },
    {
        "Date": "24/06/2022",
        "Profil Agressif": "163,1356566",
        "Profil Prudent": "113,575892",
        "Profil Modéré": "127,9491819",
        "Profil Dynamique": "143,8637676"
    },
    {
        "Date": "01/07/2022",
        "Profil Agressif": "159,9361194",
        "Profil Prudent": "113,6100315",
        "Profil Modéré": "127,5130292",
        "Profil Dynamique": "142,4983928"
    },
    {
        "Date": "08/07/2022",
        "Profil Agressif": "160,2090397",
        "Profil Prudent": "113,6445905",
        "Profil Modéré": "127,3804189",
        "Profil Dynamique": "142,5085342"
    },
    {
        "Date": "15/07/2022",
        "Profil Agressif": "162,0543818",
        "Profil Prudent": "113,6548159",
        "Profil Modéré": "127,6192977",
        "Profil Dynamique": "143,4110687"
    },
    {
        "Date": "22/07/2022",
        "Profil Agressif": "163,2523647",
        "Profil Prudent": "113,6882808",
        "Profil Modéré": "127,8653662",
        "Profil Dynamique": "144,0122019"
    },
    {
        "Date": "29/07/2022",
        "Profil Agressif": "162,2056605",
        "Profil Prudent": "113,7158985",
        "Profil Modéré": "127,894448",
        "Profil Dynamique": "143,6754866"
    },
    {
        "Date": "05/08/2022",
        "Profil Agressif": "163,789571",
        "Profil Prudent": "113,7401938",
        "Profil Modéré": "128,2573368",
        "Profil Dynamique": "144,5186523"
    },
    {
        "Date": "12/08/2022",
        "Profil Agressif": "166,0390753",
        "Profil Prudent": "113,7781663",
        "Profil Modéré": "128,6849765",
        "Profil Dynamique": "145,6309033"
    },
    {
        "Date": "19/08/2022",
        "Profil Agressif": "166,1321565",
        "Profil Prudent": "113,7895904",
        "Profil Modéré": "128,5773688",
        "Profil Dynamique": "145,6030256"
    },
    {
        "Date": "26/08/2022",
        "Profil Agressif": "166,2172311",
        "Profil Prudent": "113,8033815",
        "Profil Modéré": "128,6925525",
        "Profil Dynamique": "145,6955267"
    },
    {
        "Date": "02/09/2022",
        "Profil Agressif": "165,2517674",
        "Profil Prudent": "113,8395319",
        "Profil Modéré": "128,5005513",
        "Profil Dynamique": "145,236884"
    },
    {
        "Date": "09/09/2022",
        "Profil Agressif": "165,8318038",
        "Profil Prudent": "113,8508954",
        "Profil Modéré": "128,6449532",
        "Profil Dynamique": "145,5012087"
    },
    {
        "Date": "16/09/2022",
        "Profil Agressif": "166,0060738",
        "Profil Prudent": "113,8855164",
        "Profil Modéré": "128,3449502",
        "Profil Dynamique": "145,3038447"
    },
    {
        "Date": "23/09/2022",
        "Profil Agressif": "166,7003158",
        "Profil Prudent": "113,9192592",
        "Profil Modéré": "128,348079",
        "Profil Dynamique": "145,4976979"
    },
    {
        "Date": "30/09/2022",
        "Profil Agressif": "159,8715848",
        "Profil Prudent": "113,8941693",
        "Profil Modéré": "127,5684165",
        "Profil Dynamique": "142,4593302"
    },
    {
        "Date": "07/10/2022",
        "Profil Agressif": "160,5478093",
        "Profil Prudent": "113,9032344",
        "Profil Modéré": "127,7165802",
        "Profil Dynamique": "142,7419513"
    },
    {
        "Date": "14/10/2022",
        "Profil Agressif": "157,3947926",
        "Profil Prudent": "113,9337221",
        "Profil Modéré": "127,1993622",
        "Profil Dynamique": "141,3320105"
    },
    {
        "Date": "21/10/2022",
        "Profil Agressif": "152,3621319",
        "Profil Prudent": "113,9840983",
        "Profil Modéré": "126,7469595",
        "Profil Dynamique": "139,3025797"
    },
    {
        "Date": "28/10/2022",
        "Profil Agressif": "151,1513723",
        "Profil Prudent": "114,0037857",
        "Profil Modéré": "126,6032584",
        "Profil Dynamique": "138,8333922"
    },
    {
        "Date": "04/11/2022",
        "Profil Agressif": "150,4405406",
        "Profil Prudent": "114,0505599",
        "Profil Modéré": "126,8036491",
        "Profil Dynamique": "138,7780072"
    },
    {
        "Date": "11/11/2022",
        "Profil Agressif": "146,818695",
        "Profil Prudent": "114,0376948",
        "Profil Modéré": "126,6948574",
        "Profil Dynamique": "137,5309672"
    },
    {
        "Date": "21/11/2022",
        "Profil Agressif": "150,7721866",
        "Profil Prudent": "114,0906448",
        "Profil Modéré": "126,8028003",
        "Profil Dynamique": "138,9336538"
    },
    {
        "Date": "25/11/2022",
        "Profil Agressif": "151,3825622",
        "Profil Prudent": "114,097044",
        "Profil Modéré": "126,8110122",
        "Profil Dynamique": "139,1273912"
    },
    {
        "Date": "02/12/2022",
        "Profil Agressif": "153,5361042",
        "Profil Prudent": "114,1372305",
        "Profil Modéré": "127,2390017",
        "Profil Dynamique": "140,1601072"
    },
    {
        "Date": "09/12/2022",
        "Profil Agressif": "156,1400682",
        "Profil Prudent": "114,1684849",
        "Profil Modéré": "127,4521322",
        "Profil Dynamique": "141,1126095"
    },
    {
        "Date": "16/12/2022",
        "Profil Agressif": "153,7227105",
        "Profil Prudent": "114,2063076",
        "Profil Modéré": "127,1979268",
        "Profil Dynamique": "140,1123749"
    },
    {
        "Date": "23/12/2022",
        "Profil Agressif": "152,7568679",
        "Profil Prudent": "114,2280115",
        "Profil Modéré": "127,1862433",
        "Profil Dynamique": "139,8398483"
    },
    {
        "Date": "30/12/2022",
        "Profil Agressif": "149,7862184",
        "Profil Prudent": "114,2400929",
        "Profil Modéré": "126,9808498",
        "Profil Dynamique": "138,7955424"
    },
    {
        "Date": "06/01/2023",
        "Profil Agressif": "138,7218898",
        "Profil Prudent": "114,1652415",
        "Profil Modéré": "124,8867988",
        "Profil Dynamique": "133,2491339"
    },
    {
        "Date": "13/01/2023",
        "Profil Agressif": "144,8022748",
        "Profil Prudent": "114,2276611",
        "Profil Modéré": "125,5217348",
        "Profil Dynamique": "135,7677644"
    },
    {
        "Date": "20/01/2023",
        "Profil Agressif": "144,0387951",
        "Profil Prudent": "114,2995423",
        "Profil Modéré": "125,844353",
        "Profil Dynamique": "135,8041797"
    },
    {
        "Date": "27/01/2023",
        "Profil Agressif": "142,6701628",
        "Profil Prudent": "114,3826983",
        "Profil Modéré": "125,7949747",
        "Profil Dynamique": "135,3112085"
    },
    {
        "Date": "03/02/2023",
        "Profil Agressif": "145,667901",
        "Profil Prudent": "114,4480345",
        "Profil Modéré": "125,9985237",
        "Profil Dynamique": "136,464588"
    },
    {
        "Date": "10/02/2023",
        "Profil Agressif": "149,4146891",
        "Profil Prudent": "114,5197063",
        "Profil Modéré": "126,2540779",
        "Profil Dynamique": "137,8510971"
    },
    {
        "Date": "17/02/2023",
        "Profil Agressif": "148,8499151",
        "Profil Prudent": "114,5884148",
        "Profil Modéré": "126,1593504",
        "Profil Dynamique": "137,5393268"
    },
    {
        "Date": "24/02/2023",
        "Profil Agressif": "150,5902248",
        "Profil Prudent": "114,6544697",
        "Profil Modéré": "126,2193926",
        "Profil Dynamique": "138,2105159"
    },
    {
        "Date": "03/03/2023",
        "Profil Agressif": "149,3015698",
        "Profil Prudent": "114,7189914",
        "Profil Modéré": "126,3215368",
        "Profil Dynamique": "137,8567771"
    },
    {
        "Date": "10/03/2023",
        "Profil Agressif": "147,855414",
        "Profil Prudent": "114,7831831",
        "Profil Modéré": "126,4196736",
        "Profil Dynamique": "137,3957925"
    },
    {
        "Date": "17/03/2023",
        "Profil Agressif": "147,0978504",
        "Profil Prudent": "114,8454778",
        "Profil Modéré": "126,9952123",
        "Profil Dynamique": "137,6411293"
    },
    {
        "Date": "24/03/2023",
        "Profil Agressif": "146,9407274",
        "Profil Prudent": "114,9006388",
        "Profil Modéré": "127,1044747",
        "Profil Dynamique": "137,7102766"
    },
    {
        "Date": "31/03/2023",
        "Profil Agressif": "146,6527392",
        "Profil Prudent": "114,9443354",
        "Profil Modéré": "126,8755155",
        "Profil Dynamique": "137,3228995"
    },
    {
        "Date": "07/04/2023",
        "Profil Agressif": "147,5043741",
        "Profil Prudent": "115,0006691",
        "Profil Modéré": "127,0165653",
        "Profil Dynamique": "137,6630511"
    },
    {
        "Date": "14/04/2023",
        "Profil Agressif": "147,5300217",
        "Profil Prudent": "115,065117",
        "Profil Modéré": "127,2674764",
        "Profil Dynamique": "137,9470993"
    },
    {
        "Date": "21/04/2023",
        "Profil Agressif": "147,3753762",
        "Profil Prudent": "115,1511913",
        "Profil Modéré": "126,9183264",
        "Profil Dynamique": "137,6248312"
    },
    {
        "Date": "28/04/2023",
        "Profil Agressif": "147,4771048",
        "Profil Prudent": "115,2192387",
        "Profil Modéré": "127,0530262",
        "Profil Dynamique": "137,7970149"
    },
    {
        "Date": "05/05/2023",
        "Profil Agressif": "146,0782117",
        "Profil Prudent": "115,2938116",
        "Profil Modéré": "127,0107637",
        "Profil Dynamique": "137,3296953"
    },
    {
        "Date": "12/05/2023",
        "Profil Agressif": "149,7453976",
        "Profil Prudent": "115,3688619",
        "Profil Modéré": "127,6690971",
        "Profil Dynamique": "139,0175065"
    },
    {
        "Date": "19/05/2023",
        "Profil Agressif": "150,0685084",
        "Profil Prudent": "115,4649218",
        "Profil Modéré": "127,7430377",
        "Profil Dynamique": "139,2078921"
    },
    {
        "Date": "26/05/2023",
        "Profil Agressif": "152,0776147",
        "Profil Prudent": "115,5304302",
        "Profil Modéré": "127,9362796",
        "Profil Dynamique": "140,0416254"
    },
    {
        "Date": "02/06/2023",
        "Profil Agressif": "154,0897336",
        "Profil Prudent": "115,5922342",
        "Profil Modéré": "128,3113328",
        "Profil Dynamique": "140,9923756"
    },
    {
        "Date": "09/06/2023",
        "Profil Agressif": "152,8564994",
        "Profil Prudent": "115,6534197",
        "Profil Modéré": "128,1829856",
        "Profil Dynamique": "140,5472769"
    },
    {
        "Date": "16/06/2023",
        "Profil Agressif": "156,5976516",
        "Profil Prudent": "115,7078657",
        "Profil Modéré": "128,4166193",
        "Profil Dynamique": "141,860058"
    },
    {
        "Date": "23/06/2023",
        "Profil Agressif": "157,5452542",
        "Profil Prudent": "115,8413875",
        "Profil Modéré": "129,0894332",
        "Profil Dynamique": "142,8451636"
    },
    {
        "Date": "03/07/2023",
        "Profil Agressif": "158,3311503",
        "Profil Prudent": "115,9401766",
        "Profil Modéré": "129,1984115",
        "Profil Dynamique": "143,2991212"
    },
    {
        "Date": "07/07/2023",
        "Profil Agressif": "159,0942268",
        "Profil Prudent": "115,9492987",
        "Profil Modéré": "129,1423972",
        "Profil Dynamique": "143,5078008"
    },
    {
        "Date": "14/07/2023",
        "Profil Agressif": "159,7607231",
        "Profil Prudent": "116,0141901",
        "Profil Modéré": "129,3505468",
        "Profil Dynamique": "143,9466489"
    },
    {
        "Date": "21/07/2023",
        "Profil Agressif": "162,7029103",
        "Profil Prudent": "116,0800836",
        "Profil Modéré": "129,8365964",
        "Profil Dynamique": "145,323856"
    },
    {
        "Date": "28/07/2023",
        "Profil Agressif": "165,5517798",
        "Profil Prudent": "116,1528584",
        "Profil Modéré": "130,1726935",
        "Profil Dynamique": "146,5200954"
    },
    {
        "Date": "04/08/2023",
        "Profil Agressif": "166,1707574",
        "Profil Prudent": "116,2383092",
        "Profil Modéré": "130,3766529",
        "Profil Dynamique": "146,8623579"
    },
    {
        "Date": "11/08/2023",
        "Profil Agressif": "164,9314729",
        "Profil Prudent": "116,2935534",
        "Profil Modéré": "130,2363186",
        "Profil Dynamique": "146,3040562"
    },
    {
        "Date": "18/08/2023",
        "Profil Agressif": "164,6020901",
        "Profil Prudent": "116,3442329",
        "Profil Modéré": "130,2398391",
        "Profil Dynamique": "146,2276361"
    },
    {
        "Date": "25/08/2023",
        "Profil Agressif": "164,1570284",
        "Profil Prudent": "116,4071186",
        "Profil Modéré": "130,5197643",
        "Profil Dynamique": "146,268102"
    },
    {
        "Date": "01/09/2023",
        "Profil Agressif": "164,1087569",
        "Profil Prudent": "116,4710701",
        "Profil Modéré": "130,804578",
        "Profil Dynamique": "146,5019075"
    },
    {
        "Date": "08/09/2023",
        "Profil Agressif": "162,1834726",
        "Profil Prudent": "116,5351959",
        "Profil Modéré": "130,6388523",
        "Profil Dynamique": "145,7142237"
    },
    {
        "Date": "15/09/2023",
        "Profil Agressif": "162,7359194",
        "Profil Prudent": "116,5993793",
        "Profil Modéré": "130,8972191",
        "Profil Dynamique": "146,1094528"
    },
    {
        "Date": "22/09/2023",
        "Profil Agressif": "162,646971",
        "Profil Prudent": "116,6548984",
        "Profil Modéré": "130,7278976",
        "Profil Dynamique": "145,8927481"
    },
    {
        "Date": "02/10/2023",
        "Profil Agressif": "162,079612",
        "Profil Prudent": "116,7654287",
        "Profil Modéré": "130,5427744",
        "Profil Dynamique": "145,6820418"
    },
    {
        "Date": "06/10/2023",
        "Profil Agressif": "167,1019971",
        "Profil Prudent": "116,8225725",
        "Profil Modéré": "131,0530452",
        "Profil Dynamique": "147,9231955"
    },
    {
        "Date": "13/10/2023",
        "Profil Agressif": "167,0681628",
        "Profil Prudent": "116,876885",
        "Profil Modéré": "131,6119182",
        "Profil Dynamique": "148,3676722"
    },
    {
        "Date": "20/10/2023",
        "Profil Agressif": "167,6148241",
        "Profil Prudent": "116,9844501",
        "Profil Modéré": "132,2808114",
        "Profil Dynamique": "149,1631756"
    },
    {
        "Date": "27/10/2023",
        "Profil Agressif": "164,9433549",
        "Profil Prudent": "117,0206102",
        "Profil Modéré": "132,0801866",
        "Profil Dynamique": "148,1490885"
    },
    {
        "Date": "03/11/2023",
        "Profil Agressif": "164,8826241",
        "Profil Prudent": "117,0768363",
        "Profil Modéré": "132,1528792",
        "Profil Dynamique": "148,1667929"
    },
    {
        "Date": "10/11/2023",
        "Profil Agressif": "164,0912292",
        "Profil Prudent": "117,1390628",
        "Profil Modéré": "131,8405163",
        "Profil Dynamique": "147,6724972"
    },
    {
        "Date": "17/11/2023",
        "Profil Agressif": "164,1527926",
        "Profil Prudent": "117,2065602",
        "Profil Modéré": "132,1638886",
        "Profil Dynamique": "147,998434"
    },
    {
        "Date": "24/11/2023",
        "Profil Agressif": "164,2071882",
        "Profil Prudent": "117,27058",
        "Profil Modéré": "132,4479789",
        "Profil Dynamique": "148,2648723"
    },
    {
        "Date": "01/12/2023",
        "Profil Agressif": "162,5734972",
        "Profil Prudent": "117,3335448",
        "Profil Modéré": "132,7311902",
        "Profil Dynamique": "148,0054603"
    },
    {
        "Date": "08/12/2023",
        "Profil Agressif": "163,8107886",
        "Profil Prudent": "117,3928025",
        "Profil Modéré": "132,7644045",
        "Profil Dynamique": "148,4198439"
    },
    {
        "Date": "15/12/2023",
        "Profil Agressif": "166,3713851",
        "Profil Prudent": "117,463881",
        "Profil Modéré": "133,35763",
        "Profil Dynamique": "149,7966622"
    },
    {
        "Date": "22/12/2023",
        "Profil Agressif": "166,0492679",
        "Profil Prudent": "117,5316165",
        "Profil Modéré": "133,2909493",
        "Profil Dynamique": "149,6740653"
    },
    {
        "Date": "29/12/2023",
        "Profil Agressif": "165,3460517",
        "Profil Prudent": "117,5872244",
        "Profil Modéré": "133,4280321",
        "Profil Dynamique": "149,4663921"
    },
    {
        "Date": "05/01/2024",
        "Profil Agressif": "167,9021312",
        "Profil Prudent": "117,687482",
        "Profil Modéré": "133,8427984",
        "Profil Dynamique": "150,7074251"
    },
    {
        "Date": "12/01/2024",
        "Profil Agressif": "169,3405443",
        "Profil Prudent": "117,759702",
        "Profil Modéré": "134,4484088",
        "Profil Dynamique": "151,8441996"
    },
    {
        "Date": "19/01/2024",
        "Profil Agressif": "170,7764123",
        "Profil Prudent": "117,7888437",
        "Profil Modéré": "134,425077",
        "Profil Dynamique": "152,3466305"
    },
    {
        "Date": "26/01/2024",
        "Profil Agressif": "170,2591935",
        "Profil Prudent": "117,8168737",
        "Profil Modéré": "134,179121",
        "Profil Dynamique": "151,9193653"
    },
    {
        "Date": "02/02/2024",
        "Profil Agressif": "169,1866208",
        "Profil Prudent": "117,8763748",
        "Profil Modéré": "134,3209501",
        "Profil Dynamique": "151,7190072"
    },
    {
        "Date": "09/02/2024",
        "Profil Agressif": "171,0396173",
        "Profil Prudent": "117,9373583",
        "Profil Modéré": "134,5604598",
        "Profil Dynamique": "152,6905412"
    },
    {
        "Date": "16/02/2024",
        "Profil Agressif": "173,1565985",
        "Profil Prudent": "118,0132286",
        "Profil Modéré": "134,7333931",
        "Profil Dynamique": "153,433896"
    }
];

  const option = {
    animationDuration: 10000,
    title: {
      text: 'Comparaison des profils investisseurs',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    legend: {
      data: ['Profil Prudent', 'Profil Modéré','Profil Dynamique','Profil Agressif'],
      bottom: 10,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map(item => item.Date),
      nameLocation: 'middle'
    },
    yAxis: {
      type: 'value',
      min : 100,
      interval: 5,
    },
    series: [
      
      
      {
        name: 'Profil Prudent',
        type: 'line',
        data: data.map(item => parseFloat(item["Profil Prudent"])),
        smooth: true,
        
      },
      {
        name: 'Profil Modéré',
        type: 'line',
        data: data.map(item => parseFloat(item["Profil Modéré"])),
        smooth: true,
       
        
      },
      {
        name: 'Profil Dynamique',
        type: 'line',
        data: data.map(item => parseFloat(item["Profil Dynamique"])),
        smooth: true,
        
      },
      {
        name: 'Profil Agressif',
        type: 'line',
        data: data.map(item => parseFloat(item["Profil Agressif"])),
        smooth: true,
        
      },
    ],
  };

  return (
  <Row className="g-3">
  <Col>
      
              <ReactECharts option={option} style={{height: 600}} />
          
  </Col>
</Row>);
};

export default LineChart;
