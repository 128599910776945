import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import QuestionnaireINV from "./Questionnaire/QuestionnaireINV";
import Souscription from "./views/souscription";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Echarts from '../src/views/echarts';
import RoboAdvisor from '../src/views/roboadvisor/roboadvisor';
import Line from '../src/components/echarts/lineopcvm';
import TestLine from '../src/components/echarts/testline';
import Prudent from '../src/components/echarts/prudent';
import Modere from '../src/components/echarts/modere';
import Agressif from '../src/components/echarts/agressif';
import StatP from '../src/components/statistiques/prudent';
import Stata from '../src/components/statistiques/agressif';
import StatM from '../src/components/statistiques/modere';
import Statistiques from '../src/views/statistiques';
import Comparaison from './components/statistiques/comparaisonG';
import Accordion from '../src/components/accordionpanel';
import ChartActifsP from '../src/components/charts/prudent/chart-actif'
import Dynamique from '../src/components/echarts/dynamique';
import ChartActifM from '../src/components/charts/modéré/chart-actifs';
import ChartActifA from '../src/components/charts/agressif/chart-actifs';
import ChartActifD from '../src/components/charts/dynamique/chart-actifs';



import "./App.css";

import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
            <Route path="/echarts" component={Echarts} />
            <Route path="/roboadvisor" component={RoboAdvisor} />
            <Route path="/souscription" component={Souscription} />
            <Route path="/QuestionnaireRET" render={() => <Redirect to="/QuestionnaireRET" />} />
            <Route path="/QuestionnaireEDU" render={() => <Redirect to="/QuestionnaireEDU" />} />
            <Route path="/QuestionnaireINV" render={() => <Redirect to="/QuestionnaireINV" />} component={QuestionnaireINV} />
            <Route path="/QuestionnaireUC" render={() => <Redirect to="/QuestionnaireUC" />} />
            <Route path="/line" render={() => <Redirect to="/Line" />} component={Line} />
            <Route path="/testline" render={() => <Redirect to="/testline" />} component={TestLine} />
            <Route path="/prudent" render={() => <Redirect to="/prudent" />} component={Prudent} />
            <Route path="/modere" render={() => <Redirect to="/modere" />} component={Modere} />
            <Route path="/agressif" render={() => <Redirect to="/agressif" />} component={Agressif} />
            <Route path="/stata" render={() => <Redirect to="/stata" />} component={Stata} />
            <Route path="/statP" render={() => <Redirect to="/statP" />} component={StatP} />
            <Route path="/statM" render={() => <Redirect to="/statM" />} component={StatM} />
            <Route path="/statistiques" render={() => <Redirect to="/statistiques" />} component={Statistiques} />
            <Route path="/comparaison" render={() => <Redirect to="/comparaison" />} component={Comparaison} />
            <Route path="/accordion" render={() => <Redirect to="/accordion" />} component={Accordion} />
            <Route path="/actifp" render={() => <Redirect to="/actifp" />} component={ChartActifsP} />
            <Route path="/actifm" render={() => <Redirect to="/actifm" />} component={ChartActifM} />
            <Route path="/actifa" render={() => <Redirect to="/actifa" />} component={ChartActifA} />
            <Route path="/actifd" render={() => <Redirect to="/actifd" />} component={ChartActifD} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;