import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
  ]);
  
  const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "28/12/2015",
                "Profil Dynamique": "100",
                "OPC DIVERSIFIE": "100"
            },
            {
                "Date": "04/01/2016",
                "Profil Dynamique": "100,0365654",
                "OPC DIVERSIFIE": "100,0309613"
            },
            {
                "Date": "08/01/2016",
                "Profil Dynamique": "99,99601653",
                "OPC DIVERSIFIE": "100,0241885"
            },
            {
                "Date": "15/01/2016",
                "Profil Dynamique": "99,89428816",
                "OPC DIVERSIFIE": "100,1576911"
            },
            {
                "Date": "22/01/2016",
                "Profil Dynamique": "100,0868895",
                "OPC DIVERSIFIE": "100,3569724"
            },
            {
                "Date": "29/01/2016",
                "Profil Dynamique": "100,3724224",
                "OPC DIVERSIFIE": "100,5824369"
            },
            {
                "Date": "05/02/2016",
                "Profil Dynamique": "100,9967306",
                "OPC DIVERSIFIE": "101,0004925"
            },
            {
                "Date": "12/02/2016",
                "Profil Dynamique": "101,6795812",
                "OPC DIVERSIFIE": "101,1621205"
            },
            {
                "Date": "19/02/2016",
                "Profil Dynamique": "102,3945174",
                "OPC DIVERSIFIE": "101,5781156"
            },
            {
                "Date": "26/02/2016",
                "Profil Dynamique": "102,0651396",
                "OPC DIVERSIFIE": "101,2254875"
            },
            {
                "Date": "04/03/2016",
                "Profil Dynamique": "102,8378656",
                "OPC DIVERSIFIE": "101,5668682"
            },
            {
                "Date": "11/03/2016",
                "Profil Dynamique": "103,0157203",
                "OPC DIVERSIFIE": "101,9140458"
            },
            {
                "Date": "18/03/2016",
                "Profil Dynamique": "103,2621914",
                "OPC DIVERSIFIE": "102,3045119"
            },
            {
                "Date": "25/03/2016",
                "Profil Dynamique": "103,6985396",
                "OPC DIVERSIFIE": "102,7519339"
            },
            {
                "Date": "01/04/2016",
                "Profil Dynamique": "103,2160248",
                "OPC DIVERSIFIE": "102,6019346"
            },
            {
                "Date": "08/04/2016",
                "Profil Dynamique": "103,6309009",
                "OPC DIVERSIFIE": "102,9206079"
            },
            {
                "Date": "15/04/2016",
                "Profil Dynamique": "104,4521782",
                "OPC DIVERSIFIE": "103,5615505"
            },
            {
                "Date": "22/04/2016",
                "Profil Dynamique": "104,7369696",
                "OPC DIVERSIFIE": "103,7522526"
            },
            {
                "Date": "29/04/2016",
                "Profil Dynamique": "106,1271648",
                "OPC DIVERSIFIE": "104,7395027"
            },
            {
                "Date": "06/05/2016",
                "Profil Dynamique": "106,934654",
                "OPC DIVERSIFIE": "105,2729547"
            },
            {
                "Date": "13/05/2016",
                "Profil Dynamique": "107,1381854",
                "OPC DIVERSIFIE": "105,183788"
            },
            {
                "Date": "20/05/2016",
                "Profil Dynamique": "106,5868494",
                "OPC DIVERSIFIE": "104,8454113"
            },
            {
                "Date": "27/05/2016",
                "Profil Dynamique": "106,1123077",
                "OPC DIVERSIFIE": "104,7385989"
            },
            {
                "Date": "03/06/2016",
                "Profil Dynamique": "106,6304324",
                "OPC DIVERSIFIE": "104,944959"
            },
            {
                "Date": "10/06/2016",
                "Profil Dynamique": "106,7341385",
                "OPC DIVERSIFIE": "104,8930679"
            },
            {
                "Date": "17/06/2016",
                "Profil Dynamique": "106,7086452",
                "OPC DIVERSIFIE": "104,7622238"
            },
            {
                "Date": "24/06/2016",
                "Profil Dynamique": "107,0406107",
                "OPC DIVERSIFIE": "104,6852652"
            },
            {
                "Date": "01/07/2016",
                "Profil Dynamique": "107,2373132",
                "OPC DIVERSIFIE": "104,7314431"
            },
            {
                "Date": "11/07/2016",
                "Profil Dynamique": "107,6325517",
                "OPC DIVERSIFIE": "104,8739685"
            },
            {
                "Date": "15/07/2016",
                "Profil Dynamique": "107,9248651",
                "OPC DIVERSIFIE": "105,3629384"
            },
            {
                "Date": "22/07/2016",
                "Profil Dynamique": "108,1898696",
                "OPC DIVERSIFIE": "105,5912874"
            },
            {
                "Date": "29/07/2016",
                "Profil Dynamique": "108,4771495",
                "OPC DIVERSIFIE": "105,7237764"
            },
            {
                "Date": "05/08/2016",
                "Profil Dynamique": "108,6034548",
                "OPC DIVERSIFIE": "105,8887054"
            },
            {
                "Date": "12/08/2016",
                "Profil Dynamique": "108,5018903",
                "OPC DIVERSIFIE": "105,7620112"
            },
            {
                "Date": "19/08/2016",
                "Profil Dynamique": "108,4422724",
                "OPC DIVERSIFIE": "105,8373867"
            },
            {
                "Date": "26/08/2016",
                "Profil Dynamique": "108,2825304",
                "OPC DIVERSIFIE": "105,8112915"
            },
            {
                "Date": "02/09/2016",
                "Profil Dynamique": "108,3891443",
                "OPC DIVERSIFIE": "105,8726229"
            },
            {
                "Date": "09/09/2016",
                "Profil Dynamique": "108,5061772",
                "OPC DIVERSIFIE": "105,8789994"
            },
            {
                "Date": "16/09/2016",
                "Profil Dynamique": "108,344216",
                "OPC DIVERSIFIE": "105,8072446"
            },
            {
                "Date": "23/09/2016",
                "Profil Dynamique": "108,6708512",
                "OPC DIVERSIFIE": "105,9338017"
            },
            {
                "Date": "30/09/2016",
                "Profil Dynamique": "109,0905537",
                "OPC DIVERSIFIE": "106,3664702"
            },
            {
                "Date": "07/10/2016",
                "Profil Dynamique": "109,0467288",
                "OPC DIVERSIFIE": "106,8111987"
            },
            {
                "Date": "14/10/2016",
                "Profil Dynamique": "109,4564082",
                "OPC DIVERSIFIE": "107,1463707"
            },
            {
                "Date": "21/10/2016",
                "Profil Dynamique": "110,3421824",
                "OPC DIVERSIFIE": "107,7382554"
            },
            {
                "Date": "28/10/2016",
                "Profil Dynamique": "110,7138686",
                "OPC DIVERSIFIE": "108,0316099"
            },
            {
                "Date": "04/11/2016",
                "Profil Dynamique": "111,0669909",
                "OPC DIVERSIFIE": "108,1688723"
            },
            {
                "Date": "11/11/2016",
                "Profil Dynamique": "111,1785971",
                "OPC DIVERSIFIE": "108,4356959"
            },
            {
                "Date": "21/11/2016",
                "Profil Dynamique": "111,7617304",
                "OPC DIVERSIFIE": "108,9269932"
            },
            {
                "Date": "25/11/2016",
                "Profil Dynamique": "111,3486016",
                "OPC DIVERSIFIE": "108,9030484"
            },
            {
                "Date": "02/12/2016",
                "Profil Dynamique": "111,7691123",
                "OPC DIVERSIFIE": "109,2223537"
            },
            {
                "Date": "09/12/2016",
                "Profil Dynamique": "112,7946457",
                "OPC DIVERSIFIE": "109,9027193"
            },
            {
                "Date": "16/12/2016",
                "Profil Dynamique": "113,8340552",
                "OPC DIVERSIFIE": "110,7305353"
            },
            {
                "Date": "23/12/2016",
                "Profil Dynamique": "116,3986963",
                "OPC DIVERSIFIE": "112,46465"
            },
            {
                "Date": "30/12/2016",
                "Profil Dynamique": "115,4670204",
                "OPC DIVERSIFIE": "111,9300797"
            },
            {
                "Date": "06/01/2017",
                "Profil Dynamique": "119,3480107",
                "OPC DIVERSIFIE": "115,0304952"
            },
            {
                "Date": "13/01/2017",
                "Profil Dynamique": "119,7307935",
                "OPC DIVERSIFIE": "115,2814635"
            },
            {
                "Date": "20/01/2017",
                "Profil Dynamique": "119,2318293",
                "OPC DIVERSIFIE": "114,7710658"
            },
            {
                "Date": "27/01/2017",
                "Profil Dynamique": "119,0682581",
                "OPC DIVERSIFIE": "114,6377576"
            },
            {
                "Date": "03/02/2017",
                "Profil Dynamique": "118,5571302",
                "OPC DIVERSIFIE": "113,8810589"
            },
            {
                "Date": "10/02/2017",
                "Profil Dynamique": "118,7985261",
                "OPC DIVERSIFIE": "114,0417779"
            },
            {
                "Date": "17/02/2017",
                "Profil Dynamique": "118,586875",
                "OPC DIVERSIFIE": "113,6614232"
            },
            {
                "Date": "24/02/2017",
                "Profil Dynamique": "117,5612488",
                "OPC DIVERSIFIE": "112,5247936"
            },
            {
                "Date": "03/03/2017",
                "Profil Dynamique": "117,8551661",
                "OPC DIVERSIFIE": "113,0265712"
            },
            {
                "Date": "10/03/2017",
                "Profil Dynamique": "117,7588148",
                "OPC DIVERSIFIE": "113,0992823"
            },
            {
                "Date": "17/03/2017",
                "Profil Dynamique": "118,1899958",
                "OPC DIVERSIFIE": "113,3747186"
            },
            {
                "Date": "24/03/2017",
                "Profil Dynamique": "117,0135923",
                "OPC DIVERSIFIE": "112,0475256"
            },
            {
                "Date": "31/03/2017",
                "Profil Dynamique": "116,8082402",
                "OPC DIVERSIFIE": "111,8344553"
            },
            {
                "Date": "07/04/2017",
                "Profil Dynamique": "116,7954516",
                "OPC DIVERSIFIE": "111,616337"
            },
            {
                "Date": "14/04/2017",
                "Profil Dynamique": "116,5903983",
                "OPC DIVERSIFIE": "111,469176"
            },
            {
                "Date": "21/04/2017",
                "Profil Dynamique": "117,0643577",
                "OPC DIVERSIFIE": "111,8974031"
            },
            {
                "Date": "28/04/2017",
                "Profil Dynamique": "117,9420769",
                "OPC DIVERSIFIE": "112,8777741"
            },
            {
                "Date": "05/05/2017",
                "Profil Dynamique": "118,004187",
                "OPC DIVERSIFIE": "113,2342959"
            },
            {
                "Date": "12/05/2017",
                "Profil Dynamique": "118,1362937",
                "OPC DIVERSIFIE": "113,320653"
            },
            {
                "Date": "19/05/2017",
                "Profil Dynamique": "118,1733173",
                "OPC DIVERSIFIE": "113,2678284"
            },
            {
                "Date": "26/05/2017",
                "Profil Dynamique": "118,3517832",
                "OPC DIVERSIFIE": "113,2637808"
            },
            {
                "Date": "02/06/2017",
                "Profil Dynamique": "118,6497279",
                "OPC DIVERSIFIE": "113,4885114"
            },
            {
                "Date": "09/06/2017",
                "Profil Dynamique": "119,1141474",
                "OPC DIVERSIFIE": "113,8786821"
            },
            {
                "Date": "16/06/2017",
                "Profil Dynamique": "119,3021178",
                "OPC DIVERSIFIE": "114,1973993"
            },
            {
                "Date": "23/06/2017",
                "Profil Dynamique": "120,2995747",
                "OPC DIVERSIFIE": "114,9697551"
            },
            {
                "Date": "30/06/2017",
                "Profil Dynamique": "120,4227825",
                "OPC DIVERSIFIE": "115,238479"
            },
            {
                "Date": "07/07/2017",
                "Profil Dynamique": "121,6386769",
                "OPC DIVERSIFIE": "116,4796587"
            },
            {
                "Date": "14/07/2017",
                "Profil Dynamique": "121,6703647",
                "OPC DIVERSIFIE": "116,4004999"
            },
            {
                "Date": "21/07/2017",
                "Profil Dynamique": "121,0967963",
                "OPC DIVERSIFIE": "115,7697656"
            },
            {
                "Date": "28/07/2017",
                "Profil Dynamique": "122,1387238",
                "OPC DIVERSIFIE": "116,3416831"
            },
            {
                "Date": "04/08/2017",
                "Profil Dynamique": "121,6760125",
                "OPC DIVERSIFIE": "115,9272999"
            },
            {
                "Date": "11/08/2017",
                "Profil Dynamique": "122,5685565",
                "OPC DIVERSIFIE": "116,5215965"
            },
            {
                "Date": "18/08/2017",
                "Profil Dynamique": "122,7043535",
                "OPC DIVERSIFIE": "116,4711987"
            },
            {
                "Date": "25/08/2017",
                "Profil Dynamique": "122,4907116",
                "OPC DIVERSIFIE": "116,3837446"
            },
            {
                "Date": "04/09/2017",
                "Profil Dynamique": "123,3466154",
                "OPC DIVERSIFIE": "116,9588562"
            },
            {
                "Date": "08/09/2017",
                "Profil Dynamique": "124,1315257",
                "OPC DIVERSIFIE": "117,6060268"
            },
            {
                "Date": "15/09/2017",
                "Profil Dynamique": "124,5901759",
                "OPC DIVERSIFIE": "118,0126627"
            },
            {
                "Date": "25/09/2017",
                "Profil Dynamique": "123,5207653",
                "OPC DIVERSIFIE": "117,3122429"
            },
            {
                "Date": "29/09/2017",
                "Profil Dynamique": "122,9976184",
                "OPC DIVERSIFIE": "116,5347349"
            },
            {
                "Date": "06/10/2017",
                "Profil Dynamique": "123,8992936",
                "OPC DIVERSIFIE": "117,346534"
            },
            {
                "Date": "13/10/2017",
                "Profil Dynamique": "124,8300909",
                "OPC DIVERSIFIE": "117,7160774"
            },
            {
                "Date": "20/10/2017",
                "Profil Dynamique": "124,0182224",
                "OPC DIVERSIFIE": "117,1436826"
            },
            {
                "Date": "27/10/2017",
                "Profil Dynamique": "124,4506739",
                "OPC DIVERSIFIE": "117,4480725"
            },
            {
                "Date": "03/11/2017",
                "Profil Dynamique": "124,6505268",
                "OPC DIVERSIFIE": "117,6337703"
            },
            {
                "Date": "10/11/2017",
                "Profil Dynamique": "125,5129647",
                "OPC DIVERSIFIE": "118,2100298"
            },
            {
                "Date": "17/11/2017",
                "Profil Dynamique": "125,4405179",
                "OPC DIVERSIFIE": "118,1181355"
            },
            {
                "Date": "24/11/2017",
                "Profil Dynamique": "125,2453632",
                "OPC DIVERSIFIE": "118,0170136"
            },
            {
                "Date": "04/12/2017",
                "Profil Dynamique": "124,7240539",
                "OPC DIVERSIFIE": "117,6334979"
            },
            {
                "Date": "08/12/2017",
                "Profil Dynamique": "124,417123",
                "OPC DIVERSIFIE": "117,434481"
            },
            {
                "Date": "15/12/2017",
                "Profil Dynamique": "124,3557302",
                "OPC DIVERSIFIE": "117,432506"
            },
            {
                "Date": "22/12/2017",
                "Profil Dynamique": "123,6644666",
                "OPC DIVERSIFIE": "116,6641097"
            },
            {
                "Date": "29/12/2017",
                "Profil Dynamique": "124,6170341",
                "OPC DIVERSIFIE": "117,3732782"
            },
            {
                "Date": "05/01/2018",
                "Profil Dynamique": "125,1698191",
                "OPC DIVERSIFIE": "117,8755742"
            },
            {
                "Date": "12/01/2018",
                "Profil Dynamique": "125,82292",
                "OPC DIVERSIFIE": "118,4318123"
            },
            {
                "Date": "19/01/2018",
                "Profil Dynamique": "126,8221147",
                "OPC DIVERSIFIE": "119,2739362"
            },
            {
                "Date": "26/01/2018",
                "Profil Dynamique": "127,4686993",
                "OPC DIVERSIFIE": "119,6202993"
            },
            {
                "Date": "02/02/2018",
                "Profil Dynamique": "127,9091339",
                "OPC DIVERSIFIE": "120,2117258"
            },
            {
                "Date": "09/02/2018",
                "Profil Dynamique": "127,5671529",
                "OPC DIVERSIFIE": "119,7392902"
            },
            {
                "Date": "16/02/2018",
                "Profil Dynamique": "127,9103358",
                "OPC DIVERSIFIE": "119,9634248"
            },
            {
                "Date": "23/02/2018",
                "Profil Dynamique": "127,9809406",
                "OPC DIVERSIFIE": "120,0601571"
            },
            {
                "Date": "02/03/2018",
                "Profil Dynamique": "127,9282175",
                "OPC DIVERSIFIE": "120,1105928"
            },
            {
                "Date": "09/03/2018",
                "Profil Dynamique": "128,0741304",
                "OPC DIVERSIFIE": "120,2166414"
            },
            {
                "Date": "16/03/2018",
                "Profil Dynamique": "127,6160448",
                "OPC DIVERSIFIE": "119,9695673"
            },
            {
                "Date": "23/03/2018",
                "Profil Dynamique": "127,5208216",
                "OPC DIVERSIFIE": "119,5435518"
            },
            {
                "Date": "30/03/2018",
                "Profil Dynamique": "127,3585154",
                "OPC DIVERSIFIE": "119,700265"
            },
            {
                "Date": "06/04/2018",
                "Profil Dynamique": "126,9476695",
                "OPC DIVERSIFIE": "119,3555987"
            },
            {
                "Date": "13/04/2018",
                "Profil Dynamique": "127,2108971",
                "OPC DIVERSIFIE": "119,6227778"
            },
            {
                "Date": "20/04/2018",
                "Profil Dynamique": "127,2389164",
                "OPC DIVERSIFIE": "119,6547856"
            },
            {
                "Date": "27/04/2018",
                "Profil Dynamique": "127,4573981",
                "OPC DIVERSIFIE": "119,9842102"
            },
            {
                "Date": "04/05/2018",
                "Profil Dynamique": "127,2819831",
                "OPC DIVERSIFIE": "119,9799593"
            },
            {
                "Date": "11/05/2018",
                "Profil Dynamique": "126,7038492",
                "OPC DIVERSIFIE": "119,3070655"
            },
            {
                "Date": "18/05/2018",
                "Profil Dynamique": "125,7436204",
                "OPC DIVERSIFIE": "118,6372812"
            },
            {
                "Date": "25/05/2018",
                "Profil Dynamique": "125,9926398",
                "OPC DIVERSIFIE": "118,6018346"
            },
            {
                "Date": "01/06/2018",
                "Profil Dynamique": "125,171067",
                "OPC DIVERSIFIE": "117,9844036"
            },
            {
                "Date": "08/06/2018",
                "Profil Dynamique": "125,8481854",
                "OPC DIVERSIFIE": "118,5252359"
            },
            {
                "Date": "18/06/2018",
                "Profil Dynamique": "125,5247185",
                "OPC DIVERSIFIE": "118,2523232"
            },
            {
                "Date": "22/06/2018",
                "Profil Dynamique": "125,3627133",
                "OPC DIVERSIFIE": "118,1687172"
            },
            {
                "Date": "29/06/2018",
                "Profil Dynamique": "124,2966677",
                "OPC DIVERSIFIE": "117,5818419"
            },
            {
                "Date": "06/07/2018",
                "Profil Dynamique": "123,6032243",
                "OPC DIVERSIFIE": "117,1944368"
            },
            {
                "Date": "13/07/2018",
                "Profil Dynamique": "122,6460882",
                "OPC DIVERSIFIE": "116,4604968"
            },
            {
                "Date": "20/07/2018",
                "Profil Dynamique": "122,4604435",
                "OPC DIVERSIFIE": "116,426234"
            },
            {
                "Date": "27/07/2018",
                "Profil Dynamique": "123,5498382",
                "OPC DIVERSIFIE": "117,524011"
            },
            {
                "Date": "03/08/2018",
                "Profil Dynamique": "123,3587708",
                "OPC DIVERSIFIE": "117,4097799"
            },
            {
                "Date": "10/08/2018",
                "Profil Dynamique": "123,3800319",
                "OPC DIVERSIFIE": "117,4450704"
            },
            {
                "Date": "17/08/2018",
                "Profil Dynamique": "123,1283141",
                "OPC DIVERSIFIE": "117,4346118"
            },
            {
                "Date": "24/08/2018",
                "Profil Dynamique": "123,1466952",
                "OPC DIVERSIFIE": "117,3411077"
            },
            {
                "Date": "31/08/2018",
                "Profil Dynamique": "122,692837",
                "OPC DIVERSIFIE": "117,0259437"
            },
            {
                "Date": "07/09/2018",
                "Profil Dynamique": "120,8915222",
                "OPC DIVERSIFIE": "115,527272"
            },
            {
                "Date": "14/09/2018",
                "Profil Dynamique": "121,447066",
                "OPC DIVERSIFIE": "115,9019663"
            },
            {
                "Date": "21/09/2018",
                "Profil Dynamique": "121,4927722",
                "OPC DIVERSIFIE": "115,9886151"
            },
            {
                "Date": "28/09/2018",
                "Profil Dynamique": "121,90711",
                "OPC DIVERSIFIE": "116,2111734"
            },
            {
                "Date": "05/10/2018",
                "Profil Dynamique": "121,9406892",
                "OPC DIVERSIFIE": "116,0449464"
            },
            {
                "Date": "12/10/2018",
                "Profil Dynamique": "121,7315633",
                "OPC DIVERSIFIE": "115,856422"
            },
            {
                "Date": "19/10/2018",
                "Profil Dynamique": "121,8282184",
                "OPC DIVERSIFIE": "115,7121356"
            },
            {
                "Date": "26/10/2018",
                "Profil Dynamique": "121,8105785",
                "OPC DIVERSIFIE": "115,5714208"
            },
            {
                "Date": "02/11/2018",
                "Profil Dynamique": "121,2711176",
                "OPC DIVERSIFIE": "115,0041095"
            },
            {
                "Date": "09/11/2018",
                "Profil Dynamique": "121,5643276",
                "OPC DIVERSIFIE": "115,5123118"
            },
            {
                "Date": "16/11/2018",
                "Profil Dynamique": "121,6929002",
                "OPC DIVERSIFIE": "115,556526"
            },
            {
                "Date": "23/11/2018",
                "Profil Dynamique": "121,5757566",
                "OPC DIVERSIFIE": "115,4674343"
            },
            {
                "Date": "30/11/2018",
                "Profil Dynamique": "122,0690491",
                "OPC DIVERSIFIE": "116,0142563"
            },
            {
                "Date": "07/12/2018",
                "Profil Dynamique": "122,0780269",
                "OPC DIVERSIFIE": "115,9087608"
            },
            {
                "Date": "14/12/2018",
                "Profil Dynamique": "122,0689459",
                "OPC DIVERSIFIE": "115,8041802"
            },
            {
                "Date": "21/12/2018",
                "Profil Dynamique": "122,3594898",
                "OPC DIVERSIFIE": "115,8408163"
            },
            {
                "Date": "28/12/2018",
                "Profil Dynamique": "122,3613334",
                "OPC DIVERSIFIE": "115,797718"
            },
            {
                "Date": "04/01/2019",
                "Profil Dynamique": "122,3168523",
                "OPC DIVERSIFIE": "115,6908164"
            },
            {
                "Date": "14/01/2019",
                "Profil Dynamique": "122,3050398",
                "OPC DIVERSIFIE": "115,569102"
            },
            {
                "Date": "18/01/2019",
                "Profil Dynamique": "122,5939702",
                "OPC DIVERSIFIE": "116,0589394"
            },
            {
                "Date": "25/01/2019",
                "Profil Dynamique": "123,1199455",
                "OPC DIVERSIFIE": "116,5209843"
            },
            {
                "Date": "01/02/2019",
                "Profil Dynamique": "123,6716724",
                "OPC DIVERSIFIE": "116,8614647"
            },
            {
                "Date": "08/02/2019",
                "Profil Dynamique": "123,8045205",
                "OPC DIVERSIFIE": "117,0410552"
            },
            {
                "Date": "15/02/2019",
                "Profil Dynamique": "124,0839534",
                "OPC DIVERSIFIE": "117,395044"
            },
            {
                "Date": "22/02/2019",
                "Profil Dynamique": "124,0320971",
                "OPC DIVERSIFIE": "117,3843803"
            },
            {
                "Date": "01/03/2019",
                "Profil Dynamique": "123,4808607",
                "OPC DIVERSIFIE": "117,0980211"
            },
            {
                "Date": "08/03/2019",
                "Profil Dynamique": "123,2589781",
                "OPC DIVERSIFIE": "116,880347"
            },
            {
                "Date": "15/03/2019",
                "Profil Dynamique": "122,8067525",
                "OPC DIVERSIFIE": "116,4973443"
            },
            {
                "Date": "22/03/2019",
                "Profil Dynamique": "122,8691535",
                "OPC DIVERSIFIE": "116,5037576"
            },
            {
                "Date": "29/03/2019",
                "Profil Dynamique": "122,709833",
                "OPC DIVERSIFIE": "116,3416793"
            },
            {
                "Date": "05/04/2019",
                "Profil Dynamique": "122,712459",
                "OPC DIVERSIFIE": "116,3343923"
            },
            {
                "Date": "12/04/2019",
                "Profil Dynamique": "123,1213365",
                "OPC DIVERSIFIE": "116,7783968"
            },
            {
                "Date": "19/04/2019",
                "Profil Dynamique": "123,4182536",
                "OPC DIVERSIFIE": "117,0814244"
            },
            {
                "Date": "26/04/2019",
                "Profil Dynamique": "123,7075345",
                "OPC DIVERSIFIE": "117,1377851"
            },
            {
                "Date": "03/05/2019",
                "Profil Dynamique": "123,9440578",
                "OPC DIVERSIFIE": "117,3793224"
            },
            {
                "Date": "10/05/2019",
                "Profil Dynamique": "124,3547151",
                "OPC DIVERSIFIE": "117,8947507"
            },
            {
                "Date": "17/05/2019",
                "Profil Dynamique": "124,8806006",
                "OPC DIVERSIFIE": "118,2811512"
            },
            {
                "Date": "24/05/2019",
                "Profil Dynamique": "124,4656377",
                "OPC DIVERSIFIE": "118,0060854"
            },
            {
                "Date": "31/05/2019",
                "Profil Dynamique": "124,5696266",
                "OPC DIVERSIFIE": "117,8688112"
            },
            {
                "Date": "10/06/2019",
                "Profil Dynamique": "124,7846089",
                "OPC DIVERSIFIE": "117,9454372"
            },
            {
                "Date": "14/06/2019",
                "Profil Dynamique": "125,4828702",
                "OPC DIVERSIFIE": "118,3187441"
            },
            {
                "Date": "21/06/2019",
                "Profil Dynamique": "127,1548519",
                "OPC DIVERSIFIE": "119,6890186"
            },
            {
                "Date": "28/06/2019",
                "Profil Dynamique": "127,0606185",
                "OPC DIVERSIFIE": "119,419244"
            },
            {
                "Date": "05/07/2019",
                "Profil Dynamique": "127,0139088",
                "OPC DIVERSIFIE": "119,5058486"
            },
            {
                "Date": "12/07/2019",
                "Profil Dynamique": "127,5606947",
                "OPC DIVERSIFIE": "119,8824224"
            },
            {
                "Date": "19/07/2019",
                "Profil Dynamique": "128,4340022",
                "OPC DIVERSIFIE": "120,6194552"
            },
            {
                "Date": "26/07/2019",
                "Profil Dynamique": "128,666096",
                "OPC DIVERSIFIE": "121,0453277"
            },
            {
                "Date": "02/08/2019",
                "Profil Dynamique": "129,0431097",
                "OPC DIVERSIFIE": "121,204829"
            },
            {
                "Date": "09/08/2019",
                "Profil Dynamique": "129,1111629",
                "OPC DIVERSIFIE": "120,9347733"
            },
            {
                "Date": "16/08/2019",
                "Profil Dynamique": "129,5042793",
                "OPC DIVERSIFIE": "121,1070016"
            },
            {
                "Date": "23/08/2019",
                "Profil Dynamique": "129,4132179",
                "OPC DIVERSIFIE": "121,1823619"
            },
            {
                "Date": "30/08/2019",
                "Profil Dynamique": "130,0693252",
                "OPC DIVERSIFIE": "121,5316286"
            },
            {
                "Date": "06/09/2019",
                "Profil Dynamique": "129,7203747",
                "OPC DIVERSIFIE": "121,1643381"
            },
            {
                "Date": "13/09/2019",
                "Profil Dynamique": "129,5786596",
                "OPC DIVERSIFIE": "121,2156468"
            },
            {
                "Date": "20/09/2019",
                "Profil Dynamique": "129,0306269",
                "OPC DIVERSIFIE": "120,5899075"
            },
            {
                "Date": "27/09/2019",
                "Profil Dynamique": "129,5880097",
                "OPC DIVERSIFIE": "120,9923698"
            },
            {
                "Date": "04/10/2019",
                "Profil Dynamique": "129,2152312",
                "OPC DIVERSIFIE": "120,6350894"
            },
            {
                "Date": "11/10/2019",
                "Profil Dynamique": "128,8253441",
                "OPC DIVERSIFIE": "120,4876746"
            },
            {
                "Date": "18/10/2019",
                "Profil Dynamique": "129,4535761",
                "OPC DIVERSIFIE": "121,1203621"
            },
            {
                "Date": "25/10/2019",
                "Profil Dynamique": "129,8363211",
                "OPC DIVERSIFIE": "121,3033982"
            },
            {
                "Date": "01/11/2019",
                "Profil Dynamique": "129,8830775",
                "OPC DIVERSIFIE": "121,4533972"
            },
            {
                "Date": "08/11/2019",
                "Profil Dynamique": "129,7780447",
                "OPC DIVERSIFIE": "121,4170328"
            },
            {
                "Date": "15/11/2019",
                "Profil Dynamique": "130,1286463",
                "OPC DIVERSIFIE": "121,7051361"
            },
            {
                "Date": "22/11/2019",
                "Profil Dynamique": "130,8446396",
                "OPC DIVERSIFIE": "122,4421052"
            },
            {
                "Date": "29/11/2019",
                "Profil Dynamique": "130,9351119",
                "OPC DIVERSIFIE": "122,4941497"
            },
            {
                "Date": "06/12/2019",
                "Profil Dynamique": "130,8979104",
                "OPC DIVERSIFIE": "122,5401552"
            },
            {
                "Date": "13/12/2019",
                "Profil Dynamique": "131,6232741",
                "OPC DIVERSIFIE": "123,3810656"
            },
            {
                "Date": "20/12/2019",
                "Profil Dynamique": "132,6130447",
                "OPC DIVERSIFIE": "124,1960487"
            },
            {
                "Date": "27/12/2019",
                "Profil Dynamique": "132,6859476",
                "OPC DIVERSIFIE": "124,2457413"
            },
            {
                "Date": "03/01/2020",
                "Profil Dynamique": "133,0350566",
                "OPC DIVERSIFIE": "124,2709629"
            },
            {
                "Date": "10/01/2020",
                "Profil Dynamique": "133,8886691",
                "OPC DIVERSIFIE": "124,8979157"
            },
            {
                "Date": "17/01/2020",
                "Profil Dynamique": "134,9254666",
                "OPC DIVERSIFIE": "125,7378381"
            },
            {
                "Date": "24/01/2020",
                "Profil Dynamique": "135,4374145",
                "OPC DIVERSIFIE": "126,1012956"
            },
            {
                "Date": "31/01/2020",
                "Profil Dynamique": "135,2331266",
                "OPC DIVERSIFIE": "125,8100605"
            },
            {
                "Date": "07/02/2020",
                "Profil Dynamique": "133,78284",
                "OPC DIVERSIFIE": "124,3960868"
            },
            {
                "Date": "14/02/2020",
                "Profil Dynamique": "134,5878107",
                "OPC DIVERSIFIE": "125,0782161"
            },
            {
                "Date": "21/02/2020",
                "Profil Dynamique": "135,68348",
                "OPC DIVERSIFIE": "125,6602717"
            },
            {
                "Date": "28/02/2020",
                "Profil Dynamique": "134,4804495",
                "OPC DIVERSIFIE": "124,8167528"
            },
            {
                "Date": "06/03/2020",
                "Profil Dynamique": "131,718024",
                "OPC DIVERSIFIE": "121,931914"
            },
            {
                "Date": "13/03/2020",
                "Profil Dynamique": "127,1662163",
                "OPC DIVERSIFIE": "118,5745341"
            },
            {
                "Date": "20/03/2020",
                "Profil Dynamique": "123,1497545",
                "OPC DIVERSIFIE": "115,2591425"
            },
            {
                "Date": "27/03/2020",
                "Profil Dynamique": "124,7928147",
                "OPC DIVERSIFIE": "115,6634756"
            },
            {
                "Date": "03/04/2020",
                "Profil Dynamique": "123,8951052",
                "OPC DIVERSIFIE": "114,5413974"
            },
            {
                "Date": "10/04/2020",
                "Profil Dynamique": "123,7987504",
                "OPC DIVERSIFIE": "114,3485836"
            },
            {
                "Date": "17/04/2020",
                "Profil Dynamique": "123,1233041",
                "OPC DIVERSIFIE": "113,6908046"
            },
            {
                "Date": "24/04/2020",
                "Profil Dynamique": "122,9612743",
                "OPC DIVERSIFIE": "113,5750235"
            },
            {
                "Date": "04/05/2020",
                "Profil Dynamique": "124,4509719",
                "OPC DIVERSIFIE": "114,9513027"
            },
            {
                "Date": "08/05/2020",
                "Profil Dynamique": "124,8175986",
                "OPC DIVERSIFIE": "115,1353725"
            },
            {
                "Date": "15/05/2020",
                "Profil Dynamique": "124,7661332",
                "OPC DIVERSIFIE": "114,8304169"
            },
            {
                "Date": "22/05/2020",
                "Profil Dynamique": "126,0896993",
                "OPC DIVERSIFIE": "115,9332263"
            },
            {
                "Date": "29/05/2020",
                "Profil Dynamique": "127,2046318",
                "OPC DIVERSIFIE": "116,5914095"
            },
            {
                "Date": "05/06/2020",
                "Profil Dynamique": "128,0655336",
                "OPC DIVERSIFIE": "117,6281264"
            },
            {
                "Date": "12/06/2020",
                "Profil Dynamique": "127,8269156",
                "OPC DIVERSIFIE": "117,2370972"
            },
            {
                "Date": "19/06/2020",
                "Profil Dynamique": "129,8307355",
                "OPC DIVERSIFIE": "118,835299"
            },
            {
                "Date": "26/06/2020",
                "Profil Dynamique": "129,7886601",
                "OPC DIVERSIFIE": "119,6168615"
            },
            {
                "Date": "03/07/2020",
                "Profil Dynamique": "130,4115445",
                "OPC DIVERSIFIE": "119,9415311"
            },
            {
                "Date": "10/07/2020",
                "Profil Dynamique": "130,2358",
                "OPC DIVERSIFIE": "119,7406434"
            },
            {
                "Date": "17/07/2020",
                "Profil Dynamique": "130,7959196",
                "OPC DIVERSIFIE": "120,2659066"
            },
            {
                "Date": "24/07/2020",
                "Profil Dynamique": "131,2707738",
                "OPC DIVERSIFIE": "120,2318348"
            },
            {
                "Date": "03/08/2020",
                "Profil Dynamique": "131,425144",
                "OPC DIVERSIFIE": "120,1473858"
            },
            {
                "Date": "07/08/2020",
                "Profil Dynamique": "132,3744045",
                "OPC DIVERSIFIE": "120,5631794"
            },
            {
                "Date": "17/08/2020",
                "Profil Dynamique": "132,101532",
                "OPC DIVERSIFIE": "120,7817466"
            },
            {
                "Date": "24/08/2020",
                "Profil Dynamique": "131,9585886",
                "OPC DIVERSIFIE": "120,7672871"
            },
            {
                "Date": "28/08/2020",
                "Profil Dynamique": "131,295864",
                "OPC DIVERSIFIE": "120,1721883"
            },
            {
                "Date": "04/09/2020",
                "Profil Dynamique": "131,6783588",
                "OPC DIVERSIFIE": "120,610769"
            },
            {
                "Date": "11/09/2020",
                "Profil Dynamique": "132,032637",
                "OPC DIVERSIFIE": "120,7020169"
            },
            {
                "Date": "18/09/2020",
                "Profil Dynamique": "131,4950496",
                "OPC DIVERSIFIE": "120,2222277"
            },
            {
                "Date": "25/09/2020",
                "Profil Dynamique": "130,5703936",
                "OPC DIVERSIFIE": "119,9171271"
            },
            {
                "Date": "02/10/2020",
                "Profil Dynamique": "131,3557697",
                "OPC DIVERSIFIE": "120,3506234"
            },
            {
                "Date": "09/10/2020",
                "Profil Dynamique": "131,9780157",
                "OPC DIVERSIFIE": "120,836355"
            },
            {
                "Date": "16/10/2020",
                "Profil Dynamique": "132,3953505",
                "OPC DIVERSIFIE": "121,2035091"
            },
            {
                "Date": "23/10/2020",
                "Profil Dynamique": "132,9330104",
                "OPC DIVERSIFIE": "121,6700368"
            },
            {
                "Date": "02/11/2020",
                "Profil Dynamique": "132,5284527",
                "OPC DIVERSIFIE": "121,3182922"
            },
            {
                "Date": "09/11/2020",
                "Profil Dynamique": "132,9977715",
                "OPC DIVERSIFIE": "121,933343"
            },
            {
                "Date": "13/11/2020",
                "Profil Dynamique": "133,5053389",
                "OPC DIVERSIFIE": "122,1371361"
            },
            {
                "Date": "20/11/2020",
                "Profil Dynamique": "134,2710901",
                "OPC DIVERSIFIE": "122,9775837"
            },
            {
                "Date": "27/11/2020",
                "Profil Dynamique": "133,4257095",
                "OPC DIVERSIFIE": "122,5863656"
            },
            {
                "Date": "04/12/2020",
                "Profil Dynamique": "134,1003603",
                "OPC DIVERSIFIE": "122,7587407"
            },
            {
                "Date": "11/12/2020",
                "Profil Dynamique": "135,8255004",
                "OPC DIVERSIFIE": "124,2775734"
            },
            {
                "Date": "18/12/2020",
                "Profil Dynamique": "137,1408362",
                "OPC DIVERSIFIE": "125,3536808"
            },
            {
                "Date": "25/12/2020",
                "Profil Dynamique": "136,2063253",
                "OPC DIVERSIFIE": "124,6242228"
            },
            {
                "Date": "04/01/2021",
                "Profil Dynamique": "137,087502",
                "OPC DIVERSIFIE": "125,1641631"
            },
            {
                "Date": "08/01/2021",
                "Profil Dynamique": "136,3698625",
                "OPC DIVERSIFIE": "124,9904347"
            },
            {
                "Date": "15/01/2021",
                "Profil Dynamique": "137,1002769",
                "OPC DIVERSIFIE": "125,7550713"
            },
            {
                "Date": "22/01/2021",
                "Profil Dynamique": "138,0240361",
                "OPC DIVERSIFIE": "126,3648372"
            },
            {
                "Date": "29/01/2021",
                "Profil Dynamique": "138,5832442",
                "OPC DIVERSIFIE": "126,7841678"
            },
            {
                "Date": "05/02/2021",
                "Profil Dynamique": "138,8019924",
                "OPC DIVERSIFIE": "127,2143543"
            },
            {
                "Date": "12/02/2021",
                "Profil Dynamique": "138,1622137",
                "OPC DIVERSIFIE": "126,647498"
            },
            {
                "Date": "19/02/2021",
                "Profil Dynamique": "137,6059573",
                "OPC DIVERSIFIE": "126,4118191"
            },
            {
                "Date": "26/02/2021",
                "Profil Dynamique": "136,8293319",
                "OPC DIVERSIFIE": "125,9828093"
            },
            {
                "Date": "05/03/2021",
                "Profil Dynamique": "136,7065496",
                "OPC DIVERSIFIE": "126,0499213"
            },
            {
                "Date": "12/03/2021",
                "Profil Dynamique": "137,0080614",
                "OPC DIVERSIFIE": "126,3380254"
            },
            {
                "Date": "19/03/2021",
                "Profil Dynamique": "137,2443905",
                "OPC DIVERSIFIE": "126,3428925"
            },
            {
                "Date": "26/03/2021",
                "Profil Dynamique": "137,4862002",
                "OPC DIVERSIFIE": "126,4265146"
            },
            {
                "Date": "02/04/2021",
                "Profil Dynamique": "137,6676826",
                "OPC DIVERSIFIE": "126,717605"
            },
            {
                "Date": "09/04/2021",
                "Profil Dynamique": "137,7332496",
                "OPC DIVERSIFIE": "126,7352145"
            },
            {
                "Date": "16/04/2021",
                "Profil Dynamique": "138,3209397",
                "OPC DIVERSIFIE": "127,0541691"
            },
            {
                "Date": "23/04/2021",
                "Profil Dynamique": "138,6575539",
                "OPC DIVERSIFIE": "127,269338"
            },
            {
                "Date": "30/04/2021",
                "Profil Dynamique": "138,9670041",
                "OPC DIVERSIFIE": "127,6606971"
            },
            {
                "Date": "07/05/2021",
                "Profil Dynamique": "140,4068515",
                "OPC DIVERSIFIE": "128,5548854"
            },
            {
                "Date": "17/05/2021",
                "Profil Dynamique": "141,2066355",
                "OPC DIVERSIFIE": "129,177462"
            },
            {
                "Date": "21/05/2021",
                "Profil Dynamique": "141,2188884",
                "OPC DIVERSIFIE": "129,1610735"
            },
            {
                "Date": "28/05/2021",
                "Profil Dynamique": "141,443007",
                "OPC DIVERSIFIE": "129,1987057"
            },
            {
                "Date": "04/06/2021",
                "Profil Dynamique": "142,2222482",
                "OPC DIVERSIFIE": "129,9833219"
            },
            {
                "Date": "11/06/2021",
                "Profil Dynamique": "142,5757912",
                "OPC DIVERSIFIE": "130,3784526"
            },
            {
                "Date": "18/06/2021",
                "Profil Dynamique": "141,9117094",
                "OPC DIVERSIFIE": "130,3465358"
            },
            {
                "Date": "25/06/2021",
                "Profil Dynamique": "141,6488177",
                "OPC DIVERSIFIE": "130,1289119"
            },
            {
                "Date": "02/07/2021",
                "Profil Dynamique": "141,781834",
                "OPC DIVERSIFIE": "130,1484662"
            },
            {
                "Date": "09/07/2021",
                "Profil Dynamique": "141,6229023",
                "OPC DIVERSIFIE": "129,9000905"
            },
            {
                "Date": "16/07/2021",
                "Profil Dynamique": "141,7048806",
                "OPC DIVERSIFIE": "129,807859"
            },
            {
                "Date": "23/07/2021",
                "Profil Dynamique": "141,7623612",
                "OPC DIVERSIFIE": "129,9194436"
            },
            {
                "Date": "02/08/2021",
                "Profil Dynamique": "142,6448821",
                "OPC DIVERSIFIE": "130,7708719"
            },
            {
                "Date": "06/08/2021",
                "Profil Dynamique": "142,7961607",
                "OPC DIVERSIFIE": "131,0552273"
            },
            {
                "Date": "13/08/2021",
                "Profil Dynamique": "143,3302832",
                "OPC DIVERSIFIE": "131,394998"
            },
            {
                "Date": "23/08/2021",
                "Profil Dynamique": "143,8751173",
                "OPC DIVERSIFIE": "131,663655"
            },
            {
                "Date": "27/08/2021",
                "Profil Dynamique": "144,2449138",
                "OPC DIVERSIFIE": "131,9975467"
            },
            {
                "Date": "03/09/2021",
                "Profil Dynamique": "144,2730994",
                "OPC DIVERSIFIE": "131,9245852"
            },
            {
                "Date": "10/09/2021",
                "Profil Dynamique": "144,7890476",
                "OPC DIVERSIFIE": "132,5628601"
            },
            {
                "Date": "17/09/2021",
                "Profil Dynamique": "145,2273179",
                "OPC DIVERSIFIE": "133,1065183"
            },
            {
                "Date": "24/09/2021",
                "Profil Dynamique": "145,7036443",
                "OPC DIVERSIFIE": "133,4742746"
            },
            {
                "Date": "01/10/2021",
                "Profil Dynamique": "145,6480948",
                "OPC DIVERSIFIE": "133,3384272"
            },
            {
                "Date": "08/10/2021",
                "Profil Dynamique": "146,3183939",
                "OPC DIVERSIFIE": "133,6936134"
            },
            {
                "Date": "15/10/2021",
                "Profil Dynamique": "146,771972",
                "OPC DIVERSIFIE": "134,131891"
            },
            {
                "Date": "22/10/2021",
                "Profil Dynamique": "147,0775689",
                "OPC DIVERSIFIE": "134,1348367"
            },
            {
                "Date": "29/10/2021",
                "Profil Dynamique": "147,8763941",
                "OPC DIVERSIFIE": "134,8793188"
            },
            {
                "Date": "05/11/2021",
                "Profil Dynamique": "147,4764763",
                "OPC DIVERSIFIE": "134,3829838"
            },
            {
                "Date": "12/11/2021",
                "Profil Dynamique": "147,4626106",
                "OPC DIVERSIFIE": "134,116565"
            },
            {
                "Date": "19/11/2021",
                "Profil Dynamique": "147,6531528",
                "OPC DIVERSIFIE": "134,2605724"
            },
            {
                "Date": "26/11/2021",
                "Profil Dynamique": "146,9069379",
                "OPC DIVERSIFIE": "133,8896734"
            },
            {
                "Date": "03/12/2021",
                "Profil Dynamique": "146,5510459",
                "OPC DIVERSIFIE": "133,8478748"
            },
            {
                "Date": "10/12/2021",
                "Profil Dynamique": "147,3637198",
                "OPC DIVERSIFIE": "134,2380935"
            },
            {
                "Date": "17/12/2021",
                "Profil Dynamique": "147,3864897",
                "OPC DIVERSIFIE": "134,0862947"
            },
            {
                "Date": "24/12/2021",
                "Profil Dynamique": "147,2345021",
                "OPC DIVERSIFIE": "133,8884956"
            },
            {
                "Date": "31/12/2021",
                "Profil Dynamique": "147,9109618",
                "OPC DIVERSIFIE": "134,3956463"
            },
            {
                "Date": "07/01/2022",
                "Profil Dynamique": "148,383174",
                "OPC DIVERSIFIE": "134,8057201"
            },
            {
                "Date": "14/01/2022",
                "Profil Dynamique": "149,6683771",
                "OPC DIVERSIFIE": "135,7828604"
            },
            {
                "Date": "21/01/2022",
                "Profil Dynamique": "149,8257608",
                "OPC DIVERSIFIE": "135,6465172"
            },
            {
                "Date": "28/01/2022",
                "Profil Dynamique": "149,5467893",
                "OPC DIVERSIFIE": "135,6084442"
            },
            {
                "Date": "04/02/2022",
                "Profil Dynamique": "150,052215",
                "OPC DIVERSIFIE": "135,9503391"
            },
            {
                "Date": "11/02/2022",
                "Profil Dynamique": "150,3241054",
                "OPC DIVERSIFIE": "135,9405053"
            },
            {
                "Date": "18/02/2022",
                "Profil Dynamique": "149,988223",
                "OPC DIVERSIFIE": "135,1931568"
            },
            {
                "Date": "25/02/2022",
                "Profil Dynamique": "147,6485485",
                "OPC DIVERSIFIE": "133,354884"
            },
            {
                "Date": "04/03/2022",
                "Profil Dynamique": "146,7197821",
                "OPC DIVERSIFIE": "132,0400658"
            },
            {
                "Date": "11/03/2022",
                "Profil Dynamique": "146,2959021",
                "OPC DIVERSIFIE": "131,4536164"
            },
            {
                "Date": "18/03/2022",
                "Profil Dynamique": "147,2777078",
                "OPC DIVERSIFIE": "132,404884"
            },
            {
                "Date": "25/03/2022",
                "Profil Dynamique": "146,2530454",
                "OPC DIVERSIFIE": "131,346862"
            },
            {
                "Date": "01/04/2022",
                "Profil Dynamique": "147,1203224",
                "OPC DIVERSIFIE": "132,0308064"
            },
            {
                "Date": "08/04/2022",
                "Profil Dynamique": "147,6470279",
                "OPC DIVERSIFIE": "132,2654936"
            },
            {
                "Date": "15/04/2022",
                "Profil Dynamique": "148,0443084",
                "OPC DIVERSIFIE": "132,4358122"
            },
            {
                "Date": "22/04/2022",
                "Profil Dynamique": "148,741515",
                "OPC DIVERSIFIE": "132,9593457"
            },
            {
                "Date": "29/04/2022",
                "Profil Dynamique": "148,6292864",
                "OPC DIVERSIFIE": "132,7145167"
            },
            {
                "Date": "06/05/2022",
                "Profil Dynamique": "148,1873637",
                "OPC DIVERSIFIE": "132,3805484"
            },
            {
                "Date": "13/05/2022",
                "Profil Dynamique": "146,1945478",
                "OPC DIVERSIFIE": "131,1497485"
            },
            {
                "Date": "20/05/2022",
                "Profil Dynamique": "146,0256395",
                "OPC DIVERSIFIE": "130,9721822"
            },
            {
                "Date": "27/05/2022",
                "Profil Dynamique": "144,7488485",
                "OPC DIVERSIFIE": "130,0893826"
            },
            {
                "Date": "03/06/2022",
                "Profil Dynamique": "146,0696055",
                "OPC DIVERSIFIE": "131,1228407"
            },
            {
                "Date": "10/06/2022",
                "Profil Dynamique": "146,0340299",
                "OPC DIVERSIFIE": "130,9971295"
            },
            {
                "Date": "17/06/2022",
                "Profil Dynamique": "144,3570632",
                "OPC DIVERSIFIE": "129,4272338"
            },
            {
                "Date": "24/06/2022",
                "Profil Dynamique": "143,8637676",
                "OPC DIVERSIFIE": "129,1467014"
            },
            {
                "Date": "01/07/2022",
                "Profil Dynamique": "142,4983928",
                "OPC DIVERSIFIE": "128,3204918"
            },
            {
                "Date": "08/07/2022",
                "Profil Dynamique": "142,5085342",
                "OPC DIVERSIFIE": "128,5080942"
            },
            {
                "Date": "15/07/2022",
                "Profil Dynamique": "143,4110687",
                "OPC DIVERSIFIE": "129,0913032"
            },
            {
                "Date": "22/07/2022",
                "Profil Dynamique": "144,0122019",
                "OPC DIVERSIFIE": "129,4831714"
            },
            {
                "Date": "29/07/2022",
                "Profil Dynamique": "143,6754866",
                "OPC DIVERSIFIE": "129,2079055"
            },
            {
                "Date": "05/08/2022",
                "Profil Dynamique": "144,5186523",
                "OPC DIVERSIFIE": "129,7289856"
            },
            {
                "Date": "12/08/2022",
                "Profil Dynamique": "145,6309033",
                "OPC DIVERSIFIE": "130,5549863"
            },
            {
                "Date": "19/08/2022",
                "Profil Dynamique": "145,6030256",
                "OPC DIVERSIFIE": "130,5433278"
            },
            {
                "Date": "26/08/2022",
                "Profil Dynamique": "145,6955267",
                "OPC DIVERSIFIE": "130,4263747"
            },
            {
                "Date": "02/09/2022",
                "Profil Dynamique": "145,236884",
                "OPC DIVERSIFIE": "130,3353935"
            },
            {
                "Date": "09/09/2022",
                "Profil Dynamique": "145,5012087",
                "OPC DIVERSIFIE": "130,3631582"
            },
            {
                "Date": "16/09/2022",
                "Profil Dynamique": "145,3038447",
                "OPC DIVERSIFIE": "130,1311503"
            },
            {
                "Date": "23/09/2022",
                "Profil Dynamique": "145,4976979",
                "OPC DIVERSIFIE": "130,0473279"
            },
            {
                "Date": "30/09/2022",
                "Profil Dynamique": "142,4593302",
                "OPC DIVERSIFIE": "126,2711443"
            },
            {
                "Date": "07/10/2022",
                "Profil Dynamique": "142,7419513",
                "OPC DIVERSIFIE": "126,4992864"
            },
            {
                "Date": "14/10/2022",
                "Profil Dynamique": "141,3320105",
                "OPC DIVERSIFIE": "125,6242132"
            },
            {
                "Date": "21/10/2022",
                "Profil Dynamique": "139,3025797",
                "OPC DIVERSIFIE": "124,1681861"
            },
            {
                "Date": "28/10/2022",
                "Profil Dynamique": "138,8333922",
                "OPC DIVERSIFIE": "123,8377982"
            },
            {
                "Date": "04/11/2022",
                "Profil Dynamique": "138,7780072",
                "OPC DIVERSIFIE": "123,5073662"
            },
            {
                "Date": "11/11/2022",
                "Profil Dynamique": "137,5309672",
                "OPC DIVERSIFIE": "122,1937653"
            },
            {
                "Date": "21/11/2022",
                "Profil Dynamique": "138,9336538",
                "OPC DIVERSIFIE": "123,0572926"
            },
            {
                "Date": "25/11/2022",
                "Profil Dynamique": "139,1273912",
                "OPC DIVERSIFIE": "123,2886815"
            },
            {
                "Date": "02/12/2022",
                "Profil Dynamique": "140,1601072",
                "OPC DIVERSIFIE": "123,6564895"
            },
            {
                "Date": "09/12/2022",
                "Profil Dynamique": "141,1126095",
                "OPC DIVERSIFIE": "124,3831439"
            },
            {
                "Date": "16/12/2022",
                "Profil Dynamique": "140,1123749",
                "OPC DIVERSIFIE": "123,7045925"
            },
            {
                "Date": "23/12/2022",
                "Profil Dynamique": "139,8398483",
                "OPC DIVERSIFIE": "123,4411449"
            },
            {
                "Date": "30/12/2022",
                "Profil Dynamique": "138,7955424",
                "OPC DIVERSIFIE": "122,5157046"
            },
            {
                "Date": "06/01/2023",
                "Profil Dynamique": "133,2491339",
                "OPC DIVERSIFIE": "115,1469266"
            },
            {
                "Date": "13/01/2023",
                "Profil Dynamique": "135,7677644",
                "OPC DIVERSIFIE": "116,9135775"
            },
            {
                "Date": "20/01/2023",
                "Profil Dynamique": "135,8041797",
                "OPC DIVERSIFIE": "116,607758"
            },
            {
                "Date": "27/01/2023",
                "Profil Dynamique": "135,3112085",
                "OPC DIVERSIFIE": "116,4682569"
            },
            {
                "Date": "03/02/2023",
                "Profil Dynamique": "136,464588",
                "OPC DIVERSIFIE": "117,3135624"
            },
            {
                "Date": "10/02/2023",
                "Profil Dynamique": "137,8510971",
                "OPC DIVERSIFIE": "118,6063733"
            },
            {
                "Date": "17/02/2023",
                "Profil Dynamique": "137,5393268",
                "OPC DIVERSIFIE": "118,4047752"
            },
            {
                "Date": "24/02/2023",
                "Profil Dynamique": "138,2105159",
                "OPC DIVERSIFIE": "118,8227582"
            },
            {
                "Date": "03/03/2023",
                "Profil Dynamique": "137,8567771",
                "OPC DIVERSIFIE": "118,3687856"
            },
            {
                "Date": "10/03/2023",
                "Profil Dynamique": "137,3957925",
                "OPC DIVERSIFIE": "117,8822168"
            },
            {
                "Date": "17/03/2023",
                "Profil Dynamique": "137,6411293",
                "OPC DIVERSIFIE": "117,0551643"
            },
            {
                "Date": "24/03/2023",
                "Profil Dynamique": "137,7102766",
                "OPC DIVERSIFIE": "116,9540818"
            },
            {
                "Date": "31/03/2023",
                "Profil Dynamique": "137,3228995",
                "OPC DIVERSIFIE": "116,7575493"
            },
            {
                "Date": "07/04/2023",
                "Profil Dynamique": "137,6630511",
                "OPC DIVERSIFIE": "116,714111"
            },
            {
                "Date": "14/04/2023",
                "Profil Dynamique": "137,9470993",
                "OPC DIVERSIFIE": "116,7516492"
            },
            {
                "Date": "21/04/2023",
                "Profil Dynamique": "137,6248312",
                "OPC DIVERSIFIE": "116,88394"
            },
            {
                "Date": "28/04/2023",
                "Profil Dynamique": "137,7970149",
                "OPC DIVERSIFIE": "116,9339094"
            },
            {
                "Date": "05/05/2023",
                "Profil Dynamique": "137,3296953",
                "OPC DIVERSIFIE": "116,5310165"
            },
            {
                "Date": "12/05/2023",
                "Profil Dynamique": "139,0175065",
                "OPC DIVERSIFIE": "117,6342717"
            },
            {
                "Date": "19/05/2023",
                "Profil Dynamique": "139,2078921",
                "OPC DIVERSIFIE": "118,1287352"
            },
            {
                "Date": "26/05/2023",
                "Profil Dynamique": "140,0416254",
                "OPC DIVERSIFIE": "118,9610489"
            },
            {
                "Date": "02/06/2023",
                "Profil Dynamique": "140,9923756",
                "OPC DIVERSIFIE": "119,7740717"
            },
            {
                "Date": "09/06/2023",
                "Profil Dynamique": "140,5472769",
                "OPC DIVERSIFIE": "119,4819986"
            },
            {
                "Date": "16/06/2023",
                "Profil Dynamique": "141,860058",
                "OPC DIVERSIFIE": "120,7285631"
            },
            {
                "Date": "23/06/2023",
                "Profil Dynamique": "142,8451636",
                "OPC DIVERSIFIE": "122,2419229"
            },
            {
                "Date": "03/07/2023",
                "Profil Dynamique": "143,2991212",
                "OPC DIVERSIFIE": "122,8402437"
            },
            {
                "Date": "07/07/2023",
                "Profil Dynamique": "143,5078008",
                "OPC DIVERSIFIE": "123,1356397"
            },
            {
                "Date": "14/07/2023",
                "Profil Dynamique": "143,9466489",
                "OPC DIVERSIFIE": "123,3499308"
            },
            {
                "Date": "21/07/2023",
                "Profil Dynamique": "145,323856",
                "OPC DIVERSIFIE": "124,2650838"
            },
            {
                "Date": "28/07/2023",
                "Profil Dynamique": "146,5200954",
                "OPC DIVERSIFIE": "125,3314207"
            },
            {
                "Date": "04/08/2023",
                "Profil Dynamique": "146,8623579",
                "OPC DIVERSIFIE": "125,7090185"
            },
            {
                "Date": "11/08/2023",
                "Profil Dynamique": "146,3040562",
                "OPC DIVERSIFIE": "125,3559421"
            },
            {
                "Date": "18/08/2023",
                "Profil Dynamique": "146,2276361",
                "OPC DIVERSIFIE": "125,3039934"
            },
            {
                "Date": "25/08/2023",
                "Profil Dynamique": "146,268102",
                "OPC DIVERSIFIE": "125,0640748"
            },
            {
                "Date": "01/09/2023",
                "Profil Dynamique": "146,5019075",
                "OPC DIVERSIFIE": "125,0755981"
            },
            {
                "Date": "08/09/2023",
                "Profil Dynamique": "145,7142237",
                "OPC DIVERSIFIE": "124,6023607"
            },
            {
                "Date": "15/09/2023",
                "Profil Dynamique": "146,1094528",
                "OPC DIVERSIFIE": "124,8005955"
            },
            {
                "Date": "22/09/2023",
                "Profil Dynamique": "145,8927481",
                "OPC DIVERSIFIE": "124,8064566"
            },
            {
                "Date": "02/10/2023",
                "Profil Dynamique": "145,6820418",
                "OPC DIVERSIFIE": "125,0997794"
            },
            {
                "Date": "06/10/2023",
                "Profil Dynamique": "147,9231955",
                "OPC DIVERSIFIE": "126,9100356"
            },
            {
                "Date": "13/10/2023",
                "Profil Dynamique": "148,3676722",
                "OPC DIVERSIFIE": "126,6844607"
            },
            {
                "Date": "20/10/2023",
                "Profil Dynamique": "149,1631756",
                "OPC DIVERSIFIE": "126,6704246"
            },
            {
                "Date": "27/10/2023",
                "Profil Dynamique": "148,1490885",
                "OPC DIVERSIFIE": "125,8566608"
            },
            {
                "Date": "03/11/2023",
                "Profil Dynamique": "148,1667929",
                "OPC DIVERSIFIE": "125,8694887"
            },
            {
                "Date": "10/11/2023",
                "Profil Dynamique": "147,6724972",
                "OPC DIVERSIFIE": "125,8152819"
            },
            {
                "Date": "17/11/2023",
                "Profil Dynamique": "147,998434",
                "OPC DIVERSIFIE": "125,9811221"
            },
            {
                "Date": "24/11/2023",
                "Profil Dynamique": "148,2648723",
                "OPC DIVERSIFIE": "126,1999512"
            },
            {
                "Date": "01/12/2023",
                "Profil Dynamique": "148,0054603",
                "OPC DIVERSIFIE": "125,6740418"
            },
            {
                "Date": "08/12/2023",
                "Profil Dynamique": "148,4198439",
                "OPC DIVERSIFIE": "126,2211615"
            },
            {
                "Date": "15/12/2023",
                "Profil Dynamique": "149,7966622",
                "OPC DIVERSIFIE": "127,5989898"
            },
            {
                "Date": "22/12/2023",
                "Profil Dynamique": "149,6740653",
                "OPC DIVERSIFIE": "127,9530252"
            },
            {
                "Date": "29/12/2023",
                "Profil Dynamique": "149,4663921",
                "OPC DIVERSIFIE": "127,589309"
            },
            {
                "Date": "05/01/2024",
                "Profil Dynamique": "150,7074251",
                "OPC DIVERSIFIE": "128,8244389"
            },
            {
                "Date": "12/01/2024",
                "Profil Dynamique": "151,8441996",
                "OPC DIVERSIFIE": "130,227858"
            },
            {
                "Date": "19/01/2024",
                "Profil Dynamique": "152,3466305",
                "OPC DIVERSIFIE": "130,9249357"
            },
            {
                "Date": "26/01/2024",
                "Profil Dynamique": "151,9193653",
                "OPC DIVERSIFIE": "130,4384125"
            },
            {
                "Date": "02/02/2024",
                "Profil Dynamique": "151,7190072",
                "OPC DIVERSIFIE": "130,3002141"
            },
            {
                "Date": "09/02/2024",
                "Profil Dynamique": "152,6905412",
                "OPC DIVERSIFIE": "131,0935372"
            },
            {
                "Date": "16/02/2024",
                "Profil Dynamique": "153,433896",
                "OPC DIVERSIFIE": "131,8127978"
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
          setChartData(yourDataSet[0]);
        }
      }, [yourDataSet]);
    
      const getOption = () => {
        if (!chartData || chartData.length === 0) {
          return {}; 
        }
      
        const xAxisData = chartData.map((item) => item.Date);
      
        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');
      
        const series = legendData.map((key) => ({
          type: 'line',
          name: key,
          data: chartData.map((item) => {
            const value = item[key];
            const parsedValue = parseFloat(value.replace(',', ' '));
            return isNaN(parsedValue) ? null : parsedValue;
          }),
        }));
    
        return {
          tooltip: {
            trigger: 'axis',
          },
          title: {
            text: '',
            left: 'center',
         
          },
          legend: {
            data: legendData,
            bottom : 10,
           },
          xAxis: {
            type: 'category',
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: 100,
          },
          series,
        };
      };
    
      return (
        <Row className="g-3">
      <Col>
        
            <ReactEChartsCore echarts={echarts} option={getOption()} />
         
      </Col>
    </Row>
      );
    };
    
    export default LineChartComponent;
