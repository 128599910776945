import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
]);

const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil modéré": "100",
                "": ""
            },
            {
                "Date": "1/4/16",
                "Profil modéré": "100.0772517",
                "": ""
            },
            {
                "Date": "1/8/16",
                "Profil modéré": "100.1953103",
                "": ""
            },
            {
                "Date": "1/15/16",
                "Profil modéré": "100.1297757",
                "": ""
            },
            {
                "Date": "1/22/16",
                "Profil modéré": "100.3295867",
                "": ""
            },
            {
                "Date": "1/29/16",
                "Profil modéré": "100.5245552",
                "": ""
            },
            {
                "Date": "2/5/16",
                "Profil modéré": "100.8427128",
                "": ""
            },
            {
                "Date": "2/12/16",
                "Profil modéré": "101.4782698",
                "": ""
            },
            {
                "Date": "2/19/16",
                "Profil modéré": "101.6606767",
                "": ""
            },
            {
                "Date": "2/26/16",
                "Profil modéré": "101.6680403",
                "": ""
            },
            {
                "Date": "3/4/16",
                "Profil modéré": "102.1519919",
                "": ""
            },
            {
                "Date": "3/11/16",
                "Profil modéré": "102.1255791",
                "": ""
            },
            {
                "Date": "3/18/16",
                "Profil modéré": "102.1433075",
                "": ""
            },
            {
                "Date": "3/25/16",
                "Profil modéré": "102.2043463",
                "": ""
            },
            {
                "Date": "4/1/16",
                "Profil modéré": "102.2013365",
                "": ""
            },
            {
                "Date": "4/8/16",
                "Profil modéré": "102.5469937",
                "": ""
            },
            {
                "Date": "4/15/16",
                "Profil modéré": "102.915281",
                "": ""
            },
            {
                "Date": "4/22/16",
                "Profil modéré": "103.2885791",
                "": ""
            },
            {
                "Date": "4/29/16",
                "Profil modéré": "103.8252525",
                "": ""
            },
            {
                "Date": "5/6/16",
                "Profil modéré": "104.0791486",
                "": ""
            },
            {
                "Date": "5/13/16",
                "Profil modéré": "104.091083",
                "": ""
            },
            {
                "Date": "5/20/16",
                "Profil modéré": "103.963296",
                "": ""
            },
            {
                "Date": "5/27/16",
                "Profil modéré": "103.7009796",
                "": ""
            },
            {
                "Date": "6/3/16",
                "Profil modéré": "103.9136688",
                "": ""
            },
            {
                "Date": "6/10/16",
                "Profil modéré": "104.155012",
                "": ""
            },
            {
                "Date": "6/17/16",
                "Profil modéré": "104.3158183",
                "": ""
            },
            {
                "Date": "6/24/16",
                "Profil modéré": "104.5167526",
                "": ""
            },
            {
                "Date": "7/1/16",
                "Profil modéré": "104.6568621",
                "": ""
            },
            {
                "Date": "7/11/16",
                "Profil modéré": "104.9108125",
                "": ""
            },
            {
                "Date": "7/15/16",
                "Profil modéré": "104.6579841",
                "": ""
            },
            {
                "Date": "7/22/16",
                "Profil modéré": "104.6654812",
                "": ""
            },
            {
                "Date": "7/29/16",
                "Profil modéré": "104.8025569",
                "": ""
            },
            {
                "Date": "8/5/16",
                "Profil modéré": "104.8440242",
                "": ""
            },
            {
                "Date": "8/12/16",
                "Profil modéré": "104.7813753",
                "": ""
            },
            {
                "Date": "8/19/16",
                "Profil modéré": "104.7241793",
                "": ""
            },
            {
                "Date": "8/26/16",
                "Profil modéré": "104.5669046",
                "": ""
            },
            {
                "Date": "9/2/16",
                "Profil modéré": "104.6806813",
                "": ""
            },
            {
                "Date": "9/9/16",
                "Profil modéré": "104.749847",
                "": ""
            },
            {
                "Date": "9/16/16",
                "Profil modéré": "104.655788",
                "": ""
            },
            {
                "Date": "9/23/16",
                "Profil modéré": "104.9232845",
                "": ""
            },
            {
                "Date": "9/30/16",
                "Profil modéré": "105.0141626",
                "": ""
            },
            {
                "Date": "10/7/16",
                "Profil modéré": "104.7320942",
                "": ""
            },
            {
                "Date": "10/14/16",
                "Profil modéré": "104.9434754",
                "": ""
            },
            {
                "Date": "10/21/16",
                "Profil modéré": "105.3952677",
                "": ""
            },
            {
                "Date": "10/28/16",
                "Profil modéré": "105.54612",
                "": ""
            },
            {
                "Date": "11/4/16",
                "Profil modéré": "105.7397029",
                "": ""
            },
            {
                "Date": "11/11/16",
                "Profil modéré": "105.5300605",
                "": ""
            },
            {
                "Date": "11/21/16",
                "Profil modéré": "105.7636117",
                "": ""
            },
            {
                "Date": "11/25/16",
                "Profil modéré": "105.4852506",
                "": ""
            },
            {
                "Date": "12/2/16",
                "Profil modéré": "105.5625033",
                "": ""
            },
            {
                "Date": "12/9/16",
                "Profil modéré": "105.7501417",
                "": ""
            },
            {
                "Date": "12/16/16",
                "Profil modéré": "105.8619084",
                "": ""
            },
            {
                "Date": "12/23/16",
                "Profil modéré": "106.4883219",
                "": ""
            },
            {
                "Date": "12/30/16",
                "Profil modéré": "106.3276646",
                "": ""
            },
            {
                "Date": "1/6/17",
                "Profil modéré": "107.4319654",
                "": ""
            },
            {
                "Date": "1/13/17",
                "Profil modéré": "107.5259311",
                "": ""
            },
            {
                "Date": "1/20/17",
                "Profil modéré": "107.464629",
                "": ""
            },
            {
                "Date": "1/27/17",
                "Profil modéré": "107.3796543",
                "": ""
            },
            {
                "Date": "2/3/17",
                "Profil modéré": "107.4275994",
                "": ""
            },
            {
                "Date": "2/10/17",
                "Profil modéré": "107.6400456",
                "": ""
            },
            {
                "Date": "2/17/17",
                "Profil modéré": "107.6827596",
                "": ""
            },
            {
                "Date": "2/24/17",
                "Profil modéré": "107.6615788",
                "": ""
            },
            {
                "Date": "3/3/17",
                "Profil modéré": "107.6632583",
                "": ""
            },
            {
                "Date": "3/10/17",
                "Profil modéré": "107.5626007",
                "": ""
            },
            {
                "Date": "3/17/17",
                "Profil modéré": "107.7784684",
                "": ""
            },
            {
                "Date": "3/24/17",
                "Profil modéré": "107.6778744",
                "": ""
            },
            {
                "Date": "3/31/17",
                "Profil modéré": "107.6803958",
                "": ""
            },
            {
                "Date": "4/7/17",
                "Profil modéré": "107.8293957",
                "": ""
            },
            {
                "Date": "4/14/17",
                "Profil modéré": "107.9227567",
                "": ""
            },
            {
                "Date": "4/21/17",
                "Profil modéré": "108.0342988",
                "": ""
            },
            {
                "Date": "4/28/17",
                "Profil modéré": "108.1236946",
                "": ""
            },
            {
                "Date": "5/5/17",
                "Profil modéré": "107.9435134",
                "": ""
            },
            {
                "Date": "5/12/17",
                "Profil modéré": "108.0204513",
                "": ""
            },
            {
                "Date": "5/19/17",
                "Profil modéré": "108.1025693",
                "": ""
            },
            {
                "Date": "5/26/17",
                "Profil modéré": "108.2607775",
                "": ""
            },
            {
                "Date": "6/2/17",
                "Profil modéré": "108.3896072",
                "": ""
            },
            {
                "Date": "6/9/17",
                "Profil modéré": "108.5230593",
                "": ""
            },
            {
                "Date": "6/16/17",
                "Profil modéré": "108.5370632",
                "": ""
            },
            {
                "Date": "6/23/17",
                "Profil modéré": "108.7773349",
                "": ""
            },
            {
                "Date": "6/30/17",
                "Profil modéré": "108.6754872",
                "": ""
            },
            {
                "Date": "7/7/17",
                "Profil modéré": "108.7622592",
                "": ""
            },
            {
                "Date": "7/14/17",
                "Profil modéré": "108.8414543",
                "": ""
            },
            {
                "Date": "7/21/17",
                "Profil modéré": "108.7998334",
                "": ""
            },
            {
                "Date": "7/28/17",
                "Profil modéré": "109.1371213",
                "": ""
            },
            {
                "Date": "8/4/17",
                "Profil modéré": "109.0364927",
                "": ""
            },
            {
                "Date": "8/11/17",
                "Profil modéré": "109.3723319",
                "": ""
            },
            {
                "Date": "8/18/17",
                "Profil modéré": "109.531544",
                "": ""
            },
            {
                "Date": "8/25/17",
                "Profil modéré": "109.4517018",
                "": ""
            },
            {
                "Date": "9/4/17",
                "Profil modéré": "109.8813832",
                "": ""
            },
            {
                "Date": "9/8/17",
                "Profil modéré": "110.107955",
                "": ""
            },
            {
                "Date": "9/15/17",
                "Profil modéré": "110.20657",
                "": ""
            },
            {
                "Date": "9/25/17",
                "Profil modéré": "109.9565422",
                "": ""
            },
            {
                "Date": "9/29/17",
                "Profil modéré": "109.9872429",
                "": ""
            },
            {
                "Date": "10/6/17",
                "Profil modéré": "110.1607141",
                "": ""
            },
            {
                "Date": "10/13/17",
                "Profil modéré": "110.6231951",
                "": ""
            },
            {
                "Date": "10/20/17",
                "Profil modéré": "110.3886748",
                "": ""
            },
            {
                "Date": "10/27/17",
                "Profil modéré": "110.520051",
                "": ""
            },
            {
                "Date": "11/3/17",
                "Profil modéré": "110.5754297",
                "": ""
            },
            {
                "Date": "11/10/17",
                "Profil modéré": "110.8963406",
                "": ""
            },
            {
                "Date": "11/17/17",
                "Profil modéré": "110.845666",
                "": ""
            },
            {
                "Date": "11/24/17",
                "Profil modéré": "110.8108338",
                "": ""
            },
            {
                "Date": "12/4/17",
                "Profil modéré": "110.6574644",
                "": ""
            },
            {
                "Date": "12/8/17",
                "Profil modéré": "110.5311225",
                "": ""
            },
            {
                "Date": "12/15/17",
                "Profil modéré": "110.5697535",
                "": ""
            },
            {
                "Date": "12/22/17",
                "Profil modéré": "110.4552155",
                "": ""
            },
            {
                "Date": "12/29/17",
                "Profil modéré": "110.7629356",
                "": ""
            },
            {
                "Date": "1/5/18",
                "Profil modéré": "110.9952408",
                "": ""
            },
            {
                "Date": "1/12/18",
                "Profil modéré": "111.2135198",
                "": ""
            },
            {
                "Date": "1/19/18",
                "Profil modéré": "111.4009874",
                "": ""
            },
            {
                "Date": "1/26/18",
                "Profil modéré": "111.7392253",
                "": ""
            },
            {
                "Date": "2/2/18",
                "Profil modéré": "111.7619069",
                "": ""
            },
            {
                "Date": "2/9/18",
                "Profil modéré": "111.7347593",
                "": ""
            },
            {
                "Date": "2/16/18",
                "Profil modéré": "111.9848555",
                "": ""
            },
            {
                "Date": "2/23/18",
                "Profil modéré": "112.0222567",
                "": ""
            },
            {
                "Date": "3/2/18",
                "Profil modéré": "112.0333535",
                "": ""
            },
            {
                "Date": "3/9/18",
                "Profil modéré": "112.1087623",
                "": ""
            },
            {
                "Date": "3/16/18",
                "Profil modéré": "111.9473476",
                "": ""
            },
            {
                "Date": "3/23/18",
                "Profil modéré": "112.1565881",
                "": ""
            },
            {
                "Date": "3/30/18",
                "Profil modéré": "112.0454664",
                "": ""
            },
            {
                "Date": "4/6/18",
                "Profil modéré": "112.000031",
                "": ""
            },
            {
                "Date": "4/13/18",
                "Profil modéré": "112.0704576",
                "": ""
            },
            {
                "Date": "4/20/18",
                "Profil modéré": "112.1183264",
                "": ""
            },
            {
                "Date": "4/27/18",
                "Profil modéré": "112.1726858",
                "": ""
            },
            {
                "Date": "5/4/18",
                "Profil modéré": "112.1525167",
                "": ""
            },
            {
                "Date": "5/11/18",
                "Profil modéré": "112.1220226",
                "": ""
            },
            {
                "Date": "5/18/18",
                "Profil modéré": "111.7891117",
                "": ""
            },
            {
                "Date": "5/25/18",
                "Profil modéré": "111.9829951",
                "": ""
            },
            {
                "Date": "6/1/18",
                "Profil modéré": "111.7432481",
                "": ""
            },
            {
                "Date": "6/8/18",
                "Profil modéré": "111.9467885",
                "": ""
            },
            {
                "Date": "6/18/18",
                "Profil modéré": "111.9336091",
                "": ""
            },
            {
                "Date": "6/22/18",
                "Profil modéré": "111.8310159",
                "": ""
            },
            {
                "Date": "6/29/18",
                "Profil modéré": "111.5120968",
                "": ""
            },
            {
                "Date": "7/6/18",
                "Profil modéré": "111.3295989",
                "": ""
            },
            {
                "Date": "7/13/18",
                "Profil modéré": "111.1193425",
                "": ""
            },
            {
                "Date": "7/20/18",
                "Profil modéré": "111.0398378",
                "": ""
            },
            {
                "Date": "7/27/18",
                "Profil modéré": "111.3473113",
                "": ""
            },
            {
                "Date": "8/3/18",
                "Profil modéré": "111.2860811",
                "": ""
            },
            {
                "Date": "8/10/18",
                "Profil modéré": "111.3632655",
                "": ""
            },
            {
                "Date": "8/17/18",
                "Profil modéré": "111.2175631",
                "": ""
            },
            {
                "Date": "8/24/18",
                "Profil modéré": "111.2769295",
                "": ""
            },
            {
                "Date": "8/31/18",
                "Profil modéré": "111.2097526",
                "": ""
            },
            {
                "Date": "9/7/18",
                "Profil modéré": "110.7812891",
                "": ""
            },
            {
                "Date": "9/14/18",
                "Profil modéré": "110.9405629",
                "": ""
            },
            {
                "Date": "9/21/18",
                "Profil modéré": "110.9367251",
                "": ""
            },
            {
                "Date": "9/28/18",
                "Profil modéré": "111.1025488",
                "": ""
            },
            {
                "Date": "10/5/18",
                "Profil modéré": "111.2565422",
                "": ""
            },
            {
                "Date": "10/12/18",
                "Profil modéré": "111.288374",
                "": ""
            },
            {
                "Date": "10/19/18",
                "Profil modéré": "111.4505595",
                "": ""
            },
            {
                "Date": "10/26/18",
                "Profil modéré": "111.5262777",
                "": ""
            },
            {
                "Date": "11/2/18",
                "Profil modéré": "111.4214703",
                "": ""
            },
            {
                "Date": "11/9/18",
                "Profil modéré": "111.4540814",
                "": ""
            },
            {
                "Date": "11/16/18",
                "Profil modéré": "111.6052754",
                "": ""
            },
            {
                "Date": "11/23/18",
                "Profil modéré": "111.6042913",
                "": ""
            },
            {
                "Date": "11/30/18",
                "Profil modéré": "111.7154134",
                "": ""
            },
            {
                "Date": "12/7/18",
                "Profil modéré": "111.8772982",
                "": ""
            },
            {
                "Date": "12/14/18",
                "Profil modéré": "111.9411",
                "": ""
            },
            {
                "Date": "12/21/18",
                "Profil modéré": "112.1073481",
                "": ""
            },
            {
                "Date": "12/28/18",
                "Profil modéré": "112.3203768",
                "": ""
            },
            {
                "Date": "1/4/19",
                "Profil modéré": "112.2799832",
                "": ""
            },
            {
                "Date": "1/14/19",
                "Profil modéré": "112.4253266",
                "": ""
            },
            {
                "Date": "1/18/19",
                "Profil modéré": "112.4816496",
                "": ""
            },
            {
                "Date": "1/25/19",
                "Profil modéré": "112.7260166",
                "": ""
            },
            {
                "Date": "2/1/19",
                "Profil modéré": "112.9801596",
                "": ""
            },
            {
                "Date": "2/8/19",
                "Profil modéré": "113.0556185",
                "": ""
            },
            {
                "Date": "2/15/19",
                "Profil modéré": "113.252238",
                "": ""
            },
            {
                "Date": "2/22/19",
                "Profil modéré": "113.4038999",
                "": ""
            },
            {
                "Date": "3/1/19",
                "Profil modéré": "113.2647612",
                "": ""
            },
            {
                "Date": "3/8/19",
                "Profil modéré": "113.2252774",
                "": ""
            },
            {
                "Date": "3/15/19",
                "Profil modéré": "113.1481255",
                "": ""
            },
            {
                "Date": "3/22/19",
                "Profil modéré": "113.2740495",
                "": ""
            },
            {
                "Date": "3/29/19",
                "Profil modéré": "113.2383614",
                "": ""
            },
            {
                "Date": "4/5/19",
                "Profil modéré": "113.2380348",
                "": ""
            },
            {
                "Date": "4/12/19",
                "Profil modéré": "113.3884547",
                "": ""
            },
            {
                "Date": "4/19/19",
                "Profil modéré": "113.452066",
                "": ""
            },
            {
                "Date": "4/26/19",
                "Profil modéré": "113.6782598",
                "": ""
            },
            {
                "Date": "5/3/19",
                "Profil modéré": "113.7988481",
                "": ""
            },
            {
                "Date": "5/10/19",
                "Profil modéré": "113.9775784",
                "": ""
            },
            {
                "Date": "5/17/19",
                "Profil modéré": "114.1101715",
                "": ""
            },
            {
                "Date": "5/24/19",
                "Profil modéré": "114.0784891",
                "": ""
            },
            {
                "Date": "5/31/19",
                "Profil modéré": "114.2356294",
                "": ""
            },
            {
                "Date": "6/10/19",
                "Profil modéré": "114.4307154",
                "": ""
            },
            {
                "Date": "6/14/19",
                "Profil modéré": "114.7864441",
                "": ""
            },
            {
                "Date": "6/21/19",
                "Profil modéré": "115.3485835",
                "": ""
            },
            {
                "Date": "6/28/19",
                "Profil modéré": "115.4731814",
                "": ""
            },
            {
                "Date": "7/5/19",
                "Profil modéré": "115.3722243",
                "": ""
            },
            {
                "Date": "7/12/19",
                "Profil modéré": "115.6498241",
                "": ""
            },
            {
                "Date": "7/19/19",
                "Profil modéré": "116.0946154",
                "": ""
            },
            {
                "Date": "7/26/19",
                "Profil modéré": "116.122811",
                "": ""
            },
            {
                "Date": "8/2/19",
                "Profil modéré": "116.3734447",
                "": ""
            },
            {
                "Date": "8/9/19",
                "Profil modéré": "116.6645868",
                "": ""
            },
            {
                "Date": "8/16/19",
                "Profil modéré": "116.9614183",
                "": ""
            },
            {
                "Date": "8/23/19",
                "Profil modéré": "116.8935478",
                "": ""
            },
            {
                "Date": "8/30/19",
                "Profil modéré": "117.2537064",
                "": ""
            },
            {
                "Date": "9/6/19",
                "Profil modéré": "117.186747",
                "": ""
            },
            {
                "Date": "9/13/19",
                "Profil modéré": "117.0794346",
                "": ""
            },
            {
                "Date": "9/20/19",
                "Profil modéré": "117.0033329",
                "": ""
            },
            {
                "Date": "9/27/19",
                "Profil modéré": "117.2312328",
                "": ""
            },
            {
                "Date": "10/4/19",
                "Profil modéré": "117.1933622",
                "": ""
            },
            {
                "Date": "10/11/19",
                "Profil modéré": "117.0217163",
                "": ""
            },
            {
                "Date": "10/18/19",
                "Profil modéré": "117.2912305",
                "": ""
            },
            {
                "Date": "10/25/19",
                "Profil modéré": "117.5607286",
                "": ""
            },
            {
                "Date": "11/1/19",
                "Profil modéré": "117.6165576",
                "": ""
            },
            {
                "Date": "11/8/19",
                "Profil modéré": "117.4525704",
                "": ""
            },
            {
                "Date": "11/15/19",
                "Profil modéré": "117.6290181",
                "": ""
            },
            {
                "Date": "11/22/19",
                "Profil modéré": "117.8172159",
                "": ""
            },
            {
                "Date": "11/29/19",
                "Profil modéré": "117.8651566",
                "": ""
            },
            {
                "Date": "12/6/19",
                "Profil modéré": "117.8838031",
                "": ""
            },
            {
                "Date": "12/13/19",
                "Profil modéré": "118.1134567",
                "": ""
            },
            {
                "Date": "12/20/19",
                "Profil modéré": "118.5238913",
                "": ""
            },
            {
                "Date": "12/27/19",
                "Profil modéré": "118.7223049",
                "": ""
            },
            {
                "Date": "1/3/20",
                "Profil modéré": "119.031736",
                "": ""
            },
            {
                "Date": "1/10/20",
                "Profil modéré": "119.2890608",
                "": ""
            },
            {
                "Date": "1/17/20",
                "Profil modéré": "119.6252216",
                "": ""
            },
            {
                "Date": "1/24/20",
                "Profil modéré": "119.8229981",
                "": ""
            },
            {
                "Date": "1/31/20",
                "Profil modéré": "119.866902",
                "": ""
            },
            {
                "Date": "2/7/20",
                "Profil modéré": "119.5437643",
                "": ""
            },
            {
                "Date": "2/14/20",
                "Profil modéré": "119.8324993",
                "": ""
            },
            {
                "Date": "2/21/20",
                "Profil modéré": "120.4457287",
                "": ""
            },
            {
                "Date": "2/28/20",
                "Profil modéré": "119.9159622",
                "": ""
            },
            {
                "Date": "3/6/20",
                "Profil modéré": "119.4759055",
                "": ""
            },
            {
                "Date": "3/13/20",
                "Profil modéré": "117.8693969",
                "": ""
            },
            {
                "Date": "3/20/20",
                "Profil modéré": "116.8025268",
                "": ""
            },
            {
                "Date": "3/27/20",
                "Profil modéré": "117.848106",
                "": ""
            },
            {
                "Date": "4/3/20",
                "Profil modéré": "117.920208",
                "": ""
            },
            {
                "Date": "4/10/20",
                "Profil modéré": "118.209701",
                "": ""
            },
            {
                "Date": "4/17/20",
                "Profil modéré": "118.1392972",
                "": ""
            },
            {
                "Date": "4/24/20",
                "Profil modéré": "118.0639018",
                "": ""
            },
            {
                "Date": "5/4/20",
                "Profil modéré": "118.4400682",
                "": ""
            },
            {
                "Date": "5/8/20",
                "Profil modéré": "118.5067208",
                "": ""
            },
            {
                "Date": "5/15/20",
                "Profil modéré": "118.7215582",
                "": ""
            },
            {
                "Date": "5/22/20",
                "Profil modéré": "119.1220007",
                "": ""
            },
            {
                "Date": "5/29/20",
                "Profil modéré": "119.4954447",
                "": ""
            },
            {
                "Date": "6/5/20",
                "Profil modéré": "119.5259691",
                "": ""
            },
            {
                "Date": "6/12/20",
                "Profil modéré": "119.7433244",
                "": ""
            },
            {
                "Date": "6/19/20",
                "Profil modéré": "120.3409873",
                "": ""
            },
            {
                "Date": "6/26/20",
                "Profil modéré": "121.0140285",
                "": ""
            },
            {
                "Date": "7/3/20",
                "Profil modéré": "121.3411157",
                "": ""
            },
            {
                "Date": "7/10/20",
                "Profil modéré": "121.4157301",
                "": ""
            },
            {
                "Date": "7/17/20",
                "Profil modéré": "121.5325193",
                "": ""
            },
            {
                "Date": "7/24/20",
                "Profil modéré": "122.0148076",
                "": ""
            },
            {
                "Date": "8/3/20",
                "Profil modéré": "122.2591417",
                "": ""
            },
            {
                "Date": "8/7/20",
                "Profil modéré": "122.7535866",
                "": ""
            },
            {
                "Date": "8/17/20",
                "Profil modéré": "122.3954359",
                "": ""
            },
            {
                "Date": "8/24/20",
                "Profil modéré": "122.2634263",
                "": ""
            },
            {
                "Date": "8/28/20",
                "Profil modéré": "122.126667",
                "": ""
            },
            {
                "Date": "9/4/20",
                "Profil modéré": "122.1417932",
                "": ""
            },
            {
                "Date": "9/11/20",
                "Profil modéré": "122.3159973",
                "": ""
            },
            {
                "Date": "9/18/20",
                "Profil modéré": "122.3070683",
                "": ""
            },
            {
                "Date": "9/25/20",
                "Profil modéré": "121.7298439",
                "": ""
            },
            {
                "Date": "10/2/20",
                "Profil modéré": "122.1140087",
                "": ""
            },
            {
                "Date": "10/9/20",
                "Profil modéré": "122.3453227",
                "": ""
            },
            {
                "Date": "10/16/20",
                "Profil modéré": "122.3933957",
                "": ""
            },
            {
                "Date": "10/23/20",
                "Profil modéré": "122.5145603",
                "": ""
            },
            {
                "Date": "11/2/20",
                "Profil modéré": "122.47487",
                "": ""
            },
            {
                "Date": "11/9/20",
                "Profil modéré": "122.3807875",
                "": ""
            },
            {
                "Date": "11/13/20",
                "Profil modéré": "122.6191669",
                "": ""
            },
            {
                "Date": "11/20/20",
                "Profil modéré": "122.6799002",
                "": ""
            },
            {
                "Date": "11/27/20",
                "Profil modéré": "121.9073503",
                "": ""
            },
            {
                "Date": "12/4/20",
                "Profil modéré": "122.1770646",
                "": ""
            },
            {
                "Date": "12/11/20",
                "Profil modéré": "122.6792579",
                "": ""
            },
            {
                "Date": "12/18/20",
                "Profil modéré": "123.392115",
                "": ""
            },
            {
                "Date": "12/25/20",
                "Profil modéré": "123.3712582",
                "": ""
            },
            {
                "Date": "1/4/21",
                "Profil modéré": "123.7965264",
                "": ""
            },
            {
                "Date": "1/8/21",
                "Profil modéré": "123.2896148",
                "": ""
            },
            {
                "Date": "1/15/21",
                "Profil modéré": "123.5079257",
                "": ""
            },
            {
                "Date": "1/22/21",
                "Profil modéré": "123.952249",
                "": ""
            },
            {
                "Date": "1/29/21",
                "Profil modéré": "124.2565394",
                "": ""
            },
            {
                "Date": "2/5/21",
                "Profil modéré": "124.1309288",
                "": ""
            },
            {
                "Date": "2/12/21",
                "Profil modéré": "124.0265382",
                "": ""
            },
            {
                "Date": "2/19/21",
                "Profil modéré": "123.7283642",
                "": ""
            },
            {
                "Date": "2/26/21",
                "Profil modéré": "123.4233878",
                "": ""
            },
            {
                "Date": "3/5/21",
                "Profil modéré": "123.2720404",
                "": ""
            },
            {
                "Date": "3/12/21",
                "Profil modéré": "123.4244823",
                "": ""
            },
            {
                "Date": "3/19/21",
                "Profil modéré": "123.595254",
                "": ""
            },
            {
                "Date": "3/26/21",
                "Profil modéré": "123.7160115",
                "": ""
            },
            {
                "Date": "4/2/21",
                "Profil modéré": "123.7303733",
                "": ""
            },
            {
                "Date": "4/9/21",
                "Profil modéré": "123.7690453",
                "": ""
            },
            {
                "Date": "4/16/21",
                "Profil modéré": "124.078263",
                "": ""
            },
            {
                "Date": "4/23/21",
                "Profil modéré": "124.2039279",
                "": ""
            },
            {
                "Date": "4/30/21",
                "Profil modéré": "124.271723",
                "": ""
            },
            {
                "Date": "5/7/21",
                "Profil modéré": "124.9454191",
                "": ""
            },
            {
                "Date": "5/17/21",
                "Profil modéré": "125.1857845",
                "": ""
            },
            {
                "Date": "5/21/21",
                "Profil modéré": "125.2928292",
                "": ""
            },
            {
                "Date": "5/28/21",
                "Profil modéré": "125.5207479",
                "": ""
            },
            {
                "Date": "6/4/21",
                "Profil modéré": "125.6722741",
                "": ""
            },
            {
                "Date": "6/11/21",
                "Profil modéré": "125.7184841",
                "": ""
            },
            {
                "Date": "6/18/21",
                "Profil modéré": "125.1583825",
                "": ""
            },
            {
                "Date": "6/25/21",
                "Profil modéré": "125.1635974",
                "": ""
            },
            {
                "Date": "7/2/21",
                "Profil modéré": "125.2618519",
                "": ""
            },
            {
                "Date": "7/9/21",
                "Profil modéré": "125.3337321",
                "": ""
            },
            {
                "Date": "7/16/21",
                "Profil modéré": "125.4930616",
                "": ""
            },
            {
                "Date": "7/23/21",
                "Profil modéré": "125.4039932",
                "": ""
            },
            {
                "Date": "8/2/21",
                "Profil modéré": "125.5799547",
                "": ""
            },
            {
                "Date": "8/6/21",
                "Profil modéré": "125.5212934",
                "": ""
            },
            {
                "Date": "8/13/21",
                "Profil modéré": "125.770106",
                "": ""
            },
            {
                "Date": "8/23/21",
                "Profil modéré": "126.0875257",
                "": ""
            },
            {
                "Date": "8/27/21",
                "Profil modéré": "126.1695792",
                "": ""
            },
            {
                "Date": "9/3/21",
                "Profil modéré": "126.2730617",
                "": ""
            },
            {
                "Date": "9/10/21",
                "Profil modéré": "126.3227898",
                "": ""
            },
            {
                "Date": "9/17/21",
                "Profil modéré": "126.3080668",
                "": ""
            },
            {
                "Date": "9/24/21",
                "Profil modéré": "126.4365353",
                "": ""
            },
            {
                "Date": "10/1/21",
                "Profil modéré": "126.5474117",
                "": ""
            },
            {
                "Date": "10/8/21",
                "Profil modéré": "126.8255826",
                "": ""
            },
            {
                "Date": "10/15/21",
                "Profil modéré": "126.9239988",
                "": ""
            },
            {
                "Date": "10/22/21",
                "Profil modéré": "127.2065042",
                "": ""
            },
            {
                "Date": "10/29/21",
                "Profil modéré": "127.2693813",
                "": ""
            },
            {
                "Date": "11/5/21",
                "Profil modéré": "127.3799959",
                "": ""
            },
            {
                "Date": "11/12/21",
                "Profil modéré": "127.7293948",
                "": ""
            },
            {
                "Date": "11/19/21",
                "Profil modéré": "127.8526535",
                "": ""
            },
            {
                "Date": "11/26/21",
                "Profil modéré": "127.4848944",
                "": ""
            },
            {
                "Date": "12/3/21",
                "Profil modéré": "127.2482673",
                "": ""
            },
            {
                "Date": "12/10/21",
                "Profil modéré": "127.5906552",
                "": ""
            },
            {
                "Date": "12/17/21",
                "Profil modéré": "127.7333914",
                "": ""
            },
            {
                "Date": "12/24/21",
                "Profil modéré": "127.7037782",
                "": ""
            },
            {
                "Date": "12/31/21",
                "Profil modéré": "127.9363945",
                "": ""
            },
            {
                "Date": "1/7/22",
                "Profil modéré": "128.0324955",
                "": ""
            },
            {
                "Date": "1/14/22",
                "Profil modéré": "128.3856156",
                "": ""
            },
            {
                "Date": "1/21/22",
                "Profil modéré": "128.5618739",
                "": ""
            },
            {
                "Date": "1/28/22",
                "Profil modéré": "128.3911066",
                "": ""
            },
            {
                "Date": "2/4/22",
                "Profil modéré": "128.4889155",
                "": ""
            },
            {
                "Date": "2/11/22",
                "Profil modéré": "128.7466518",
                "": ""
            },
            {
                "Date": "2/18/22",
                "Profil modéré": "129.0836415",
                "": ""
            },
            {
                "Date": "2/25/22",
                "Profil modéré": "128.5972973",
                "": ""
            },
            {
                "Date": "3/4/22",
                "Profil modéré": "128.8353519",
                "": ""
            },
            {
                "Date": "3/11/22",
                "Profil modéré": "128.9429017",
                "": ""
            },
            {
                "Date": "3/18/22",
                "Profil modéré": "129.0752382",
                "": ""
            },
            {
                "Date": "3/25/22",
                "Profil modéré": "128.8319927",
                "": ""
            },
            {
                "Date": "4/1/22",
                "Profil modéré": "129.0009151",
                "": ""
            },
            {
                "Date": "4/8/22",
                "Profil modéré": "129.2521354",
                "": ""
            },
            {
                "Date": "4/15/22",
                "Profil modéré": "129.528445",
                "": ""
            },
            {
                "Date": "4/22/22",
                "Profil modéré": "129.6136188",
                "": ""
            },
            {
                "Date": "4/29/22",
                "Profil modéré": "129.6263161",
                "": ""
            },
            {
                "Date": "5/6/22",
                "Profil modéré": "129.3836485",
                "": ""
            },
            {
                "Date": "5/13/22",
                "Profil modéré": "128.6541575",
                "": ""
            },
            {
                "Date": "5/20/22",
                "Profil modéré": "128.6501436",
                "": ""
            },
            {
                "Date": "5/27/22",
                "Profil modéré": "128.353742",
                "": ""
            },
            {
                "Date": "6/3/22",
                "Profil modéré": "128.642753",
                "": ""
            },
            {
                "Date": "6/10/22",
                "Profil modéré": "128.630691",
                "": ""
            },
            {
                "Date": "6/17/22",
                "Profil modéré": "128.4528593",
                "": ""
            },
            {
                "Date": "6/24/22",
                "Profil modéré": "128.2275622",
                "": ""
            },
            {
                "Date": "7/1/22",
                "Profil modéré": "127.7822936",
                "": ""
            },
            {
                "Date": "7/8/22",
                "Profil modéré": "127.634725",
                "": ""
            },
            {
                "Date": "7/15/22",
                "Profil modéré": "127.8773862",
                "": ""
            },
            {
                "Date": "7/22/22",
                "Profil modéré": "128.128125",
                "": ""
            },
            {
                "Date": "7/29/22",
                "Profil modéré": "128.1625941",
                "": ""
            },
            {
                "Date": "8/5/22",
                "Profil modéré": "128.5304863",
                "": ""
            },
            {
                "Date": "8/12/22",
                "Profil modéré": "128.957361",
                "": ""
            },
            {
                "Date": "8/19/22",
                "Profil modéré": "128.8442139",
                "": ""
            },
            {
                "Date": "8/26/22",
                "Profil modéré": "128.9634372",
                "": ""
            },
            {
                "Date": "9/2/22",
                "Profil modéré": "128.7601643",
                "": ""
            },
            {
                "Date": "9/9/22",
                "Profil modéré": "128.9108467",
                "": ""
            },
            {
                "Date": "9/16/22",
                "Profil modéré": "128.597903",
                "": ""
            },
            {
                "Date": "9/23/22",
                "Profil modéré": "128.597209",
                "": ""
            },
            {
                "Date": "9/30/22",
                "Profil modéré": "127.8420158",
                "": ""
            },
            {
                "Date": "10/7/22",
                "Profil modéré": "127.9899701",
                "": ""
            },
            {
                "Date": "10/14/22",
                "Profil modéré": "127.4669173",
                "": ""
            },
            {
                "Date": "10/21/22",
                "Profil modéré": "127.0112333",
                "": ""
            },
            {
                "Date": "10/28/22",
                "Profil modéré": "126.8532298",
                "": ""
            },
            {
                "Date": "11/4/22",
                "Profil modéré": "127.069164",
                "": ""
            },
            {
                "Date": "11/11/22",
                "Profil modéré": "126.9720988",
                "": ""
            },
            {
                "Date": "11/21/22",
                "Profil modéré": "127.0853113",
                "": ""
            },
            {
                "Date": "11/25/22",
                "Profil modéré": "127.0902314",
                "": ""
            },
            {
                "Date": "12/2/22",
                "Profil modéré": "127.5291292",
                "": ""
            },
            {
                "Date": "12/9/22",
                "Profil modéré": "127.7394444",
                "": ""
            },
            {
                "Date": "12/16/22",
                "Profil modéré": "127.4774188",
                "": ""
            },
            {
                "Date": "12/23/22",
                "Profil modéré": "127.4687988",
                "": ""
            },
            {
                "Date": "12/30/22",
                "Profil modéré": "127.2687981",
                "": ""
            },
            {
                "Date": "1/6/23",
                "Profil modéré": "125.1881942",
                "": ""
            },
            {
                "Date": "1/13/23",
                "Profil modéré": "125.8281964",
                "": ""
            },
            {
                "Date": "1/20/23",
                "Profil modéré": "126.1680174",
                "": ""
            },
            {
                "Date": "1/27/23",
                "Profil modéré": "126.121659",
                "": ""
            },
            {
                "Date": "2/3/23",
                "Profil modéré": "126.3193016",
                "": ""
            },
            {
                "Date": "2/10/23",
                "Profil modéré": "126.56514",
                "": ""
            },
            {
                "Date": "2/17/23",
                "Profil modéré": "126.4697451",
                "": ""
            },
            {
                "Date": "2/24/23",
                "Profil modéré": "126.5241402",
                "": ""
            },
            {
                "Date": "3/3/23",
                "Profil modéré": "126.6370906",
                "": ""
            },
            {
                "Date": "3/10/23",
                "Profil modéré": "126.7461426",
                "": ""
            },
            {
                "Date": "3/17/23",
                "Profil modéré": "127.3554953",
                "": ""
            },
            {
                "Date": "3/24/23",
                "Profil modéré": "127.467884",
                "": ""
            },
            {
                "Date": "3/31/23",
                "Profil modéré": "127.2332227",
                "": ""
            },
            {
                "Date": "4/7/23",
                "Profil modéré": "127.3838634",
                "": ""
            },
            {
                "Date": "4/14/23",
                "Profil modéré": "127.6441933",
                "": ""
            },
            {
                "Date": "4/21/23",
                "Profil modéré": "127.2712902",
                "": ""
            },
            {
                "Date": "4/28/23",
                "Profil modéré": "127.403986",
                "": ""
            },
            {
                "Date": "5/5/23",
                "Profil modéré": "127.3609931",
                "": ""
            },
            {
                "Date": "5/12/23",
                "Profil modéré": "128.0327863",
                "": ""
            },
            {
                "Date": "5/19/23",
                "Profil modéré": "128.0918765",
                "": ""
            },
            {
                "Date": "5/26/23",
                "Profil modéré": "128.2805661",
                "": ""
            },
            {
                "Date": "6/2/23",
                "Profil modéré": "128.6586051",
                "": ""
            },
            {
                "Date": "6/9/23",
                "Profil modéré": "128.525997",
                "": ""
            },
            {
                "Date": "6/16/23",
                "Profil modéré": "128.7510836",
                "": ""
            },
            {
                "Date": "6/23/23",
                "Profil modéré": "129.4071512",
                "": ""
            },
            {
                "Date": "7/3/23",
                "Profil modéré": "129.5062251",
                "": ""
            },
            {
                "Date": "7/7/23",
                "Profil modéré": "129.4450956",
                "": ""
            },
            {
                "Date": "7/14/23",
                "Profil modéré": "129.6564419",
                "": ""
            },
            {
                "Date": "7/21/23",
                "Profil modéré": "130.1498054",
                "": ""
            },
            {
                "Date": "7/28/23",
                "Profil modéré": "130.4826743",
                "": ""
            },
            {
                "Date": "8/4/23",
                "Profil modéré": "130.6855447",
                "": ""
            },
            {
                "Date": "8/11/23",
                "Profil modéré": "130.5411605",
                "": ""
            },
            {
                "Date": "8/18/23",
                "Profil modéré": "130.5464695",
                "": ""
            },
            {
                "Date": "8/25/23",
                "Profil modéré": "130.8399127",
                "": ""
            },
            {
                "Date": "9/1/23",
                "Profil modéré": "131.1375159",
                "": ""
            },
            {
                "Date": "9/8/23",
                "Profil modéré": "130.9668619",
                "": ""
            },
            {
                "Date": "9/15/23",
                "Profil modéré": "131.2310801",
                "": ""
            },
            {
                "Date": "9/22/23",
                "Profil modéré": "131.0614081",
                "": ""
            },
            {
                "Date": "10/2/23",
                "Profil modéré": "130.8447046",
                "": ""
            },
            {
                "Date": "10/6/23",
                "Profil modéré": "131.3501848",
                "": ""
            },
            {
                "Date": "10/13/23",
                "Profil modéré": "131.9393225",
                "": ""
            },
            {
                "Date": "10/20/23",
                "Profil modéré": "132.6400665",
                "": ""
            },
            {
                "Date": "10/27/23",
                "Profil modéré": "132.4375925",
                "": ""
            },
            {
                "Date": "11/3/23",
                "Profil modéré": "132.5127089",
                "": ""
            },
            {
                "Date": "11/10/23",
                "Profil modéré": "132.1823459",
                "": ""
            },
            {
                "Date": "11/17/23",
                "Profil modéré": "132.5153597",
                "": ""
            },
            {
                "Date": "11/24/23",
                "Profil modéré": "132.8038798",
                "": ""
            },
            {
                "Date": "12/1/23",
                "Profil modéré": "133.1021179",
                "": ""
            },
            {
                "Date": "12/8/23",
                "Profil modéré": "133.1278531",
                "": ""
            },
            {
                "Date": "12/15/23",
                "Profil modéré": "133.7279315",
                "": ""
            },
            {
                "Date": "12/22/23",
                "Profil modéré": "133.6484836",
                "": ""
            },
            {
                "Date": "12/29/23",
                "Profil modéré": "133.7972401",
                "": ""
            },
            {
                "Date": "1/5/24",
                "Profil modéré": "134.2094007",
                "": ""
            },
            {
                "Date": "1/12/24",
                "Profil modéré": "134.815542",
                "": ""
            },
            {
                "Date": "",
                "Profil modéré": "",
                "": ""
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
            setChartData(yourDataSet[0]);
        }
    }, [yourDataSet]);

    const getOption = () => {
        if (!chartData || chartData.length === 0) {
            return {};
        }

        const xAxisData = chartData.map((item) => item.Date);

        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');

        const series = legendData.map((key) => ({
            type: 'line',
            name: key,
            data: chartData.map((item) => {
                const value = item[key];
                const parsedValue = parseFloat(value.replace(',', ' '));
                return isNaN(parsedValue) ? null : parsedValue;
            }),
            itemStyle: {
                color: '#91cc75' // Set the color for the line
            }
        }));

        return {
            animationDuration: 10000,
            tooltip: {
                trigger: 'axis',
            },
            title: {
                text: '',
                left: 'center',

            },
            legend: {
                data: legendData,
                bottom: 10,


            },
            xAxis: {
                type: 'category',
                data: xAxisData,
            },
            yAxis: {
                type: 'value',
                min: 100,
            },
            series,
        };
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactEChartsCore echarts={echarts} option={getOption()} />

            </Col>
        </Row>
    );
};

export default LineChartComponent;
