import React, { Component } from 'react';
import { Card, Form } from 'react-bootstrap';
import '../../src/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class RadioOptionsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
        };
    }

    handleOptionChange = (event) => {
        this.setState({ selectedOption: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
      
        // Find the selected option's URL
        const selectedOption = radioOptions.find(
          (option) => option.value === this.state.selectedOption
        );
      
        if (selectedOption) {
          this.props.history.push(selectedOption.url);
        }
      };
      

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Card className="funnel__box text-center">
                    <Card.Header className="funnel__box-header">
                        <h2 className="funnel__box-title">
                            Parlons de votre projet
                        </h2>
                        <div className="funnel__box-title">
                            
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <p className='funnel__form-label mb-3'>Quel est votre plan d’investissement ?</p>
                        <Form onSubmit={this.handleSubmit}>
                            <div className="radio-options">
                                {radioOptions.map((option) => (
                                    <div
                                    className={`radio-option ${this.state.selectedOption === option.value ? 'selected' : ''}`}
                                    key={option.value}
                                  >
                                        <input
                                            type="radio"
                                            name="typeProjet"
                                            id={option.value}
                                            value={option.value}
                                            checked={this.state.selectedOption === option.value}
                                            onChange={this.handleOptionChange}
                                        />
                                        <label htmlFor={option.value}>
                                            <i className={`icon-project ${option.iconClass}`}></i>
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button className='sous-btn mb-3' type="submit">Démarrer le questionnaire</button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default RadioOptionsCard;

const radioOptions = [
    {
        value: 'EPARGNE',
        iconClass: 'yicon-piggy-bank',
        label: "Plan d'épargne Retraite",
        url: "/roboadvisor"
        
    },
    {
        value: 'EPARGNE_SECURITE',
        iconClass: 'yicon-umbrella',
        label: "Plan d'épargne Education",
        url: "/roboadvisor"
    },
    {
        value: 'EPARGNE_AVC',
        iconClass: 'yicon-umbrella',
        label: "Assurances Vie Classiques",
        url: "/roboadvisor"
    },
    {
        value: 'EPARGNE_UC',
        iconClass: 'yicon-umbrella',
        label: "Assurances Vie : Unités de Comptes",
        url: "/roboadvisor"
    },
    // Add more options here
];
