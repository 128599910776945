import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Card, Col, Row } from 'react-bootstrap';

const ConfidenceBandChart = () => {
    const data = [
        {
            "Année": "2024",
            "Min": "100",
            "Estimation": "100",
            "Max": "100",
            "": ""
        },
        {
            "Année": "2025",
            "Min": "100.9924198",
            "Estimation": "102.125467",
            "Max": "103.4790438",
            "": ""
        },
        {
            "Année": "2026",
            "Min": "102.5467225",
            "Estimation": "104.2432272",
            "Max": "105.0530457",
            "": ""
        },
        {
            "Année": "2027",
            "Min": "104.9521332",
            "Estimation": "106.4913801",
            "Max": "107.5337565",
            "": ""
        },
        {
            "Année": "2028",
            "Min": "107.4001388",
            "Estimation": "108.8002106",
            "Max": "109.8746076",
            "": ""
        },
        {
            "Année": "2029",
            "Min": "109.9363213",
            "Estimation": "111.0279712",
            "Max": "112.2466519",
            "": ""
        },
        {
            "Année": "2030",
            "Min": "112.4916506",
            "Estimation": "113.2318908",
            "Max": "114.0179233",
            "": ""
        }
    ];

    const option = {
        title: {
            text: '',
            left: 'center',
        },
        legend: {
            data: ['Estimation', 'Min', 'Max'],
            bottom: 10,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map(item => item.Année),
        },
        yAxis: {
            type: 'value',
            min: 100,
        },
        series: [
            {
                name: 'Min',
                type: 'line',
                data: data.map(item => parseFloat(item.Min)),
                smooth: true,
                lineStyle: {
                    opacity: 0, // Make the line invisible
                },
                areaStyle: {
                    color: 'blue',
                    opacity: 0.8, // Fill color for the area below the line

                },
            },
            {
                name: 'Max',
                type: 'line',
                data: data.map(item => [item.Année, parseFloat(item.Max)]),
                smooth: true,
                lineStyle: {
                    opacity: 0, // Make the line invisible
                },
                areaStyle: {
                    color: 'green',
                    opacity: 0.5,

                },
            },
            {
                name: 'Estimation',
                type: 'line',
                data: data.map(item => [item.Année, parseFloat(item.Estimation)]),
                smooth: true,
                itemStyle: {
                    color: 'red', // Adjust color as needed
                },
            },
        ],
    };

    return (
        <Row className="g-3">
            <Col>

                <ReactECharts option={option} />

            </Col>
        </Row>
    );
};

export default ConfidenceBandChart;
