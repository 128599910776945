
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from '../helpers/utils';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const StackedLineChart = () => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const customColors = [
    'rgb(0, 0, 255)',   // Custom color 1
    'rgb(0, 128, 0)',   // Custom color 2
    'rgb(255, 0, 0)',   // Custom color 3
    'rgb(255, 255, 0)',  // Custom color 4
    'rgb(255, 165, 0)'  // Custom color 5
  ];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: getColor('dark'),
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: days,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => value.substring(0, 3),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: [
      {
        name: 'Matcha Latte',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: 'white',
          borderColor: customColors[0], // Use the first custom color
          borderWidth: 2
        },
        lineStyle: {
          color: customColors[0], // Use the first custom color
        },
        symbol: 'circle',
        stack: 'product',
        data: [120, 132, 101, 134, 90, 230, 210]
      },
      {
        name: 'Milk Tea',
        type: 'line',
        symbolSize: 10,
        itemStyle: {
          color: 'white',
          borderColor: customColors[1], // Use the second custom color
          borderWidth: 2
        },
        lineStyle: {
          color: customColors[1], // Use the second custom color
        },
        symbol: 'circle',
        stack: 'product',
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        name: 'Cheese Cocoa',
        type: 'line',
        symbolSize: 10,
        itemStyle: {
          color: 'white',
          borderColor: customColors[2], // Use the third custom color
          borderWidth: 2
        },
        lineStyle: {
          color: customColors[2], // Use the third custom color
        },
        symbol: 'circle',
        stack: 'product',
        data: [150, 232, 201, 154, 190, 330, 410]
      },
      {
        name: 'Cheese Brownie',
        type: 'line',
        symbolSize: 10,
        itemStyle: {
          color: 'white',
          borderColor: customColors[3], // Use the fourth custom color
          borderWidth: 2
        },
        lineStyle: {
          color: customColors[3], // Use the fourth custom color
        },
        symbol: 'circle',
        stack: 'product',
        data: [320, 332, 301, 334, 390, 330, 320]
      },
      {
        name: 'Matcha Cocoa',
        type: 'line',
        symbolSize: 10,
        itemStyle: {
          color: 'white',
          borderColor: customColors[4], // Use the fifth custom color
          borderWidth: 2
        },
        lineStyle: {
          color: customColors[4], // Use the fifth custom color
        },
        symbol: 'circle',
        stack: 'product',
        data: [820, 932, 901, 934, 1290, 1330, 1320]
      }
    ],
    grid: { right: 10, left: 5, bottom: 5, top: 8, containLabel: true }
  });
  
  return (
    <Row className="g-3">
        <Col>
          <Card dir="ltr" className="h-100">
            <Card.Body>
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '400px' }}

    />
     </Card.Body>
          </Card>
        </Col>
      </Row>
  );
};

export default StackedLineChart;
