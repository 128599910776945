import React from "react";
import logo from '../assets/robo/logo copy.png';
const Footer = () => (
  <footer className="bg-light p-3 text-center">
   <img src={logo} alt="Your Logo Alt Text" className="logo" />
    <p>
    Fintech Robo Advisor © 2024
    </p>
  </footer>
);

export default Footer;
