// Import the necessary libraries and components
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TitleComponent,
    TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from '../../../helpers/utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Card, Col, Row } from 'react-bootstrap';

// Apply ECharts components
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer,
    LegendComponent
]);

const PieEdgeAlignChart = () => {


    // name = OPCVM, value = profile, label= 1 of 9, 
    const data = [
        {
            value: 35,
            name: 'WAFA GESTION',
            itemStyle: {
                color: '#a5243d',
            },
        },
        {
            value: 32,
            name: 'VALORIS MANAGEMENT',
            itemStyle: {
                color: '#006cff',
            },
        },
        {
            value: 4,
            name: 'CAPITAL TRUST GESTION',
            itemStyle: {
                color: '#be9f3b',
            },
        },
        {
            value: 24,
            name: 'WINEO GESTION',
            itemStyle: {
                color: '#ba181b',
            },
        },
        {
            value: 5,
            name: 'IRG ASSET MANAGEMENT',
            itemStyle: {
                color: '#660708',
            },
        },
        
    ];
    const chartStyle = {
        height: '400px',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };

    const getOption = () => {
        return {
            title: {
                text: "Classification par société de gestion",
                left: 'center',
                textStyle: {
                    fontSize: 14
                }
            },
            series: {
                type: 'sunburst',
                data: data,
                radius: [0, '95%'],
                label: {
                    fontSize: 8,
                    fontWeight: 'bold',
                    formatter: function (params) {
                        return `${params.name} ${params.value} %`;
                    },
                },
                sort: undefined,
                emphasis: {
                    focus: 'ancestor'
                },
                levels: [
                    {},
                    {
                        r0: '10%',
                        r: '85%',
                        itemStyle: {
                            borderWidth: 2
                        },
                       
                    },
                    {
                        r0: '30%',
                        r: '60%',
                        label: {
                            align: 'right'
                        }
                    },
                    {
                        r0: '60%',
                        r: '65%',
                        label: {
                            position: 'outside',
                            padding: 3,
                            silent: false
                        },
                        itemStyle: {
                            borderWidth: 3
                        }
                    }
                ]
            }
        };
    };

    return (
        <div>

            <ReactEChartsCore
                echarts={echarts}
                option={getOption()}
                style={chartStyle}

            />

        </div>
    );
};

export default PieEdgeAlignChart;
