
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from '../helpers/utils';
import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const StackedChart = () => {
  let xAxisData = [];
  let data1 = [];
  let data2 = [];
  let data3 = [];
  let data4 = [];

  for (let i = 0; i < 10; i += 1) {
    xAxisData.push('Class' + (i + 1));
    data1.push((Math.random() * 2).toFixed(2));
    data2.push((Math.random() * 5).toFixed(2));
    data3.push((Math.random() + 0.3).toFixed(2));
    data4.push(-Math.random().toFixed(2));
  }

  const customColors = [
    'rgb(0, 0, 255)',   // Custom color 1
    'rgb(0, 128, 0)',   // Custom color 2
    'rgb(255, 0, 0)',   // Custom color 3
    'rgb(255, 255, 0)'  // Custom color 4
  ];

  const emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: rgbaColor(getColor('dark'), 0.3)
    }
  };

  const getOption = () => ({
    color: customColors,  // Use custom colors
    legend: {
      data: ['Bar1', 'Bar2', 'Bar3', 'Bar4'],
      textStyle: {
        color: getColor('gray-700')
      },
      left: 0
    },
    toolbox: {
      feature: {
        magicType: {
          type: ['stack', 'tiled']
        }
      },
      iconStyle: {
        borderColor: getColor('gray-700'),
        borderWidth: 1
      }
    },
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      data: xAxisData,
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        color: getColor('gray-600')
      },
      axisLine: {
        lineStyle: {
          color: getColor('gray-400')
        }
      }
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLabel: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        name: 'Bar1',
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: data1
      },
      {
        name: 'Bar2',
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: data2
      },
      {
        name: 'Bar3',
        type: 'bar',
        stack: 'two',
        emphasis: emphasisStyle,
        data: data3
      },
      {
        name: 'Bar4',
        type: 'bar',
        stack: 'two',
        emphasis: emphasisStyle,
        data: data4
      }
    ],
    grid: {
      top: '10%',
      bottom: 10,
      left: 5,
      right: 7,
      containLabel: true
    }
  });

  return (
    <Row className="g-3">
        <Col>
          <Card dir="ltr" className="h-100">
            <Card.Body>
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '21.88rem' }}
    />
    </Card.Body>
    </Card>
        </Col>
      </Row>
  );
};

export default StackedChart;
