// Import the necessary libraries and components
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from '../helpers/utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { Card, Col, Row } from 'react-bootstrap';

// Apply ECharts components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const PieEdgeAlignChart = () => {
  const chartRef = useRef(null);


  // name = OPCVM, value = profile, label= 1 of 9, 
  const data = [
    {
      value: 80,
      name: 'Starter',
      label: {
        className: 'pie-slice-starter', // Add custom class for "Starter"
      },
    },
    {
      value: 1048,
      name: 'Starter Pro',
      label: {
        className: 'pie-slice-starter-pro', // Add custom class for "Starter Pro"
      },
    },
    {
      value: 735,
      name: 'Basic',
      label: {
        className: 'pie-slice-basic', // Add custom class for "Basic"
      },
    },
    {
      value: 580,
      name: 'Optimal',
      label: {
        className: 'pie-slice-optimal', // Add custom class for "Optimal"
      },
    },
    {
      value: 484,
      name: 'Business',
      label: {
        className: 'pie-slice-business', // Add custom class for "Business"
      },
    },
    {
      value: 600,
      name: 'Classic addition',
      label: {
        className: 'pie-slice-classic-addition', // Add custom class for "Classic addition"
      },
    },
    {
      value: 300,
      name: 'Premium',
      label: {
        className: 'pie-slice-premium', // Add custom class for "Premium"
      },
    },
    {
      value: 300,
      name: 'Platinum',
      label: {
        className: 'pie-slice-platinum', // Add custom class for "Platinum"
      },
    },
    {
      value: 400,
      name: 'Platinum Pro',
      label: {
        className: 'pie-slice-platinum-pro', // Add custom class for "Platinum Pro"
      },
    },
  ];

  const getOption = () => {
    return {
      title: [
        {
          text: 'Pie Edge Align Chart',
          left: 'center',
          textStyle: {
            color: getColor('gray-600'),
          },
        },
        {
          subtext: 'alignTo: "edge"',
          left: '50%',
          top: '85%',
          textAlign: 'center',
          subtextStyle: {
            color: getColor('gray-700'),
          },
        },
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none',
        },
      },
      series: [
        {
          type: 'pie',
          radius: window.innerWidth < 530 ? '45%' : '60%',
          center: ['50%', '50%'],
          data: data,
          label: {
            position: 'outer',
            alignTo: 'edge',
            edgeDistance: 20,
          },
          left: '5%',
          right: '5%',
          top: 0,
          bottom: 0,
        },
      ],
    };
  };

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '45%' }],
      });
    } else {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '60%' }],
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div>
      <Row className="g-3">
        <Col>
          <Card dir="ltr" className="h-100">
            <Card.Header light={false}>Pie chart</Card.Header>
            <Card.Body>
              <ReactEChartsCore
                echarts={echarts}
                option={getOption()}
                ref={chartRef}
                
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PieEdgeAlignChart;
