import React from 'react'
import { Row, Card, Col } from 'react-bootstrap'


const StepOne = ({ selections, setSelections }) => {
    const handleRadioChange = (value) => {
        setSelections({ ...selections, age: value });
    };

    return (
        <>
            <Row>
                <div className="d-flex justify-content-center align-items-center">
                    <Card className="funnel__box text-center">
                        <Card.Header className="funnel__box-header">
                            <h2 className="funnel__box-title">
                                Parlons de votre projet
                            </h2>
                            <div className="funnel__box-title">
                                Question 2/24
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p className='funnel__form-label mb-3'>Quel est votre age ?</p>
                            <Col className="radio-col">
                                <input
                                    type="radio"
                                    id="age-less-than-30"
                                    name="age"
                                    value="Moins de 30 ans"
                                    checked={selections.age === 'Moins de 30 ans'}
                                    onChange={() => handleRadioChange('Moins de 30 ans')}
                                />
                                <label htmlFor="age-less-than-30" className="radio-label">
                                    Moins de 30 ans
                                </label>
                            </Col>
                            <Col className="radio-col">
                                <input
                                    type="radio"
                                    id="age-between-31-and-40"
                                    name="age"
                                    value="Entre 31 et 40 ans"
                                    checked={selections.age === 'Entre 31 et 40 ans'}
                                    onChange={() => handleRadioChange('Entre 31 et 40 ans')}
                                />
                                <label htmlFor="age-between-31-and-40" className="radio-label">
                                    Entre 31 et 40 ans
                                </label>
                            </Col>
                            <Col className="radio-col">
                                <input
                                    type="radio"
                                    id="age-between-41-and-50"
                                    name="age"
                                    value="Entre 41 et 50 ans"
                                    checked={selections.age === 'Entre 41 et 50 ans'}
                                    onChange={() => handleRadioChange('Entre 41 et 50 ans')}
                                />
                                <label htmlFor="age-between-41-and-50" className="radio-label">
                                    Entre 41 et 50 ans
                                </label>
                            </Col>
                            <Col className="radio-col">
                                <input
                                    type="radio"
                                    id="age-between-51-and-60"
                                    name="age"
                                    value="Entre 51 et 60 ans"
                                    checked={selections.age === 'Entre 51 et 60 ans'}
                                    onChange={() => handleRadioChange('Entre 51 et 60 ans')}
                                />
                                <label htmlFor="age-between-51-and-60" className="radio-label">
                                    Entre 51 et 60 ans
                                </label>
                            </Col>
                            <Col className="radio-col">
                                <input
                                    type="radio"
                                    id="age-over-60"
                                    name="age"
                                    value="Plus de 60 ans"
                                    checked={selections.age === 'Plus de 60 ans'}
                                    onChange={() => handleRadioChange('Plus de 60 ans')}
                                />
                                <label htmlFor="age-over-60" className="radio-label">
                                    Plus de 60 ans
                                </label>
                            </Col>

                        </Card.Body>
                    </Card>
                </div>

            </Row>
        </>
    );
};

export default StepOne;