import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Agressif from '../../components/charts/agressif/chart-actifs';
import Prudent from '../../components/charts/prudent/chart-actif';
import Modéré from '../../components/charts/modéré/chart-actifs';
import Dynamique from '../../components/charts/dynamique/chart-actifs';

const SuccessPage = ({ selections, onRestart, onDownload }) => {
  const { selectedProfiles } = selections;

  const getMaxProfile = () => {
    const maxScore = Math.max(...Object.values(selectedProfiles));
    const maxProfile = Object.keys(selectedProfiles).find(profile => selectedProfiles[profile] === maxScore);
    return maxProfile;
  };

 

  
  const maxProfile = getMaxProfile();

  const renderMaxProfileComponent = () => {
    switch (maxProfile) {
      case 'agressif':
        return <Agressif />;
      case 'prudent':
        return <Prudent />;
      case 'modéré':
        const prudentScore = selectedProfiles.prudent;
        const agressifScore = selectedProfiles.agressif;
        const modereScore = selectedProfiles.modéré;
  
        const diffToPrudent = Math.abs(modereScore - prudentScore);
        const diffToAgressif = Math.abs(modereScore - agressifScore);
  
        if (diffToPrudent < diffToAgressif) {
          return <Modéré />;
        } else {
          return <Dynamique />; 
        }
      default:
        return null;
    }
  };
  

  return (
    <div>
      <Row>
        <Card className='mb-3'>
          <Card.Body>
         {/*  <p>Prudent: {selectedProfiles.prudent}</p>
              <p>Modéré: {selectedProfiles.modéré}</p>
              <p>Agressif: {selectedProfiles.agressif}</p> */}
            
            {renderMaxProfileComponent()}
          </Card.Body>
        </Card>
      </Row>
      <Row className='mb-3'>
        <Col className='mb-3' xxl={6} lg={12}>
          {/* <Button style={{ marginRight: 20 }} onClick={onDownload} className='mb-3'>
            Télécharger la simulation
          </Button> */}
          <Button onClick={onRestart} className='mb-3'>
            Refaire la simulation
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SuccessPage;