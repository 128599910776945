import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Card, Col, Row } from 'react-bootstrap';

const ConfidenceBandChart = () => {
  const data = [
    {
        "Année": "2024",
        "Min": "100",
        "Estimation": "100",
        "Max": "100"
    },
    {
        "Année": "2025",
        "Min": "90,08739602",
        "Estimation": "104,6224267",
        "Max": "119,7355632"
    },
    {
        "Année": "2026",
        "Min": "96,50021256",
        "Estimation": "108,2969276",
        "Max": "127,4345391"
    },
    {
        "Année": "2027",
        "Min": "99,90681601",
        "Estimation": "112,9306308",
        "Max": "123,2128004"
    },
    {
        "Année": "2028",
        "Min": "108,9376741",
        "Estimation": "117,1815669",
        "Max": "135,3198357"
    },
    {
        "Année": "2029",
        "Min": "106,152498",
        "Estimation": "122,6782081",
        "Max": "138,0690442"
    },
    {
        "Année": "2030",
        "Min": "113,4246051",
        "Estimation": "126,7242758",
        "Max": "149,9843119"
    }
];

  const option = {
    title: {
        text: '',
        left: 'center',
      },
      legend: {
        data: ['Estimation', 'Min', 'Max'],
        bottom: 10,
      },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map(item => item.Année),
    },
    yAxis: {
      type: 'value',
      min: 95,
    },
    series: [
        {
            name: 'Min',
            type: 'line',
            data: data.map(item => parseFloat(item.Min)),
            smooth: true,
            lineStyle: {
              opacity: 0, // Make the line invisible
            },
            areaStyle: {
                color: 'blue', // Fill color for the area below the line
              opacity: 0.8,
            },
          },
      {
        name: 'Max',
        type: 'line',
        data: data.map(item => [item.Année, parseFloat(item.Max)]),
        smooth: true,
        lineStyle: {
            opacity: 0, // Make the line invisible
          },
          areaStyle: {
            color: 'green',
            opacity: 0.5,
          },
      },
      {
        name: 'Estimation',
        type: 'line',
        data: data.map(item => [item.Année, parseFloat(item.Estimation)]),
        smooth: true,
        itemStyle: {
          color: 'red', // Adjust color as needed
        },
      },
    ],
  };

  return (
    <Row className="g-3">
        <Col>
           
                    <ReactECharts option={option} />
                
        </Col>
    </Row>
);
};

export default ConfidenceBandChart;
