import React, { Component } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import StatM from '../../src/components/statistiques/modere'
import StatA from '../../src/components/statistiques/agressif'
import StatP from '../../src/components/statistiques/prudent'
import StackedP from '../../src/components/statistiques/StackedP'
import StackedM from '../../src/components/statistiques/StackedM'
import StackedA from '../../src/components/statistiques/StackedA'
import LineA from '../../src/components/statistiques/lineA'
import LineM from '../../src/components/statistiques/lineM'
import LineP from '../../src/components/statistiques/lineP'
import AreaA from '../../src/components/statistiques/areaA'
import AreaM from '../../src/components/statistiques/areaM'
import AreaP from '../../src/components/statistiques/areaP'

export default class statistiques extends Component {
    render() {
        return (
            <>
                <div>
                    <Row className="g-3">
                        <Col lg={4} md={12}>
                            <StatP />
                        </Col>
                        <Col lg={4} md={12}>
                            <StatM />
                        </Col>
                        <Col lg={4} md={12}>
                            <StatA />
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={12}>
                            <StackedP />
                        </Col>
                        <Col lg={4} md={12}>
                            <StackedM />
                        </Col>
                        <Col lg={4} md={12}>
                            <StackedA />
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={12}>
                            <LineP />
                        </Col>
                        <Col lg={4} md={12}>
                            <LineM />
                        </Col>
                        <Col lg={4} md={12}>
                            <LineA />
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={12}>
                            <AreaP />
                        </Col>
                        <Col lg={4} md={12}>
                            <AreaM />
                        </Col>
                        <Col lg={4} md={12}>
                            <AreaA />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
