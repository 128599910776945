import React, { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { Card, Col, Row } from 'react-bootstrap';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    AxisPointerComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    LineChart,
    AxisPointerComponent,
  ]);
  
  const LineChartComponent = () => {
    const [chartData, setChartData] = useState([]);
    const yourDataSet = [
        [
            {
                "Date": "12/28/15",
                "Profil modéré": "100",
                "OPC DIVERSIFIE": "100"
            },
            {
                "Date": "1/4/16",
                "Profil modéré": "100.0772517",
                "OPC DIVERSIFIE": "100.0259493"
            },
            {
                "Date": "1/8/16",
                "Profil modéré": "100.1953103",
                "OPC DIVERSIFIE": "100.0213162"
            },
            {
                "Date": "1/15/16",
                "Profil modéré": "100.1297757",
                "OPC DIVERSIFIE": "100.153864"
            },
            {
                "Date": "1/22/16",
                "Profil modéré": "100.3295867",
                "OPC DIVERSIFIE": "100.3564656"
            },
            {
                "Date": "1/29/16",
                "Profil modéré": "100.5245552",
                "OPC DIVERSIFIE": "100.5775727"
            },
            {
                "Date": "2/5/16",
                "Profil modéré": "100.8427128",
                "OPC DIVERSIFIE": "100.9866485"
            },
            {
                "Date": "2/12/16",
                "Profil modéré": "101.4782698",
                "OPC DIVERSIFIE": "101.1474136"
            },
            {
                "Date": "2/19/16",
                "Profil modéré": "101.6606767",
                "OPC DIVERSIFIE": "101.5520239"
            },
            {
                "Date": "2/26/16",
                "Profil modéré": "101.6680403",
                "OPC DIVERSIFIE": "101.2129769"
            },
            {
                "Date": "3/4/16",
                "Profil modéré": "102.1519919",
                "OPC DIVERSIFIE": "101.5463914"
            },
            {
                "Date": "3/11/16",
                "Profil modéré": "102.1255791",
                "OPC DIVERSIFIE": "101.8843795"
            },
            {
                "Date": "3/18/16",
                "Profil modéré": "102.1433075",
                "OPC DIVERSIFIE": "102.2649728"
            },
            {
                "Date": "3/25/16",
                "Profil modéré": "102.2043463",
                "OPC DIVERSIFIE": "102.7044331"
            },
            {
                "Date": "4/1/16",
                "Profil modéré": "102.2013365",
                "OPC DIVERSIFIE": "102.5654834"
            },
            {
                "Date": "4/8/16",
                "Profil modéré": "102.5469937",
                "OPC DIVERSIFIE": "102.8862032"
            },
            {
                "Date": "4/15/16",
                "Profil modéré": "102.915281",
                "OPC DIVERSIFIE": "103.5196356"
            },
            {
                "Date": "4/22/16",
                "Profil modéré": "103.2885791",
                "OPC DIVERSIFIE": "103.7175641"
            },
            {
                "Date": "4/29/16",
                "Profil modéré": "103.8252525",
                "OPC DIVERSIFIE": "104.679789"
            },
            {
                "Date": "5/6/16",
                "Profil modéré": "104.0791486",
                "OPC DIVERSIFIE": "105.1967753"
            },
            {
                "Date": "5/13/16",
                "Profil modéré": "104.091083",
                "OPC DIVERSIFIE": "105.1117857"
            },
            {
                "Date": "5/20/16",
                "Profil modéré": "103.963296",
                "OPC DIVERSIFIE": "104.7832773"
            },
            {
                "Date": "5/27/16",
                "Profil modéré": "103.7009796",
                "OPC DIVERSIFIE": "104.6795818"
            },
            {
                "Date": "6/3/16",
                "Profil modéré": "103.9136688",
                "OPC DIVERSIFIE": "104.8788907"
            },
            {
                "Date": "6/10/16",
                "Profil modéré": "104.155012",
                "OPC DIVERSIFIE": "104.832097"
            },
            {
                "Date": "6/17/16",
                "Profil modéré": "104.3158183",
                "OPC DIVERSIFIE": "104.7113657"
            },
            {
                "Date": "6/24/16",
                "Profil modéré": "104.5167526",
                "OPC DIVERSIFIE": "104.6332386"
            },
            {
                "Date": "7/1/16",
                "Profil modéré": "104.6568621",
                "OPC DIVERSIFIE": "104.6831783"
            },
            {
                "Date": "7/11/16",
                "Profil modéré": "104.9108125",
                "OPC DIVERSIFIE": "104.8244744"
            },
            {
                "Date": "7/15/16",
                "Profil modéré": "104.6579841",
                "OPC DIVERSIFIE": "105.2918012"
            },
            {
                "Date": "7/22/16",
                "Profil modéré": "104.6654812",
                "OPC DIVERSIFIE": "105.5072401"
            },
            {
                "Date": "7/29/16",
                "Profil modéré": "104.8025569",
                "OPC DIVERSIFIE": "105.6408159"
            },
            {
                "Date": "8/5/16",
                "Profil modéré": "104.8440242",
                "OPC DIVERSIFIE": "105.7961343"
            },
            {
                "Date": "8/12/16",
                "Profil modéré": "104.7813753",
                "OPC DIVERSIFIE": "105.6654364"
            },
            {
                "Date": "8/19/16",
                "Profil modéré": "104.7241793",
                "OPC DIVERSIFIE": "105.7432106"
            },
            {
                "Date": "8/26/16",
                "Profil modéré": "104.5669046",
                "OPC DIVERSIFIE": "105.7162367"
            },
            {
                "Date": "9/2/16",
                "Profil modéré": "104.6806813",
                "OPC DIVERSIFIE": "105.7784926"
            },
            {
                "Date": "9/9/16",
                "Profil modéré": "104.749847",
                "OPC DIVERSIFIE": "105.7873835"
            },
            {
                "Date": "9/16/16",
                "Profil modéré": "104.655788",
                "OPC DIVERSIFIE": "105.7202286"
            },
            {
                "Date": "9/23/16",
                "Profil modéré": "104.9232845",
                "OPC DIVERSIFIE": "105.8467607"
            },
            {
                "Date": "9/30/16",
                "Profil modéré": "105.0141626",
                "OPC DIVERSIFIE": "106.2825508"
            },
            {
                "Date": "10/7/16",
                "Profil modéré": "104.7320942",
                "OPC DIVERSIFIE": "106.7193389"
            },
            {
                "Date": "10/14/16",
                "Profil modéré": "104.9434754",
                "OPC DIVERSIFIE": "107.0566494"
            },
            {
                "Date": "10/21/16",
                "Profil modéré": "105.3952677",
                "OPC DIVERSIFIE": "107.650729"
            },
            {
                "Date": "10/28/16",
                "Profil modéré": "105.54612",
                "OPC DIVERSIFIE": "107.9402677"
            },
            {
                "Date": "11/4/16",
                "Profil modéré": "105.7397029",
                "OPC DIVERSIFIE": "108.0764958"
            },
            {
                "Date": "11/11/16",
                "Profil modéré": "105.5300605",
                "OPC DIVERSIFIE": "108.3366328"
            },
            {
                "Date": "11/21/16",
                "Profil modéré": "105.7636117",
                "OPC DIVERSIFIE": "108.8236427"
            },
            {
                "Date": "11/25/16",
                "Profil modéré": "105.4852506",
                "OPC DIVERSIFIE": "108.7954795"
            },
            {
                "Date": "12/2/16",
                "Profil modéré": "105.5625033",
                "OPC DIVERSIFIE": "109.1064042"
            },
            {
                "Date": "12/9/16",
                "Profil modéré": "105.7501417",
                "OPC DIVERSIFIE": "109.7643131"
            },
            {
                "Date": "12/16/16",
                "Profil modéré": "105.8619084",
                "OPC DIVERSIFIE": "110.5691168"
            },
            {
                "Date": "12/23/16",
                "Profil modéré": "106.4883219",
                "OPC DIVERSIFIE": "112.2544338"
            },
            {
                "Date": "12/30/16",
                "Profil modéré": "106.3276646",
                "OPC DIVERSIFIE": "111.7461809"
            },
            {
                "Date": "1/6/17",
                "Profil modéré": "107.4319654",
                "OPC DIVERSIFIE": "114.77345"
            },
            {
                "Date": "1/13/17",
                "Profil modéré": "107.5259311",
                "OPC DIVERSIFIE": "115.0066414"
            },
            {
                "Date": "1/20/17",
                "Profil modéré": "107.464629",
                "OPC DIVERSIFIE": "114.5166043"
            },
            {
                "Date": "1/27/17",
                "Profil modéré": "107.3796543",
                "OPC DIVERSIFIE": "114.3904199"
            },
            {
                "Date": "2/3/17",
                "Profil modéré": "107.4275994",
                "OPC DIVERSIFIE": "113.6551565"
            },
            {
                "Date": "2/10/17",
                "Profil modéré": "107.6400456",
                "OPC DIVERSIFIE": "113.8120647"
            },
            {
                "Date": "2/17/17",
                "Profil modéré": "107.6827596",
                "OPC DIVERSIFIE": "113.4467669"
            },
            {
                "Date": "2/24/17",
                "Profil modéré": "107.6615788",
                "OPC DIVERSIFIE": "112.3408255"
            },
            {
                "Date": "3/3/17",
                "Profil modéré": "107.6632583",
                "OPC DIVERSIFIE": "112.8351434"
            },
            {
                "Date": "3/10/17",
                "Profil modéré": "107.5626007",
                "OPC DIVERSIFIE": "112.9068791"
            },
            {
                "Date": "3/17/17",
                "Profil modéré": "107.7784684",
                "OPC DIVERSIFIE": "113.1773824"
            },
            {
                "Date": "3/24/17",
                "Profil modéré": "107.6778744",
                "OPC DIVERSIFIE": "111.8708027"
            },
            {
                "Date": "3/31/17",
                "Profil modéré": "107.6803958",
                "OPC DIVERSIFIE": "111.6696347"
            },
            {
                "Date": "4/7/17",
                "Profil modéré": "107.8293957",
                "OPC DIVERSIFIE": "111.4542634"
            },
            {
                "Date": "4/14/17",
                "Profil modéré": "107.9227567",
                "OPC DIVERSIFIE": "111.3144331"
            },
            {
                "Date": "4/21/17",
                "Profil modéré": "108.0342988",
                "OPC DIVERSIFIE": "111.7338564"
            },
            {
                "Date": "4/28/17",
                "Profil modéré": "108.1236946",
                "OPC DIVERSIFIE": "112.6936942"
            },
            {
                "Date": "5/5/17",
                "Profil modéré": "107.9435134",
                "OPC DIVERSIFIE": "113.0453184"
            },
            {
                "Date": "5/12/17",
                "Profil modéré": "108.0204513",
                "OPC DIVERSIFIE": "113.1383331"
            },
            {
                "Date": "5/19/17",
                "Profil modéré": "108.1025693",
                "OPC DIVERSIFIE": "113.0858653"
            },
            {
                "Date": "5/26/17",
                "Profil modéré": "108.2607775",
                "OPC DIVERSIFIE": "113.0918733"
            },
            {
                "Date": "6/2/17",
                "Profil modéré": "108.3896072",
                "OPC DIVERSIFIE": "113.3081792"
            },
            {
                "Date": "6/9/17",
                "Profil modéré": "108.5230593",
                "OPC DIVERSIFIE": "113.6834839"
            },
            {
                "Date": "6/16/17",
                "Profil modéré": "108.5370632",
                "OPC DIVERSIFIE": "113.9943597"
            },
            {
                "Date": "6/23/17",
                "Profil modéré": "108.7773349",
                "OPC DIVERSIFIE": "114.7534784"
            },
            {
                "Date": "6/30/17",
                "Profil modéré": "108.6754872",
                "OPC DIVERSIFIE": "115.0199959"
            },
            {
                "Date": "7/7/17",
                "Profil modéré": "108.7622592",
                "OPC DIVERSIFIE": "116.2428448"
            },
            {
                "Date": "7/14/17",
                "Profil modéré": "108.8414543",
                "OPC DIVERSIFIE": "116.1667444"
            },
            {
                "Date": "7/21/17",
                "Profil modéré": "108.7998334",
                "OPC DIVERSIFIE": "115.5493803"
            },
            {
                "Date": "7/28/17",
                "Profil modéré": "109.1371213",
                "OPC DIVERSIFIE": "116.1082618"
            },
            {
                "Date": "8/4/17",
                "Profil modéré": "109.0364927",
                "OPC DIVERSIFIE": "115.702696"
            },
            {
                "Date": "8/11/17",
                "Profil modéré": "109.3723319",
                "OPC DIVERSIFIE": "116.2855486"
            },
            {
                "Date": "8/18/17",
                "Profil modéré": "109.531544",
                "OPC DIVERSIFIE": "116.2337289"
            },
            {
                "Date": "8/25/17",
                "Profil modéré": "109.4517018",
                "OPC DIVERSIFIE": "116.1493649"
            },
            {
                "Date": "9/4/17",
                "Profil modéré": "109.8813832",
                "OPC DIVERSIFIE": "116.7114452"
            },
            {
                "Date": "9/8/17",
                "Profil modéré": "110.107955",
                "OPC DIVERSIFIE": "117.3518141"
            },
            {
                "Date": "9/15/17",
                "Profil modéré": "110.20657",
                "OPC DIVERSIFIE": "117.7456163"
            },
            {
                "Date": "9/25/17",
                "Profil modéré": "109.9565422",
                "OPC DIVERSIFIE": "117.0600969"
            },
            {
                "Date": "9/29/17",
                "Profil modéré": "109.9872429",
                "OPC DIVERSIFIE": "116.2879106"
            },
            {
                "Date": "10/6/17",
                "Profil modéré": "110.1607141",
                "OPC DIVERSIFIE": "117.0898553"
            },
            {
                "Date": "10/13/17",
                "Profil modéré": "110.6231951",
                "OPC DIVERSIFIE": "117.449292"
            },
            {
                "Date": "10/20/17",
                "Profil modéré": "110.3886748",
                "OPC DIVERSIFIE": "116.8943442"
            },
            {
                "Date": "10/27/17",
                "Profil modéré": "110.520051",
                "OPC DIVERSIFIE": "117.1929918"
            },
            {
                "Date": "11/3/17",
                "Profil modéré": "110.5754297",
                "OPC DIVERSIFIE": "117.3791957"
            },
            {
                "Date": "11/10/17",
                "Profil modéré": "110.8963406",
                "OPC DIVERSIFIE": "117.9501774"
            },
            {
                "Date": "11/17/17",
                "Profil modéré": "110.845666",
                "OPC DIVERSIFIE": "117.8648231"
            },
            {
                "Date": "11/24/17",
                "Profil modéré": "110.8108338",
                "OPC DIVERSIFIE": "117.7667347"
            },
            {
                "Date": "12/4/17",
                "Profil modéré": "110.6574644",
                "OPC DIVERSIFIE": "117.3887226"
            },
            {
                "Date": "12/8/17",
                "Profil modéré": "110.5311225",
                "OPC DIVERSIFIE": "117.2027736"
            },
            {
                "Date": "12/15/17",
                "Profil modéré": "110.5697535",
                "OPC DIVERSIFIE": "117.2027184"
            },
            {
                "Date": "12/22/17",
                "Profil modéré": "110.4552155",
                "OPC DIVERSIFIE": "116.4498966"
            },
            {
                "Date": "12/29/17",
                "Profil modéré": "110.7629356",
                "OPC DIVERSIFIE": "117.1484942"
            },
            {
                "Date": "1/5/18",
                "Profil modéré": "110.9952408",
                "OPC DIVERSIFIE": "117.6370489"
            },
            {
                "Date": "1/12/18",
                "Profil modéré": "111.2135198",
                "OPC DIVERSIFIE": "118.1943134"
            },
            {
                "Date": "1/19/18",
                "Profil modéré": "111.4009874",
                "OPC DIVERSIFIE": "119.014703"
            },
            {
                "Date": "1/26/18",
                "Profil modéré": "111.7392253",
                "OPC DIVERSIFIE": "119.3606501"
            },
            {
                "Date": "2/2/18",
                "Profil modéré": "111.7619069",
                "OPC DIVERSIFIE": "119.9398057"
            },
            {
                "Date": "2/9/18",
                "Profil modéré": "111.7347593",
                "OPC DIVERSIFIE": "119.4809297"
            },
            {
                "Date": "2/16/18",
                "Profil modéré": "111.9848555",
                "OPC DIVERSIFIE": "119.699381"
            },
            {
                "Date": "2/23/18",
                "Profil modéré": "112.0222567",
                "OPC DIVERSIFIE": "119.7930638"
            },
            {
                "Date": "3/2/18",
                "Profil modéré": "112.0333535",
                "OPC DIVERSIFIE": "119.844352"
            },
            {
                "Date": "3/9/18",
                "Profil modéré": "112.1087623",
                "OPC DIVERSIFIE": "119.9486547"
            },
            {
                "Date": "3/16/18",
                "Profil modéré": "111.9473476",
                "OPC DIVERSIFIE": "119.7097764"
            },
            {
                "Date": "3/23/18",
                "Profil modéré": "112.1565881",
                "OPC DIVERSIFIE": "119.2926508"
            },
            {
                "Date": "3/30/18",
                "Profil modéré": "112.0454664",
                "OPC DIVERSIFIE": "119.4547321"
            },
            {
                "Date": "4/6/18",
                "Profil modéré": "112.000031",
                "OPC DIVERSIFIE": "119.1236087"
            },
            {
                "Date": "4/13/18",
                "Profil modéré": "112.0704576",
                "OPC DIVERSIFIE": "119.3876864"
            },
            {
                "Date": "4/20/18",
                "Profil modéré": "112.1183264",
                "OPC DIVERSIFIE": "119.4212509"
            },
            {
                "Date": "4/27/18",
                "Profil modéré": "112.1726858",
                "OPC DIVERSIFIE": "119.7434561"
            },
            {
                "Date": "5/4/18",
                "Profil modéré": "112.1525167",
                "OPC DIVERSIFIE": "119.7414348"
            },
            {
                "Date": "5/11/18",
                "Profil modéré": "112.1220226",
                "OPC DIVERSIFIE": "119.0668595"
            },
            {
                "Date": "5/18/18",
                "Profil modéré": "111.7891117",
                "OPC DIVERSIFIE": "118.4163529"
            },
            {
                "Date": "5/25/18",
                "Profil modéré": "111.9829951",
                "OPC DIVERSIFIE": "118.3794116"
            },
            {
                "Date": "6/1/18",
                "Profil modéré": "111.7432481",
                "OPC DIVERSIFIE": "117.7498459"
            },
            {
                "Date": "6/8/18",
                "Profil modéré": "111.9467885",
                "OPC DIVERSIFIE": "118.2740536"
            },
            {
                "Date": "6/18/18",
                "Profil modéré": "111.9336091",
                "OPC DIVERSIFIE": "118.001493"
            },
            {
                "Date": "6/22/18",
                "Profil modéré": "111.8310159",
                "OPC DIVERSIFIE": "117.9179707"
            },
            {
                "Date": "6/29/18",
                "Profil modéré": "111.5120968",
                "OPC DIVERSIFIE": "117.3355327"
            },
            {
                "Date": "7/6/18",
                "Profil modéré": "111.3295989",
                "OPC DIVERSIFIE": "116.9567106"
            },
            {
                "Date": "7/13/18",
                "Profil modéré": "111.1193425",
                "OPC DIVERSIFIE": "116.2398961"
            },
            {
                "Date": "7/20/18",
                "Profil modéré": "111.0398378",
                "OPC DIVERSIFIE": "116.2064962"
            },
            {
                "Date": "7/27/18",
                "Profil modéré": "111.3473113",
                "OPC DIVERSIFIE": "117.295746"
            },
            {
                "Date": "8/3/18",
                "Profil modéré": "111.2860811",
                "OPC DIVERSIFIE": "117.1910928"
            },
            {
                "Date": "8/10/18",
                "Profil modéré": "111.3632655",
                "OPC DIVERSIFIE": "117.228333"
            },
            {
                "Date": "8/17/18",
                "Profil modéré": "111.2175631",
                "OPC DIVERSIFIE": "117.2181848"
            },
            {
                "Date": "8/24/18",
                "Profil modéré": "111.2769295",
                "OPC DIVERSIFIE": "117.1315978"
            },
            {
                "Date": "8/31/18",
                "Profil modéré": "111.2097526",
                "OPC DIVERSIFIE": "116.8238147"
            },
            {
                "Date": "9/7/18",
                "Profil modéré": "110.7812891",
                "OPC DIVERSIFIE": "115.3467653"
            },
            {
                "Date": "9/14/18",
                "Profil modéré": "110.9405629",
                "OPC DIVERSIFIE": "115.7132382"
            },
            {
                "Date": "9/21/18",
                "Profil modéré": "110.9367251",
                "OPC DIVERSIFIE": "115.7976736"
            },
            {
                "Date": "9/28/18",
                "Profil modéré": "111.1025488",
                "OPC DIVERSIFIE": "116.0153555"
            },
            {
                "Date": "10/5/18",
                "Profil modéré": "111.2565422",
                "OPC DIVERSIFIE": "115.8509208"
            },
            {
                "Date": "10/12/18",
                "Profil modéré": "111.288374",
                "OPC DIVERSIFIE": "115.6684804"
            },
            {
                "Date": "10/19/18",
                "Profil modéré": "111.4505595",
                "OPC DIVERSIFIE": "115.5267616"
            },
            {
                "Date": "10/26/18",
                "Profil modéré": "111.5262777",
                "OPC DIVERSIFIE": "115.3906767"
            },
            {
                "Date": "11/2/18",
                "Profil modéré": "111.4214703",
                "OPC DIVERSIFIE": "114.8287358"
            },
            {
                "Date": "11/9/18",
                "Profil modéré": "111.4540814",
                "OPC DIVERSIFIE": "115.3326939"
            },
            {
                "Date": "11/16/18",
                "Profil modéré": "111.6052754",
                "OPC DIVERSIFIE": "115.3766722"
            },
            {
                "Date": "11/23/18",
                "Profil modéré": "111.6042913",
                "OPC DIVERSIFIE": "115.2885973"
            },
            {
                "Date": "11/30/18",
                "Profil modéré": "111.7154134",
                "OPC DIVERSIFIE": "115.8304386"
            },
            {
                "Date": "12/7/18",
                "Profil modéré": "111.8772982",
                "OPC DIVERSIFIE": "115.7367008"
            },
            {
                "Date": "12/14/18",
                "Profil modéré": "111.9411",
                "OPC DIVERSIFIE": "115.6270014"
            },
            {
                "Date": "12/21/18",
                "Profil modéré": "112.1073481",
                "OPC DIVERSIFIE": "115.6668175"
            },
            {
                "Date": "12/28/18",
                "Profil modéré": "112.3203768",
                "OPC DIVERSIFIE": "115.6203948"
            },
            {
                "Date": "1/4/19",
                "Profil modéré": "112.2799832",
                "OPC DIVERSIFIE": "115.5090637"
            },
            {
                "Date": "1/14/19",
                "Profil modéré": "112.4253266",
                "OPC DIVERSIFIE": "115.3945868"
            },
            {
                "Date": "1/18/19",
                "Profil modéré": "112.4816496",
                "OPC DIVERSIFIE": "115.8837451"
            },
            {
                "Date": "1/25/19",
                "Profil modéré": "112.7260166",
                "OPC DIVERSIFIE": "116.349183"
            },
            {
                "Date": "2/1/19",
                "Profil modéré": "112.9801596",
                "OPC DIVERSIFIE": "116.6782644"
            },
            {
                "Date": "2/8/19",
                "Profil modéré": "113.0556185",
                "OPC DIVERSIFIE": "116.8319722"
            },
            {
                "Date": "2/15/19",
                "Profil modéré": "113.252238",
                "OPC DIVERSIFIE": "117.1861558"
            },
            {
                "Date": "2/22/19",
                "Profil modéré": "113.4038999",
                "OPC DIVERSIFIE": "117.1596504"
            },
            {
                "Date": "3/1/19",
                "Profil modéré": "113.2647612",
                "OPC DIVERSIFIE": "116.8682782"
            },
            {
                "Date": "3/8/19",
                "Profil modéré": "113.2252774",
                "OPC DIVERSIFIE": "116.6686761"
            },
            {
                "Date": "3/15/19",
                "Profil modéré": "113.1481255",
                "OPC DIVERSIFIE": "116.2789186"
            },
            {
                "Date": "3/22/19",
                "Profil modéré": "113.2740495",
                "OPC DIVERSIFIE": "116.3112638"
            },
            {
                "Date": "3/29/19",
                "Profil modéré": "113.2383614",
                "OPC DIVERSIFIE": "116.1306814"
            },
            {
                "Date": "4/5/19",
                "Profil modéré": "113.2380348",
                "OPC DIVERSIFIE": "116.1287626"
            },
            {
                "Date": "4/12/19",
                "Profil modéré": "113.3884547",
                "OPC DIVERSIFIE": "116.5674677"
            },
            {
                "Date": "4/19/19",
                "Profil modéré": "113.452066",
                "OPC DIVERSIFIE": "116.8928969"
            },
            {
                "Date": "4/26/19",
                "Profil modéré": "113.6782598",
                "OPC DIVERSIFIE": "116.9501283"
            },
            {
                "Date": "5/3/19",
                "Profil modéré": "113.7988481",
                "OPC DIVERSIFIE": "117.1976059"
            },
            {
                "Date": "5/10/19",
                "Profil modéré": "113.9775784",
                "OPC DIVERSIFIE": "117.7164065"
            },
            {
                "Date": "5/17/19",
                "Profil modéré": "114.1101715",
                "OPC DIVERSIFIE": "118.1103667"
            },
            {
                "Date": "5/24/19",
                "Profil modéré": "114.0784891",
                "OPC DIVERSIFIE": "117.8392588"
            },
            {
                "Date": "5/31/19",
                "Profil modéré": "114.2356294",
                "OPC DIVERSIFIE": "117.7165337"
            },
            {
                "Date": "6/10/19",
                "Profil modéré": "114.4307154",
                "OPC DIVERSIFIE": "117.7901866"
            },
            {
                "Date": "6/14/19",
                "Profil modéré": "114.7864441",
                "OPC DIVERSIFIE": "118.1692899"
            },
            {
                "Date": "6/21/19",
                "Profil modéré": "115.3485835",
                "OPC DIVERSIFIE": "119.5416441"
            },
            {
                "Date": "6/28/19",
                "Profil modéré": "115.4731814",
                "OPC DIVERSIFIE": "119.277708"
            },
            {
                "Date": "7/5/19",
                "Profil modéré": "115.3722243",
                "OPC DIVERSIFIE": "119.361514"
            },
            {
                "Date": "7/12/19",
                "Profil modéré": "115.6498241",
                "OPC DIVERSIFIE": "119.7342509"
            },
            {
                "Date": "7/19/19",
                "Profil modéré": "116.0946154",
                "OPC DIVERSIFIE": "120.4800036"
            },
            {
                "Date": "7/26/19",
                "Profil modéré": "116.122811",
                "OPC DIVERSIFIE": "120.8974418"
            },
            {
                "Date": "8/2/19",
                "Profil modéré": "116.3734447",
                "OPC DIVERSIFIE": "121.0613407"
            },
            {
                "Date": "8/9/19",
                "Profil modéré": "116.6645868",
                "OPC DIVERSIFIE": "120.7921623"
            },
            {
                "Date": "8/16/19",
                "Profil modéré": "116.9614183",
                "OPC DIVERSIFIE": "120.959143"
            },
            {
                "Date": "8/23/19",
                "Profil modéré": "116.8935478",
                "OPC DIVERSIFIE": "121.0385932"
            },
            {
                "Date": "8/30/19",
                "Profil modéré": "117.2537064",
                "OPC DIVERSIFIE": "121.390949"
            },
            {
                "Date": "9/6/19",
                "Profil modéré": "117.186747",
                "OPC DIVERSIFIE": "121.0272591"
            },
            {
                "Date": "9/13/19",
                "Profil modéré": "117.0794346",
                "OPC DIVERSIFIE": "121.0800929"
            },
            {
                "Date": "9/20/19",
                "Profil modéré": "117.0033329",
                "OPC DIVERSIFIE": "120.4548519"
            },
            {
                "Date": "9/27/19",
                "Profil modéré": "117.2312328",
                "OPC DIVERSIFIE": "120.8551725"
            },
            {
                "Date": "10/4/19",
                "Profil modéré": "117.1933622",
                "OPC DIVERSIFIE": "120.5069349"
            },
            {
                "Date": "10/11/19",
                "Profil modéré": "117.0217163",
                "OPC DIVERSIFIE": "120.3840639"
            },
            {
                "Date": "10/18/19",
                "Profil modéré": "117.2912305",
                "OPC DIVERSIFIE": "121.0306544"
            },
            {
                "Date": "10/25/19",
                "Profil modéré": "117.5607286",
                "OPC DIVERSIFIE": "121.2064099"
            },
            {
                "Date": "11/1/19",
                "Profil modéré": "117.6165576",
                "OPC DIVERSIFIE": "121.3742267"
            },
            {
                "Date": "11/8/19",
                "Profil modéré": "117.4525704",
                "OPC DIVERSIFIE": "121.3483101"
            },
            {
                "Date": "11/15/19",
                "Profil modéré": "117.6290181",
                "OPC DIVERSIFIE": "121.6545202"
            },
            {
                "Date": "11/22/19",
                "Profil modéré": "117.8172159",
                "OPC DIVERSIFIE": "122.4140324"
            },
            {
                "Date": "11/29/19",
                "Profil modéré": "117.8651566",
                "OPC DIVERSIFIE": "122.489207"
            },
            {
                "Date": "12/6/19",
                "Profil modéré": "117.8838031",
                "OPC DIVERSIFIE": "122.5252829"
            },
            {
                "Date": "12/13/19",
                "Profil modéré": "118.1134567",
                "OPC DIVERSIFIE": "123.3835999"
            },
            {
                "Date": "12/20/19",
                "Profil modéré": "118.5238913",
                "OPC DIVERSIFIE": "124.1918794"
            },
            {
                "Date": "12/27/19",
                "Profil modéré": "118.7223049",
                "OPC DIVERSIFIE": "124.2527064"
            },
            {
                "Date": "1/3/20",
                "Profil modéré": "119.031736",
                "OPC DIVERSIFIE": "124.2548929"
            },
            {
                "Date": "1/10/20",
                "Profil modéré": "119.2890608",
                "OPC DIVERSIFIE": "124.8901168"
            },
            {
                "Date": "1/17/20",
                "Profil modéré": "119.6252216",
                "OPC DIVERSIFIE": "125.7728104"
            },
            {
                "Date": "1/24/20",
                "Profil modéré": "119.8229981",
                "OPC DIVERSIFIE": "126.1454712"
            },
            {
                "Date": "1/31/20",
                "Profil modéré": "119.866902",
                "OPC DIVERSIFIE": "125.8580541"
            },
            {
                "Date": "2/7/20",
                "Profil modéré": "119.5437643",
                "OPC DIVERSIFIE": "124.4346934"
            },
            {
                "Date": "2/14/20",
                "Profil modéré": "119.8324993",
                "OPC DIVERSIFIE": "125.1192512"
            },
            {
                "Date": "2/21/20",
                "Profil modéré": "120.4457287",
                "OPC DIVERSIFIE": "125.7089059"
            },
            {
                "Date": "2/28/20",
                "Profil modéré": "119.9159622",
                "OPC DIVERSIFIE": "124.875154"
            },
            {
                "Date": "3/6/20",
                "Profil modéré": "119.4759055",
                "OPC DIVERSIFIE": "122.0405547"
            },
            {
                "Date": "3/13/20",
                "Profil modéré": "117.8693969",
                "OPC DIVERSIFIE": "118.7621829"
            },
            {
                "Date": "3/20/20",
                "Profil modéré": "116.8025268",
                "OPC DIVERSIFIE": "115.4675473"
            },
            {
                "Date": "3/27/20",
                "Profil modéré": "117.848106",
                "OPC DIVERSIFIE": "115.831981"
            },
            {
                "Date": "4/3/20",
                "Profil modéré": "117.920208",
                "OPC DIVERSIFIE": "114.7664488"
            },
            {
                "Date": "4/10/20",
                "Profil modéré": "118.209701",
                "OPC DIVERSIFIE": "114.62251"
            },
            {
                "Date": "4/17/20",
                "Profil modéré": "118.1392972",
                "OPC DIVERSIFIE": "113.977797"
            },
            {
                "Date": "4/24/20",
                "Profil modéré": "118.0639018",
                "OPC DIVERSIFIE": "113.8438338"
            },
            {
                "Date": "5/4/20",
                "Profil modéré": "118.4400682",
                "OPC DIVERSIFIE": "115.1992626"
            },
            {
                "Date": "5/8/20",
                "Profil modéré": "118.5067208",
                "OPC DIVERSIFIE": "115.3787465"
            },
            {
                "Date": "5/15/20",
                "Profil modéré": "118.7215582",
                "OPC DIVERSIFIE": "115.0794212"
            },
            {
                "Date": "5/22/20",
                "Profil modéré": "119.1220007",
                "OPC DIVERSIFIE": "116.1779847"
            },
            {
                "Date": "5/29/20",
                "Profil modéré": "119.4954447",
                "OPC DIVERSIFIE": "116.7981811"
            },
            {
                "Date": "6/5/20",
                "Profil modéré": "119.5259691",
                "OPC DIVERSIFIE": "117.8111054"
            },
            {
                "Date": "6/12/20",
                "Profil modéré": "119.7433244",
                "OPC DIVERSIFIE": "117.4579983"
            },
            {
                "Date": "6/19/20",
                "Profil modéré": "120.3409873",
                "OPC DIVERSIFIE": "119.0090033"
            },
            {
                "Date": "6/26/20",
                "Profil modéré": "121.0140285",
                "OPC DIVERSIFIE": "119.8307657"
            },
            {
                "Date": "7/3/20",
                "Profil modéré": "121.3411157",
                "OPC DIVERSIFIE": "120.1437769"
            },
            {
                "Date": "7/10/20",
                "Profil modéré": "121.4157301",
                "OPC DIVERSIFIE": "119.9615281"
            },
            {
                "Date": "7/17/20",
                "Profil modéré": "121.5325193",
                "OPC DIVERSIFIE": "120.4582702"
            },
            {
                "Date": "7/24/20",
                "Profil modéré": "122.0148076",
                "OPC DIVERSIFIE": "120.4189061"
            },
            {
                "Date": "8/3/20",
                "Profil modéré": "122.2591417",
                "OPC DIVERSIFIE": "120.3402442"
            },
            {
                "Date": "8/7/20",
                "Profil modéré": "122.7535866",
                "OPC DIVERSIFIE": "120.7340827"
            },
            {
                "Date": "8/17/20",
                "Profil modéré": "122.3954359",
                "OPC DIVERSIFIE": "120.9360116"
            },
            {
                "Date": "8/24/20",
                "Profil modéré": "122.2634263",
                "OPC DIVERSIFIE": "120.9336524"
            },
            {
                "Date": "8/28/20",
                "Profil modéré": "122.126667",
                "OPC DIVERSIFIE": "120.3778361"
            },
            {
                "Date": "9/4/20",
                "Profil modéré": "122.1417932",
                "OPC DIVERSIFIE": "120.7944034"
            },
            {
                "Date": "9/11/20",
                "Profil modéré": "122.3159973",
                "OPC DIVERSIFIE": "120.8781725"
            },
            {
                "Date": "9/18/20",
                "Profil modéré": "122.3070683",
                "OPC DIVERSIFIE": "120.4236625"
            },
            {
                "Date": "9/25/20",
                "Profil modéré": "121.7298439",
                "OPC DIVERSIFIE": "120.1243904"
            },
            {
                "Date": "10/2/20",
                "Profil modéré": "122.1140087",
                "OPC DIVERSIFIE": "120.5336727"
            },
            {
                "Date": "10/9/20",
                "Profil modéré": "122.3453227",
                "OPC DIVERSIFIE": "121.0044306"
            },
            {
                "Date": "10/16/20",
                "Profil modéré": "122.3933957",
                "OPC DIVERSIFIE": "121.3509251"
            },
            {
                "Date": "10/23/20",
                "Profil modéré": "122.5145603",
                "OPC DIVERSIFIE": "121.7967859"
            },
            {
                "Date": "11/2/20",
                "Profil modéré": "122.47487",
                "OPC DIVERSIFIE": "121.4415091"
            },
            {
                "Date": "11/9/20",
                "Profil modéré": "122.3807875",
                "OPC DIVERSIFIE": "122.0438018"
            },
            {
                "Date": "11/13/20",
                "Profil modéré": "122.6191669",
                "OPC DIVERSIFIE": "122.2273447"
            },
            {
                "Date": "11/20/20",
                "Profil modéré": "122.6799002",
                "OPC DIVERSIFIE": "123.0325531"
            },
            {
                "Date": "11/27/20",
                "Profil modéré": "121.9073503",
                "OPC DIVERSIFIE": "122.6468556"
            },
            {
                "Date": "12/4/20",
                "Profil modéré": "122.1770646",
                "OPC DIVERSIFIE": "122.8002401"
            },
            {
                "Date": "12/11/20",
                "Profil modéré": "122.6792579",
                "OPC DIVERSIFIE": "124.2975637"
            },
            {
                "Date": "12/18/20",
                "Profil modéré": "123.392115",
                "OPC DIVERSIFIE": "125.3770029"
            },
            {
                "Date": "12/25/20",
                "Profil modéré": "123.3712582",
                "OPC DIVERSIFIE": "124.6943989"
            },
            {
                "Date": "1/4/21",
                "Profil modéré": "123.7965264",
                "OPC DIVERSIFIE": "125.2323371"
            },
            {
                "Date": "1/8/21",
                "Profil modéré": "123.2896148",
                "OPC DIVERSIFIE": "125.0552197"
            },
            {
                "Date": "1/15/21",
                "Profil modéré": "123.5079257",
                "OPC DIVERSIFIE": "125.8175823"
            },
            {
                "Date": "1/22/21",
                "Profil modéré": "123.952249",
                "OPC DIVERSIFIE": "126.4212299"
            },
            {
                "Date": "1/29/21",
                "Profil modéré": "124.2565394",
                "OPC DIVERSIFIE": "126.83099"
            },
            {
                "Date": "2/5/21",
                "Profil modéré": "124.1309288",
                "OPC DIVERSIFIE": "127.2661263"
            },
            {
                "Date": "2/12/21",
                "Profil modéré": "124.0265382",
                "OPC DIVERSIFIE": "126.7283756"
            },
            {
                "Date": "2/19/21",
                "Profil modéré": "123.7283642",
                "OPC DIVERSIFIE": "126.4964837"
            },
            {
                "Date": "2/26/21",
                "Profil modéré": "123.4233878",
                "OPC DIVERSIFIE": "126.0753012"
            },
            {
                "Date": "3/5/21",
                "Profil modéré": "123.2720404",
                "OPC DIVERSIFIE": "126.1246474"
            },
            {
                "Date": "3/12/21",
                "Profil modéré": "123.4244823",
                "OPC DIVERSIFIE": "126.4149973"
            },
            {
                "Date": "3/19/21",
                "Profil modéré": "123.595254",
                "OPC DIVERSIFIE": "126.3975791"
            },
            {
                "Date": "3/26/21",
                "Profil modéré": "123.7160115",
                "OPC DIVERSIFIE": "126.4666927"
            },
            {
                "Date": "4/2/21",
                "Profil modéré": "123.7303733",
                "OPC DIVERSIFIE": "126.760295"
            },
            {
                "Date": "4/9/21",
                "Profil modéré": "123.7690453",
                "OPC DIVERSIFIE": "126.786649"
            },
            {
                "Date": "4/16/21",
                "Profil modéré": "124.078263",
                "OPC DIVERSIFIE": "127.1054877"
            },
            {
                "Date": "4/23/21",
                "Profil modéré": "124.2039279",
                "OPC DIVERSIFIE": "127.3440438"
            },
            {
                "Date": "4/30/21",
                "Profil modéré": "124.271723",
                "OPC DIVERSIFIE": "127.7440377"
            },
            {
                "Date": "5/7/21",
                "Profil modéré": "124.9454191",
                "OPC DIVERSIFIE": "128.6287734"
            },
            {
                "Date": "5/17/21",
                "Profil modéré": "125.1857845",
                "OPC DIVERSIFIE": "129.2539756"
            },
            {
                "Date": "5/21/21",
                "Profil modéré": "125.2928292",
                "OPC DIVERSIFIE": "129.2405865"
            },
            {
                "Date": "5/28/21",
                "Profil modéré": "125.5207479",
                "OPC DIVERSIFIE": "129.2918608"
            },
            {
                "Date": "6/4/21",
                "Profil modéré": "125.6722741",
                "OPC DIVERSIFIE": "130.0702263"
            },
            {
                "Date": "6/11/21",
                "Profil modéré": "125.7184841",
                "OPC DIVERSIFIE": "130.4770151"
            },
            {
                "Date": "6/18/21",
                "Profil modéré": "125.1583825",
                "OPC DIVERSIFIE": "130.4442651"
            },
            {
                "Date": "6/25/21",
                "Profil modéré": "125.1635974",
                "OPC DIVERSIFIE": "130.2449379"
            },
            {
                "Date": "7/2/21",
                "Profil modéré": "125.2618519",
                "OPC DIVERSIFIE": "130.2737245"
            },
            {
                "Date": "7/9/21",
                "Profil modéré": "125.3337321",
                "OPC DIVERSIFIE": "130.0169819"
            },
            {
                "Date": "7/16/21",
                "Profil modéré": "125.4930616",
                "OPC DIVERSIFIE": "129.9268744"
            },
            {
                "Date": "7/23/21",
                "Profil modéré": "125.4039932",
                "OPC DIVERSIFIE": "130.0420178"
            },
            {
                "Date": "8/2/21",
                "Profil modéré": "125.5799547",
                "OPC DIVERSIFIE": "130.9155569"
            },
            {
                "Date": "8/6/21",
                "Profil modéré": "125.5212934",
                "OPC DIVERSIFIE": "131.1870219"
            },
            {
                "Date": "8/13/21",
                "Profil modéré": "125.770106",
                "OPC DIVERSIFIE": "131.5393365"
            },
            {
                "Date": "8/23/21",
                "Profil modéré": "126.0875257",
                "OPC DIVERSIFIE": "131.7860092"
            },
            {
                "Date": "8/27/21",
                "Profil modéré": "126.1695792",
                "OPC DIVERSIFIE": "132.139758"
            },
            {
                "Date": "9/3/21",
                "Profil modéré": "126.2730617",
                "OPC DIVERSIFIE": "132.0722308"
            },
            {
                "Date": "9/10/21",
                "Profil modéré": "126.3227898",
                "OPC DIVERSIFIE": "132.7135393"
            },
            {
                "Date": "9/17/21",
                "Profil modéré": "126.3080668",
                "OPC DIVERSIFIE": "133.2727289"
            },
            {
                "Date": "9/24/21",
                "Profil modéré": "126.4365353",
                "OPC DIVERSIFIE": "133.6306093"
            },
            {
                "Date": "10/1/21",
                "Profil modéré": "126.5474117",
                "OPC DIVERSIFIE": "133.5019126"
            },
            {
                "Date": "10/8/21",
                "Profil modéré": "126.8255826",
                "OPC DIVERSIFIE": "133.8579411"
            },
            {
                "Date": "10/15/21",
                "Profil modéré": "126.9239988",
                "OPC DIVERSIFIE": "134.3189436"
            },
            {
                "Date": "10/22/21",
                "Profil modéré": "127.2065042",
                "OPC DIVERSIFIE": "134.3232037"
            },
            {
                "Date": "10/29/21",
                "Profil modéré": "127.2693813",
                "OPC DIVERSIFIE": "135.0686906"
            },
            {
                "Date": "11/5/21",
                "Profil modéré": "127.3799959",
                "OPC DIVERSIFIE": "134.5760794"
            },
            {
                "Date": "11/12/21",
                "Profil modéré": "127.7293948",
                "OPC DIVERSIFIE": "134.3360167"
            },
            {
                "Date": "11/19/21",
                "Profil modéré": "127.8526535",
                "OPC DIVERSIFIE": "134.48079"
            },
            {
                "Date": "11/26/21",
                "Profil modéré": "127.4848944",
                "OPC DIVERSIFIE": "134.1155837"
            },
            {
                "Date": "12/3/21",
                "Profil modéré": "127.2482673",
                "OPC DIVERSIFIE": "134.0530183"
            },
            {
                "Date": "12/10/21",
                "Profil modéré": "127.5906552",
                "OPC DIVERSIFIE": "134.453809"
            },
            {
                "Date": "12/17/21",
                "Profil modéré": "127.7333914",
                "OPC DIVERSIFIE": "134.2909269"
            },
            {
                "Date": "12/24/21",
                "Profil modéré": "127.7037782",
                "OPC DIVERSIFIE": "134.1007832"
            },
            {
                "Date": "12/31/21",
                "Profil modéré": "127.9363945",
                "OPC DIVERSIFIE": "134.6290405"
            },
            {
                "Date": "1/7/22",
                "Profil modéré": "128.0324955",
                "OPC DIVERSIFIE": "135.0349"
            },
            {
                "Date": "1/14/22",
                "Profil modéré": "128.3856156",
                "OPC DIVERSIFIE": "136.0247849"
            },
            {
                "Date": "1/21/22",
                "Profil modéré": "128.5618739",
                "OPC DIVERSIFIE": "135.8654586"
            },
            {
                "Date": "1/28/22",
                "Profil modéré": "128.3911066",
                "OPC DIVERSIFIE": "135.805071"
            },
            {
                "Date": "2/4/22",
                "Profil modéré": "128.4889155",
                "OPC DIVERSIFIE": "136.1727653"
            },
            {
                "Date": "2/11/22",
                "Profil modéré": "128.7466518",
                "OPC DIVERSIFIE": "136.1724892"
            },
            {
                "Date": "2/18/22",
                "Profil modéré": "129.0836415",
                "OPC DIVERSIFIE": "135.4136096"
            },
            {
                "Date": "2/25/22",
                "Profil modéré": "128.5972973",
                "OPC DIVERSIFIE": "133.5272083"
            },
            {
                "Date": "3/4/22",
                "Profil modéré": "128.8353519",
                "OPC DIVERSIFIE": "132.2205583"
            },
            {
                "Date": "3/11/22",
                "Profil modéré": "128.9429017",
                "OPC DIVERSIFIE": "131.636287"
            },
            {
                "Date": "3/18/22",
                "Profil modéré": "129.0752382",
                "OPC DIVERSIFIE": "132.6271931"
            },
            {
                "Date": "3/25/22",
                "Profil modéré": "128.8319927",
                "OPC DIVERSIFIE": "131.5701113"
            },
            {
                "Date": "4/1/22",
                "Profil modéré": "129.0009151",
                "OPC DIVERSIFIE": "132.2605673"
            },
            {
                "Date": "4/8/22",
                "Profil modéré": "129.2521354",
                "OPC DIVERSIFIE": "132.4725039"
            },
            {
                "Date": "4/15/22",
                "Profil modéré": "129.528445",
                "OPC DIVERSIFIE": "132.6497015"
            },
            {
                "Date": "4/22/22",
                "Profil modéré": "129.6136188",
                "OPC DIVERSIFIE": "133.1880628"
            },
            {
                "Date": "4/29/22",
                "Profil modéré": "129.6263161",
                "OPC DIVERSIFIE": "132.925018"
            },
            {
                "Date": "5/6/22",
                "Profil modéré": "129.3836485",
                "OPC DIVERSIFIE": "132.5870092"
            },
            {
                "Date": "5/13/22",
                "Profil modéré": "128.6541575",
                "OPC DIVERSIFIE": "131.3319596"
            },
            {
                "Date": "5/20/22",
                "Profil modéré": "128.6501436",
                "OPC DIVERSIFIE": "131.1707801"
            },
            {
                "Date": "5/27/22",
                "Profil modéré": "128.353742",
                "OPC DIVERSIFIE": "130.2996738"
            },
            {
                "Date": "6/3/22",
                "Profil modéré": "128.642753",
                "OPC DIVERSIFIE": "131.3529783"
            },
            {
                "Date": "6/10/22",
                "Profil modéré": "128.630691",
                "OPC DIVERSIFIE": "131.2142938"
            },
            {
                "Date": "6/17/22",
                "Profil modéré": "128.4528593",
                "OPC DIVERSIFIE": "129.603954"
            },
            {
                "Date": "6/24/22",
                "Profil modéré": "128.2275622",
                "OPC DIVERSIFIE": "129.3655981"
            },
            {
                "Date": "7/1/22",
                "Profil modéré": "127.7822936",
                "OPC DIVERSIFIE": "128.5205934"
            },
            {
                "Date": "7/8/22",
                "Profil modéré": "127.634725",
                "OPC DIVERSIFIE": "128.7094175"
            },
            {
                "Date": "7/15/22",
                "Profil modéré": "127.8773862",
                "OPC DIVERSIFIE": "129.2802178"
            },
            {
                "Date": "7/22/22",
                "Profil modéré": "128.128125",
                "OPC DIVERSIFIE": "129.6929214"
            },
            {
                "Date": "7/29/22",
                "Profil modéré": "128.1625941",
                "OPC DIVERSIFIE": "129.4250577"
            },
            {
                "Date": "8/5/22",
                "Profil modéré": "128.5304863",
                "OPC DIVERSIFIE": "129.9562575"
            },
            {
                "Date": "8/12/22",
                "Profil modéré": "128.957361",
                "OPC DIVERSIFIE": "130.7986108"
            },
            {
                "Date": "8/19/22",
                "Profil modéré": "128.8442139",
                "OPC DIVERSIFIE": "130.7906426"
            },
            {
                "Date": "8/26/22",
                "Profil modéré": "128.9634372",
                "OPC DIVERSIFIE": "130.6628147"
            },
            {
                "Date": "9/2/22",
                "Profil modéré": "128.7601643",
                "OPC DIVERSIFIE": "130.5408605"
            },
            {
                "Date": "9/9/22",
                "Profil modéré": "128.9108467",
                "OPC DIVERSIFIE": "130.5816414"
            },
            {
                "Date": "9/16/22",
                "Profil modéré": "128.597903",
                "OPC DIVERSIFIE": "130.3725047"
            },
            {
                "Date": "9/23/22",
                "Profil modéré": "128.597209",
                "OPC DIVERSIFIE": "130.2877119"
            },
            {
                "Date": "9/30/22",
                "Profil modéré": "127.8420158",
                "OPC DIVERSIFIE": "126.5905607"
            },
            {
                "Date": "10/7/22",
                "Profil modéré": "127.9899701",
                "OPC DIVERSIFIE": "126.8233985"
            },
            {
                "Date": "10/14/22",
                "Profil modéré": "127.4669173",
                "OPC DIVERSIFIE": "125.9440098"
            },
            {
                "Date": "10/21/22",
                "Profil modéré": "127.0112333",
                "OPC DIVERSIFIE": "124.4890992"
            },
            {
                "Date": "10/28/22",
                "Profil modéré": "126.8532298",
                "OPC DIVERSIFIE": "124.1583337"
            },
            {
                "Date": "11/4/22",
                "Profil modéré": "127.069164",
                "OPC DIVERSIFIE": "123.8300551"
            },
            {
                "Date": "11/11/22",
                "Profil modéré": "126.9720988",
                "OPC DIVERSIFIE": "122.5375133"
            },
            {
                "Date": "11/21/22",
                "Profil modéré": "127.0853113",
                "OPC DIVERSIFIE": "123.4303801"
            },
            {
                "Date": "11/25/22",
                "Profil modéré": "127.0902314",
                "OPC DIVERSIFIE": "123.6875864"
            },
            {
                "Date": "12/2/22",
                "Profil modéré": "127.5291292",
                "OPC DIVERSIFIE": "124.0811192"
            },
            {
                "Date": "12/9/22",
                "Profil modéré": "127.7394444",
                "OPC DIVERSIFIE": "124.8043718"
            },
            {
                "Date": "12/16/22",
                "Profil modéré": "127.4774188",
                "OPC DIVERSIFIE": "124.1094308"
            },
            {
                "Date": "12/23/22",
                "Profil modéré": "127.4687988",
                "OPC DIVERSIFIE": "123.843641"
            },
            {
                "Date": "12/30/22",
                "Profil modéré": "127.2687981",
                "OPC DIVERSIFIE": "122.9341495"
            },
            {
                "Date": "1/6/23",
                "Profil modéré": "125.1881942",
                "OPC DIVERSIFIE": "115.7218605"
            },
            {
                "Date": "1/13/23",
                "Profil modéré": "125.8281964",
                "OPC DIVERSIFIE": "117.4981639"
            },
            {
                "Date": "1/20/23",
                "Profil modéré": "126.1680174",
                "OPC DIVERSIFIE": "117.1874971"
            },
            {
                "Date": "1/27/23",
                "Profil modéré": "126.121659",
                "OPC DIVERSIFIE": "117.0418696"
            },
            {
                "Date": "2/3/23",
                "Profil modéré": "126.3193016",
                "OPC DIVERSIFIE": "117.9132158"
            },
            {
                "Date": "2/10/23",
                "Profil modéré": "126.56514",
                "OPC DIVERSIFIE": "119.2079463"
            },
            {
                "Date": "2/17/23",
                "Profil modéré": "126.4697451",
                "OPC DIVERSIFIE": "119.0123793"
            },
            {
                "Date": "2/24/23",
                "Profil modéré": "126.5241402",
                "OPC DIVERSIFIE": "119.4535929"
            },
            {
                "Date": "3/3/23",
                "Profil modéré": "126.6370906",
                "OPC DIVERSIFIE": "119.0094121"
            },
            {
                "Date": "3/10/23",
                "Profil modéré": "126.7461426",
                "OPC DIVERSIFIE": "118.5314459"
            },
            {
                "Date": "3/17/23",
                "Profil modéré": "127.3554953",
                "OPC DIVERSIFIE": "117.7060402"
            },
            {
                "Date": "3/24/23",
                "Profil modéré": "127.467884",
                "OPC DIVERSIFIE": "117.5996552"
            },
            {
                "Date": "3/31/23",
                "Profil modéré": "127.2332227",
                "OPC DIVERSIFIE": "117.4496891"
            },
            {
                "Date": "4/7/23",
                "Profil modéré": "127.3838634",
                "OPC DIVERSIFIE": "117.4278293"
            },
            {
                "Date": "4/14/23",
                "Profil modéré": "127.6441933",
                "OPC DIVERSIFIE": "117.4534743"
            },
            {
                "Date": "4/21/23",
                "Profil modéré": "127.2712902",
                "OPC DIVERSIFIE": "117.5973738"
            },
            {
                "Date": "4/28/23",
                "Profil modéré": "127.403986",
                "OPC DIVERSIFIE": "117.6434597"
            },
            {
                "Date": "5/5/23",
                "Profil modéré": "127.3609931",
                "OPC DIVERSIFIE": "117.2283382"
            },
            {
                "Date": "5/12/23",
                "Profil modéré": "128.0327863",
                "OPC DIVERSIFIE": "118.3242476"
            },
            {
                "Date": "5/19/23",
                "Profil modéré": "128.0918765",
                "OPC DIVERSIFIE": "118.8352426"
            },
            {
                "Date": "5/26/23",
                "Profil modéré": "128.2805661",
                "OPC DIVERSIFIE": "119.6661765"
            },
            {
                "Date": "6/2/23",
                "Profil modéré": "128.6586051",
                "OPC DIVERSIFIE": "120.4683798"
            },
            {
                "Date": "6/9/23",
                "Profil modéré": "128.525997",
                "OPC DIVERSIFIE": "120.1822607"
            },
            {
                "Date": "6/16/23",
                "Profil modéré": "128.7510836",
                "OPC DIVERSIFIE": "121.4491307"
            },
            {
                "Date": "6/23/23",
                "Profil modéré": "129.4071512",
                "OPC DIVERSIFIE": "122.9385966"
            },
            {
                "Date": "7/3/23",
                "Profil modéré": "129.5062251",
                "OPC DIVERSIFIE": "123.5585118"
            },
            {
                "Date": "7/7/23",
                "Profil modéré": "129.4450956",
                "OPC DIVERSIFIE": "123.824372"
            },
            {
                "Date": "7/14/23",
                "Profil modéré": "129.6564419",
                "OPC DIVERSIFIE": "124.0657376"
            },
            {
                "Date": "7/21/23",
                "Profil modéré": "130.1498054",
                "OPC DIVERSIFIE": "124.992958"
            },
            {
                "Date": "7/28/23",
                "Profil modéré": "130.4826743",
                "OPC DIVERSIFIE": "126.0508869"
            },
            {
                "Date": "8/4/23",
                "Profil modéré": "130.6855447",
                "OPC DIVERSIFIE": "126.4330071"
            },
            {
                "Date": "8/11/23",
                "Profil modéré": "130.5411605",
                "OPC DIVERSIFIE": "126.0739354"
            },
            {
                "Date": "8/18/23",
                "Profil modéré": "130.5464695",
                "OPC DIVERSIFIE": "126.0150742"
            },
            {
                "Date": "8/25/23",
                "Profil modéré": "130.8399127",
                "OPC DIVERSIFIE": "125.7908622"
            },
            {
                "Date": "9/1/23",
                "Profil modéré": "131.1375159",
                "OPC DIVERSIFIE": "125.8142502"
            },
            {
                "Date": "9/8/23",
                "Profil modéré": "130.9668619",
                "OPC DIVERSIFIE": "125.3347718"
            },
            {
                "Date": "9/15/23",
                "Profil modéré": "131.2310801",
                "OPC DIVERSIFIE": "125.5258068"
            },
            {
                "Date": "9/22/23",
                "Profil modéré": "131.0614081",
                "OPC DIVERSIFIE": "125.5330768"
            },
            {
                "Date": "10/2/23",
                "Profil modéré": "130.8447046",
                "OPC DIVERSIFIE": "125.8099325"
            },
            {
                "Date": "10/6/23",
                "Profil modéré": "131.3501848",
                "OPC DIVERSIFIE": "127.6097831"
            },
            {
                "Date": "10/13/23",
                "Profil modéré": "131.9393225",
                "OPC DIVERSIFIE": "127.3777605"
            },
            {
                "Date": "10/20/23",
                "Profil modéré": "132.6400665",
                "OPC DIVERSIFIE": "127.3426268"
            },
            {
                "Date": "10/27/23",
                "Profil modéré": "132.4375925",
                "OPC DIVERSIFIE": "126.5254875"
            },
            {
                "Date": "11/3/23",
                "Profil modéré": "132.5127089",
                "OPC DIVERSIFIE": "126.5506852"
            },
            {
                "Date": "11/10/23",
                "Profil modéré": "132.1823459",
                "OPC DIVERSIFIE": "126.4981764"
            },
            {
                "Date": "11/17/23",
                "Profil modéré": "132.5153597",
                "OPC DIVERSIFIE": "126.697771"
            },
            {
                "Date": "11/24/23",
                "Profil modéré": "132.8038798",
                "OPC DIVERSIFIE": "126.9244081"
            },
            {
                "Date": "12/1/23",
                "Profil modéré": "133.1021179",
                "OPC DIVERSIFIE": "126.4195313"
            },
            {
                "Date": "12/8/23",
                "Profil modéré": "133.1278531",
                "OPC DIVERSIFIE": "126.9767569"
            },
            {
                "Date": "12/15/23",
                "Profil modéré": "133.7279315",
                "OPC DIVERSIFIE": "128.3563766"
            },
            {
                "Date": "12/22/23",
                "Profil modéré": "133.6484836",
                "OPC DIVERSIFIE": "128.7189196"
            },
            {
                "Date": "12/29/23",
                "Profil modéré": "133.7972401",
                "OPC DIVERSIFIE": "128.3453473"
            },
            {
                "Date": "1/5/24",
                "Profil modéré": "134.2094007",
                "OPC DIVERSIFIE": "129.5491215"
            },
            {
                "Date": "1/12/24",
                "Profil modéré": "134.815542",
                "OPC DIVERSIFIE": "130.9522298"
            }
        ]
    ];

    useEffect(() => {
        if (yourDataSet && yourDataSet.length > 0) {
          setChartData(yourDataSet[0]);
        }
      }, [yourDataSet]);
    
      const getOption = () => {
        if (!chartData || chartData.length === 0) {
          return {}; 
        }
      
        const xAxisData = chartData.map((item) => item.Date);
      
        const legendData = Object.keys(chartData[0]).filter((key) => key !== 'Date');
      
        const series = legendData.map((key) => ({
          type: 'line',
          name: key,
          data: chartData.map((item) => {
            const value = item[key];
            const parsedValue = parseFloat(value.replace(',', ' '));
            return isNaN(parsedValue) ? null : parsedValue;
          }),
        }));
    
        return {
          tooltip: {
            trigger: 'axis',
          },
          title: {
            text: '',
            left: 'center',
         
          },
          legend: {
            data: legendData,
            bottom : 10,
           },
          xAxis: {
            type: 'category',
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: 100,
          },
          series,
        };
      };
    
      return (
        <Row className="g-3">
      <Col>
        
            <ReactEChartsCore echarts={echarts} option={getOption()} />
         
      </Col>
    </Row>
      );
    };
    
    export default LineChartComponent;
