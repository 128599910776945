import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { Card, Col, Row } from 'react-bootstrap';

const ConfidenceBandChart = () => {
  const data = [
    {
        "Année": "2024",
        "Min": "100",
        "Estimation": "100",
        "Max": "100",
        "": ""
    },
    {
        "Année": "2025",
        "Min": "97.85190106",
        "Estimation": "103.3018405",
        "Max": "107.6903001",
        "": ""
    },
    {
        "Année": "2026",
        "Min": "101.1241574",
        "Estimation": "106.6951768",
        "Max": "111.3721574",
        "": ""
    },
    {
        "Année": "2027",
        "Min": "105.0465978",
        "Estimation": "110.5989685",
        "Max": "114.6027074",
        "": ""
    },
    {
        "Année": "2028",
        "Min": "111.496449",
        "Estimation": "114.6812756",
        "Max": "119.4701784",
        "": ""
    },
    {
        "Année": "2029",
        "Min": "112.7869928",
        "Estimation": "118.2842507",
        "Max": "123.7009654",
        "": ""
    },
    {
        "Année": "2030",
        "Min": "117.1236102",
        "Estimation": "121.3694556",
        "Max": "128.3952481"
    }
];

  const option = {
    title: {
        text: '',
        left: 'center',
      },
      legend: {
        data: ['Estimation', 'Min', 'Max'],
        bottom: 10,
      },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map(item => item.Année),
    },
    yAxis: {
      type: 'value',
      min: 95,
    },
    series: [
        {
            name: 'Min',
            type: 'line',
            data: data.map(item => parseFloat(item.Min)),
            smooth: true,
            lineStyle: {
              opacity: 0, // Make the line invisible
            },
            areaStyle: {
                color: 'blue', // Fill color for the area below the line
              opacity: 0.8,
            },
          },
      {
        name: 'Max',
        type: 'line',
        data: data.map(item => [item.Année, parseFloat(item.Max)]),
        smooth: true,
        lineStyle: {
            opacity: 0, // Make the line invisible
          },
          areaStyle: {
            color: 'green',
            opacity: 0.5,
          },
      },
      {
        name: 'Estimation',
        type: 'line',
        data: data.map(item => [item.Année, parseFloat(item.Estimation)]),
        smooth: true,
        itemStyle: {
          color: 'red', // Adjust color as needed
        },
      },
    ],
  };

  return (
    <Row className="g-3">
        <Col>
           
                    <ReactECharts option={option} />
                
        </Col>
    </Row>
);
};

export default ConfidenceBandChart;
