import React, { useState } from 'react';
import Step1 from '../../components/wizard-steps/Step1';
import Step2 from '../../components/wizard-steps/Step2';
import Step3 from '../../components/wizard-steps/Step3';
import Step4 from '../../components/wizard-steps/Step4';
import Step5 from '../../components/wizard-steps/Step5';
import Step6 from '../../components/wizard-steps/Step6';
import Step7 from '../../components/wizard-steps/Step7';
import Step8 from '../../components/wizard-steps/Step8';
import Step9 from '../../components/wizard-steps/Step9';
import Step10 from '../../components/wizard-steps/Step10';
import Step11 from '../../components/wizard-steps/Step11';
import Step12 from '../../components/wizard-steps/Step12';
import Step13 from '../../components/wizard-steps/Step13';
import Step14 from '../../components/wizard-steps/Step14';
import Step15 from '../../components/wizard-steps/Step15';
import Step16 from '../../components/wizard-steps/Step16';
import Step17 from '../../components/wizard-steps/Step17';
import Step18 from '../../components/wizard-steps/Step18';
import Step19 from '../../components/wizard-steps/Step19';
import Step20 from '../../components/wizard-steps/Step20';
import Step21 from '../../components/wizard-steps/Step21';
import Step22 from '../../components/wizard-steps/Step22';
import Step23 from '../../components/wizard-steps/Step23';
import Step24 from '../../components/wizard-steps/Step24';
import Step25 from '../../components/wizard-steps/Step25';
import SuccessPage from '../../components/wizard-steps/SuccessWizard';
import '../../assets/css/Home.css'
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Container, Button, Form } from 'react-bootstrap'; // Import Bootstrap components

import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";



// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDqNw6DJCXse8f0PK5bPXqtg_nXOkgHojM",
  authDomain: "roboadvisor-44b6d.firebaseapp.com",
  databaseURL: "https://roboadvisor-44b6d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "roboadvisor-44b6d",
  storageBucket: "roboadvisor-44b6d.appspot.com",
  messagingSenderId: "578132343521",
  appId: "1:578132343521:web:681af5ed824cf6a5ba15a0",
  measurementId: "G-329Q2G165L"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Now you can use Firebase and Firestore in your application
const db = getFirestore(app);


function WizardApp() {
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [selections, setSelections] = useState({
    age: '',
    statut: '',
    charge: '',
    education: '',
    emploi: '',
    revenuA: '',
    revenuS: '',
    patrimoine: '',
    patrimoineV: '',
    situationF: '',
    revenuI: '',
    objectif: '',
    horizon: '',
    connaissance: '',
    expérience: '',
    tolérance: '',
    perte: '',
    perteExp: '',
    réaction: '',
    attitude: '',
    réactionP: '',
    baisse: '',
    gain: '',
    profile: '',
    selectedProfiles: {
      prudent: 0,
      modéré: 0,
      agressif: 0,
    },
  }
  );

  const [setDownloadedData] = useState('');
  const getMaxProfile = () => {
    const { selectedProfiles } = selections;
    const maxScore = Math.max(...Object.values(selectedProfiles));
    return Object.keys(selectedProfiles).find(profile => selectedProfiles[profile] === maxScore);
  };

  const constructDownloadData = () => {
    const maxProfile = getMaxProfile();
    const userSelections = {
      ...selections,
      maxProfile: maxProfile,
    };

    const jsonData = JSON.stringify(userSelections, null, 2);
    setDownloadedData(jsonData);

    // For downloading as a JSON file
    const downloadLink = document.createElement('a');
    const file = new Blob([jsonData], { type: 'application/json' });
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = 'user_selections.json';
    downloadLink.click();
  };

  const handleNext = async () => {
    if (currentStep === 1 && !selections.age) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 2 && !selections.statut) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 3 && !selections.charge) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 4 && !selections.education) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 5 && !selections.emploi) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 6 && !selections.revenuA) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 7 && !selections.revenuS) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 8 && !selections.patrimoine) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 9 && !selections.patrimoineV) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 10 && !selections.situationF) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 11 && !selections.revenuI) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 12 && !selections.objectif) {
      // If no age is selected, don't proceed
      return;
    }

    if (currentStep === 13 && !selections.horizon) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 14 && !selections.connaissance) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 15 && !selections.expérience) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 16 && !selections.tolérance) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 17 && !selections.perte) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 18 && !selections.perteExp) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 19 && !selections.réaction) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 20 && !selections.attitude) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 21 && !selections.réactionP) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 22 && !selections.baisse) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 23 && !selections.gain) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 24 && !selections.profile) {
      // If no age is selected, don't proceed
      return;
    }
    if (currentStep === 25 && !emailIsValid(email)) {
      // If no age is selected, don't proceed
      setEmailError('Veuillez entrer une adresse e-mail valide.');
      return;
    }else {
      // Clear the error message if the email is valid
      setEmailError('');
    }

    setCurrentStep(currentStep + 1);

    if (currentStep === 25) {
      try {

        await addDoc(collection(db, 'userSelections'), {
          selections: selections,
          email: email,
          timestamp: serverTimestamp()
        });
        console.log('Data successfully inserted into Firestore!');
      } catch (error) {
        console.error('Error inserting data into Firestore:', error);
      }
      analytics.logEvent('user_selection', {
        step: currentStep,
        action: 'next',
      });
    };
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleRestart = () => {
    // Add logic to reset the wizard state and navigate to the first step
    setCurrentStep(1); // Example: setStep(1);
    // Ensure you reset your state and navigate accordingly.
    setEmail('');
    setSelections({
      age: '',
      statut: '',
      charge: '',
      education: '',
      emploi: '',
      revenuA: '',
      revenuS: '',
      patrimoine: '',
      patrimoineV: '',
      situationF: '',
      revenuI: '',
      objectif: '',
      horizon: '',
      connaissance: '',
      expérience: '',
      tolérance: '',
      perte: '',
      perteExp: '',
      réaction: '',
      attitude: '',
      réactionP: '',
      baisse: '',
      gain: '',
      profile: '',
      selectedProfiles: {
        prudent: 0,
        modéré: 0,
        agressif: 0,
      },
    });
  };

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <Container >
      <h1 className="my-4" style={{ color: '#023b6e', fontFamily: 'inherit', fontWeight: 'bold', fontSize: 28 }}></h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {currentStep === 1 && <Step1 selections={selections} setSelections={setSelections} />}
        {currentStep === 2 && <Step2 selections={selections} setSelections={setSelections} />}
        {currentStep === 3 && <Step3 selections={selections} setSelections={setSelections} />}
        {currentStep === 4 && <Step4 selections={selections} setSelections={setSelections} />}
        {currentStep === 5 && <Step5 selections={selections} setSelections={setSelections} />}
        {currentStep === 6 && <Step6 selections={selections} setSelections={setSelections} />}
        {currentStep === 7 && <Step7 selections={selections} setSelections={setSelections} />}
        {currentStep === 8 && <Step8 selections={selections} setSelections={setSelections} />}
        {currentStep === 9 && <Step9 selections={selections} setSelections={setSelections} />}
        {currentStep === 10 && <Step10 selections={selections} setSelections={setSelections} />}
        {currentStep === 11 && <Step11 selections={selections} setSelections={setSelections} />}
        {currentStep === 12 && <Step12 selections={selections} setSelections={setSelections} />}

        {currentStep === 13 && <Step13 selections={selections} setSelections={setSelections} />}
        {currentStep === 14 && <Step14 selections={selections} setSelections={setSelections} />}
        {currentStep === 15 && <Step15 selections={selections} setSelections={setSelections} />}
        {currentStep === 16 && <Step16 selections={selections} setSelections={setSelections} />}
        {currentStep === 17 && <Step17 selections={selections} setSelections={setSelections} />}
        {currentStep === 18 && <Step18 selections={selections} setSelections={setSelections} />}
        {currentStep === 19 && <Step19 selections={selections} setSelections={setSelections} />}
        {currentStep === 20 && <Step20 selections={selections} setSelections={setSelections} />}
        {currentStep === 21 && <Step21 selections={selections} setSelections={setSelections} />}
        {currentStep === 22 && <Step22 selections={selections} setSelections={setSelections} />}
        {currentStep === 23 && <Step23 selections={selections} setSelections={setSelections} />}
        {currentStep === 24 && <Step24 selections={selections} setSelections={setSelections}  />}
        {currentStep === 25 && (
         <Form.Group controlId="formEmail" style={{ maxWidth: '400px', margin: '0 auto' }}>
         <Form.Label style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Veuillez fournir votre adresse e-mail</Form.Label>
         <Form.Control
           type="email"
           placeholder="Entrez votre adresse e-mail"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           style={{ fontSize: '16px', padding: '10px', borderRadius: '5px' }}
         />
         {emailError && (
           <Form.Text style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
             Veuillez entrer une adresse e-mail valide.
           </Form.Text>
         )}
         <Form.Text style={{ fontSize: '14px', color: 'grey', marginTop: '5px' }}>
           Nous respectons votre vie privée et ne partageons jamais vos informations personnelles avec des tiers.
         </Form.Text>
       </Form.Group>
        )} 
       {/*  && (
         <Form.Group controlId="formEmail" style={{ maxWidth: '400px', margin: '0 auto' }}>
         <Form.Label style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Veuillez fournir votre adresse e-mail</Form.Label>
         <Form.Control
           type="email"
           placeholder="Entrez votre adresse e-mail"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           style={{ fontSize: '16px', padding: '10px', borderRadius: '5px' }}
         />
         {emailError && (
           <Form.Text style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
             Veuillez entrer une adresse e-mail valide.
           </Form.Text>
         )}
         <Form.Text style={{ fontSize: '14px', color: 'grey', marginTop: '5px' }}>
           Nous respectons votre vie privée et ne partageons jamais vos informations personnelles avec des tiers.
         </Form.Text>
       </Form.Group>
        )} */}


        {currentStep === 26 && <SuccessPage selections={selections} onRestart={handleRestart} onDownload={constructDownloadData} />}
        {currentStep < 26 && (
          <div className="my-4" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button variant="secondary" onClick={handlePrevious} disabled={currentStep === 1}>
              Question précédente
            </Button>
            <Button variant="primary" onClick={handleNext}>
              {currentStep === 25 ? 'Terminer' : 'Question suivante'}
            </Button>

          </div>
        )}
      </div>
    </Container>
  );
}

export default WizardApp;